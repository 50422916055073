<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <DxForm
        @field-data-changed="formFieldDataChanged"
        :form-data="getUser"
        :ref="formRef"
        :read-only="false"
        :visible="true"
      >
        <DxGroupItem :col-count="2" css-class="group-padding">
          <DxGroupItem>
            <DxSimpleItem
              data-field="employee_id"
              :label="{ text: 'Nama Karyawan' }"
            >
              <!-- <DxSimpleItem data-field="tipe_akses" :label="{ text: 'Level' }">               -->
              <template #default>
                <DxSelectBox
                  :data-source="EmployeeData"
                  :show-clear-button="true"
                  display-expr="full_name"
                  value-expr="id"
                  :value="getUserStatus == 'edit' ? getUser.employee_id : null"
                  :searchEnabled="true"
                  @value-changed="EmployeeSelectedChanged"
                ></DxSelectBox>
              </template>
            </DxSimpleItem>

            <DxSimpleItem
              data-field="warehouse_id"
              :label="{ text: 'Pilih Warehouse' }"
            >
              <template #default>
                <DxSelectBox
                  :data-source="GetWarehouse"
                  display-expr="name"
                  value-expr="id"
                  placeholder="Select Warehouse"
                  @value-changed="valueChangedWarehouse"
                  :search-enabled="true"
                  :value="
                    getUserStatus == 'edit'
                      ? getUser.warehouse_id
                      : null
                  "
                >
                </DxSelectBox>
              </template>
            </DxSimpleItem>

            <DxSimpleItem
              data-field="name"
              :label="{ text: 'Nama User' }"
              :editor-options="{ maxLength: '100' }"
            >
              <DxRequiredRule message="Nama User Wajib di Isi" />
            </DxSimpleItem>

            <DxSimpleItem
              data-field="email"
              :label="{ text: 'Email' }"
              :editor-options="{ maxLength: '150' }"
            >
              <!-- <DxRequiredRule message="email Wajib di Isi" /> -->
            </DxSimpleItem>

            <DxSimpleItem data-field="role_id" :label="{ text: 'Level' }">
              <template #default>
                <DxSelectBox
                  :data-source="RoleData"
                  :show-clear-button="true"
                  display-expr="name"
                  value-expr="id"
                  :value="getUserStatus == 'edit' ? getUser.role_id : null"
                  :searchEnabled="true"
                  @value-changed="RoleSelectedChanged"
                ></DxSelectBox>
              </template>
            </DxSimpleItem>

            <DxSimpleItem
              data-field="password"
              :label="{ text: 'Password' }"
              :editor-options="{ maxLength: '100', mode: 'password' }"
            >
              <DxRequiredRule message="Password Wajib di Isi" />
            </DxSimpleItem>
          </DxGroupItem>
        </DxGroupItem>
        <EmployeeData @employeeData="setEmployeeData" />
        <RoleData @roleData="setRoleData" />
      </DxForm>
    </div>
  </div>
</template>
<script>
import EmployeeData from "@/components/employee/EmployeeData.vue";
import RoleData from "@/components/users/RoleData.vue";
import { DxSelectBox } from "devextreme-vue/select-box";
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from "devextreme-vue/form";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
const formRef = "user-form";

export default {
  components: {
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    DxSelectBox,
    DxRequiredRule,
    RoleData,
    EmployeeData,
  },
  data: function () {
    return {
      formRef,
      TargetTypeData: [],
      vUserData: [],
      RoleData: [],
      EmployeeData: [],
      GetWarehouse: [],
    };
  },
  created() {
    // this.userId = window.localStorage.getItem("usrid");
    // this.currentUUid = this.uuid;
    axios
      .get( "warehouses")
      .then((response) => {
        //console.log(JSON.stringify(response.data));
        this.GetWarehouse = response.data.data;
      });

  },
  mounted() {
    // console.log(
    //   "CustomerUserForm : getCustomer = " +
    //     JSON.stringify(this.getCustomer)
    // );
    // console.log(
    //   "CustomerUserForm : getCustomerStatus = " + this.getStatus
    // );
    this.formSet();
  },
  methods: {
    // ...mapActions("customer", ["setForm"]),
    ...mapActions("users", ["setUser", "setUserForm"]),
        ///////////////////////
    valueChangedWarehouse(e) {
      this.$refs[formRef].instance.updateData({
        warehouse_id: e.value,
      });
    },
    ///////////////////////
    setRoleData(value) {
      this.RoleData = value;
      // console.log("RoleData = " + JSON.stringify(this.RoleData));
    },

    RoleSelectedChanged(e) {
      console.log("role_id = " + e.value);
      this.$refs[formRef].instance.updateData({
        role_id: e.value,
      });
    },
    setEmployeeData(value) {
      this.EmployeeData = value;
      // console.log("EmployeeData = " + JSON.stringify(this.EmployeeData));
    },
    EmployeeSelectedChanged(e) {
      console.log("employee_id = " + e.value);
      // console.log("tipe_akses = " + e.value);
      this.$refs[formRef].instance.updateData({
        // tipe_akses: e.value,
        employee_id: e.value,
      });
    },
    formFieldDataChanged(e) {
      this.setUser(e.component.option("formData"));
      console.log("setUser : " + JSON.stringify(this.getUser));
    },
    formSet() {
      this.setUserForm(this.$refs[formRef].instance);
    },
  },
  computed: {
    ...mapGetters("users", ["getUser", "getUserStatus"]),
  },
};
</script>
<style>
.group-padding {
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.form-block {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  padding-left: 40px;
  padding-right: 40px;
}
</style>

<template>
  <DxForm
    class="form-block"
    :form-data="getStockReturn"
    :read-only="true"
    :ref="formRef"
    :col-count="2"
  >
    <DxGroupItem>
      <DxSimpleItem
        data-field="transaction_number"
        :label="{ text: 'No Transaksi' }"
      />
      <DxSimpleItem
        data-field="received_date"
        :label="{ text: 'Tanggal Terima' }"
      />
      <DxSimpleItem data-field="retur_number" :label="{ text: 'No Retur' }" />
      <DxSimpleItem
        data-field="origin_type_name"
        :label="{ text: 'Tipe Asal' }"
      >
      </DxSimpleItem>
    </DxGroupItem>
    <DxGroupItem>
      <DxSimpleItem data-field="origin_name" :label="{ text: 'Asal' }">
      </DxSimpleItem>

      <DxSimpleItem data-field="destination_name" :label="{ text: 'Tujuan' }">
      </DxSimpleItem>

      <DxSimpleItem data-field="remark" :label="{ text: 'Catatan' }">
      </DxSimpleItem>
    </DxGroupItem>
  </DxForm>
</template>
<script>
import { DxForm, DxSimpleItem, DxGroupItem } from "devextreme-vue/form";
import DxTextArea from "devextreme-vue/text-area";
import { mapGetters, mapActions } from "vuex";

const formRef = "stockreturn-form";

export default {
  components: {
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    // eslint-disable-next-line
    DxTextArea,
  },
  data: function () {
    return {
      formRef,
    };
  },

  methods: {
    ...mapActions("stockreturn", [
      "setStockReturn",
      "clearStockReturn",
      "setStockReturnStatus",
      "clearStockReturnStatus",
    ]),
  },
  computed: {
    ...mapGetters("stockreturn", [
      "getStockReturn",
      "getStockReturnStatus",
      "getStockReturnForm",
    ]),
  },
};
</script>
<style>
.group-padding {
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.form-block {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  padding-left: 40px;
  padding-right: 40px;
}
</style>

<template>
  <DxForm> </DxForm>
</template>

<script>
import {} from "devextreme-vue/data-grid";
// import CustomStore from "devextreme/data/custom_store";
import { DxForm } from "devextreme-vue/form";
// import LjrService from "@/services/LjrService";
// import { mapActions } from "vuex";
import axios from "axios";


export default {
  props: ["level"],
  components: {
    DxForm,
  },
  created() {
    var AUTH_TOKEN = "Bearer " + window.localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;
    var vthis = this;
    console.log("level :" + vthis.level);
    if (vthis.level)
      axios
        .get("master_menus", {
          params: {
            level: vthis.level,
          },
        })
        .then(function (response) {
          // console.log(
          //   "response master_menus :" + JSON.stringify(response.data.data)
          // );
          vthis.$emit("menuData", response.data.data);
        });
    else {
      axios.get("master_menus").then((response) => {
        // console.log("mediaData = " + JSON.stringify(response.data.data));
        // this.res = JSON.stringify(response.data);
        vthis.$emit("menuData", response.data.data);
      });
    }
  },
  data() {
    return {
      res: [],
    };
  },
  methods: {
    // ...mapActions("product", ["setProduct"]),
  },
  mounted() {},
};
</script>

<style>
</style>


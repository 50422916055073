<!---JourneypView -->
<template>
  <DxForm class="form-block" :form-data="getCompany" :read-only="true">
    <DxGroupItem :col-count="2" css-class="group-padding">
      <DxGroupItem>
        <DxSimpleItem data-field="name" :label="{ text: 'Nama' }" />
        <DxSimpleItem data-field="short_name" :label="{ text: 'Short Name' }" />
      </DxGroupItem>
    </DxGroupItem>
  </DxForm>
</template>
<script>
import { DxForm, DxSimpleItem, DxGroupItem } from "devextreme-vue/form";
import DxTextArea from "devextreme-vue/text-area";
import { mapGetters } from "vuex";
// import axios from "axios";


export default {
  components: {
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    // eslint-disable-next-line
    DxTextArea,
  },
  data: function () {
    return {
      Company: [],
      // formRef,
    };
  },
  computed: {
    ...mapGetters("company", ["getCompany"]),
  },
};
</script>
<style>
.group-padding {
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.form-block {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  padding-left: 40px;
  padding-right: 40px;
}
</style>

<template>
  <DxForm
    class="form-block"
    :form-data="getMasterItem"
    :read-only="true"
    :ref="formRef"
    :col-count="2"
  >
    <DxGroupItem>
      <DxSimpleItem data-field="item_code" :label="{ text: 'Item Code' }" />
      <DxSimpleItem data-field="item_name" :label="{ text: 'Item Name' }" />
      <DxSimpleItem
        data-field="item_measurement"
        :label="{ text: 'Item Masurement' }"
      />
      <DxSimpleItem
        data-field="item_size_measurement"
        :label="{ text: 'Item Size Masurement' }"
      />
      <DxSimpleItem
        data-field="item_dimension"
        :label="{ text: 'Item Dimension' }"
      />
    </DxGroupItem>
    <DxGroupItem>
      <DxSimpleItem
        data-field="item_desc"
        editor-type="dxTextArea"
        :editor-options="{ height: 90 }"
        :label="{ text: 'Item Desc' }"
      />
      <DxSimpleItem data-field="item_image" :label="{ text: 'Item Image' }">
        <template #default>
          <img :src="this.getMasterItem.item_image" />
        </template>
      </DxSimpleItem>
    </DxGroupItem>
  </DxForm>
</template>
<script>
import { DxForm, DxSimpleItem, DxGroupItem } from "devextreme-vue/form";
import DxTextArea from "devextreme-vue/text-area";
import { mapGetters, mapActions } from "vuex";

const formRef = "masteritem-form";

export default {
  components: {
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    // eslint-disable-next-line
    DxTextArea,
  },
  data: function () {
    return {
      formRef,
    };
  },

  methods: {
    ...mapActions("masteritem", [
      "setMasterItem",
      "clearMasterItem",
      "setMasterItemStatus",
      "clearMasterItemStatus",
    ]),
  },
  computed: {
    ...mapGetters("masteritem", [
      "getMasterItem",
      "getMasterItemStatus",
      "getMasterItemForm",
    ]),
  },
};
</script>
<style>
.group-padding {
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.form-block {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  padding-left: 40px;
  padding-right: 40px;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"content-block dx-card responsive-paddings"},[_c('DxForm',{ref:_vm.formRef,attrs:{"form-data":_vm.getStockFirstStatus == 'edit' ? _vm.getStockFirst : null,"col-count":2},on:{"field-data-changed":_vm.formFieldDataChanged}},[_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"data-field":"user_id","visible":false,"editor-options":{ value: _vm.userId }}}),_c('DxSimpleItem',{attrs:{"data-field":"uuid","visible":false,"editor-options":{
            value: _vm.currentUUid,
          }}}),_c('DxSimpleItem',{attrs:{"data-field":"warehouse_id","label":{ text: 'Pilih Warehouse' }},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('DxSelectBox',{attrs:{"data-source":_vm.GetWarehouse,"display-expr":"name","value-expr":"id","placeholder":"Select Warehouse","search-enabled":true,"value":_vm.getStockFirstStatus == 'edit'
                  ? _vm.getStockFirst.warehouse_id
                  : null},on:{"value-changed":_vm.valueChangedWarehouse}})]},proxy:true}])}),_c('DxSimpleItem',{attrs:{"data-field":"item_id","label":{ text: 'Pilih Item' }},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('DxSelectBox',{ref:_vm.selectItemRef,attrs:{"data-source":_vm.GetItem,"display-expr":"item_code_name","value-expr":"id","placeholder":"Select Item","show-drop-down-button":true,"search-enabled":true,"value":_vm.getStockFirstStatus == 'edit' ? _vm.getStockFirst.item_id : null},on:{"value-changed":_vm.valueChangedItem,"selection-changed":_vm.selectionChangedItem}},[_c('DxSelectBoxButton',{attrs:{"name":"dropDown","location":"before"}}),_c('DxSelectBoxButton',{attrs:{"name":"clearButton","location":"after","options":{visible:_vm.buttonVisible, icon: 'clear',
                           type: 'danger', onClick: _vm.clearSelection }}})],1)]},proxy:true}])}),_c('DxSimpleItem',{attrs:{"data-field":"qty","label":{ text: 'Jumlah' }}},[_c('DxRequiredRule',{attrs:{"message":"Please fill Item Stock"}})],1)],1),_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"data-field":"item_condition_id","label":{ text: 'Select Item Condition' }},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('DxSelectBox',{attrs:{"data-source":_vm.GetItemCondition,"display-expr":"name","value-expr":"id","placeholder":"Select Item Condition","search-enabled":true,"value":_vm.getStockFirstStatus == 'edit'
                  ? _vm.getStockFirst.item_condition_id
                  : null},on:{"value-changed":_vm.valueChangedItemCondition}})]},proxy:true}])}),_c('DxSimpleItem',{attrs:{"data-field":"batch_number","label":{ text: 'Batch Number' },"editor-type":"dxTextBox","editor-options":_vm.batchNumberOptions}},[_c('DxRequiredRule',{attrs:{"message":"Please fill batch number"}})],1),_c('DxSimpleItem',{attrs:{"data-field":"expired_date","label":{ text: 'Expired Date' },"editor-type":"dxDateBox","editor-options":{ dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ss' }}})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div id="DeptForm">
    <h2 class="content-block">
      {{ getEmployeeStatus == "add" ? "Tambah" : "Ubah" }}
      Karyawans
    </h2>
    <DxToolbar class="padding-block">
      <DxItem
        :options="backButtonOptions"
        widget="dxButton"
        location="before"
      />
      <DxItem
        :options="saveButtonOptions"
        widget="dxButton"
        location="before"
      />
    </DxToolbar>

    <EmployeeForm
      @uploadFile="setUploadFile"
      @cUploadFile="setcUploadFile"
      @Img64File="getImg64File"
      @signFile="setUploadSignFile"
      @signFileName="setSignFileName"
      @signImg64File="getSignImg64File"
    />

    <span v-html="linebreaks"></span>
    <DxToolbar class="padding-block">
      <DxItem
        :options="backButtonOptions"
        widget="dxButton"
        location="before"
      />
      <DxItem
        :options="saveButtonOptions"
        widget="dxButton"
        location="before"
      />
    </DxToolbar>
    <DxLoadPanel
      :position="{ of: '#mainContent' }"
      :visible.sync="loadingVisible"
      :show-indicator="true"
      :show-pane="true"
      :shading="true"
      :close-on-outside-click="false"
      shading-color="rgba(0,0,0,0.4)"
    />
  </div>
</template>
<script>
import EmployeeForm from "@/components/employee/EmployeeForm.vue";

import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import { DxLoadPanel } from "devextreme-vue/load-panel";

import router from "../../router";
import { mapGetters, mapActions } from "vuex";
// import CustomerService from "@/services/CustomerService";
import EmployeeService from "@/services/EmployeeService";
// import { uuid } from 'vue-uuid';
import axios from "axios";

export default {
  components: {
    DxToolbar,
    DxItem,
    EmployeeForm,
    DxLoadPanel,
  },
  data() {
    return {
      linebreaks: "<br>",
      loadingVisible: false,
      backButtonOptions: {
        icon: "chevronleft",
        text: "Kembali",
        onClick: () => {
          router.push("/employee");
        },
      },
      saveButtonOptions: {
        icon: "save",
        text: "Simpan",
        onClick: () => {
          // eslint-disable-next-line no-console
          var validateResult = this.getEmployeeForm.validate();
          if (validateResult.isValid) {
            // var Customer = {
            //   name : this.getCustomer.name,
            //   shortName : this.getCustomer.shortName,
            //   // uniqueId : uuid.v4(),
            //   description : this.getCustomer.description
            // };
            this.userId = window.localStorage.getItem("usrid");
            var Employee = this.getEmployee;
            console.log("Add Employee : " + JSON.stringify(this.getEmployee));
            var vThis = this;

            // if (Employee.is_active == "Aktif") {
            //   Employee.is_active = true;
            // } else {
            //   Employee.is_active = false;
            // }

            if (this.getEmployeeStatus == "add") {
              console.log("Add Employee ");
              this.loadingVisible = true;
              Employee.created_by = this.userId;
              if (this.getEmployee.location_office_id == 1) {
                Employee.company_branch_id = 33;
              }
              console.log("uploadfile add: " + this.uploadfile);
              //UPLOAD FILE
              if (this.uploadfile != null) {
                this.uploadFileSubmit();
              }
              if (this.uploadSignFile != null) {
                this.uploadFileSignSubmit();
              }

              EmployeeService.insert(
                Employee,
                function () {
                  vThis.loadingVisible = false;
                  vThis.clearEmployeeStatus();
                  vThis.clearEmployee();
                  router.push("/employee");
                },
                function () {
                  vThis.loadingVisible = false;
                }
              );
            } else if (this.getEmployeeStatus == "edit") {
              console.log("Edit Employee ");
              Employee.updated_by = this.userId;
              console.log("uploadfile edit: " + this.uploadfile);
              //UPLOAD FILE
              if (this.uploadfile != null) {
                this.uploadFileSubmit();
              }
              if (this.uploadSignFile != null) {
                this.uploadFileSignSubmit();
              }

              EmployeeService.update(
                this.getEmployee.id,
                Employee,
                function () {
                  vThis.loadingVisible = false;
                  vThis.clearEmployeeStatus();
                  vThis.clearEmployee();
                  router.push("/employee");
                },
                function () {
                  vThis.loadingVisible = false;
                }
              );
            }
            // }
          }
        },
      },
      // cuploadfile: null,
      // uploadfile: null,
      // img64: null,
    };
  },
  methods: {
    ...mapActions("employee", [
      "setEmployee",
      "setEmployeeStatus",
      "clearEmployee",
      "clearEmployeeStatus",
    ]),
    //////////////////////////////////////////
    setcUploadFile(value) {
      if (value != null) {
        this.cuploadfile = value;
        console.log("cuploadfile = ", value);
      } else {
        return;
      }
    },
    setUploadFile(value) {
      // var fileData = value.target.files[0];
      var fileData = value;
      this.uploadfile = fileData;
      console.log("setUploadFile = ", value);
    },
    getImg64File(value) {
      this.img64 = value;
      console.log("img64 = ", value);
    },

    uploadFileSubmit() {
      console.log("uploadFileSubmit = ModifyEmployeePages");
      let currentObj = this;
      let token = window.localStorage.getItem("token");
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
      };

      let formData = new FormData();
      formData.append("file", this.uploadfile);
      formData.append("cfile", this.cuploadfile);
      formData.append("img64", this.img64);
      console.log("file : " + this.uploadfile);
      console.log("cfile : " + this.cuploadfile);
      axios
        .post("employees/upload/img/1", formData, config)
        .then(function (response) {
          console.log(response);
          currentObj.success = response.data.success;
        })
        .catch(function (error) {
          console.log(error);
          currentObj.output = error;
        });
    },

    //////////////////////////////////////////
    setUploadSignFile(value) {
      if (value != null) {
        this.uploadSignFile = value;
        console.log("setUploadSignFile = ", value);
      } else {
        return;
      }
    },
    setSignFileName(value) {
      // var fileData = value.target.files[0];
      var fileData = value;
      this.signFileName = fileData;
      console.log("setSignFileName = ", value);
    },
    getSignImg64File(value) {
      this.signImg64 = value;
      console.log("getSignImg64File = ", value);
    },

    uploadFileSignSubmit() {
      console.log("uploadFileSignSubmit = ModifyEmployeePages");
      let currentObj = this;
      let token = window.localStorage.getItem("token");
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
      };

      let formData = new FormData();
      formData.append("file", this.uploadSignFile);
      formData.append("cfile", this.signFileName);
      formData.append("img64", this.signImg64);

      console.log("Sign File : " + this.uploadSignFile);
      console.log("SignFileName : " + this.signFileName);

      axios
        .post("employees/upload/img/1", formData, config)
        .then(function (response) {
          console.log(response);
          currentObj.success = response.data.success;
        })
        .catch(function (error) {
          console.log(error);
          currentObj.output = error;
        });
    },
    //////////////////////////////////////////
  },
  computed: {
    ...mapGetters("employee", [
      "getEmployee",
      "getEmployeeStatus",
      "getEmployeeForm",
    ]),
  },
};
</script>

<style>
.toolbar-label,
.toolbar-label > b {
  font-size: 16px;
}

#products {
  margin-top: 10px;
}

.padding-block {
  padding-left: 20px;
  padding-right: 20px;
}
</style>
<template>
  <div>
    <h2 class="content-block">Stock Out</h2>
    <stock-out-table />
  </div>
</template>

<script>
import StockOutTable from "@/components/stock-out/StockOutTable.vue";
export default {
  components: {
    StockOutTable,
  },
};
</script>
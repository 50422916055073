<template>
  <div id="DeptForm">
    <h2 class="content-block">
      {{ getMasterRoleStatus == "add" ? "Tambah" : "Ubah" }}
      Master Role
    </h2>
    <DxToolbar class="padding-block">
      <DxItem
        :options="backButtonOptions"
        widget="dxButton"
        location="before"
      />
      <DxItem
        :options="saveButtonOptions"
        widget="dxButton"
        location="before"
      />
    </DxToolbar>
    <MasterRoleForm />
    <span v-html="linebreaks"></span>
    <DxToolbar class="padding-block">
      <DxItem
        :options="backButtonOptions"
        widget="dxButton"
        location="before"
      />
      <DxItem
        :options="saveButtonOptions"
        widget="dxButton"
        location="before"
      />
    </DxToolbar>
    <DxLoadPanel
      :position="{ of: '#mainContent' }"
      :visible.sync="loadingVisible"
      :show-indicator="true"
      :show-pane="true"
      :shading="true"
      :close-on-outside-click="false"
      shading-color="rgba(0,0,0,0.4)"
    />
  </div>
</template>
<script>
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import { DxLoadPanel } from "devextreme-vue/load-panel";
import MasterRoleForm from "@/components/master-role/MasterRoleForm.vue";
import router from "../../router";
import { mapGetters, mapActions } from "vuex";
import MasterRoleService from "@/services/MasterRoleService";
// import { uuid } from 'vue-uuid';
// import axios from "axios";


export default {
  components: {
    DxToolbar,
    DxItem,
    DxLoadPanel,
    MasterRoleForm,
  },
  data() {
    return {
      PriceData: [],
      linebreaks: "<br>",
      loadingVisible: false,
      backButtonOptions: {
        icon: "chevronleft",
        text: "Kembali",
        onClick: () => {
          // this.clearCustomer();
          // this.clearStatus();
          this.clearMasterRoleStatus();

          // console.log(
          //   "ModifyMasterRolePages : MasterRole = " +
          //     JSON.stringify(this.getMasterRole.description)
          // );
          // this.clearMasterRole();
          // router.push("/master/masterrole");
          this.$router.go(-1);
        },
      },
      saveButtonOptions: {
        icon: "save",
        text: "Simpan",
        onClick: () => {
          // eslint-disable-next-line no-console
          var validateResult = this.getMasterRoleorm.validate();
          if (validateResult.isValid) {
            // var Customer = {
            //   name : this.getCustomer.name,
            //   shortName : this.getCustomer.shortName,
            //   // uniqueId : uuid.v4(),
            //   description : this.getCustomer.description
            // };

            // var Customer = this.getCustomer;
            this.userId = window.localStorage.getItem("usrid");
            var MasterRole = this.getMasterRole;
            // console.log(
            //   "ModifyMasterRolePages : getCustomer = " +
            //     JSON.stringify(Customer)
            // );
            // console.log(
            //   "ModifyMasterRolePages : getMasterRoleStatus = " +
            //     JSON.stringify(this.getMasterRoleStatus)
            // );
            // console.log(
            //   "ModifyMasterRolePages : getMasterRole = " +
            //     JSON.stringify(MasterRole)
            // );
            var vThis = this;

            if (MasterRole) {
              console.log("MasterRole = " + JSON.stringify(MasterRole));
              // vThis.setContactStatus("add");

              if (this.getMasterRoleStatus == "add") {
                this.loadingVisible = true;

                MasterRole.created_by = this.userId;
                MasterRoleService.insert(
                  MasterRole,
                  function () {
                    vThis.loadingVisible = false;
                    vThis.clearMasterRoleStatus();
                    vThis.clearMasterRole();
                    router.push("/master/masterrole");
                  },
                  function () {
                    vThis.loadingVisible = false;
                  }
                );
              } else if (this.getMasterRoleStatus == "edit") {
                MasterRole.updated_by = this.userId;
                MasterRoleService.update(
                  this.getMasterRole.id,
                  MasterRole,
                  function () {
                    vThis.loadingVisible = false;
                    vThis.clearMasterRoleStatus();
                    vThis.clearMasterRole();
                  },
                  function () {
                    vThis.loadingVisible = false;
                  }
                );
              }
            } else {
              router.push("/master/masterrole");
            }
          }
        },
      },
    };
  },
  methods: {
    ...mapActions("masterrole", [
      "setMasterRole",
      "setMasterRoleStatus",
      "clearMasterRole",
      "clearMasterRoleStatus",
    ]),
  },
  computed: {
    ...mapGetters("masterrole", [
      "getMasterRole",
      "getMasterRoleStatus",
      "getMasterRoleForm",
    ]),
  },
};
</script>

<style>
.toolbar-label,
.toolbar-label > b {
  font-size: 16px;
}

#products {
  margin-top: 10px;
}

.padding-block {
  padding-left: 20px;
  padding-right: 20px;
}
</style>
<template>
  <div>
        <h2 class="content-block">Warehouses</h2>
    <warehouse-table />
  </div>
</template>
<script>
import WarehouseTable from "@/components/warehouse/WarehouseTable.vue";
export default {
  components: {
    WarehouseTable,
  },
};
</script>
<template>
  <div>
    <h2 class="content-block">Stock Report</h2>
    <stock-report-table />
  </div>
</template>

<script>
import StockReportTable from "@/components/stock-report/StockReportTable.vue";
export default {
  components: {
    StockReportTable,
  },
};
</script>
<template>
  <div id="DeptForm">
    <h2 class="content-block">
      {{ getMasterMenuStatus == "add" ? "Tambah" : "Ubah" }}
      Tipe Perjanjian
    </h2>
    <DxToolbar class="padding-block">
      <DxItem
        :options="backButtonOptions"
        widget="dxButton"
        location="before"
      />
      <DxItem
        :options="saveButtonOptions"
        widget="dxButton"
        location="before"
      />
    </DxToolbar>
    <MasterMenuForm />
    <span v-html="linebreaks"></span>
    <DxToolbar class="padding-block">
      <DxItem
        :options="backButtonOptions"
        widget="dxButton"
        location="before"
      />
      <DxItem
        :options="saveButtonOptions"
        widget="dxButton"
        location="before"
      />
    </DxToolbar>
    <DxLoadPanel
      :position="{ of: '#mainContent' }"
      :visible.sync="loadingVisible"
      :show-indicator="true"
      :show-pane="true"
      :shading="true"
      :close-on-outside-click="false"
      shading-color="rgba(0,0,0,0.4)"
    />
  </div>
</template>
<script>
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import { DxLoadPanel } from "devextreme-vue/load-panel";
import MasterMenuForm from "@/components/master-menu/MasterMenuForm.vue";
import router from "../../router";
import { mapGetters, mapActions } from "vuex";
import MasterMenuService from "@/services/MasterMenuService";
// import { uuid } from 'vue-uuid';
// import axios from "axios";


export default {
  components: {
    DxToolbar,
    DxItem,
    DxLoadPanel,
    MasterMenuForm,
  },
  data() {
    return {
      PriceData: [],
      linebreaks: "<br>",
      loadingVisible: false,
      backButtonOptions: {
        icon: "chevronleft",
        text: "Kembali",
        onClick: () => {
          // this.clearCustomer();
          // this.clearStatus();
          this.clearMasterMenuStatus();

          // console.log(
          //   "ModifyMasterMenuPages : MasterMenu = " +
          //     JSON.stringify(this.getMasterMenu.description)
          // );
          // this.clearMasterMenu();
          router.push("/master/mastermenu");
        },
      },
      saveButtonOptions: {
        icon: "save",
        text: "Simpan",
        onClick: () => {
          // eslint-disable-next-line no-console
          var validateResult = this.getMasterMenuForm.validate();
          if (validateResult.isValid) {
            // var Customer = {
            //   name : this.getCustomer.name,
            //   shortName : this.getCustomer.shortName,
            //   // uniqueId : uuid.v4(),
            //   description : this.getCustomer.description
            // };

            // var Customer = this.getCustomer;
            this.userId = window.localStorage.getItem("usrid");
            var MasterMenu = this.getMasterMenu;
            // console.log(
            //   "ModifyMasterMenuPages : getCustomer = " +
            //     JSON.stringify(Customer)
            // );
            // console.log(
            //   "ModifyMasterMenuPages : getMasterMenuStatus = " +
            //     JSON.stringify(this.getMasterMenuStatus)
            // );
            // console.log(
            //   "ModifyMasterMenuPages : getMasterMenu = " +
            //     JSON.stringify(MasterMenu)
            // );
            var vThis = this;

            if (MasterMenu) {
              console.log("MasterMenu = " + JSON.stringify(MasterMenu));
              // vThis.setContactStatus("add");

              if (this.getMasterMenuStatus == "add") {
                this.loadingVisible = true;

                MasterMenu.created_by = this.userId;
                MasterMenuService.insert(
                  MasterMenu,
                  function () {
                    vThis.loadingVisible = false;
                    vThis.clearMasterMenuStatus();
                    vThis.clearMasterMenu();
                    router.push("/master/mastermenu");
                  },
                  function () {
                    vThis.loadingVisible = false;
                  }
                );
  
              } else if (this.getMasterMenuStatus == "edit") {
                MasterMenu.updated_by = this.userId;
                MasterMenuService.update(
                  this.getMasterMenu.id,
                  MasterMenu,
                  function () {
                    vThis.loadingVisible = false;
                    vThis.clearMasterMenuStatus();
                    vThis.clearMasterMenu();
                  },
                  function () {
                    vThis.loadingVisible = false;
                  }
                );
              }
            } else {
              router.push("/master/mastermenu");
            }
          }
        },
      },
    };
  },
  methods: {
    ...mapActions("mastermenu", [
      "setMasterMenu",
      "setMasterMenuStatus",
      "clearMasterMenu",
      "clearMasterMenuStatus",
    ]),

  },
  computed: {
    ...mapGetters("mastermenu", [
      "getMasterMenu",
      "getMasterMenuStatus",
      "getMasterMenuForm",
    ]),
  },
};
</script>

<style>
.toolbar-label,
.toolbar-label > b {
  font-size: 16px;
}

#products {
  margin-top: 10px;
}

.padding-block {
  padding-left: 20px;
  padding-right: 20px;
}
</style>
const state = {
  stockreport: null,
  stockreportStatus: "",
  stockreportForm: null,
};

const getters = {
  getStockReport: (state) => {
    return state.stockreport;
  },
  getStockReportStatus: (state) => {
    return state.stockreportStatus;
  },
  getStockReportForm: (state) => {
    return state.stockreportForm;
  },
};

const mutations = {
  setStockReport: (state, stockreport) => {
    state.stockreport = stockreport;
  },
  clearStockReport: (state) => {
    state.stockreport = null;
  },

  setStockReportStatus: (state, status) => {
    state.stockreportStatus = status;
  },
  clearStockReportStatus: (state) => {
    state.stockreportStatus = "";
  },

  setStockReportForm: (state, status) => {
    state.stockreportForm = status;
  },
  clearStockReportForm: (state) => {
    state.stockreportForm = "";
  },
};

const actions = {
  setStockReport({ commit }, data) {
    commit("setStockReport", data);
  },
  clearStockReport({ commit }) {
    commit("clearStockReport");
  },

  setStockReportStatus({ commit }, status) {
    commit("setStockReportStatus", status);
  },
  clearStockReportStatus({ commit }) {
    commit("clearStockReportStatus");
  },

  setStockReportForm({ commit }, data) {
    commit("setStockReportForm", data);
  },
  clearStockReportForm({ commit }) {
    commit("clearStockReportForm");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <h2>
        Selamat Datang <strong>{{ this.useremail }}, anda login sebagai Admin</strong>
      </h2>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {

  data() {
    return {
      loggedIn: localStorage.getItem("loggedIn"),
      // token: localStorage.getItem("token"),
      // user: [],
      useremail: localStorage.getItem("useremail"),
    };
  },

  created() {},
  methods: {
    ...mapActions("userlogin", ["setUserLogin", "setUserLoginStatus"]),
  },

  //check user logged in or not
  mounted() {
    if (!this.loggedIn) {
      return this.$router.push({ name: "login" });
    }
  },
  computed: {
    ...mapGetters("userlogin", ["getUserLogin", "getUserLoginStatus"]),
  },
};
</script>
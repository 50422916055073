<template>
  <div>
    <dx-data-grid
      class="dx-card wide-card"
      :data-source="dataSource"
      :show-borders="true"
      :focused-row-index="0"
      :focused-row-enabled="true"
      :column-auto-width="true"
      :column-hiding-enabled="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :loadPanel="loadPanel"
      :selected-row-keys="selectedRowKeys"
      @toolbar-preparing="onToolbarPreparing($event)"
      @selection-changed="onSelectionChanged"
      @rowExpanding="onRowExpanding"
      @exporting="onExporting"
      :ref="gridRef"
    >
      <dx-paging :page-size="10" />
      <dx-pager
        :showPageSizeSelector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
        :showNavigationButtons="true"
        infoText="Hal {0} dari {1} ({2} items)"
        :visible="true"
      />
      <dx-filter-row :visible="filterStatus" />
      <DxSelection mode="multiple" />
      <DxColumn data-field="transaction_number" caption="Transaction Number" />
      <DxColumn
        data-field="received_date"
        dataType="date"
        format="dd/MM/yyyy"
        caption="Received Date"
      />
      <DxColumn data-field="mutation_number" caption="Nomor Mutasi" />
      <DxColumn data-field="origin_type_name" caption="Tipe" />
      <DxColumn data-field="origin_name" caption="Asal" />
      <DxColumn data-field="destination_name" caption="Tujuan" />
      <DxColumn data-field="remark" caption="Catatan" />

      <DxMasterDetail :enabled="true" template="masterDetailTemplate" />
      <template #masterDetailTemplate="{ data: StockInDetail }">
        <ChildStockInTable :template-data="StockInDetail" />
      </template>
    </dx-data-grid>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxPager,
  DxPaging,
  DxSelection,
  DxColumn,
  DxFilterRow,
  DxMasterDetail,
} from "devextreme-vue/data-grid";
import CustomStore from "devextreme/data/custom_store";
import StockInService from "@/services/StockInService";
import ChildStockInTable from "@/components/stock-in/ChildStockInTable.vue";
import router from "../../router";
import { mapGetters, mapActions } from "vuex";
import { confirm } from "devextreme/ui/dialog";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";

import axios from "axios";

const gridRef = "stockin-table";

export default {
  // props: ["StockInId"],
  components: {
    DxDataGrid,
    DxPager,
    DxPaging,
    DxSelection,
    DxColumn,
    DxFilterRow,
    DxMasterDetail,
    ChildStockInTable,
  },
  data() {
    const vthis = this;
    const dataSource = {
      store: new CustomStore({
        key: "id",
        load: async function (loadOptions) {
          var sortedBy = "";
          if (loadOptions.sort) {
            sortedBy += loadOptions.sort[0].selector;
            if (loadOptions.sort[0].desc) {
              sortedBy += " desc";
            } else {
              sortedBy += " asc";
            }
          }

          var perPage = loadOptions.take;
          var offset = loadOptions.skip;
          var page = offset / perPage + 1;

          var datax = vthis.fetch({
            perPage: perPage || 10,
            page: page || 1,
            sortedBy: sortedBy,
            parentId: "null",
          });

          return await datax;
        },
      }),
    };

    return {
      filterStatus: false,
      gridRef,
      dataSource: dataSource,
      pageSizes: [5, 10, 15],
      selectedRowKeys: [],
      loadPanel: {
        text: "Memuat...",
      },
      btnAdd: null,
      btnEdit: null,
      btnDelete: null,
      btnView: null,

      tipe: this.tipe,
      custid: null,
      mydata: null,
      FormStockIn: null,
      warehouseId: null,
    };
  },
  created() {
    this.userId = window.localStorage.getItem("usrid");
    this.warehouseId = window.localStorage.getItem("wrhid");
    this.roleid = window.localStorage.getItem("roleid");
    // this.currentUUid = this.uuid;
  },
  methods: {
    ...mapActions("stockin", [
      "setStockIn",
      "clearStockIn",
      "setStockInStatus",
      "clearStockInStatus",
    ]),

    async fetch(params = {}) {
      // eslint-disable-next-line no-console
      if (Object.keys(params).length === 0) {
        params = {
          perPage: 10,
          page: 1,
          // parentId: "null",
        };
      }
      // var results = await StockInService.getData(params);
      if (this.roleid == 5) {
        var results;
        results = await StockInService.getDataByWarehouseId(
          this.warehouseId,
          params
        );
      } else {
        results = await StockInService.getData(params);
      }
      // eslint-disable-next-line no-console
      var datax = await {
        data: results.data,
        totalCount: 10,
        // totalCount: results.total,
      };
      return await datax;
    },

    onSelectionChanged({ selectedRowKeys, selectedRowsData }) {
      // console.log("selectedRowKeys : " + selectedRowKeys.length);
      if (selectedRowKeys.length > 0) {
        if (selectedRowKeys.length == 1) {
          this.setStockIn(selectedRowsData[0]);
          this.btnAdd.option({
            disabled: false,
          });
          this.btnEdit.option({
            disabled: false,
          });
          this.btnView.option({
            disabled: false,
          });
        } else {
          this.setStockIn(selectedRowsData);
          // console.log(selectedRowsData);
          this.btnAdd.option({
            disabled: true,
          });
          this.btnEdit.option({
            disabled: true,
          });
          this.btnView.option({
            disabled: true,
          });
        }
        this.btnDelete.option({
          disabled: false,
        });
      } else {
        this.clearStockIn();
        this.btnAdd.option({
          disabled: true,
        });
        this.btnEdit.option({
          disabled: true,
        });
        this.btnDelete.option({
          disabled: true,
        });
        this.btnView.option({
          disabled: true,
        });
      }
    },
    onToolbarPreparing(e) {
      var vThis = this;

      e.toolbarOptions.items.unshift(
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "add",
            text: "Tambah",
            onInitialized: function (e) {
              vThis.btnAdd = e.component;
            },
            onClick: function () {
              vThis.setStockInStatus("add");
              router.push("/stock/in/add");
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "edit",
            text: "Edit",
            disabled: true,
            onInitialized: function (e) {
              vThis.btnEdit = e.component;
            },
            onClick: function () {
              vThis.setStockInStatus("edit");
              router.push("/stock/in/edit");
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "trash",
            text: "Hapus",
            disabled: true,
            onInitialized: function (e) {
              vThis.btnDelete = e.component;
            },
            onClick: function () {
              let result = confirm(
                "<i>Anda yakin mau menghapus data ini ?</i>",
                "Hapus StockIn StockIn"
              );
              result.then((dialogResult) => {
                if (dialogResult) {
                  var StockInData = vThis.getStockIn;
                  console.log(vThis.getStockIn);
                  var ids = "";
                  if (Array.isArray(StockInData)) {
                    StockInData.forEach(function (item, index) {
                      if (index == 0) {
                        ids += item.id;
                      } else {
                        ids += "," + item.id;
                      }
                    });
                  } else {
                    ids = StockInData.id;
                  }
                  vThis.deleteReport(StockInData.uuid);

                  StockInService.delete(
                    ids,
                    function () {
                      vThis.clearStockIn();
                      vThis.dataGrid.refresh();
                    },
                    function (error) {
                      // eslint-disable-next-line no-console
                      console.log(error);
                    }
                  );
                }
              });
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "detailslayout",
            text: "Detail",
            disabled: true,
            onInitialized: function (e) {
              vThis.btnView = e.component;
            },
            onClick: function () {
              router.push("/stock/in/view");
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "refresh",
            text: "Muat Ulang",
            onClick: function () {
              vThis.dataGrid.refresh();
            },
          },
        },
        // Filter //
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "search",
            text: vThis.filterText,
            disabled: false,
            onInitialized: function (e) {
              vThis.btnFilter = e.component;
            },
            onClick: function () {
              if (vThis.filterStatus === true) {
                vThis.filterStatus = false;
                vThis.filterText = "Filter ON";
                // console.log("filterStatus : " + vThis.filterStatus);
              } else {
                vThis.filterStatus = true;
                vThis.filterText = "Filter Off";
                // console.log("filterStatus : " + vThis.filterStatus);
              }
            },
          },
        }
      );
    },
    async deleteReport(uuid) {
      var vThis = this;
      // var vuuid = uuid;
      /////////////////////////
      try {
        const response1 = await axios.get("stock_reports/getid/" + uuid);
        var reportId = JSON.stringify(response1.data.data[0].id);
        // console.log(
        //   "report_id : " +
        //     JSON.stringify("reportId :" + response1.data.data[0].id)
        // );
      } catch (error) {
        console.error(error);
      }
      /////////////////////////
      try {
        const response2 = await axios.delete("stock_reports/" + reportId);
        // console.log(response2);
        vThis.success = response2.data.success;
      } catch (error) {
        console.error(error);
      }
    },
    onRowExpanding(e) {
      var dataGrid = e.component;
      dataGrid.collapseAll(-1);
    },
    getDataCust() {
      let currentObj = this;
      axios
        .get("stockins/" + this.custid)
        .then(function (response) {
          // console.log(response);
          // currentObj.success = response.data.success;
          // currentObj.mydata = response;
          currentObj.FormStockIn = response.data.data;
          // console.log("mydata = " + JSON.stringify(response.data.data));
          // console.log(
          //   "getStockIn = " + JSON.stringify(currentObj.getStockIn)
          // );
        })
        .catch(function (error) {
          console.log(error);
          currentObj.output = error;
        });
    },

    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Main sheet");
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        customizeCell: function (options) {
          const excelCell = options;
          excelCell.font = { name: "Arial", size: 12 };
          excelCell.alignment = { horizontal: "left" };
        },
      }).then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "data-stockin.xlsx"
          );
        });
      });
      e.cancel = true;
    },
  },
  computed: {
    ...mapGetters("stockin", [
      "getStockIn",
      "getStockInStatus",
      "getStockInForm",
    ]),
    dataGrid: function () {
      return this.$refs[gridRef].instance;
    },
  },
};
</script>

<style>
</style>
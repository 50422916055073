var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"content-block dx-card responsive-paddings"},[_c('DxForm',{ref:_vm.formRef,attrs:{"form-data":_vm.getStockReturnStatus == 'edit' ? _vm.getStockReturn : null,"col-count":2},on:{"field-data-changed":_vm.formFieldDataChanged}},[_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"data-field":"user_id","visible":false,"editor-options":{ value: _vm.userId }}}),_c('DxSimpleItem',{attrs:{"data-field":"uuid","visible":false,"editor-options":{
            value: _vm.currentUUid,
          }}}),_c('DxSimpleItem',{attrs:{"data-field":"received_date","label":{ text: 'Tanggal Retur Barang' },"editor-type":"dxDateBox"}},[_c('DxRequiredRule',{attrs:{"message":"Tanggal Retur Barang Wajib di Isi"}})],1),_c('DxSimpleItem',{attrs:{"data-field":"retur_number","label":{ text: 'Nomor Retur' }}},[_c('DxRequiredRule',{attrs:{"message":"Nomor Mutasi Wajib di Isi"}})],1),_c('DxSimpleItem',{attrs:{"data-field":"origin_type_id","label":{ text: 'Pilih Tipe Asal Retur' }},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('DxSelectBox',{attrs:{"data-source":_vm.GetSourceType,"display-expr":"source_type_name","value-expr":"id","placeholder":"Select Origin Type","search-enabled":true,"value":_vm.getStockReturnStatus == 'edit'
                  ? _vm.getStockReturn.origin_type_id
                  : null},on:{"value-changed":_vm.valueChangedOriginType}})]},proxy:true}])})],1),_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"data-field":"origin_id","label":{ text: 'Pilih Asal Retur' }},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('DxSelectBox',{attrs:{"data-source":_vm.GetOrigin,"display-expr":"name","value-expr":"id","placeholder":"Select Origin","search-enabled":true,"value":_vm.getStockReturnStatus == 'edit'
                  ? _vm.getStockReturn.origin_id
                  : null},on:{"value-changed":_vm.valueChangedOrigin}})]},proxy:true}])}),_c('DxSimpleItem',{attrs:{"data-field":"destination_id","label":{ text: 'Pilih Warehouse Tujuan' }},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('DxSelectBox',{attrs:{"data-source":_vm.GetWarehouseDest,"display-expr":"name","value-expr":"id","placeholder":"Select Warehouse Destination","search-enabled":true,"value":_vm.getStockReturnStatus == 'edit'
                  ? _vm.getStockReturn.destination_id
                  : null},on:{"value-changed":_vm.valueChangedWarehouse}})]},proxy:true}])}),_c('DxSimpleItem',{attrs:{"data-field":"remark","label":{ text: 'Keterangan' }}})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <h2 class="content-block">Stock Out Detail</h2>
    <stock-out-detail-table />
  </div>
</template>

<script>
import StockOutDetailTable from "@/components/stock-out-detail/StockOutDetailTable.vue";
export default {
  components: {
    StockOutDetailTable,
  },
};
</script>
<template>
  <div>
    <h2 class="content-block">Stock Return</h2>
    <stock-return-table />
  </div>
</template>

<script>
import StockReturnTable from "@/components/stock-return/StockReturnTable.vue";
export default {
  components: {
    StockReturnTable,
  },
};
</script>
<!-- JourneyViewPages -->
<template>
  <div id="DeptForm">
    <h2 class="content-block">EmployeeTable View</h2>
    <DxToolbar class="padding-block">
      <DxItem
        :options="backButtonOptions"
        widget="dxButton"
        location="before"
      />
    </DxToolbar>
    <EmployeeView />
    <DxLoadPanel
      :position="{ of: '#mainContent' }"
      :visible.sync="loadingVisible"
      :show-indicator="true"
      :show-pane="true"
      :shading="true"
      :close-on-outside-click="false"
      shading-color="rgba(0,0,0,0.4)"
    />
  </div>
</template>

<script>
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import { DxLoadPanel } from "devextreme-vue/load-panel";
// import notify from 'devextreme/ui/notify';
import EmployeeView from "@/components/employee/EmployeeView.vue";
// import router from "../../router";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    DxToolbar,
    DxItem,
    EmployeeView,
    DxLoadPanel,
  },
  data() {
    return {
      loadingVisible: false,
      backButtonOptions: {
        icon: "chevronleft",
        text: "Kembali",
        onClick: () => {
          this.clearEmployeeStatus();
          this.clearEmployee();
          // router.push("/manager/employee");
          this.$router.go(-1);
        },
      },
    };
  },

  methods: {
    ...mapActions("employee", ["clearEmployee", "clearEmployeeStatus"]),
  },
  computed: {
    ...mapGetters("employee", ["getEmployee", "getEmployeeStatus"]),
  },
};
</script>

<style>
.toolbar-label,
.toolbar-label > b {
  font-size: 16px;
}

#products {
  margin-top: 10px;
}

.padding-block {
  padding-left: 20px;
  padding-right: 20px;
}
</style>
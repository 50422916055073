<template>
  <div id="DeptForm">
    <h2 class="content-block">
      {{ getStockFirstStatus == "add" ? "Tambah" : "Ubah" }}
      Stock First
    </h2>
    <DxToolbar class="padding-block">
      <DxItem
        :options="backButtonOptions"
        widget="dxButton"
        location="before"
      />
      <DxItem
        :options="saveButtonOptions"
        widget="dxButton"
        location="before"
      />
    </DxToolbar>
    <StockFirstForm />
    <span v-html="linebreaks"></span>
    <DxToolbar class="padding-block">
      <DxItem
        :options="backButtonOptions"
        widget="dxButton"
        location="before"
      />
      <DxItem
        :options="saveButtonOptions"
        widget="dxButton"
        location="before"
      />
    </DxToolbar>
    <DxLoadPanel
      :position="{ of: '#mainContent' }"
      :visible.sync="loadingVisible"
      :show-indicator="true"
      :show-pane="true"
      :shading="true"
      :close-on-outside-click="false"
      shading-color="rgba(0,0,0,0.4)"
    />
  </div>
</template>
<script>
import StockFirstForm from "@/components/stock-first/StockFirstForm.vue";
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import { DxLoadPanel } from "devextreme-vue/load-panel";
import router from "../../router";
import { mapGetters, mapActions } from "vuex";
// import { formatDate } from "devextreme/localization";
import StockFirstService from "@/services/StockFirstService";
import moment from "moment";
import axios from "axios";
// import { uuid } from 'vue-uuid';

export default {
  components: {
    DxToolbar,
    DxItem,
    StockFirstForm,
    DxLoadPanel,
  },
  data() {
    return {
      linebreaks: "<br>",
      loadingVisible: false,
      report_id: null,
      backButtonOptions: {
        icon: "chevronleft",
        text: "Kembali",
        onClick: () => {
          this.$router.go(-1);
        },
      },
      saveButtonOptions: {
        icon: "save",
        text: "Simpan",
        onClick: () => {
          // eslint-disable-next-line no-console
          var validateResult = this.getStockFirstForm.validate();
          if (validateResult.isValid) {
            // var StockFirst = {
            //   name : this.getStockFirst.name,
            //   shortName : this.getStockFirst.shortName,
            //   // uniqueId : uuid.v4(),
            //   description : this.getStockFirst.description
            // };
            this.userId = window.localStorage.getItem("usrid");
            var vThis = this;
            var StockFirst = this.getStockFirst;

            if (StockFirst) {
              if (this.getStockFirstStatus == "add") {
                console.log("Add StockFirst StockFirst ");
                this.loadingVisible = true;
                StockFirst.created_by = this.userId;

                //code ini untuk menjaga tanggal tidak berubah pada waktu konversi ke format JSON
                var expired_date = StockFirst.expired_date;
                // var os = expired_date.getTimezoneOffset();
                // expired_date = new Date(
                //   expired_date.getTime() - os * 60 * 1000
                // );
                // expired_date = formatDate(expired_date, "yyyy-MM-dd 23:00:00");
                console.log("expired_date : " + expired_date);
                ////////////////////////////////////////////////////////////
                // StockFirst.expired_date = expired_date;
                StockFirst.transaction_number = this.format_trx_date(
                  new Date()
                );

                ////////////////////////////////////////////////////////////
                let formData = new FormData();
                formData.append("warehouse_id", StockFirst.warehouse_id);
                formData.append("uuid", StockFirst.uuid);
                formData.append(
                  "transaction_number",
                  StockFirst.transaction_number
                );
                formData.append("stock_action", 100); //stock masuk
                formData.append("batch_number", StockFirst.batch_number);
                formData.append("item_id", StockFirst.item_id);
                formData.append("qty", StockFirst.qty);
                formData.append(
                  "item_condition_id",
                  StockFirst.item_condition_id
                );
                formData.append("remark", "firststock");
                formData.append("expired_date", expired_date);

                axios
                  .post("stock_reports", formData)
                  .then(function (response) {
                    console.log(response);
                    vThis.success = response.data.success;
                  })
                  .catch(function (error) {
                    console.log(error);
                    vThis.output = error;
                  });
                ////////////////////////////////////////////////////////////

                StockFirst.received_date = new Date();
                StockFirstService.insert(
                  StockFirst,
                  function () {
                    vThis.loadingVisible = false;
                    vThis.clearStockFirstStatus();
                    vThis.clearStockFirst();
                    router.push("/stock/first");
                  },
                  function () {
                    vThis.loadingVisible = false;
                  }
                );
              } else if (this.getStockFirstStatus == "edit") {
                console.log("Edit StockFirst ");
                console.log("Edit StockFirst " + StockFirst.warehouse_id);
                StockFirst.updated_by = this.userId;

                const param = {
                  warehouse_id: StockFirst.warehouse_id,
                  uuid: StockFirst.uuid,
                  transaction_number: StockFirst.transaction_number,
                  stock_action: 100,
                  batch_number: StockFirst.batch_number,
                  item_id: StockFirst.item_id,
                  qty: StockFirst.qty,
                  item_condition_id: StockFirst.item_condition_id,
                  remark: "firststock",
                  expired_date: StockFirst.expired_date,
                };

                vThis.updateReport(StockFirst.uuid, param);

                StockFirstService.update(
                  this.getStockFirst.id,
                  StockFirst,
                  function () {
                    vThis.loadingVisible = false;
                    vThis.clearStockFirstStatus();
                    vThis.clearStockFirst();
                    router.push("/stock/first");
                  },
                  function () {
                    vThis.loadingVisible = false;
                  }
                );
              }
            }
          }
        },
      },
    };
  },
  methods: {
    ...mapActions("stockfirst", [
      "setStockFirst",
      "clearStockFirst",
      "setStockFirstStatus",
      "clearStockFirstStatus",
    ]),

    async updateReport(uuid, param) {
      // this.vReportId = this.getVehicleRepair.vehicle_report_id;
      var vThis = this;
      // var vuuid = uuid;

      /////////////////////////
      try {
        const response1 = await axios.get("stock_reports?uuid=" + uuid);
        var reportId = JSON.stringify(response1.data.data[0].id);
        console.log(
          "report_id : " +
            JSON.stringify("reportId :" + response1.data.data[0].id)
        );
      } catch (error) {
        console.error(error);
      }
      /////////////////////////
      try {
        const response2 = await axios.put("stock_reports/" + reportId, param);
        console.log(response2);
        vThis.success = response2.data.success;
      } catch (error) {
        console.error(error);
      }

      // const param = { report_status: "In Repair" };
      // const patchresult = await axios.patch(
      //   "vehicle_damage_reports/" + reportId,
      //   param
      // );
      // console.log("patchresult : " + JSON.stringify(patchresult));

    },
    
    format_trx_date(value) {
      if (value) {
        return moment(String(value)).format("YYYYMMDDHHmmss");
      }
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD 20:00:00");
      }
    },
  },
  computed: {
    ...mapGetters("stockfirst", [
      "getStockFirst",
      "getStockFirstStatus",
      "getStockFirstForm",
    ]),
  },
};
</script>

<style>
.toolbar-label,
.toolbar-label > b {
  font-size: 16px;
}

#products {
  margin-top: 10px;
}

.padding-block {
  padding-left: 20px;
  padding-right: 20px;
}
</style>
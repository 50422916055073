<template>
  <DxForm
    class="form-block"
    @field-data-changed="formFieldDataChanged"
    :form-data="getMasterItemStatus == 'edit' ? getMasterItem : null"
    :ref="formRef"
    :col-count="2"
  >
    <DxGroupItem>
      <DxSimpleItem
        data-field="user_id"
        :visible="false"
        :editor-options="{ value: userId }"
      />
      <DxSimpleItem data-field="item_code" :label="{ text: 'Item Code' }">
        <DxRequiredRule message="Item Code Wajib di Isi" />
      </DxSimpleItem>

      <DxSimpleItem data-field="item_name" :label="{ text: 'Item Name' }">
        <DxRequiredRule message="Item Name Wajib di Isi" />
      </DxSimpleItem>

      <DxSimpleItem
        data-field="item_measurement"
        :label="{ text: 'Item measurement' }"
        editor-type="dxNumberBox"
        :editor-options="{
          max: '999',
        }"
      >
        <!-- <DxRequiredRule message="Item measurement Wajib di Isi" /> -->
      </DxSimpleItem>
    </DxGroupItem>

    <DxGroupItem>
      <DxSimpleItem
        data-field="item_size_measurement"
        :label="{ text: 'Item size measurement' }"
      >
        <!-- <DxRequiredRule message="Item measurement Wajib di Isi" /> -->
      </DxSimpleItem>

      <DxSimpleItem
        data-field="item_dimension"
        :label="{ text: 'Item dimension' }"
      >
        <!-- <DxRequiredRule message="Item dimension Wajib di Isi" /> -->
      </DxSimpleItem>

      <DxSimpleItem
        data-field="item_desc"
        editor-type="dxTextArea"
        :editor-options="{ height: 90 }"
        :label="{ text: 'Item Deskripsi' }"
      />
    </DxGroupItem>
  </DxForm>
</template>
<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from "devextreme-vue/form";
import DxTextArea from "devextreme-vue/text-area";
import { mapGetters, mapActions } from "vuex";

const formRef = "masteritem-form";

export default {
  components: {
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    // eslint-disable-next-line
    DxTextArea,
    DxRequiredRule,
  },
  data: function () {
    return {
      userId: null,
      formRef,
    };
  },
  created() {
    this.userId = window.localStorage.getItem("usrid");
  },
  mounted() {
    this.formSet();
  },
  methods: {
    ...mapActions("masteritem", [
      "setMasterItem",
      "clearMasterItem",
      "setMasterItemStatus",
      "clearMasterItemStatus",
      "setMasterItemForm",
    ]),

    formFieldDataChanged(e) {
      this.setMasterItem(e.component.option("formData"));
    },
    formSet() {
      this.setMasterItemForm(this.$refs[formRef].instance);
    },
  },

  computed: {
    ...mapGetters("masteritem", [
      "getMasterItem",
      "getMasterItemStatus",
      "getMasterItemForm",
    ]),
  },
};
</script>
<style>
.group-padding {
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.form-block {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  padding-left: 40px;
  padding-right: 40px;
}
</style>

<template>
  <div>
    <!-- <div class="content-block dx-card responsive-paddings">
      <h2>{{ getCustomer.name }}</h2>
      {{ getCustomer.street }}<br />
      {{ getCustomer.phone }}<br />
      {{ getCustomer.email }}<br />
    </div> -->

    <div class="content-block dx-card responsive-paddings">
      <DxForm
        @field-data-changed="formFieldDataChanged"
        :form-data="getCompany"
        :ref="formRef"
        :read-only="false"
        :visible="true"
      >
        <DxGroupItem :col-count="2" css-class="group-padding">
          <DxGroupItem>
            <DxSimpleItem
              data-field="name"
              :label="{ text: 'Nama Perusahaan' }"
              :visible="true"
              :editor-options="{ maxLength: '100' }"
            />
            <DxSimpleItem
              data-field="short_name"
              :label="{ text: 'Singkatan' }"
              :editor-options="{ maxLength: '50' }"
            />
          </DxGroupItem>
        </DxGroupItem>
      </DxForm>
    </div>
  </div>
</template>
<script>
import { DxForm, DxSimpleItem, DxGroupItem } from "devextreme-vue/form";
import DxTextArea from "devextreme-vue/text-area";
import { mapGetters, mapActions } from "vuex";

const formRef = "customer-form";

export default {
  components: {
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    // eslint-disable-next-line
    DxTextArea,
  },
  data: function () {
    return {
      formRef,
    };
  },

  mounted() {
    this.formSet();
  },
  methods: {
    ...mapActions("company", ["setCompany","setCompanyForm"]),

    formFieldDataChanged(e) {
      this.setCompany(e.component.option("formData"));
    },
    formSet() {
      this.setCompanyForm(this.$refs[formRef].instance);
    },
  },
  computed: {
    ...mapGetters("company", ["getCompany", "getCompanyStatus"]),
  },
};
</script>
<style>
.group-padding {
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.form-block {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  padding-left: 40px;
  padding-right: 40px;
}
</style>

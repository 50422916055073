<template>
  <div>
    <div class="master-detail-caption">Stock In Detail</div>
    <dx-data-grid
      class="dx-card wide-card"
      :data-source="dataSource"
      :show-borders="true"
      :focused-row-index="0"
      :focused-row-enabled="true"
      :column-auto-width="true"
      :column-hiding-enabled="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :loadPanel="loadPanel"
      :selected-row-keys="selectedRowKeys"
      @toolbar-preparing="onToolbarPreparing($event)"
      @selection-changed="onSelectionChanged"
      @rowExpanding="onRowExpanding"
      @exporting="onExporting"
      :ref="gridRef"
    >
      <dx-paging :page-size="10" />
      <dx-pager
        :showPageSizeSelector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
        :showNavigationButtons="true"
        infoText="Hal {0} dari {1} ({2} items)"
        :visible="true"
      />
      <dx-filter-row :visible="filterStatus" />
      <DxSelection mode="multiple" />
      <DxSearchPanel :visible="true" />
      <DxExport :enabled="true" />
      <DxColumn
        data-field="no"
        caption="No"
        :width="70"
        cell-template="nomor"
      />
      <template #nomor="{ data }">
        {{ data.rowIndex + 1 }}
      </template>

      <DxColumn
        data-field="transaction_number"
        width="200"
        caption="No Transaksi"
      />
      <DxColumn data-field="item_name" width="200" caption="Nama Item" />
      <DxColumn data-field="qty" caption="Jumlah" />
      <DxColumn data-field="price" caption="Harga" />
      <DxColumn data-field="batch_number" caption="Batch Number" />
      <DxColumn data-field="item_condition_name" caption="Kondisi" />
      <!-- <DxColumn
        data-field="item_condition"
        caption="Item Condition"
        :customize-text="customizeText"
      /> -->
      <DxColumn
        data-field="expired_date"
        dataType="date"
        format="dd/MM/yyyy"
        caption="Tanggal Expired"
      />
    </dx-data-grid>
    <div class="lastQty">
      Total Jumlah :
      <span class="lastItemQty">{{ totalQty }}</span>
    </div>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxPager,
  DxPaging,
  DxSelection,
  DxColumn,
  DxFilterRow,
  DxExport,
  DxSearchPanel,
} from "devextreme-vue/data-grid";
import CustomStore from "devextreme/data/custom_store";
import StockInDetailService from "@/services/StockInDetailService";

import router from "../../router";
import { mapGetters, mapActions } from "vuex";
import { confirm } from "devextreme/ui/dialog";

import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import axios from "axios";

const gridRef = "StockInDetail-table";

export default {
  // props: ["CustomerId"],
  components: {
    DxDataGrid,
    DxPager,
    DxPaging,
    DxSelection,
    DxColumn,
    DxFilterRow,
    DxExport,
    DxSearchPanel,
  },
  props: {
    templateData: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.userId = window.localStorage.getItem("usrid");
    var my_json = {
      transaction_number: this.templateData.data.transaction_number,
      warehouse_id: this.templateData.data.destination_id,
    };
    this.StockInDetailTemp.push(my_json);
    // this.setStockInDetailTrxNum(this.templateData.data.transaction_number);
    this.setStockInDetailTrxNum(this.StockInDetailTemp);
    this.getTotalQty(this.templateData.data.transaction_number);
    console.log(
      "templateData transaction_number : ",
      this.templateData.data.transaction_number
    );
    console.log("templateData user_id: ", this.templateData.data.user_id);
    // this.setAgreement(this.templateData.data);
  },
  data() {
    const vthis = this;
    const dataSource = {
      store: new CustomStore({
        key: "id",
        load: async function (loadOptions) {
          var sortedBy = "";
          if (loadOptions.sort) {
            sortedBy += loadOptions.sort[0].selector;
            if (loadOptions.sort[0].desc) {
              sortedBy += " desc";
            } else {
              sortedBy += " asc";
            }
          }

          var perPage = loadOptions.take;
          var offset = loadOptions.skip;
          var page = offset / perPage + 1;

          var datax = vthis.fetch({
            perPage: perPage || 10,
            page: page || 1,
            sortedBy: sortedBy,
            parentId: "null",
          });

          return await datax;
        },
      }),
    };
    return {
      gridRef,
      dataSource: dataSource,
      pageSizes: [5, 10, 15],
      selectedRowKeys: [],
      loadPanel: {
        text: "Memuat...",
      },
      btnAdd: null,
      btnEdit: null,
      btnDelete: null,
      btnView: null,
      btnCustFollow: null,
      btnCustPotensial: null,
      btnCustNew: null,
      tipe: this.tipe,
      filterStatus: false,
      filterText: "Filter On",
      StockInDetailTemp: [],
      totalQty: null,
    };
  },
  methods: {
    ...mapActions("stockin", [
      "setStockIn",
      "clearStockIn",
      "setStockInStatus",
      "clearStockInStatus",
      "setStockInForm",
    ]),
    ...mapActions("stockindetail", [
      "setStockInDetail",
      "clearStockInDetail",
      "setStockInDetailStatus",
      "clearStockInDetailStatus",
      "setStockInDetailForm",
      "setStockInDetailTrxNum",
    ]),
    ...mapActions("stockquarantine", [
      "setStockQuarantine",
      "clearStockQuarantine",
      "setStockQuarantineStatus",
      "clearStockQuarantineStatus",
      "setStockQuarantineForm",
    ]),
    ...mapActions("stockdestroy", [
      "setStockDestroy",
      "clearStockDestroy",
      "setStockDestroyStatus",
      "clearStockDestroyStatus",
      "setStockDestroyForm",
    ]),
    customizeText(cellInfo) {
      if (cellInfo.value == 1) {
        return "Good";
      } else {
        return "Not Good";
      }
    },
    async getTotalQty(trx_num) {
      try {
        const response = await axios.get(
          "stock_in_details/qty/trx/" + trx_num
        );
        this.totalQty = response.data.data;
        console.log("totalQty: " + this.totalQty);
      } catch (error) {
        console.error(error);
      }
    },
    async fetch(params = {}) {
      // eslint-disable-next-line no-console
      if (Object.keys(params).length === 0) {
        params = {
          perPage: 10,
          page: 1,
          // parentId: "null",
        };
      }
      // var results = await StockInService.getData(params);
      var results = await StockInDetailService.getStockInDetailDataByTrxNumber(
        this.templateData.data.user_id,
        this.templateData.data.transaction_number,
        params
      );
      // eslint-disable-next-line no-console
      var datax = await {
        data: results.data,
        totalCount: 10,
        // totalCount: results.total,
      };
      return await datax;
    },

    onSelectionChanged({ selectedRowKeys, selectedRowsData }) {
      // console.log("selectedRowKeys : " + selectedRowKeys.length);
      if (selectedRowKeys.length > 0) {
        if (selectedRowKeys.length == 1) {
          this.setStockInDetail(selectedRowsData[0]);
          this.setStockQuarantine(selectedRowsData[0]);
          this.setStockDestroy(selectedRowsData[0]);
          // this.setStockQuarantine(selectedRowsData[0]);
          console.log(
            "getStockInDetail : " + JSON.stringify(this.getStockInDetail)
          );
          this.btnAdd.option({
            disabled: false,
          });
          this.btnEdit.option({
            disabled: false,
          });
          this.btnView.option({
            disabled: false,
          });
          this.btnStockQuarantine.option({
            disabled: false,
          });
          this.btnStockDestroy.option({
            disabled: false,
          });
        } else {
          this.setStockIn(selectedRowsData);
          // console.log(selectedRowsData);
          this.btnAdd.option({
            disabled: true,
          });
          this.btnEdit.option({
            disabled: true,
          });
          this.btnView.option({
            disabled: true,
          });
          this.btnStockQuarantine.option({
            disabled: true,
          });
          this.btnStockDestroy.option({
            disabled: true,
          });
        }
        this.btnDelete.option({
          disabled: false,
        });
      } else {
        this.clearStockIn();
        this.btnAdd.option({
          disabled: true,
        });
        this.btnEdit.option({
          disabled: true,
        });
        this.btnDelete.option({
          disabled: true,
        });
        this.btnView.option({
          disabled: true,
        });
        this.btnStockQuarantine.option({
          disabled: true,
        });
        this.btnStockDestroy.option({
          disabled: true,
        });
      }
    },
    onToolbarPreparing(e) {
      var vThis = this;

      e.toolbarOptions.items.unshift(
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "add",
            text: "Tambah",
            onInitialized: function (e) {
              vThis.btnAdd = e.component;
            },
            onClick: function () {
              vThis.setStockInDetailStatus("add");
              router.push("/stock/in/detail/add");
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "edit",
            text: "Edit",
            disabled: true,
            visible: this.roleid == 5 ? false : true, //jika role bukan user maka dihide
            onInitialized: function (e) {
              vThis.btnEdit = e.component;
            },
            onClick: function () {
              vThis.setStockInDetailStatus("edit");
              router.push("/stock/in/detail/edit");
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "trash",
            text: "Hapus",
            disabled: true,
            visible: this.roleid == 5 ? false : true, //jika role bukan user maka dihide
            onInitialized: function (e) {
              vThis.btnDelete = e.component;
            },
            onClick: function () {
              let result = confirm(
                "<i>Anda yakin mau menghapus data ini ?</i>",
                "Hapus StockIn StockIn"
              );
              result.then((dialogResult) => {
                if (dialogResult) {
                  var StockInDetail = vThis.getStockInDetail;
                  console.log(vThis.getStockInDetail);
                  var ids = "";
                  if (Array.isArray(StockInDetail)) {
                    StockInDetail.forEach(function (item, index) {
                      if (index == 0) {
                        ids += item.id;
                      } else {
                        ids += "," + item.id;
                      }
                    });
                  } else {
                    ids = StockInDetail.id;
                  }

                  vThis.deleteReport(StockInDetail.uuid);

                  StockInDetailService.delete(
                    ids,
                    function () {
                      vThis.clearStockInDetail();
                      vThis.dataGrid.refresh();
                    },
                    function (error) {
                      // eslint-disable-next-line no-console
                      console.log(error);
                    }
                  );
                }
              });
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "detailslayout",
            text: "Detail",
            disabled: true,
            onInitialized: function (e) {
              vThis.btnView = e.component;
            },
            onClick: function () {
              router.push("/stock/in/detail/view");
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "refresh",
            text: "Muat Ulang",
            onClick: function () {
              vThis.dataGrid.refresh();
            },
          },
        },
        // Filter //
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "search",
            text: vThis.filterText,
            disabled: false,
            visible: false,
            onInitialized: function (e) {
              vThis.btnFilter = e.component;
            },
            onClick: function () {
              if (vThis.filterStatus === true) {
                vThis.filterStatus = false;
                vThis.filterText = "Filter ON";
              } else {
                vThis.filterStatus = true;
                vThis.filterText = "Filter Off";
              }
            },
          },
        },
        // Stock Quarantine //
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "lock",
            text: "Karantina",
            disabled: true,
            visible: false,
            onInitialized: function (e) {
              vThis.btnStockQuarantine = e.component;
            },
            onClick: function () {
              vThis.setStockQuarantineStatus("addFromStockIn");
              router.push("/stock/quarantine/edit");
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "cancel1",
            text: "Destroy",
            disabled: true,
            visible: false,
            onInitialized: function (e) {
              vThis.btnStockDestroy = e.component;
            },
            onClick: function () {
              vThis.setStockDestroyStatus("addFromStockIn");
              router.push("/stock/destroy/edit");
            },
          },
        }
      );
    },
    async deleteReport(uuid) {
      var vThis = this;
      // var vuuid = uuid;
      /////////////////////////
      try {
        const response1 = await axios.get("stock_reports?uuid=" + uuid);
        var reportId = JSON.stringify(response1.data.data[0].id);
        // console.log(
        //   "report_id : " +
        //     JSON.stringify("reportId :" + response1.data.data[0].id)
        // );
      } catch (error) {
        console.error(error);
      }
      /////////////////////////
      try {
        const response2 = await axios.delete("stock_reports/" + reportId);
        // console.log(response2);
        vThis.success = response2.data.success;
      } catch (error) {
        console.error(error);
      }
    },
    onRowExpanding(e) {
      var dataGrid = e.component;
      dataGrid.collapseAll(-1);
    },
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Main sheet");
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        customizeCell: function (options) {
          const excelCell = options;
          excelCell.font = { name: "Arial", size: 12 };
          excelCell.alignment = { horizontal: "left" };
        },
      }).then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "data-stock-in-detail.xlsx"
          );
        });
      });
      e.cancel = true;
    },
  },
  computed: {
    ...mapGetters("stockin", [
      "getStockIn",
      "getStockInStatus",
      "getStockInForm",
    ]),

    ...mapGetters("stockindetail", [
      "getStockInDetail",
      "getStockInDetailStatus",
      "getStockInDetailForm",
    ]),

    ...mapGetters("stockquarantine", [
      "getStockQuarantine",
      "getStockQuarantineStatus",
      "getStockQuarantineForm",
    ]),

    ...mapGetters("stockdestroy", [
      "getStockDestroy",
      "getStockDestroyStatus",
      "getStockDestroyForm",
    ]),
    dataGrid: function () {
      return this.$refs[gridRef].instance;
    },
  },
};
</script>

<style>
.master-detail-caption {
  padding: 0 0 5px 10px;
  font-size: 14px;
  font-weight: bold;
}
</style>
<!---JourneypView -->
<template>
  <DxForm class="form-block" :form-data="getRoleMenu" :read-only="true">
    <DxGroupItem :col-count="2" css-class="group-padding">
      <DxGroupItem>
        <!-- <DxSimpleItem
          data-field="role_id"
          :label="{ text: 'role_id' }"
          :editor-options="{ maxLength: '20' }"
        /> -->
        <DxSimpleItem
          data-field="role_name"
          :label="{ text: 'role_name' }"
          :editor-options="{ maxLength: '50' }"
        />
        <!-- <DxSimpleItem
          data-field="menu_id"
          :label="{ text: 'menu_id' }"
          :editor-options="{ maxLength: '50' }"
        /> -->
        <DxSimpleItem
          data-field="menu_name"
          :label="{ text: 'menu_name' }"
          :editor-options="{ maxLength: '50' }"
        />
      </DxGroupItem>
    </DxGroupItem>
  </DxForm>
</template>
<script>
import { DxForm, DxSimpleItem, DxGroupItem } from "devextreme-vue/form";
import DxTextArea from "devextreme-vue/text-area";
import { mapGetters } from "vuex";
// import axios from "axios";


export default {
  components: {
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    // eslint-disable-next-line
    DxTextArea,
  },
  data: function () {
    return {
      rolemenu: [],
      // formRef,
    };
  },
  computed: {
    ...mapGetters("rolemenu", ["getRoleMenu"]),
  },
};
</script>
<style>
.group-padding {
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.form-block {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  padding-left: 40px;
  padding-right: 40px;
}
</style>

<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      Stock Activity
      <dx-data-grid
        class="dx-card wide-card"
        :data-source="dataSourceReport.length > 0 ? dataSourceReport : dataNull"
        :show-borders="true"
        :focused-row-index="0"
        :focused-row-enabled="true"
        :column-auto-width="true"
        :column-hiding-enabled="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :loadPanel="loadPanel"
        :selected-row-keys="selectedRowKeys"
        @toolbar-preparing="onToolbarPreparing($event)"
        @selection-changed="onSelectionChanged"
        @rowExpanding="onRowExpanding"
        @exporting="onExporting"
        :ref="gridRef"
        :onContentReady="onContentReady1"
      >
        <dx-paging :page-size="10" />
        <dx-pager
          :showPageSizeSelector="true"
          :allowed-page-sizes="pageSizes"
          :show-info="true"
          :showNavigationButtons="true"
          infoText="Hal {0} dari {1} ({2} items)"
          :visible="true"
        />
        <dx-filter-row :visible="filterStatus" />
        <DxSelection mode="multiple" />
        <DxColumn data-field="transaction_number" caption="No Trx" />
        <!-- <DxColumn
          data-field="trx_date"
          dataType="date"
          format="dd/MM/yyyy"
          caption="Tgl Trx"
        /> -->

        <DxColumn data-field="inv_num" caption="No Invoice" />
        <DxColumn data-field="batch_number" caption="No Batch" />
        <DxColumn
          data-field="stock_action"
          caption="Tipe"
          width="70"
          :customize-text="stock_action_customizeText"
        />
        <DxColumn data-field="item_name" caption="Item" />
        <DxColumn data-field="qty" caption="Jumlah" />
        <DxColumn data-field="item_condition_name" caption="Kondisi" />
        <DxColumn data-field="remark" caption="Catatan" />
        <DxColumn
          data-field="expired_date"
          dataType="date"
          format="dd/MM/yyyy"
          caption="expired_date"
        />
        <DxExport :enabled="true" />
        <template #pilihWarehouse>
          <div class="mytitle">Warehouse :</div>
        </template>

        <template #pilihItem>
          <div class="mytitle">Item :</div>
        </template>

        <template #warehouse>
          <div class="selectbox">
            <span class="b">
              <DxSelectBox
                :data-source="GetWarehouse"
                :width="200"
                :show-clear-button="true"
                display-expr="name"
                value-expr="id"
                placeholder="Select Warehouse"
                :searchEnabled="true"
                @value-changed="warehouseSelectedChanged"
              ></DxSelectBox>
            </span>
          </div>
        </template>

        <template #mItem>
          <div class="selectbox">
            <span class="b">
              <DxSelectBox
                :data-source="GetItem"
                :width="300"
                display-expr="item_code_name"
                value-expr="id"
                placeholder="Select Item"
                @value-changed="itemSelectedChanged"
                :search-enabled="true"
              >
              </DxSelectBox>
            </span>
          </div>
        </template>
      </dx-data-grid>
      <div class="lastQty">
        Sisa Stok yang Tersedia :
        <span class="lastItemQty">{{ lastItemQty }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxPager,
  DxPaging,
  DxSelection,
  DxColumn,
  DxFilterRow,
  DxExport,
} from "devextreme-vue/data-grid";
// import { DxTreeList, DxHeaderFilter } from "devextreme-vue/tree-list";
import CustomStore from "devextreme/data/custom_store";
import StockReportService from "@/services/StockReportService";

// import router from "../../router";
import { mapGetters, mapActions } from "vuex";
// import { confirm } from "devextreme/ui/dialog";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { exportDataGrid } from "devextreme/excel_exporter";
import { DxSelectBox } from "devextreme-vue/select-box";

import { Workbook } from "exceljs";
import saveAs from "file-saver";

import axios from "axios";

axios.defaults.headers.common["Authorization"] =
  "Bearer " + window.localStorage.getItem("token");
const gridRef = "stockreport-table";

export default {
  // props: ["StockReportId"],
  components: {
    DxDataGrid,
    DxPager,
    DxPaging,
    DxSelection,
    DxColumn,
    DxFilterRow,
    DxExport,
    // DxTreeList,
    // DxHeaderFilter,
    DxSelectBox,
    // DxLoadPanel
  },
  data() {
    const vthis = this;
    const dataSource = {
      store: new CustomStore({
        key: "id",
        load: async function (loadOptions) {
          var sortedBy = "";
          if (loadOptions.sort) {
            sortedBy += loadOptions.sort[0].selector;
            if (loadOptions.sort[0].desc) {
              sortedBy += " desc";
            } else {
              sortedBy += " asc";
            }
          }

          var perPage = loadOptions.take;
          var offset = loadOptions.skip;
          var page = offset / perPage + 1;

          var datax = vthis.fetch({
            perPage: perPage || 10,
            page: page || 1,
            sortedBy: sortedBy,
            parentId: "null",
          });

          return await datax;
        },
      }),
    };

    return {
      filterStatus: false,
      gridRef,
      dataSource: dataSource,
      pageSizes: [5, 10, 15],
      selectedRowKeys: [],
      loadPanel: {
        text: "Memuat...",
      },
      btnAdd: null,
      btnEdit: null,
      btnDelete: null,
      btnView: null,

      tipe: this.tipe,
      custid: null,
      mydata: null,
      FormStockReport: null,
      expandedRowKeys: [1, 2],
      // selectedRowKeys: [1, 29, 42],

      GetSourceType: [],
      GetWarehouse: [],
      GetOrigin: [],
      GetItem: [],
      GetItemCondition: [],
      warehouseId: null,
      lastItemQty: null,
      itemName: "",
      warehouseName: "",
      dataNull: [
        {
          warehouse_name: "N/A",
          transaction_number: "N/A",
          uuid: "N/A",
          batch_number: "N/A",
          stock_action: "N/A",
          item_name: "N/A",
          qty: "N/A",
          item_condition_name: "N/A",
        },
      ],

      dataSourceReportNull: [
        {
          item_code: "N/A",
          item_name: "N/A",
          total_first_stock: "N/A",
          total_return: "N/A",
          total_item_in: "N/A",
          total_item_out: "N/A",
          total_item_good: "N/A",
          total_item_quarantine: "N/A",
          total_item_destroy: "N/A",
          total_item: "N/A",
        },
      ],
      dataSourceReport: [],
      dataSourceReportTmp: [],
    };
  },
  created() {
    // this.userId = window.localStorage.getItem("usrid");
    // this.currentUUid = this.uuid;
    // axios
    //   .get( "source_types")
    //   .then((response) => {
    //     this.GetSourceType = response.data.data;
    //   });

    let currentUrl = window.location.origin;

    if (currentUrl.includes("localhost")) {
      axios.defaults.baseURL = "http://127.0.0.1:84/api/";
      // axios.defaults.baseURL = "http://bewms.local:10080/api/";
    } else if (currentUrl.includes("10.1.10.21")) {
      axios.defaults.baseURL = "http://10.1.10.21:84/api/";
    } else {
      axios.defaults.baseURL = process.env.VUE_APP_AUTH_API_URL;
    }

    this.roleid = window.localStorage.getItem("roleid");
    this.warehouseId = window.localStorage.getItem("wrhid");
    if (this.roleid == 5) {
      axios
        .get(
           "warehouses/id/" + this.warehouseId
        )
        .then((response) => {
          this.GetWarehouse = response.data.data;
        });
    } else {
      axios
        .get( "warehouses")
        .then((response) => {
          this.GetWarehouse = response.data.data;
        });
    }

    // axios
    //   .get( "warehouses")
    //   .then((response) => {
    //     //console.log(JSON.stringify(response.data));
    //     this.GetWarehouse = response.data.data;
    //   });
    axios
      .get( "master_items")
      .then((response) => {
        var tempData = response.data.data;
        var itemData = [];
        tempData.forEach(function (item) {
          item.item_code_name = item.item_code +" "+item.item_name;
          itemData.push(item);
        });
        this.GetItem = itemData;
      });
  },
  methods: {
    ...mapActions("stockreport", [
      "setStockReport",
      "clearStockReport",
      "setStockReportStatus",
      "clearStockReportStatus",
    ]),
    warehouseSelectedChanged(e) {
      console.log("e : " + e.value);
      this.myParam = {
        warehouse_id: e.value,
      };
      this.warehouseId = e.value;
      // this.getData(this.myParam);
      this.showLoadPanel();
    },
    // warehouseSelectedChanged2(e) {
    //   console.log("e : " + e.value);
    //   this.myParam = {
    //     warehouse_id: e.value,
    //   };
    //   // let warehouse_id = e.value;
    //   this.getReportByWarehouseId2(myParam);
    //   this.showLoadPanel();
    // },
    itemSelectedChanged(e) {
      console.log("e : " + e.value);
      this.myParam = {
        warehouse_id: this.warehouseId,
        item_id: e.value,
      };
      // let item_id = e.value;
      this.getData(this.myParam);
      this.getLastItemQty(this.warehouseId, e.value);
      this.showLoadPanel();
    },
    showLoadPanel() {
      this.loadingVisible = true;
    },

    onContentReady1() {
      this.loadingVisible = false;
    },

    async getData(param) {
      // const param = { dateType: vDateType, yearPickup: vYearPickup };
      try {
        const response = await axios.get("stock_reports", {
          params: param,
        });
        this.dataSourceReport = response.data.data;
        // console.log(
        //   "dataSourceReport: " + JSON.stringify(this.dataSourceReport)
        // );
      } catch (error) {
        console.error(error);
      }
    },
    async getLastItemQty(warehouse_id, item_id) {
      try {
        const response = await axios.get(
          "stock_reports/wrh/" + warehouse_id + "/item/" + item_id
        );
        this.lastItemQty = response.data.data[0].last_item_qty;
        this.itemName = response.data.data[0].item_name;
        this.warehouseName = response.data.data[0].warehouse_name;

        console.log("lastItemQty: " + JSON.stringify(this.lastItemQty));
      } catch (error) {
        console.error(error);
      }
    },
    async getReportByWarehouseId(warehouse_id) {
      try {
        const response = await axios.get("stock_reports/wrh/" + warehouse_id);
        this.dataSourceReport = response.data.data;
        // console.log(
        //   "dataSourceReportTmp: " + JSON.stringify(this.dataSourceReportTmp)
        // );
      } catch (error) {
        console.error(error);
      }
    },

    async getReportByItemId(item_id) {
      try {
        const response = await axios.get("stock_reports/item/" + item_id);
        this.dataSourceReportTmp = response.data.data;
        // console.log(
        //   "dataSourceReportTmp: " + JSON.stringify(this.dataSourceReportTmp)
        // );
      } catch (error) {
        console.error(error);
      }
    },

    stock_action_customizeText(cellInfo) {
      // if (cellInfo.value == 1) {
      //   return "In";
      // } else {
      //   return "Out";
      // }

      switch (cellInfo.value) {
        case 100:
          return "In";
        case 110:
          return "In";

        case 120:
          return "In";

        case 200:
          return "Out";

        case 210:
          return "Out";

        case 220:
          return "Out";

        default:
          return "In";
      }
    },
    async fetch(params = {}) {
      // eslint-disable-next-line no-console
      if (Object.keys(params).length === 0) {
        params = {
          perPage: 10,
          page: 1,
          // parentId: "null",
        };
      }
      var results = await StockReportService.getData(params);
      // eslint-disable-next-line no-console
      var datax = await {
        data: results.data,
        totalCount: 10,
        // totalCount: results.total,
      };
      return await datax;
    },

    onSelectionMenuChanged({ selectedRowKeys, selectedRowsData }) {
      // console.log("selectedRowKeys.length : " + selectedRowKeys.length);
      if (selectedRowKeys.length > 0) {
        // this.setMasterMenu(selectedRowsData);
        console.log(JSON.stringify(selectedRowsData));
        console.log(JSON.stringify(selectedRowKeys));
      }

      // // console.log(JSON.stringify(this.OfferingPrice.length));
      //  this.setOfferingLetterPrice(this.OfferingPrice);
    },
    onSelectionChanged({ selectedRowKeys, selectedRowsData }) {
      // console.log("selectedRowKeys : " + selectedRowKeys.length);
      if (selectedRowKeys.length > 0) {
        if (selectedRowKeys.length == 1) {
          this.setStockReport(selectedRowsData[0]);
          this.btnAdd.option({
            disabled: false,
          });
          this.btnEdit.option({
            disabled: false,
          });
          this.btnView.option({
            disabled: false,
          });
        } else {
          this.setStockReport(selectedRowsData);
          // console.log(selectedRowsData);
          this.btnAdd.option({
            disabled: true,
          });
          this.btnEdit.option({
            disabled: true,
          });
          this.btnView.option({
            disabled: true,
          });
        }
        this.btnDelete.option({
          disabled: false,
        });
      } else {
        this.clearStockReport();
        this.btnAdd.option({
          disabled: true,
        });
        this.btnEdit.option({
          disabled: true,
        });
        this.btnDelete.option({
          disabled: true,
        });
        this.btnView.option({
          disabled: true,
        });
      }
    },
    onToolbarPreparing(e) {
      // var vThis = this;

      e.toolbarOptions.items.unshift(
        // {
        //   location: "before",
        //   template: "titlereport",
        // },
        {
          location: "before",
          template: "pilihWarehouse",
        },
        {
          location: "before",
          template: "warehouse",
        },
        {
          location: "before",
          template: "pilihItem",
        },
        {
          location: "before",
          template: "mItem",
        }

        // {
        //   location: "before",
        //   widget: "dxSelectBox",
        //   options: {
        //     icon: "detailslayout",
        //     text: "Tampilkan Detail",
        //     width: 100,
        //     items: [
        //       {
        //         value: "year",
        //         text: "Year",
        //       },
        //       {
        //         value: "month",
        //         text: "Month",
        //       },
        //       {
        //         value: "quarter",
        //         text: "Quartal",
        //       },
        //       {
        //         value: "semester",
        //         text: "Semester",
        //       },
        //     ],
        //     displayExpr: "text",
        //     valueExpr: "value",
        //     // value: "year",
        //     onValueChanged: this.groupChanged.bind(this),
        //   },
        // },

        // {
        //   location: "before",
        //   widget: "dxButton",
        //   options: {
        //     icon: "add",
        //     text: "Tambah",
        //     onInitialized: function (e) {
        //       vThis.btnAdd = e.component;
        //     },
        //     onClick: function () {
        //       vThis.setStockReportStatus("add");
        //       router.push("/stock/in/add");
        //     },
        //   },
        // },

        // {
        //   location: "before",
        //   widget: "dxButton",
        //   options: {
        //     icon: "edit",
        //     text: "Edit",
        //     disabled: true,
        //     onInitialized: function (e) {
        //       vThis.btnEdit = e.component;
        //     },
        //     onClick: function () {
        //       vThis.setStockReportStatus("edit");
        //       router.push("/stock/in/edit");
        //     },
        //   },
        // },

        // {
        //   location: "before",
        //   widget: "dxButton",
        //   options: {
        //     icon: "trash",
        //     text: "Hapus",
        //     disabled: true,
        //     onInitialized: function (e) {
        //       vThis.btnDelete = e.component;
        //     },
        //     onClick: function () {
        //       let result = confirm(
        //         "<i>Anda yakin mau menghapus data ini ?</i>",
        //         "Hapus StockReport StockReport"
        //       );
        //       result.then((dialogResult) => {
        //         if (dialogResult) {
        //           var StockReportData = vThis.getStockReport;
        //           console.log(vThis.getStockReport);
        //           var ids = "";
        //           if (Array.isArray(StockReportData)) {
        //             StockReportData.forEach(function (item, index) {
        //               if (index == 0) {
        //                 ids += item.id;
        //               } else {
        //                 ids += "," + item.id;
        //               }
        //             });
        //           } else {
        //             ids = StockReportData.id;
        //           }
        //           StockReportService.delete(
        //             ids,
        //             function () {
        //               vThis.clearStockReport();
        //               vThis.dataGrid.refresh();
        //             },
        //             function (error) {
        //               // eslint-disable-next-line no-console
        //               console.log(error);
        //             }
        //           );
        //         }
        //       });
        //     },
        //   },
        // },

        // {
        //   location: "before",
        //   widget: "dxButton",
        //   options: {
        //     icon: "detailslayout",
        //     text: "Detail",
        //     disabled: true,
        //     onInitialized: function (e) {
        //       vThis.btnView = e.component;
        //     },
        //     onClick: function () {
        //       router.push("/stock/report/view");
        //     },
        //   },
        // },
        // {
        //   location: "before",
        //   widget: "dxButton",
        //   options: {
        //     icon: "refresh",
        //     text: "Muat Ulang",
        //     onClick: function () {
        //       vThis.dataGrid.refresh();
        //     },
        //   },
        // },
        // Filter //
        // {
        //   location: "before",
        //   widget: "dxButton",
        //   options: {
        //     icon: "search",
        //     text: vThis.filterText,
        //     disabled: false,
        //     onInitialized: function (e) {
        //       vThis.btnFilter = e.component;
        //     },
        //     onClick: function () {
        //       if (vThis.filterStatus === true) {
        //         vThis.filterStatus = false;
        //         vThis.filterText = "Filter ON";
        //         // console.log("filterStatus : " + vThis.filterStatus);
        //       } else {
        //         vThis.filterStatus = true;
        //         vThis.filterText = "Filter Off";
        //         // console.log("filterStatus : " + vThis.filterStatus);
        //       }
        //     },
        //   },
        // }
      );
    },
    groupChanged(e) {
      console.log("log e : " + e.value);
    },
    onRowExpanding(e) {
      var dataGrid = e.component;
      dataGrid.collapseAll(-1);
    },
    getDataCust() {
      let currentObj = this;
      axios
        .get("stockreports/" + this.custid)
        .then(function (response) {
          // console.log(response);
          // currentObj.success = response.data.success;
          // currentObj.mydata = response;
          currentObj.FormStockReport = response.data.data;
          // console.log("mydata = " + JSON.stringify(response.data.data));
          // console.log(
          //   "getStockReport = " + JSON.stringify(currentObj.getStockReport)
          // );
        })
        .catch(function (error) {
          console.log(error);
          currentObj.output = error;
        });
    },

    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Workbook");
      // const worksheet = workbook
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        topLeftCell: { row: 6, column: 1 },
      })
        .then((cellRange) => {
          // header
          const headerRow2 = worksheet.getRow(2);
          const headerRow3 = worksheet.getRow(3);
          const headerRow4 = worksheet.getRow(4);

          headerRow2.height = 30;
          worksheet.mergeCells(2, 1, 2, 8);

          headerRow2.getCell(1).value = "Stok Card";
          headerRow2.getCell(1).font = { name: "Segoe UI Light", size: 22 };
          headerRow2.getCell(1).alignment = { horizontal: "center" };

          headerRow3.getCell(1).value = "Warehouse : ";
          headerRow3.getCell(1).font = { name: "Segoe UI Light", size: 14 };
          headerRow3.getCell(1).alignment = { horizontal: "left" };
          headerRow3.getCell(2).value = this.warehouseName;
          headerRow3.getCell(2).font = { name: "Segoe UI Light", size: 14 };
          headerRow3.getCell(2).alignment = { horizontal: "left" };

          headerRow4.getCell(1).value = "Item Name : ";
          headerRow4.getCell(1).font = { name: "Segoe UI Light", size: 14 };
          headerRow4.getCell(1).alignment = { horizontal: "left" };

          headerRow4.getCell(2).value = this.itemName;
          headerRow4.getCell(2).font = { name: "Segoe UI Light", size: 14 };
          headerRow4.getCell(2).alignment = { horizontal: "left" };
          // footer
          const footerRowIndex = cellRange.to.row + 2;
          const footerRow = worksheet.getRow(footerRowIndex);
          worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 8);

          footerRow.getCell(1).value = "Warehouse Management System, Ver.1.0.0";
          footerRow.getCell(1).font = {
            color: { argb: "BFBFBF" },
            italic: true,
          };
          footerRow.getCell(1).alignment = { horizontal: "right" };
        })
        .then(function () {
          workbook.xlsx.writeBuffer().then(function (buffer) {
            saveAs(
              new Blob([buffer], { type: "application/octet-stream" }),
              "WMS-Stock-Card.xlsx"
            );
          });
        });
      e.cancel = true;
    },
  },
  computed: {
    ...mapGetters("stockreport", [
      "getStockReport",
      "getStockReportStatus",
      "getStockReportForm",
    ]),
    dataGrid: function () {
      return this.$refs[gridRef].instance;
    },
  },
};
</script>

<style>
.mytitle {
  /* height: 50px; */
  display: inline-block;
  /* padding-left: 20px; */
  padding-bottom: 30px;
  width: 100px;
  /* vertical-align: middle; */
  margin-left: 10px;
  /* margin-right: 10px; */
  /* border-left: 1px solid rgb(206, 204, 204); */
}

.selectbox {
  height: 80px;
  /* padding-top: 10px; */
  padding-left: 10px;
  width: 250px;
  /* display: inline-block; */
}

span.b {
  display: inline-block;
  /* width: 120px; */
  /* height: 50px; */
  /* padding: 0 5px;
  vertical-align: middle;
  box-sizing: content-box; */
}
.lastQty {
  text-align: right;
  /* color: darkblue; */
  font-size: 18px;
  /* font-weight: bold; */
}
.lastItemQty {
  color: darkblue;
  font-size: 18px;
  font-weight: bold;
}
.count {
  padding-top: 15px;
  line-height: 27px;
  margin: 0;
}
</style>
import Api from "@/services/Api";

export default {
  async getData(params) {
    var results = null;

    params.withResult = true;

    await Api()
      .get("clients_customers", {
        params: params,
      })
      .then((data) => {
        results = data.data;
        // console.log(results);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });

    return results;
  },
  async getDataByCustomerId(customer_id, params) {
    var results = null;

    params.withResult = true;

    await Api()
      .get("clients_customers/" + customer_id, {
        params: params,
      })
      .then((data) => {
        results = data.data;
        // console.log(results);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });

    return results;
  },
  async getDataByUserId(user_id, params) {
    var results = null;

    params.withResult = true;

    await Api()
      .get("clients_customers/userid/" + user_id, {
        params: params,
      })
      .then((data) => {
        results = data.data;
        // console.log(results);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });

    return results;
  },
  async getContactDataByUserId(user_id, params) {
    var results = null;

    params.withResult = true;

    await Api()
      .get("clients_customers/contact/userid/" + user_id, {
        params: params,
      })
      .then((data) => {
        results = data.data;
        // console.log(results);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });

    return results;
  },

  async getTestDataByUserId(user_id, params) {
    var results = null;

    params.withResult = true;

    await Api()
      .get("clients_customers/test/userid/" + user_id, {
        params: params,
      })
      .then((data) => {
        results = data.data;
        // console.log(results);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });

    return results;
  },

  async getTypeByUserId(userid, typeNumber, params) {
    var results = null;

    params.withResult = true;

    await Api()
      .get("clients_customers/type/" + typeNumber, {
        params: params,
      })
      .then((data) => {
        results = data.data;
        // console.log(results);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });

    return results;
  },
  async getExistingCustomer(userid, params) {
    var results = null;

    params.withResult = true;

    await Api()
      .get("clients_customers/existing/userid/" + userid, {
        params: params,
      })
      .then((data) => {
        results = data.data;
        // console.log(results);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });

    return results;
  },
  async getType(typeNumber, userid, params) {
    var results = null;

    params.withResult = true;

    await Api()
      .get("clients_customers/type/" + typeNumber + "/" + userid, {
        params: params,
      })
      .then((data) => {
        results = data.data;
        // console.log(results);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });

    return results;
  },
  async getTotalCustomerByType(typeNumber, userid, params) {
    var results = null;
    params.withResult = true;

    await Api()
      .get("customer_types/show_total/" + typeNumber + "/" + userid, {
        params: params,
      })
      .then((data) => {
        results = data.data;
        // console.log(results);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });

    return results;
  },

  async getFullFormByType(typeNumber, userid, params) {
    var results = null;
    params.withResult = true;

    await Api()
      .get("clients_customers/full_form/" + typeNumber + "/" + userid, {
        params: params,
      })
      .then((data) => {
        results = data.data;
        // console.log(results);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });

    return results;
  },
  insert(requestBody, callback, errCallback) {
    return Api()
      .post("clients_customers", requestBody)
      .then((data) => {
        callback(data);
      })
      .catch((error) => {
        errCallback(error);
      });
  },
  update(id, requestBody, callback, errCallback) {
    return Api()
      .put("clients_customers/" + id, requestBody)
      .then((data) => {
        callback(data);
      })
      .catch((error) => {
        errCallback(error);
      });
  },
  delete(id, callback, errCallback) {
    return Api()
      .delete("clients_customers/" + id)
      .then((data) => {
        callback(data);
      })
      .catch((error) => {
        errCallback(error);
      });
  },
};

<template>
  <div id="DeptForm">
    <h2 class="content-block">Rincian Customer</h2>
    <DxToolbar class="padding-block">
      <DxItem
        :options="backButtonOptions"
        widget="dxButton"
        location="before"
      />
    </DxToolbar>
    <CustomerView />
    <DxLoadPanel
      :position="{ of: '#mainContent' }"
      :visible.sync="loadingVisible"
      :show-indicator="true"
      :show-pane="true"
      :shading="true"
      :close-on-outside-click="false"
      shading-color="rgba(0,0,0,0.4)"
    />
  </div>
</template>
<script>
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import { DxLoadPanel } from "devextreme-vue/load-panel";
// import notify from 'devextreme/ui/notify';
import CustomerView from "@/components/customer/CustomerView.vue";
// import router from "../../router";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    DxToolbar,
    DxItem,
    CustomerView,
    DxLoadPanel,
  },
  data() {
    return {
      // tipecustomer:'',
      loadingVisible: false,
      backButtonOptions: {
        icon: "chevronleft",
        text: "Kembali",
        onClick: () => {
          this.clearCustomerStatus();
          this.clearCustomer();

          this.$router.go(-1);
          // switch (this.tipecustomer) {
          //   case 1:
          //     router.push("/customer/prospek");
          //     break;
          //   case 2:
          //     router.push("/customer/followup");
          //     break;
          //   case 3:
          //     router.push("/customer/potensial");
          //     break;
          //   default:
          //     router.push("/customer");
          // }
          //  router.push("/customer");
        },
      },
    };
  },
  methods: {
    setTypeCustomer(value) {
      console.log("typecustomer = " + value);
      this.tipecustomer = value;
    },
    ...mapActions("customer", ["clearCustomer", "clearCustomerStatus"]),
  },
  computed: {
    ...mapGetters("customer", [
      "getCustomer",
      "getCustomerStatus",
      "getCustomerForm",
    ]),
  },
};
</script>

<style>
.toolbar-label,
.toolbar-label > b {
  font-size: 16px;
}

#products {
  margin-top: 10px;
}

.padding-block {
  padding-left: 20px;
  padding-right: 20px;
}
</style>
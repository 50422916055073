const state = {
  stockin: null,
  stockinStatus: "",
  stockinForm: null,
};

const getters = {
  getStockIn: (state) => {
    return state.stockin;
  },
  getStockInStatus: (state) => {
    return state.stockinStatus;
  },
  getStockInForm: (state) => {
    return state.stockinForm;
  },
};

const mutations = {
  setStockIn: (state, stockin) => {
    state.stockin = stockin;
  },
  clearStockIn: (state) => {
    state.stockin = null;
  },

  setStockInStatus: (state, status) => {
    state.stockinStatus = status;
  },
  clearStockInStatus: (state) => {
    state.stockinStatus = "";
  },

  setStockInForm: (state, status) => {
    state.stockinForm = status;
  },
  clearStockInForm: (state) => {
    state.stockinForm = "";
  },
};

const actions = {
  setStockIn({ commit }, data) {
    commit("setStockIn", data);
  },
  clearStockIn({ commit }) {
    commit("clearStockIn");
  },

  setStockInStatus({ commit }, status) {
    commit("setStockInStatus", status);
  },
  clearStockInStatus({ commit }) {
    commit("clearStockInStatus");
  },

  setStockInForm({ commit }, data) {
    commit("setStockInForm", data);
  },
  clearStockInForm({ commit }) {
    commit("clearStockInForm");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

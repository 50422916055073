<template>
  <DxForm
    class="form-block"
    @field-data-changed="formFieldDataChanged"
    :form-data="getCustomerStatus == 'edit' ? getCustomer : null"
    :ref="formRef"
    :col-count="2"
  >
    <DxGroupItem>
      <DxSimpleItem data-field="code" :label="{ text: 'Customer Code' }">
        <DxRequiredRule message="Customer Code Wajib di Isi" />
      </DxSimpleItem>

      <DxSimpleItem data-field="name" :label="{ text: 'Customer Name' }">
        <DxRequiredRule message="Customer Name Wajib di Isi" />
      </DxSimpleItem>

      <DxSimpleItem data-field="phone" :label="{ text: 'Customer Phone' }">
        <DxRequiredRule message="Customer Phone Wajib di Isi" />
      </DxSimpleItem>
    </DxGroupItem>

    <DxGroupItem>
      <DxSimpleItem
        data-field="address"
        editor-type="dxTextArea"
        :editor-options="{ height: 90 }"
        :label="{ text: 'Customer Address' }"
      />

      <DxSimpleItem
        data-field="desc"
        editor-type="dxTextArea"
        :editor-options="{ height: 90 }"
        :label="{ text: 'Customer Desc' }"
      />
    </DxGroupItem>
  </DxForm>
</template>
<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from "devextreme-vue/form";

// import { DxSelectBox } from "devextreme-vue/select-box";
// import { DxNumberBox } from 'devextreme-vue/number-box';
import { mapGetters, mapActions } from "vuex";
import axios from "axios";

// axios.defaults.withCredentials = false;

// function handleErrors(response) {
//   if (!response.ok) {
//     throw Error(response.statusText);
//   }
//   return response;
// }

// function isNotEmpty(value) {
//   return value !== undefined && value !== null && value !== "";
// }

const formRef = "customer-form";

export default {
  components: {
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    // DxSelectBox,
    DxRequiredRule,
    // DxNumberBox,
    // DxLookup,
    // CustomerSourceData,
    // BadanUsahaData,
    // ProvinceData,
    // CityData,
    // CountryData,
    // BusinessTypeData,
  },
  data: function () {
    return {
      userId: null,
      provinceId: null,
      provinceData: null,
      cityId: null,
      cityData: null,
      cityData2: {},
      countryData: null,
      BusinessTypeData: null,
      customerSourceData: null,
      customerCity: "",
      businessTypeId: "",
      validationRules: {
        tipebisniss: [{ type: "required", message: "tipebisnis is required." }],
      },
      formRef,
      badanUsahaData: null,
    };
  },
  created() {
    // this.roleid = window.localStorage.getItem("roleid");
    // var userIdsales = window.localStorage.getItem("usrid");
    // var userIdAdmin = this.getCustomer.user_id;
    // this.userId = this.roleid < 5 ? this.getCustomer.user_id : userIdsales;
    // this.userId = window.localStorage.getItem("usrid");
  },
  mounted() {
    this.formSet();
  },
  methods: {
    //---------------------------------------
    ...mapActions("customer", ["setCustomer", "setCustomerForm"]),

    formFieldDataChanged(e) {
      this.setCustomer(e.component.option("formData"));
      // console.log("getCustomer : " + JSON.stringify(this.getCustomer));
    },
    formSet() {
      this.setCustomerForm(this.$refs[formRef].instance);
    },
    //---------------------------------------
    // setProvinceValue(e) {
    //   this.$emit("provinceId", e.value);
    // },
    postalcodeChanged(e) {
      console.log("postalcodeChanged : " + e.value);
    },
    //---------------------------------------
    // setCustomerSourceData(value) {
    //   this.customerSourceData = value;
    // },
    CustomerSourceSelectedChanged(e) {
      // console.log("source_id : " + e.value);
      this.$refs[formRef].instance.updateData({
        source_id: e.value,
      });
    },
    //---------------------------------------
    // setProvinceValue(e) {
    //   this.$emit("provinceId", e.value);
    // },
    // setBadanUsahaData(value) {
    //   this.badanUsahaData = value;
    // },
    BadanUsahaSelectedChanged(e) {
      // console.log("badanusaha_id : " + e.value);
      this.$refs[formRef].instance.updateData({
        badanusaha_id: e.value,
      });
    },
    //---------------------------------------
    // setProvinceValue(e) {
    //   this.$emit("provinceId", e.value);
    // },
    // setProvinceData(value) {
    //   this.provinceData = value;
    // },
    ProvinceSelectedChanged(e) {
      // console.log("provinceId : " + e.value);
      this.$refs[formRef].instance.updateData({
        province_id: e.value,
      });
      var $province_id = e.value;
      // this.provinceId = e.value;
      console.log("province_id : " + $province_id);
      this.getCityByProvinceId($province_id);
    },

    getCityByProvinceId($province_id) {
      let currentObj = this;
      axios
        .get("master_cities/provinceid/" + $province_id)
        .then(function (response) {
          currentObj.cityData2 = response.data.data;
          // console.log("cityData2 : " + JSON.stringify(currentObj.cityData2));
        })
        .catch(function (error) {
          console.log(error);
          currentObj.output = error;
        });
    },
    //---------------------------------------
    // setCityValue(e) {
    //   this.$emit("cityId", e.value);
    // },
    // setCityData(value) {
    //   // this.cityData = value;
    //   if (this.getStatus == "edit") {
    //     this.cityData2 = value;
    //   } else {
    //     this.cityData = value;
    //   }
    // },
    CitySelectedChanged(e) {
      this.$refs[formRef].instance.updateData({
        city_id: e.value,
      });
    },

    //---------------------------------------
    // setCountryValue(e) {
    //   this.$emit("countryId", e.value);
    // },
    // setCountryData(value) {
    //   this.countryData = value;
    // },
    CountrySelectedChanged(e) {
      // console.log("countryId : " + e.value);
      this.$refs[formRef].instance.updateData({
        country_id: e.value,
      });
    },
    //---------------------------------------
    // setBusinessTypeValue(e) {
    //   this.$emit("businessTypeId", e.value);
    // },
    // setBusinessTypeData(value) {
    //   this.BusinessTypeData = value;
    // },
    BusinessTypeSelectedChanged(e) {
      // console.log("business_type : " + e.value);
      this.$refs[formRef].instance.updateData({
        business_type: e.value,
      });
    },
  },
  computed: {
    ...mapGetters("customer", ["getCustomer", "getCustomerStatus"]),
  },
};
</script>
<style>
.group-padding {
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.form-block {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  padding-left: 40px;
  padding-right: 40px;
}
.dx-field-item-help-text,
.dx-field-item-label-text {
  font-weight: bold;
  color: #797777;
}
</style>

<template>
  <DxForm> </DxForm>
</template>

<script>
import { DxForm } from "devextreme-vue/form";
import axios from "axios";


export default {
  components: {
    DxForm,
  },
  created() {
    var AUTH_TOKEN = "Bearer " + window.localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;
    
    axios.get("employees").then((response) => {
      this.$emit("employeeData", response.data.data);
    });
  },
  data() {
    return {
      res: [],
    };
  },
};
</script>

<style>
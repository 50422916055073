const state = {
  stockreturndetail: null,
  stockreturndetailStatus: "",
  stockreturndetailForm: null,
  stockreturndetailTrxNum: null,
};

const getters = {
  getStockReturnDetail: (state) => {
    return state.stockreturndetail;
  },
  getStockReturnDetailStatus: (state) => {
    return state.stockreturndetailStatus;
  },
  getStockReturnDetailForm: (state) => {
    return state.stockreturndetailForm;
  },
  getStockReturnDetailTrxNum: (state) => {
    return state.stockoutdetailTrxNum;
  },
};

const mutations = {
  setStockReturnDetail: (state, stockreturndetail) => {
    state.stockreturndetail = stockreturndetail;
  },
  clearStockReturnDetail: (state) => {
    state.stockreturndetail = null;
  },

  setStockReturnDetailStatus: (state, status) => {
    state.stockreturndetailStatus = status;
  },
  clearStockReturnDetailStatus: (state) => {
    state.stockreturndetailStatus = "";
  },

  setStockReturnDetailForm: (state, status) => {
    state.stockreturndetailForm = status;
  },
  clearStockReturnDetailForm: (state) => {
    state.stockreturndetailForm = "";
  },

  setStockReturnDetailTrxNum: (state, status) => {
    state.stockoutdetailTrxNum = status;
  },
  clearStockReturnDetailTrxNum: (state) => {
    state.stockoutdetailTrxNum = "";
  },
};

const actions = {
  setStockReturnDetail({ commit }, data) {
    commit("setStockReturnDetail", data);
  },
  clearStockReturnDetail({ commit }) {
    commit("clearStockReturnDetail");
  },

  setStockReturnDetailStatus({ commit }, status) {
    commit("setStockReturnDetailStatus", status);
  },
  clearStockReturnDetailStatus({ commit }) {
    commit("clearStockReturnDetailStatus");
  },

  setStockReturnDetailForm({ commit }, data) {
    commit("setStockReturnDetailForm", data);
  },
  clearStockReturnDetailForm({ commit }) {
    commit("clearStockReturnDetailForm");
  },

  setStockReturnDetailTrxNum({ commit }, data) {
    commit("setStockReturnDetailTrxNum", data);
  },
  clearStockReturnDetailTrxNum({ commit }) {
    commit("clearStockReturnDetailTrxNum");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div>
    <dx-data-grid
      class="dx-card wide-card"
      :data-source="dataSource"
      :show-borders="true"
      :focused-row-index="0"
      :focused-row-enabled="true"
      :column-auto-width="true"
      :column-hiding-enabled="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :loadPanel="loadPanel"
      :selected-row-keys="selectedRowKeys"
      @toolbar-preparing="onToolbarPreparing($event)"
      @selection-changed="onSelectionChanged"
      @rowExpanding="onRowExpanding"
      @exporting="onExporting"
      :ref="gridRef"
    >
      <dx-paging :page-size="10" />
      <dx-pager
        :showPageSizeSelector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
        :showNavigationButtons="true"
        infoText="Hal {0} dari {1} ({2} items)"
        :visible="true"
      />
      <dx-filter-row :visible="filterStatus" />
      <DxSelection mode="multiple" />
      <DxColumn
        data-field="transaction_number"
        caption="No.Transaksi"
        :width="140"
      />
      <DxColumn
        data-field="delivery_date"
        caption="Tanggal Pengiriman"
        dataType="date"
        format="dd/MM/yyyy"
        :width="160"
      />
      <DxColumn data-field="invoice_number" caption="No.Invoice" :width="120" />
      <DxColumn data-field="qty" caption="Total Barang" dataType="number" :width="120" />
      <DxColumn data-field="destination_type_name" caption="Tipe" />
      <DxColumn
        data-field="origin_name"
        caption="Asal Pengiriman"
        :width="170"
      />
      <DxColumn
        data-field="destination_name"
        caption="Tujuan Pengiriman"
        :width="170"
      />
      <DxColumn
        data-field="delivery_status"
        caption="Status Kirim"
        :width="150"
      />
      <DxColumn data-field="remark" caption="Catatan" :width="150" />

      <DxMasterDetail :enabled="true" template="masterDetailTemplate" />
      <template #masterDetailTemplate="{ data: StockOutDetail }">
        <ChildStockOutTable :template-data="StockOutDetail" />
      </template>
      <DxExport :enabled="true" />
    </dx-data-grid>

    <table id="stock_out_detail_table" style="display: none">
      <tr>
        <th>Item Code</th>
        <th>Item Name</th>
        <th>Qty</th>
        <th>No Batch</th>
        <th>Expired Date</th>
        <th>Condition</th>
      </tr>
      <!-- <tr v-for="data in PriceData" :key="data.id" :data="data">
        <td colspan="4">{{ data.province_name }}</td>
      </tr> -->

      <template v-for="data in GetStockOutDetail" :data="data">
        <tr :key="data.id">
          <td>{{ data.item_code }}</td>
          <td>{{ data.item_name }}</td>
          <td>{{ data.qty }}</td>
          <td>{{ data.batch_number }}</td>
          <td>{{ data.expired_date | formatDate }}</td>
          <td>{{ data.item_condition_name }}</td>
        </tr>
      </template>
    </table>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxPager,
  DxPaging,
  DxSelection,
  DxColumn,
  DxFilterRow,
  DxMasterDetail,
  DxExport,
} from "devextreme-vue/data-grid";
import CustomStore from "devextreme/data/custom_store";
import StockOutService from "@/services/StockOutService";
import ChildStockOutTable from "@/components/stock-out/ChildStockOutTable.vue";
import router from "../../router";
import { mapGetters, mapActions } from "vuex";
import { confirm } from "devextreme/ui/dialog";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import axios from "axios";

const gridRef = "stockout-table";

export default {
  // props: ["StockOutId"],
  components: {
    DxDataGrid,
    DxPager,
    DxPaging,
    DxSelection,
    DxColumn,
    DxFilterRow,
    DxMasterDetail,
    ChildStockOutTable,
    DxExport,
  },
  data() {
    const vthis = this;
    const dataSource = {
      store: new CustomStore({
        key: "id",
        load: async function (loadOptions) {
          var sortedBy = "";
          if (loadOptions.sort) {
            sortedBy += loadOptions.sort[0].selector;
            if (loadOptions.sort[0].desc) {
              sortedBy += " desc";
            } else {
              sortedBy += " asc";
            }
          }

          var perPage = loadOptions.take;
          var offset = loadOptions.skip;
          var page = offset / perPage + 1;

          var datax = vthis.fetch({
            perPage: perPage || 10,
            page: page || 1,
            sortedBy: sortedBy,
            parentId: "null",
            startDate : vthis.startDate,
            endDate : vthis.endDate
          });

          return await datax;
        },
      }),
    };

    return {
      filterStatus: false,
      gridRef,
      dataSource: dataSource,
      pageSizes: [5, 10, 15],
      selectedRowKeys: [],
      loadPanel: {
        text: "Memuat...",
      },
      btnAdd: null,
      btnEdit: null,
      btnDelete: null,
      btnView: null,
      startDate : null,
      endDate : null,
      startDateObj : null,
      endDateObj : null,
      tipe: this.tipe,
      custid: null,
      mydata: null,
      FormStockOut: null,
      trx_num: null,
      GetStockOutDetail: [],
      tblStockOutDetail: [],
    };
  },
  created() {
    this.userId = window.localStorage.getItem("usrid");
    this.warehouseId = window.localStorage.getItem("wrhid");
    this.roleid = window.localStorage.getItem("roleid");
    this.currentUUid = this.uuid;
  },
  methods: {
    ...mapActions("stockout", [
      "setStockOut",
      "clearStockOut",
      "setStockOutStatus",
      "clearStockOutStatus",
    ]),
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY");
      }
    },
    format_date_with_pattern(value,pattern) {
      if (value) {
        return moment(String(value)).format(pattern);
      }
    },
    getDateNow() {
      return moment().format("YYYY-MM-DD");
    },
    async fetch(params = {}) {
      // eslint-disable-next-line no-console
      if (Object.keys(params).length === 0) {
        params = {
          perPage: 10,
          page: 1,
          // parentId: "null",
        };
      }

      // var results = await StockOutService.getData(params);
      if (this.roleid == 5) {
        var results;
        results = await StockOutService.getDataByWarehouseId(
          this.warehouseId,
          params
        );
      } else {
        results = await StockOutService.getData(params);
      }
      // eslint-disable-next-line no-console
      var datax = await {
        data: results.data,
        totalCount: 10
        // totalCount: results.total,
      };
      return await datax;
    },

    onSelectionChanged({ selectedRowKeys, selectedRowsData }) {
      // console.log("selectedRowKeys : " + selectedRowKeys.length);
      if (selectedRowKeys.length > 0) {
        if (selectedRowKeys.length == 1) {
          this.setStockOut(selectedRowsData[0]);
          console.log(
            "transaction_number :" + selectedRowsData[0].transaction_number
          );
          this.trx_num = selectedRowsData[0].transaction_number;
          this.funcGetStockOutDetail();
          console.log(
            "GetStockOutDetail :" + JSON.stringify(this.GetStockOutDetail)
          );

          this.btnAdd.option({
            disabled: false,
          });
          this.btnEdit.option({
            disabled: false,
          });
          this.btnView.option({
            disabled: false,
          });
          this.btnDelete.option({
            disabled: false,
          });
          this.btnPrint.option({
            disabled: false,
          });
          this.btnApprove.option({
            disabled: false,
          });
        } else {
          this.setStockOut(selectedRowsData);
          // console.log(selectedRowsData);
          this.btnAdd.option({
            disabled: true,
          });
          this.btnEdit.option({
            disabled: true,
          });
          this.btnView.option({
            disabled: true,
          });
          this.btnDelete.option({
            disabled: true,
          });
          this.btnPrint.option({
            disabled: true,
          });
          this.btnApprove.option({
            disabled: true,
          });
        }
      } else {
        this.clearStockOut();
        this.btnAdd.option({
          disabled: true,
        });
        this.btnEdit.option({
          disabled: true,
        });
        this.btnDelete.option({
          disabled: true,
        });
        this.btnView.option({
          disabled: true,
        });
        this.btnPrint.option({
          disabled: true,
        });
        this.btnApprove.option({
          disabled: true,
        });
      }
    },
    onToolbarPreparing(e) {
      var vThis = this;

      e.toolbarOptions.items.unshift(
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "add",
            text: "Tambah",
            onInitialized: function (e) {
              vThis.btnAdd = e.component;
            },
            onClick: function () {
              vThis.setStockOutStatus("add");
              router.push("/stock/out/add");
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "edit",
            text: "Edit",
            disabled: true,
            visible: this.roleid == 5 ? false : true, //jika role bukan user maka dihide
            onInitialized: function (e) {
              vThis.btnEdit = e.component;
            },
            onClick: function () {
              vThis.setStockOutStatus("edit");
              router.push("/stock/out/edit");
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "trash",
            text: "Hapus",
            disabled: true,
            visible: this.roleid == 5 ? false : true, //jika role bukan user maka dihide
            onInitialized: function (e) {
              vThis.btnDelete = e.component;
            },
            onClick: function () {
              let result = confirm(
                "<i>Anda yakin mau menghapus data ini ?</i>",
                "Hapus StockOut StockOut"
              );
              result.then((dialogResult) => {
                if (dialogResult) {
                  var StockOutData = vThis.getStockOut;
                  console.log(vThis.getStockOut);
                  var ids = "";
                  if (Array.isArray(StockOutData)) {
                    StockOutData.forEach(function (item, index) {
                      if (index == 0) {
                        ids += item.id;
                      } else {
                        ids += "," + item.id;
                      }
                    });
                  } else {
                    ids = StockOutData.id;
                  }
                  let dateNow = vThis.getDateNow();
                  console.log(dateNow);
                  const param = {
                    transaction_number: StockOutData.transaction_number,
                    deleted_at: dateNow,
                  };
                  vThis.deleteStockoutDetail(param); // hapus stock-out detail
                  vThis.deleteStockOutReport(param);// hapus stock-out di report

                  StockOutService.delete(
                    ids,
                    function () {
                      vThis.clearStockOut();
                      vThis.dataGrid.refresh();
                    },
                    function (error) {
                      // eslint-disable-next-line no-console
                      console.log(error);
                    }
                  );
                }
              });
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "detailslayout",
            text: "Detail",
            disabled: true,
            onInitialized: function (e) {
              vThis.btnView = e.component;
            },
            onClick: function () {
              router.push("/stock/out/view");
            },
          },
        },
        {
          location: "before",
          widget: "dxDateBox",
          options: {
            displayFormat:"dd/MM/yyyy",
            showClearButton: true,
            onValueChanged : function(e) {
               vThis.startDateObj = e.value;
               vThis.startDate = vThis.format_date_with_pattern(e.value,"yyyy-MM-DD");
               vThis.dataGrid.refresh();
            }
          }
        },
        {
          location: "before",
          text: "-"
        },
        {
          location: "before",
          widget: "dxDateBox",
          options: {
            displayFormat:"dd/MM/yyyy",
            showClearButton: true,
            onValueChanged : function(e) {
              vThis.endDateObj = e.value;
              vThis.endDate = vThis.format_date_with_pattern(e.value,"yyyy-MM-DD");
              vThis.dataGrid.refresh();
            }
          }
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "refresh",
            text: "Refresh",
            onClick: function () {
              vThis.dataGrid.refresh();
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "print",
            text: "Print",
            onInitialized: function (e) {
              vThis.btnPrint = e.component;
            },
            onClick: function () {
              const pdf = new jsPDF("l", "mm", [148, 210]);
              //pdf.setFont("helvetica");
              //pdf.setFontSize(9);
              pdf.setFontSize(9);
              pdf.setFont(undefined, "bold");
              pdf.text(
                "No. Invoice : " + vThis.getStockOut.invoice_number,
                10,
                10
              );
              pdf.text(150, 10, "Penerima :  ");
              pdf.setFont(undefined, "normal");
              pdf.text(150, 14, vThis.getStockOut.destination_name);

              pdf.text(
                vThis.format_date(vThis.getStockOut.delivery_date),
                10,
                14
              );
              // pdf.text(
              //   150,
              //   14,
              //   "Alamat :  " + vThis.getStockOut.destination_address
              // );
              pdf.setFont(undefined, "bold");
              pdf.text(150, 20, "Alamat Penerima :  ");
              pdf.setFont(undefined, "normal");
              pdf.text(150, 24, vThis.getStockOut.destination_address, {
                maxWidth: 50,
                align: "left",
              });
              pdf.setFontSize(16);
              pdf.setFont(undefined, "normal");
              pdf.text("Packing List Outbound", 10, 25);
              pdf.setFontSize(9);
              pdf.setFont(undefined, "bold");
              pdf.text("Warehouse : " + vThis.getStockOut.origin_name, 10, 30);
              pdf.setFontSize(14);
              pdf.setFont(undefined, "normal");
              pdf.text(
                vThis.getStockOut.destination_code +
                  " - " +
                  vThis.getStockOut.destination_name,
                10,
                50
              );

              pdf.setFontSize(9);
              pdf.text(10, 58, vThis.getStockOut.destination_address, {
                maxWidth: 60,
                align: "left",
              });

              pdf.text("Status : " + vThis.getStockOut.delivery_status, 10, 75);
              pdf.text(
                "Delivery Date : " +
                  vThis.format_date(vThis.getStockOut.delivery_date),
                10,
                79
              );
              pdf.text(
                "Remark Delivered : " + vThis.getStockOut.remark,
                10,
                83
              );

              //pdf.save(pdfName + '.pdf');
              //let header = ["Item Code","Item Name","Qty","Free","Total","Batch Number","Expired Date","Item Condition"];
              //let header = ["Item Code","Item Name","Qty"];

              //let data = this.GetStockoutDetailPDF;

              //let rows = this.GetStockoutDetailPDF;
              //pdf.table(10, 89, data, header);

              // pdf.autoTable({
              //   //columnStyles: { 0: { halign: 'center', fillColor: [0, 255, 0] } }, // Cells in first column centered and green
              //   margin: { top: 86 },
              // });

              // var columns = [
              //     {title: "ID", dataKey: "id"},
              //     {title: "Name", dataKey: "name"},
              //     {title: "Country", dataKey: "country"},
              //    ];
              // var rows = [
              //     {"id": 1, "name": "Shaw", "country": "Tanzania"},
              //     {"id": 2, "name": "Nelson", "country": "Kazakhstan"},
              //     {"id": 3, "name": "Garcia", "country": "Madagascar"}
              // ];

              // var columns = [
              //   { title: "Item Code", dataKey: "item_code" },
              //   { title: "Item Name", dataKey: "item_name" },
              //   { title: "Qty", dataKey: "qty" },
              //   { title: "No Batch", dataKey: "batch_number" },
              //   { title: "Expired Date", dataKey: "expired_date" },
              // ];
              // var rows = vThis.GetStockOutDetail;

              pdf.autoTable({
                // theme: "plain",\
                html: "#stock_out_detail_table",
                theme: "striped",
                startY: 90,
                startX: -10,
                cellWidth: "auto",
                margin: { top: 30, bottom: 30 },
                styles: {
                  lineColor: [0, 0, 0],
                  lineWidth: 0.1,
                  valign: "middle",
                  halign: "left",
                  maxCellHeight: 1,
                  fontSize: 9,
                },
                columnStyles: {
                  0: { cellWidth: 20 },
                  1: { cellWidth: 50 },
                  2: { cellWidth: 10 },
                  3: { cellWidth: 20 },
                  4: { cellWidth: 50 },
                  5: { cellWidth: 20 },
                },
                headStyles: {
                  fillColor: [204, 217, 255],
                  textColor: [255, 255, 255],
                },
                // footStyles: {
                //     fillColor: [241, 196, 15],
                //     fontSize: 15
                // },
                bodyStyles: {
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                },

                // didParseCell: function (table) {
                //   if (table.section === "head") {
                //     table.cell.styles.fillColor = "#3559db";
                //   }
                // },
                didParseCell: function (data) {
                  if (data.row.index === 0) {
                    data.cell.styles.fillColor = [92, 149, 197];
                  }
                },
                // columnStyles: {
                //   "Item Code": {
                //     halign: "left",
                //     cellWidth: 50,
                //   },
                //   "Item Name": {
                //     halign: "left",
                //     cellWidth: 50,
                //   },
                //   Qty: {
                //     halign: "left",
                //     cellWidth: 50,
                //   },
                //   "No Batch": {
                //     halign: "left",
                //     cellWidth: 10500,
                //   },
                //   "Expired Date": {
                //     halign: "left",
                //     cellWidth: 50,
                //   },
                // },
                tableWidth: "wrap",
              });
              var finalY = pdf.finalY;
              finalY = pdf.lastAutoTable.finalY;
              finalY = pdf.lastAutoTable.finalY + 10;
              pdf.setFontSize(9);
              pdf.setFont(undefined, "normal");
              pdf.text("Sales Counter", 25, finalY);

              pdf.setFontSize(9);
              pdf.setFont(undefined, "normal");
              pdf.text("Warehouse", 85, finalY);

              pdf.setFontSize(9);
              pdf.setFont(undefined, "normal");
              pdf.text("Receiver", 150, finalY);

              finalY = finalY + 10;
              pdf.setFontSize(9);
              pdf.setFont(undefined, "normal");
              pdf.text("(               )", 20, finalY);

              pdf.setFontSize(9);
              pdf.setFont(undefined, "normal");
              pdf.text("(               )", 75, finalY);

              pdf.setFontSize(9);
              pdf.setFont(undefined, "normal");
              pdf.text("(               )", 140, finalY);

              // pdf.save('packing-list-outbound.pdf');

              // var string = pdf.output("datauristring");
              // var embed =
              //   "<embed width='100%' height='100%' src='" + string + "'/>";
              // var x = window.open();
              // x.document.open();
              // x.document.write(embed);
              // x.document.close();
              window.open(pdf.output("bloburl"), "_blank");
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "check",
            text: "Approve",
            disabled: true,
            visible: this.roleid == 5 ? false : true, //jika role bukan admin/manager maka dihide
            onInitialized: function (e) {
              vThis.btnApprove = e.component;
            },
            onClick: function () {
              let result = confirm(
                "<i>Anda yakin mau meng-approve data ini ?</i>",
                "StockOut Approval"
              );
              result.then((dialogResult) => {
                if (dialogResult) {
                  console.log("id :" + vThis.getStockOut.id);

                  const param = {
                    status: 2,
                    // transaction_number: vThis.getStockOut.transaction_number,
                    // delivery_date: vThis.getStockOut.delivery_date,
                    // invoice_number: vThis.getStockOut.invoice_number,
                    // origin_id: vThis.getStockOut.origin_id,
                    // destination_id: vThis.getStockOut.destination_id,
                    // destination_type_id: vThis.getStockOut.destination_type_id,
                  };

                  // vThis.updateReport(StockOutDetail.uuid, param);
                  vThis.approveStockout(vThis.getStockOut.id, param);
                }
              });
            },
          },
        },
        // Filter //
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "search",
            text: vThis.filterText,
            disabled: false,
            onInitialized: function (e) {
              vThis.btnFilter = e.component;
            },
            onClick: function () {
              if (vThis.filterStatus === true) {
                vThis.filterStatus = false;
                vThis.filterText = "Filter ON";
                // console.log("filterStatus : " + vThis.filterStatus);
              } else {
                vThis.filterStatus = true;
                vThis.filterText = "Filter Off";
                // console.log("filterStatus : " + vThis.filterStatus);
              }
            },
          },
        }
      );
    },

    async deleteStockoutDetail(param) {
      try {
        const response2 = await axios.put("stock_out_details/del/1", param);
        // console.log(response2);
        this.success = response2.data.success;
        this.dataGrid.refresh();
      } catch (error) {
        console.error(error);
      }
    },
    async deleteStockOutReport(param) {
      try {
        const response2 = await axios.put("stock_reports/del/1", param);
        // console.log(response2);
        this.success = response2.data.success;
        this.dataGrid.refresh();
      } catch (error) {
        console.error(error);
      }
    },
    async approveStockout(id, param) {
      // const param = { delivery_status: "Approve" };
      // const patchresult = await axios.patch("stock_out_headers/" + id, param);
      // console.log("patchresult : " + JSON.stringify(patchresult));

      try {
        const response2 = await axios.put("stock_out_headers/" + id, param);
        console.log(response2);
        this.success = response2.data.success;
        this.dataGrid.refresh();
      } catch (error) {
        console.error(error);
      }
    },

    onRowExpanding(e) {
      var dataGrid = e.component;
      dataGrid.collapseAll(-1);
    },
    getDataCust() {
      let currentObj = this;
      axios
        .get("stockouts/" + this.custid)
        .then(function (response) {
          // console.log(response);
          // currentObj.success = response.data.success;
          // currentObj.mydata = response;
          currentObj.FormStockOut = response.data.data;
          // console.log("mydata = " + JSON.stringify(response.data.data));
          // console.log(
          //   "getStockOut = " + JSON.stringify(currentObj.getStockOut)
          // );
        })
        .catch(function (error) {
          console.log(error);
          currentObj.output = error;
        });
    },
    // GetStockoutDetail
    funcGetStockOutDetail() {
      let currentObj = this;
      axios
        .get("stock_out_details/usr/1/trx/" + this.trx_num)
        .then(function (response) {
          currentObj.GetStockOutDetail = response.data.data;
          console.log(
            "GetStockOutDetail = " + JSON.stringify(response.data.data)
          );
        })
        .catch(function (error) {
          console.log(error);
          currentObj.output = error;
        });
    },
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Workbook");

      let periode = null;
      let topLeftCell = { row: 4, column: 1 };
      let fileName = "WMS-Data-Stock-Outbound.xlsx";

      if (this.startDateObj != null && this.endDateObj != null) {
         periode = this.format_date_with_pattern(this.startDateObj,"DD/MM/yyyy")+" - "+this.format_date_with_pattern(this.endDateObj,"DD/MM/yyyy");
         topLeftCell = { row: 5, column: 1 };
         fileName = "WMS-Data-Stock-Outbound-"+this.format_date_with_pattern(this.startDateObj,"yyyyMMDD")+"-"+this.format_date_with_pattern(this.endDateObj,"yyyyMMDD")+".xlsx";
      }

      // const worksheet = workbook
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        topLeftCell: topLeftCell,
      })
        .then((cellRange) => {
          // header
          const headerRow = worksheet.getRow(2);
          headerRow.height = 30;
          worksheet.mergeCells(2, 1, 2, 8);

          headerRow.getCell(1).value = "Data Stock Outbound";
          headerRow.getCell(1).font = { name: "Segoe UI Light", size: 22 };
          headerRow.getCell(1).alignment = { horizontal: "left" };

          if (periode != null) {
            const headerRow2 = worksheet.getRow(3);

            headerRow2.getCell(1).value = "Periode : "+periode;
            headerRow2.getCell(1).font = { name: "Segoe UI Light", size: 14 };
            headerRow2.getCell(1).alignment = { horizontal: "left" };
          }

          // footer
          const footerRowIndex = cellRange.to.row + 2;
          const footerRow = worksheet.getRow(footerRowIndex);
          worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 8);

          footerRow.getCell(1).value = "";
          footerRow.getCell(1).font = {
            color: { argb: "BFBFBF" },
            italic: true,
          };
          footerRow.getCell(1).alignment = { horizontal: "right" };
        })
        .then(function () {
          workbook.xlsx.writeBuffer().then(function (buffer) {
            saveAs(
              new Blob([buffer], { type: "application/octet-stream" }),
              fileName
            );
          });
        });
      e.cancel = true;
    },
  },
  computed: {
    ...mapGetters("stockout", [
      "getStockOut",
      "getStockOutStatus",
      "getStockOutForm",
    ]),
    dataGrid: function () {
      return this.$refs[gridRef].instance;
    },
  },
};
</script>

<style>
#stock_out_detail_table td,
#stock_out_detail_table th {
  border: 1px solid rgb(185, 184, 184);
  padding: 5px;
  text-align: center;
}
#stock_out_detail_table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #0bc27c;
  color: white;
}
</style>
const state = {
  MasterMenu: null,
  MasterMenuStatus: null,
  MasterMenuForm: null,
};

const getters = {
  getMasterMenu: (state) => {
    return state.MasterMenu;
  },
  getMasterMenuStatus: (state) => {
    return state.MasterMenuStatus;
  },
  getMasterMenuForm: (state) => {
    return state.MasterMenuForm;
  },
};

const mutations = {
  setMasterMenu: (state, MasterMenu) => {
    state.MasterMenu = MasterMenu;
  },
  clearMasterMenu: (state) => {
    state.MasterMenu = null;
  },

  setMasterMenuStatus: (state, MasterMenuStatus) => {
    state.MasterMenuStatus = MasterMenuStatus;
  },
  clearMasterMenuStatus: (state) => {
    state.MasterMenuStatus = null;
  },

  setMasterMenuForm: (state, MasterMenuForm) => {
    state.MasterMenuForm = MasterMenuForm;
  },
  clearMasterMenuForm: (state) => {
    state.MasterMenuForm = null;
  },
};

const actions = {
  setMasterMenu({ commit }, MasterMenu) {
    commit("setMasterMenu", MasterMenu);
  },
  clearMasterMenu({ commit }) {
    commit("clearMasterMenu");
  },
  setMasterMenuStatus({ commit }, MasterMenuStatus) {
    commit("setMasterMenuStatus", MasterMenuStatus);
  },
  clearMasterMenuStatus({ commit }) {
    commit("clearMasterMenuStatus");
  },
  setMasterMenuForm({ commit }, MasterMenuForm) {
    commit("setMasterMenuForm", MasterMenuForm);
  },
  clearMasterMenuForm({ commit }) {
    commit("clearMasterMenuForm");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

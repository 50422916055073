<template>
  <div>
    <dx-data-grid
      class="dx-card wide-card"
      :data-source="dataSource"
      :show-row-lines="true"
      :show-borders="true"
      :row-alternation-enabled="true"
      :loadPanel="loadPanel"
      :selected-row-keys="selectedRowKeys"
      :remote-operations="true"
      :column-auto-width="true"
      @toolbar-preparing="onToolbarPreparing($event)"
      @selection-changed="onSelectionChanged"
      @rowExpanding="onRowExpanding"
      @exporting="onExporting"
      :ref="gridRef"
      :allow-column-resizing="true"
    >
      <dx-paging :page-size="10" />
      <dx-pager
        :showPageSizeSelector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
        :showNavigationButtons="true"
        infoText="Hal {0} dari {1} ({2} items)"
      />
      <dx-filter-row :visible="filterStatus" />
      <DxSelection mode="multiple" />
      <DxColumn data-field="item_code" caption="Item Code" />
      <DxColumn data-field="item_name" caption="Item Name" />
      <!-- <DxColumn data-field="item_measurement" caption="Measurement" />
      <DxColumn data-field="item_size_measurement" caption="Size Measurement" />
      <DxColumn data-field="item_dimension" caption="Dimension" /> -->
      <DxColumn
        data-field="item_desc"
        caption="Item Description"
        :width="600"
      />

      <!-- <DxColumn
        :width="250"
        :allow-sorting="false"
        data-field="item_image"
        cell-template="cellTemplate"
      />
      <template #cellTemplate="{ data }">
        <img :src="data.value" />
      </template> -->
      <DxExport :enabled="true" />
    </dx-data-grid>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxPager,
  DxPaging,
  DxSelection,
  DxColumn,
  DxFilterRow,
  DxExport,
} from "devextreme-vue/data-grid";
import CustomStore from "devextreme/data/custom_store";
import MasterItemService from "@/services/MasterItemService";
import router from "../../router";
import { mapGetters, mapActions } from "vuex";
import { confirm } from "devextreme/ui/dialog";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";

import axios from "axios";

const gridRef = "masteritem-table";

export default {
  // props: ["MasterItemId"],
  components: {
    DxDataGrid,
    DxPager,
    DxPaging,
    DxSelection,
    DxColumn,
    DxFilterRow,
    DxExport,
  },
  data() {
    const vthis = this;
    const dataSource = {
      store: new CustomStore({
        key: "id",
        load: async function (loadOptions) {
          var sortedBy = "";
          if (loadOptions.sort) {
            sortedBy += loadOptions.sort[0].selector;
            if (loadOptions.sort[0].desc) {
              sortedBy += " desc";
            } else {
              sortedBy += " asc";
            }
          }

          var perPage = loadOptions.take;
          var offset = loadOptions.skip;
          var page = offset / perPage + 1;

          var datax = vthis.fetch({
            perPage: perPage || 10,
            page: page || 1,
            sortedBy: sortedBy,
            parentId: "null",
          });

          return await datax;
        },
      }),
    };

    return {
      filterStatus: false,
      gridRef,
      dataSource: dataSource,
      pageSizes: [5, 10, 15],
      selectedRowKeys: [],
      loadPanel: {
        text: "Memuat...",
      },
      btnAdd: null,
      btnEdit: null,
      btnDelete: null,
      btnView: null,

      tipe: this.tipe,
      custid: null,
      mydata: null,
      FormMasterItem: null,
    };
  },
  created() {
    axios
      .get( "master_items")
      .then((response) => {
        //console.log(JSON.stringify(response.data));
        this.dataSource = response.data.data;
      });
  },
  methods: {
    ...mapActions("masteritem", [
      "setMasterItem",
      "clearMasterItem",
      "setMasterItemStatus",
      "clearMasterItemStatus",
    ]),

    async fetch(params = {}) {
      // eslint-disable-next-line no-console
      if (Object.keys(params).length === 0) {
        params = {
          perPage: 10,
          page: 1,
          // parentId: "null",
        };
      }
      var results = await MasterItemService.getData(params);
      // eslint-disable-next-line no-console
      var datax = await {
        data: results.data,
        totalCount: 10,
        // totalCount: results.total,
      };
      return await datax;
    },

    onSelectionChanged({ selectedRowKeys, selectedRowsData }) {
      // console.log("selectedRowKeys : " + selectedRowKeys.length);
      if (selectedRowKeys.length > 0) {
        if (selectedRowKeys.length == 1) {
          this.setMasterItem(selectedRowsData[0]);
          this.btnAdd.option({
            disabled: false,
          });
          this.btnEdit.option({
            disabled: false,
          });
          this.btnView.option({
            disabled: false,
          });
        } else {
          this.setMasterItem(selectedRowsData);
          // console.log(selectedRowsData);
          this.btnAdd.option({
            disabled: true,
          });
          this.btnEdit.option({
            disabled: true,
          });
          this.btnView.option({
            disabled: true,
          });
        }
        this.btnDelete.option({
          disabled: false,
        });
      } else {
        this.clearMasterItem();
        this.btnAdd.option({
          disabled: true,
        });
        this.btnEdit.option({
          disabled: true,
        });
        this.btnDelete.option({
          disabled: true,
        });
        this.btnView.option({
          disabled: true,
        });
      }
    },
    onToolbarPreparing(e) {
      var vThis = this;

      e.toolbarOptions.items.unshift(
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "add",
            text: "Tambah",
            onInitialized: function (e) {
              vThis.btnAdd = e.component;
            },
            onClick: function () {
              vThis.setMasterItemStatus("add");
              router.push("/master/item/add");
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "edit",
            text: "Edit",
            disabled: true,
            onInitialized: function (e) {
              vThis.btnEdit = e.component;
            },
            onClick: function () {
              vThis.setMasterItemStatus("edit");
              router.push("/master/item/edit");
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "trash",
            text: "Hapus",
            disabled: true,
            onInitialized: function (e) {
              vThis.btnDelete = e.component;
            },
            onClick: function () {
              let result = confirm(
                "<i>Anda yakin mau menghapus data ini ?</i>",
                "Hapus MasterItem MasterItem"
              );
              result.then((dialogResult) => {
                if (dialogResult) {
                  var MasterItemData = vThis.getMasterItem;
                  console.log(vThis.getMasterItem);
                  var ids = "";
                  if (Array.isArray(MasterItemData)) {
                    MasterItemData.forEach(function (item, index) {
                      if (index == 0) {
                        ids += item.id;
                      } else {
                        ids += "," + item.id;
                      }
                    });
                  } else {
                    ids = MasterItemData.id;
                  }
                  MasterItemService.delete(
                    ids,
                    function () {
                      vThis.clearMasterItem();
                      vThis.dataGrid.refresh();
                    },
                    function (error) {
                      // eslint-disable-next-line no-console
                      console.log(error);
                    }
                  );
                }
              });
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "detailslayout",
            text: "Detail",
            disabled: true,
            onInitialized: function (e) {
              vThis.btnView = e.component;
            },
            onClick: function () {
              router.push("/master/item/view");
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "refresh",
            text: "Muat Ulang",
            onClick: function () {
              vThis.dataGrid.refresh();
            },
          },
        },
        // Filter //
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "search",
            text: vThis.filterText,
            disabled: false,
            onInitialized: function (e) {
              vThis.btnFilter = e.component;
            },
            onClick: function () {
              if (vThis.filterStatus === true) {
                vThis.filterStatus = false;
                vThis.filterText = "Filter ON";
                // console.log("filterStatus : " + vThis.filterStatus);
              } else {
                vThis.filterStatus = true;
                vThis.filterText = "Filter Off";
                // console.log("filterStatus : " + vThis.filterStatus);
              }
            },
          },
        }
      );
    },
    onRowExpanding(e) {
      var dataGrid = e.component;
      dataGrid.collapseAll(-1);
    },

    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Workbook");
      // const worksheet = workbook
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        topLeftCell: { row: 4, column: 1 },
      })
        .then((cellRange) => {
          // header
          const headerRow = worksheet.getRow(2);
          headerRow.height = 30;
          worksheet.mergeCells(2, 1, 2, 8);

          headerRow.getCell(1).value = "Daftar Item Stock";
          headerRow.getCell(1).font = { name: "Segoe UI Light", size: 22 };
          headerRow.getCell(1).alignment = { horizontal: "left" };

          // footer
          const footerRowIndex = cellRange.to.row + 2;
          const footerRow = worksheet.getRow(footerRowIndex);
          worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 8);

          footerRow.getCell(1).value = "";
          footerRow.getCell(1).font = {
            color: { argb: "BFBFBF" },
            italic: true,
          };
          footerRow.getCell(1).alignment = { horizontal: "right" };
        })
        .then(function () {
          workbook.xlsx.writeBuffer().then(function (buffer) {
            saveAs(
              new Blob([buffer], { type: "application/octet-stream" }),
              "data-item-wms.xlsx"
            );
          });
        });
      e.cancel = true;
    },
  },
  computed: {
    ...mapGetters("masteritem", [
      "getMasterItem",
      "getMasterItemStatus",
      "getMasterItemForm",
    ]),
    dataGrid: function () {
      return this.$refs[gridRef].instance;
    },
  },
};
</script>

<style>
</style>
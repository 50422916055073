<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <DxForm
        @field-data-changed="formFieldDataChanged"
        :form-data="getStockReturnStatus == 'edit' ? getStockReturn : null"
        :ref="formRef"
        :col-count="2"
      >
        <DxGroupItem>
          <DxSimpleItem
            data-field="user_id"
            :visible="false"
            :editor-options="{ value: userId }"
          />
          <DxSimpleItem
            data-field="uuid"
            :visible="false"
            :editor-options="{
              value: currentUUid,
            }"
          />
          <DxSimpleItem
            data-field="received_date"
            :label="{ text: 'Tanggal Retur Barang' }"
            editor-type="dxDateBox"
          >
            <DxRequiredRule message="Tanggal Retur Barang Wajib di Isi" />
          </DxSimpleItem>

          <DxSimpleItem
            data-field="retur_number"
            :label="{ text: 'Nomor Retur' }"
          >
            <DxRequiredRule message="Nomor Mutasi Wajib di Isi" />
          </DxSimpleItem>

          <DxSimpleItem
            data-field="origin_type_id"
            :label="{ text: 'Pilih Tipe Asal Retur' }"
          >
            <template #default>
              <DxSelectBox
                :data-source="GetSourceType"
                display-expr="source_type_name"
                value-expr="id"
                placeholder="Select Origin Type"
                @value-changed="valueChangedOriginType"
                :search-enabled="true"
                :value="
                  getStockReturnStatus == 'edit'
                    ? getStockReturn.origin_type_id
                    : null
                "
              >
              </DxSelectBox>
            </template>
          </DxSimpleItem>
        </DxGroupItem>

        <DxGroupItem>
          <DxSimpleItem
            data-field="origin_id"
            :label="{ text: 'Pilih Asal Retur' }"
          >
            <template #default>
              <DxSelectBox
                :data-source="GetOrigin"
                display-expr="name"
                value-expr="id"
                placeholder="Select Origin"
                @value-changed="valueChangedOrigin"
                :search-enabled="true"
                :value="
                  getStockReturnStatus == 'edit'
                    ? getStockReturn.origin_id
                    : null
                "
              >
              </DxSelectBox>
            </template>
          </DxSimpleItem>

          <DxSimpleItem
            data-field="destination_id"
            :label="{ text: 'Pilih Warehouse Tujuan' }"
          >
            <template #default>
              <DxSelectBox
                :data-source="GetWarehouseDest"
                display-expr="name"
                value-expr="id"
                placeholder="Select Warehouse Destination"
                @value-changed="valueChangedWarehouse"
                :search-enabled="true"
                :value="
                  getStockReturnStatus == 'edit'
                    ? getStockReturn.destination_id
                    : null
                "
              >
              </DxSelectBox>
            </template>
          </DxSimpleItem>

          <DxSimpleItem data-field="remark" :label="{ text: 'Keterangan' }">
          </DxSimpleItem>
        </DxGroupItem>
      </DxForm>
    </div>
    <!-- <div class="content-block dx-card responsive-paddings">
      <dx-data-grid
        class="dx-card wide-card"
        :data-source="dataSource"
        :show-borders="true"
        :focused-row-index="0"
        :focused-row-enabled="true"
        :column-auto-width="true"
        :column-hiding-enabled="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :loadPanel="loadPanel"
        :selected-row-keys="selectedRowKeys"
        @toolbar-preparing="onToolbarPreparing($event)"
        @selection-changed="onSelectionChanged"
        @rowExpanding="onRowExpanding"
        @exporting="onExporting"
        :ref="gridRef"
      >
        <dx-paging :page-size="10" />
        <dx-pager
          :showPageSizeSelector="true"
          :allowed-page-sizes="pageSizes"
          :show-info="true"
          :showNavigationButtons="true"
          infoText="Hal {0} dari {1} ({2} items)"
          :visible="true"
        />
        <dx-filter-row :visible="filterStatus" />
        <DxSelection mode="multiple" />
        <DxColumn data-field="item_id" caption="Item" />
        <DxColumn data-field="qty" caption="qty" />
        <DxColumn data-field="price" caption="price" />
        <DxColumn data-field="batch_number" caption="batch_number" />
        <DxColumn data-field="item_condition" caption="item_condition" />
        <DxColumn
          data-field="expired_date"
          dataType="date"
          format="dd/MM/yyyy"
          caption="expired_month"
        />
      </dx-data-grid>
    </div> -->
  </div>
</template>
<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from "devextreme-vue/form";
// import {
//   DxDataGrid,
//   DxPager,
//   DxPaging,
//   DxSelection,
//   DxColumn,
//   DxFilterRow,
// } from "devextreme-vue/data-grid";
// import CustomStore from "devextreme/data/custom_store";
import StockReturnDetailService from "@/services/StockReturnDetailService";
import DxTextArea from "devextreme-vue/text-area";
import { mapGetters, mapActions } from "vuex";
import DxSelectBox from "devextreme-vue/select-box";
import { uuid } from "vue-uuid";
import axios from "axios";
const formRef = "stockreturn-form";
const gridRef = "gridRef";
export default {
  components: {
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    // eslint-disable-next-line
    DxTextArea,
    DxRequiredRule,
    DxSelectBox,

    // DxDataGrid,
    // DxPager,
    // DxPaging,
    // DxSelection,
    // DxColumn,
    // DxFilterRow,
  },
  data: function () {
    // const vthis = this;
    // const dataSource = {
    //   store: new CustomStore({
    //     key: "id",
    //     load: async function (loadOptions) {
    //       var sortedBy = "";
    //       if (loadOptions.sort) {
    //         sortedBy += loadOptions.sort[0].selector;
    //         if (loadOptions.sort[0].desc) {
    //           sortedBy += " desc";
    //         } else {
    //           sortedBy += " asc";
    //         }
    //       }

    //       var perPage = loadOptions.take;
    //       var offset = loadOptions.skip;
    //       var page = offset / perPage + 1;

    //       var datax = vthis.fetch({
    //         perPage: perPage || 10,
    //         page: page || 1,
    //         sortedBy: sortedBy,
    //         parentId: "null",
    //       });

    //       return await datax;
    //     },
    //   }),
    // };
    return {
      userId: null,
      filterStatus: false,
      gridRef,
      // dataSource: dataSource,
      pageSizes: [5, 10, 15],
      selectedRowKeys: [],
      loadPanel: {
        text: "Memuat...",
      },
      formRef,
      GetSourceType: [],
      GetWarehouse: [],
      GetOrigin: [],
      GetWarehouseDest: [],
      uuid: uuid.v1(),
    };
  },
  created() {
    this.currentUUid = this.uuid;
    this.userId = window.localStorage.getItem("usrid");
    axios
      .get( "source_types")
      .then((response) => {
        this.GetSourceType = response.data.data;
      });

    this.roleid = window.localStorage.getItem("roleid");
    this.warehouseId = window.localStorage.getItem("wrhid");
    if (this.roleid == 5) {
      axios
        .get(
           "warehouses/id/" + this.warehouseId
        )
        .then((response) => {
          this.GetWarehouseDest = response.data.data;
        });
    } else {
      axios
        .get( "warehouses")
        .then((response) => {
          this.GetWarehouseDest = response.data.data;
        });
    }

    // axios
    //   .get( "warehouses")
    //   .then((response) => {
    //     //console.log(JSON.stringify(response.data));
    //     this.GetWarehouseDest = response.data.data;
    //   });
    if (this.getStockReturn) {
      switch (this.getStockReturn.origin_type_id) {
        case 1:
          axios
            .get( "warehouses")
            .then((response) => {
              this.GetOrigin = response.data.data;
            });
          break;
        case 2:
          axios
            .get( "customers")
            .then((response) => {
              this.GetOrigin = response.data.data;
            });
          break;
        case 3:
          axios
            .get( "clients_customers")
            .then((response) => {
              this.GetOrigin = response.data.data;
            });
          break;
        default:
          axios
            .get( "warehouses")
            .then((response) => {
              this.GetOrigin = response.data.data;
            });
      }
    }
  },
  mounted() {
    this.formSet();
  },
  methods: {
    ...mapActions("stockreturn", [
      "setStockReturn",
      "clearStockReturn",
      "setStockReturnStatus",
      "clearStockReturnStatus",
      "setStockReturnForm",
    ]),

    async fetch(params = {}) {
      // eslint-disable-next-line no-console
      if (Object.keys(params).length === 0) {
        params = {
          perPage: 10,
          page: 1,
          // parentId: "null",
        };
      }
      var results = await StockReturnDetailService.getData(params);
      // eslint-disable-next-line no-console
      var datax = await {
        data: results.data,
        totalCount: 10,
        // totalCount: results.total,
      };
      return await datax;
    },

    valueChangedOriginType(e) {
      this.$refs[formRef].instance.updateData({
        origin_type_id: e.value,
      });
      switch (e.value) {
        case 1:
          axios
            .get( "warehouses")
            .then((response) => {
              this.GetOrigin = response.data.data;
            });
          break;
        case 2:
          axios
            .get( "customers")
            .then((response) => {
              this.GetOrigin = response.data.data;
            });
          break;
        case 3:
          axios
            .get( "clients_customers")
            .then((response) => {
              this.GetOrigin = response.data.data;
            });
          break;
        default:
          axios
            .get( "warehouses")
            .then((response) => {
              this.GetOrigin = response.data.data;
            });
      }
    },

    valueChangedWarehouse(e) {
      this.$refs[formRef].instance.updateData({
        destination_id: e.value,
      });
    },
    valueChangedOrigin(e) {
      this.$refs[formRef].instance.updateData({
        origin_id: e.value,
      });
    },
    formFieldDataChanged(e) {
      this.setStockReturn(e.component.option("formData"));
    },
    formSet() {
      this.setStockReturnForm(this.$refs[formRef].instance);
    },
  },

  computed: {
    ...mapGetters("stockreturn", [
      "getStockReturn",
      "getStockReturnStatus",
      "getStockReturnForm",
    ]),
  },
};
</script>
<style>
.group-padding {
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.form-block {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  padding-left: 40px;
  padding-right: 40px;
}
</style>

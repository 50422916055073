import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import Vue from "vue";

import App from "./App";
import router from "./router";
import appInfo from "./app-info";
import appVersion from "./app-version";
import store from './store/index';
import moment from 'moment'
// import "jquery-ui/themes/base/all.css";
// import "devextreme/dist/css/dx.common.css";
// import "devextreme/dist/css/dx.light.css";
// import "@devexpress/analytics-core/dist/css/dx-analytics.common.css";
// import "@devexpress/analytics-core/dist/css/dx-analytics.light.css";
// import "@devexpress/analytics-core/dist/css/dx-querybuilder.css";
// import "devexpress-reporting/dist/css/dx-webdocumentviewer.css";
// import "devexpress-reporting/dist/css/dx-reportdesigner.css";

Vue.config.productionTip = false;
Vue.prototype.$appInfo = appInfo;
Vue.prototype.$appVersion = appVersion;
Vue.config.productionTip = false

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
});

new Vue({
  router,
  store,
  filters: {
    unescape: v => unescape(v)
  },
  render: h => h(App)
}).$mount("#app");

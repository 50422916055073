<template>
  <DxForm
    class="form-block"
    @field-data-changed="formFieldDataChanged"
    :form-data="getCustomer"
    :read-only="true"
    :ref="formRef"
  >
    <DxGroupItem :col-count="2" css-class="group-padding">
      <DxGroupItem>
        <DxSimpleItem
          data-field="customer_code"
          :label="{ text: 'Customer Code' }"
        >
        </DxSimpleItem>

        <DxSimpleItem
          data-field="customer_name"
          :label="{ text: 'Customer Name' }"
        >
        </DxSimpleItem>

        <DxSimpleItem
          data-field="customer_phone"
          :label="{ text: 'Customer Phone' }"
        >
        </DxSimpleItem>
      </DxGroupItem>
      <DxGroupItem>
        <DxSimpleItem
          data-field="customer_address"
          editor-type="dxTextArea"
          :editor-options="{ height: 90 }"
          :label="{ text: 'Customer Address' }"
        />
        <DxSimpleItem
          data-field="customer_desc"
          editor-type="dxTextArea"
          :editor-options="{ height: 90 }"
          :label="{ text: 'Customer Desc' }"
        />
      </DxGroupItem>
    </DxGroupItem>
  </DxForm>
</template>
<script>
import { DxForm, DxSimpleItem, DxGroupItem } from "devextreme-vue/form";
import DxTextArea from "devextreme-vue/text-area";
import { mapGetters, mapActions } from "vuex";

const formRef = "customer-form";

export default {
  components: {
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    // eslint-disable-next-line
    DxTextArea,
  },
  data: function () {
    return {
      formRef,
    };
  },
  mounted() {
    this.formSet();
    this.$emit("typecustomer", this.getCustomer.type_id);
  },
  methods: {
    ...mapActions("customer", ["setCustomer", "setCustomerForm"]),
    formFieldDataChanged(e) {
      this.setCustomer(e.component.option("formData"));
    },
    formSet() {
      this.setForm(this.$refs[formRef].instance);
    },
  },
  computed: {
    ...mapGetters("customer", ["getCustomer", "getCustomerStatus"]),
  },
};
</script>
<style>
.group-padding {
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.form-block {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  padding-left: 40px;
  padding-right: 40px;
}
</style>

<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <DxForm
        @field-data-changed="formFieldDataChanged"
        :form-data="
          getStockReturnDetailStatus == 'edit' ? getStockReturnDetail : null
        "
        :ref="formRef"
        :col-count="2"
      >
        <DxGroupItem>
          <DxSimpleItem
            data-field="user_id"
            :visible="false"
            :editor-options="{ value: userId }"
          />
          <DxSimpleItem
            data-field="uuid"
            :visible="false"
            :editor-options="{
              value: currentUUid,
            }"
          />
          <DxSimpleItem
            data-field="warehouse_id"
            :label="{ text: 'warehouse_id' }"
            :visible="false"
            :editor-options="{
              value: this.getStockReturnDetailTrxNum[0].warehouse_id,
            }"
          />
          <DxSimpleItem
            data-field="transaction_number"
            :label="{ text: 'No Transaksi' }"
            :visible="false"
            :editor-options="{
              value: this.getStockReturnDetailTrxNum[0].transaction_number,
            }"
          >
            <!-- <DxRequiredRule message="'No Transaksi Wajib di Isi" /> -->
          </DxSimpleItem>

          <!-- <DxSimpleItem data-field="item_id" :label="{ text: 'Nama Item' }">
            <DxRequiredRule message="Nama Item Wajib di Isi" />
          </DxSimpleItem> -->

          <DxSimpleItem
            data-field="item_id"
            :label="{ text: 'Pilih Nama Item' }"
          >
            <template #default>
              <DxSelectBox
                :data-source="GetItem"
                display-expr="item_name"
                value-expr="id"
                placeholder="Select Source"
                @value-changed="valueChangedItem"
                :search-enabled="true"
                :value="
                  getStockReturnDetailStatus == 'edit'
                    ? getStockReturnDetail.item_id
                    : null
                "
              >
              </DxSelectBox>
            </template>
          </DxSimpleItem>

          <DxSimpleItem data-field="qty" :label="{ text: 'Jumlah' }">
            <DxRequiredRule message="Jumlah Wajib di Isi" />
          </DxSimpleItem>

          <DxSimpleItem data-field="price" :label="{ text: 'Harga' }">
            <DxRequiredRule message="Harga Wajib di Isi" />
          </DxSimpleItem>
        </DxGroupItem>
        <DxGroupItem>
          <DxSimpleItem
            data-field="batch_number"
            :label="{ text: 'Batch number' }"
          >
            <DxRequiredRule message="Batch number Wajib di Isi" />
          </DxSimpleItem>

          <DxSimpleItem
            data-field="item_condition"
            :label="{ text: 'Pilih Kondisi Item' }"
          >
            <template #default>
              <DxSelectBox
                :data-source="GetItemCondition"
                display-expr="name"
                value-expr="id"
                placeholder="Select Source"
                @value-changed="valueChangedItemCondition"
                :search-enabled="true"
                :value="
                  getStockReturnDetailStatus == 'edit'
                    ? getStockReturnDetail.item_condition_id
                    : null
                "
              >
              </DxSelectBox>
            </template>
          </DxSimpleItem>

          <DxSimpleItem
            data-field="expired_date"
            :label="{ text: 'Tanggal Expired' }"
            editor-type="dxDateBox"
            :editor-options="{ dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ss' }"
          >
            <DxRequiredRule message="Tanggal Expired Barang Wajib di Isi" />
          </DxSimpleItem>
        </DxGroupItem>
      </DxForm>
    </div>
  </div>
</template>
<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from "devextreme-vue/form";
// import {
//   DxDataGrid,
//   DxPager,
//   DxPaging,
//   DxSelection,
//   DxColumn,
//   DxFilterRow,
// } from "devextreme-vue/data-grid";
// import CustomStore from "devextreme/data/custom_store";
// import StockReturnDetailService from "@/services/StockReturnDetailService";
import DxTextArea from "devextreme-vue/text-area";
import { mapGetters, mapActions } from "vuex";
import DxSelectBox from "devextreme-vue/select-box";
import { uuid } from "vue-uuid";
import axios from "axios";
const formRef = "StockReturnDetail-form";
const gridRef = "gridRef";
export default {
  components: {
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    // eslint-disable-next-line
    DxTextArea,
    DxRequiredRule,
    DxSelectBox,
    // DxDataGrid,
    // DxPager,
    // DxPaging,
    // DxSelection,
    // DxColumn,
    // DxFilterRow,
  },
  data: function () {
    return {
      userId: null,
      filterStatus: false,
      gridRef,
      // dataSource: dataSource,
      pageSizes: [5, 10, 15],
      selectedRowKeys: [],
      loadPanel: {
        text: "Memuat...",
      },
      formRef,
      GetSourceType: [],
      GetWarehouse: [],
      GetOrigin: [],
      GetWarehouseDest: [],
      GetItem: [],
      GetItemCondition: [],
      uuid: uuid.v1(),
    };
  },
  created() {
    console.log(
      "this.getStockReturnDetailTrxNum :" +
        JSON.stringify(this.getStockReturnDetailTrxNum)
    );
    this.currentUUid = this.uuid;
    this.userId = window.localStorage.getItem("usrid");
    axios
      .get( "master_items")
      .then((response) => {
        this.GetItem = response.data.data;
      });
    axios
      .get( "item_conditions")
      .then((response) => {
        //console.log(JSON.stringify(response.data));
        this.GetItemCondition = response.data.data;
      });
  },
  mounted() {
    this.formSet();
  },
  methods: {
    ...mapActions("stockreturndetail", [
      "setStockReturnDetail",
      "clearStockReturnDetail",
      "setStockReturnDetailStatus",
      "clearStockReturnDetailStatus",
      "setStockReturnDetailForm",
    ]),

    valueChangedItem(e) {
      this.$refs[formRef].instance.updateData({
        item_id: e.value,
      });
    },
    valueChangedItemCondition(e) {
      this.$refs[formRef].instance.updateData({
        item_condition_id: e.value,
      });
    },
    formFieldDataChanged(e) {
      console.log(JSON.stringify(e.component.option("formData")));
      this.setStockReturnDetail(e.component.option("formData"));
    },
    formSet() {
      this.setStockReturnDetailForm(this.$refs[formRef].instance);
    },
  },

  computed: {
    ...mapGetters("stockreturn", ["getStockReturn"]),
    ...mapGetters("stockreturndetail", [
      "getStockReturnDetail",
      "getStockReturnDetailStatus",
      "getStockReturnDetailForm",
      "getStockReturnDetailTrxNum",
    ]),
  },
};
</script>
<style>
.group-padding {
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.form-block {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  padding-left: 40px;
  padding-right: 40px;
}
</style>

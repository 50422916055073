const state = {
  companybranch: null,
  companybranchStatus: null,
  companybranchForm: null,
};

const getters = {
  getCompanyBranch: (state) => {
    return state.companybranch;
  },
  getCompanyBranchStatus: (state) => {
    return state.companybranchStatus;
  },
  getCompanyBranchForm: (state) => {
    return state.companybranchForm;
  },
};

const mutations = {
  setCompanyBranch: (state, companybranch) => {
    state.companybranch = companybranch;
  },
  clearCompanyBranch: (state) => {
    state.companybranch = null;
  },

  setCompanyBranchStatus: (state, status) => {
    state.companybranchStatus = status;
  },
  clearCompanyBranchStatus: (state) => {
    state.companybranchStatus = "";
  },

  setCompanyBranchForm: (state, status) => {
    state.companybranchForm = status;
  },
  clearCompanyBranchForm: (state) => {
    state.companybranchForm = "";
  },
};

const actions = {
  setCompanyBranch({ commit }, data) {
    commit("setCompanyBranch", data);
  },
  clearCompanyBranch({ commit }) {
    commit("clearCompanyBranch");
  },

  setCompanyBranchStatus({ commit }, status) {
    commit("setCompanyBranchStatus", status);
  },
  clearCompanyBranchStatus({ commit }) {
    commit("clearCompanyBranchStatus");
  },

  setCompanyBranchForm({ commit }, status) {
    commit("setCompanyBranchForm", status);
  },
  clearCompanyBranchForms({ commit }) {
    commit("clearCompanyBranchForm");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div id="DeptForm">
    <h2 class="content-block">
      {{ getStockDestroyStatus == "add" ? "Tambah" : "Ubah" }}
      Stock Quarantine
    </h2>
    <DxToolbar class="padding-block">
      <DxItem
        :options="backButtonOptions"
        widget="dxButton"
        location="before"
      />
      <DxItem
        :options="saveButtonOptions"
        widget="dxButton"
        location="before"
      />
    </DxToolbar>
    <StockDestroyForm />
    <span v-html="linebreaks"></span>
    <DxToolbar class="padding-block">
      <DxItem
        :options="backButtonOptions"
        widget="dxButton"
        location="before"
      />
      <DxItem
        :options="saveButtonOptions"
        widget="dxButton"
        location="before"
      />
    </DxToolbar>
    <DxLoadPanel
      :position="{ of: '#mainContent' }"
      :visible.sync="loadingVisible"
      :show-indicator="true"
      :show-pane="true"
      :shading="true"
      :close-on-destroyside-click="false"
      shading-color="rgba(0,0,0,0.4)"
    />
  </div>
</template>
<script>
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import { DxLoadPanel } from "devextreme-vue/load-panel";
import router from "../../router";
import { mapGetters, mapActions } from "vuex";
import StockDestroyForm from "@/components/stock-destroy/StockDestroyForm.vue";
import StockDestroyService from "@/services/StockDestroyService";
import moment from "moment";
import axios from "axios";
// import { uuid } from 'vue-uuid';

export default {
  components: {
    DxToolbar,
    DxItem,
    StockDestroyForm,
    DxLoadPanel,
  },
  data() {
    return {
      linebreaks: "<br>",
      loadingVisible: false,
      backButtonOptions: {
        icon: "chevronleft",
        text: "Kembali",
        onClick: () => {
          this.$router.go(-1);
        },
      },
      saveButtonOptions: {
        icon: "save",
        text: "Simpan",
        onClick: () => {
          // eslint-disable-next-line no-console
          var validateResult = this.getStockDestroyForm.validate();
          if (validateResult.isValid) {
            // var StockDestroy = {
            //   name : this.getStockDestroy.name,
            //   shortName : this.getStockDestroy.shortName,
            //   // uniqueId : uuid.v4(),
            //   description : this.getStockDestroy.description
            // };
            this.userId = window.localStorage.getItem("usrid");
            var vThis = this;
            var StockDestroy = this.getStockDestroy;

            if (StockDestroy) {
              if (this.getStockDestroyStatus == "add") {
                console.log("Add StockDestroy");
                this.loadingVisible = true;
                StockDestroy.created_by = this.userId;
                StockDestroy.transaction_number = this.format_date(new Date());

                ////////////////////////////////////////////////////////////
                let formData = new FormData();
                formData.append("warehouse_id", StockDestroy.warehouse_id);
                formData.append("uuid", StockDestroy.uuid);
                formData.append(
                  "transaction_number",
                  StockDestroy.transaction_number
                );
                formData.append("stock_action", 220); //StockDestroy
                formData.append("batch_number", StockDestroy.batch_number);
                formData.append("item_id", StockDestroy.item_id);
                formData.append("qty", StockDestroy.qty);
                formData.append(
                  "item_condition_id",
                  StockDestroy.item_condition_id
                );
                formData.append("remark", "destroy");
                formData.append("expired_date", StockDestroy.expired_date);

                axios
                  .post("stock_reports", formData)
                  .then(function (response) {
                    console.log(response);
                    vThis.success = response.data.success;
                  })
                  .catch(function (error) {
                    console.log(error);
                    vThis.output = error;
                  });
                ////////////////////////////////////////////////////////////

                StockDestroyService.insert(
                  StockDestroy,
                  function () {
                    vThis.loadingVisible = false;
                    vThis.clearStockDestroyStatus();
                    vThis.clearStockDestroy();
                    router.push("/stock/destroy");
                  },
                  function () {
                    vThis.loadingVisible = false;
                  }
                );
              } else if (this.getStockDestroyStatus == "edit") {
                console.log("Edit StockDestroy ");
                StockDestroy.updated_by = this.userId;

                const param = {
                  warehouse_id: StockDestroy.warehouse_id,
                  uuid: StockDestroy.uuid,
                  transaction_number: StockDestroy.transaction_number,
                  stock_action: 220,
                  batch_number: StockDestroy.batch_number,
                  item_id: StockDestroy.item_id,
                  qty: StockDestroy.qty,
                  item_condition_id: StockDestroy.item_condition_id,
                  remark: "firststock",
                  expired_date: StockDestroy.expired_date,
                };

                vThis.updateReport(StockDestroy.uuid, param);

                StockDestroyService.update(
                  this.getStockDestroy.id,
                  StockDestroy,
                  function () {
                    vThis.loadingVisible = false;
                    vThis.clearStockDestroyStatus();
                    vThis.clearStockDestroy();
                    router.push("/stock/destroy");
                  },
                  function () {
                    vThis.loadingVisible = false;
                  }
                );
              } else if (this.getStockDestroyStatus == "addFromStockIn") {
                console.log("Add StockDestroy");
                this.loadingVisible = true;
                StockDestroy.created_by = this.userId;
                /////////////////////
                let formData = new FormData();
                formData.append("warehouse_id", StockDestroy.warehouse_id);
                formData.append("uuid", StockDestroy.uuid);
                formData.append(
                  "transaction_number",
                  StockDestroy.transaction_number
                );
                formData.append("stock_action", StockDestroy.stock_action); //stock Destroy
                formData.append("batch_number", StockDestroy.batch_number);
                formData.append("item_id", StockDestroy.item_id);
                formData.append("qty", StockDestroy.qty);
                formData.append(
                  "item_condition_id",
                  StockDestroy.item_condition_id
                );
                formData.append("remark", "stock-destroy");
                formData.append("expired_date", StockDestroy.expired_date);
                axios
                  .post("stock_reports", formData)
                  .then(function (response) {
                    console.log(response);
                    vThis.success = response.data.success;
                  })
                  .catch(function (error) {
                    console.log(error);
                    vThis.output = error;
                  });
                // selanjutanya input data ke tabel master
                StockDestroyService.insert(
                  StockDestroy,
                  function () {
                    vThis.loadingVisible = false;
                    vThis.clearStockDestroyStatus();
                    vThis.clearStockDestroy();
                    router.push("/stock/destroy");
                  },
                  function () {
                    vThis.loadingVisible = false;
                  }
                );
              }
            }
          }
        },
      },
    };
  },
  methods: {
    ...mapActions("stockdestroy", [
      "setStockDestroy",
      "clearStockDestroy",
      "setStockDestroyStatus",
      "clearStockDestroyStatus",
    ]),
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYYMMDDHHmmss");
      }
    },
  },
  computed: {
    ...mapGetters("stockdestroy", [
      "getStockDestroy",
      "getStockDestroyStatus",
      "getStockDestroyForm",
    ]),
  },
};
</script>

<style>
.toolbar-label,
.toolbar-label > b {
  font-size: 16px;
}

#products {
  margin-top: 10px;
}

.padding-block {
  padding-left: 20px;
  padding-right: 20px;
}
</style>
<template>
  <div id="DeptForm">
    <h2 class="content-block">
      {{ getStockInStatus == "add" ? "Tambah" : "Ubah" }}
      Stock In
    </h2>
    <DxToolbar class="padding-block">
      <DxItem
        :options="backButtonOptions"
        widget="dxButton"
        location="before"
      />
      <DxItem
        :options="saveButtonOptions"
        widget="dxButton"
        location="before"
      />
    </DxToolbar>
    <StockInForm />
    <span v-html="linebreaks"></span>
    <DxToolbar class="padding-block">
      <DxItem
        :options="backButtonOptions"
        widget="dxButton"
        location="before"
      />
      <DxItem
        :options="saveButtonOptions"
        widget="dxButton"
        location="before"
      />
    </DxToolbar>
    <DxLoadPanel
      :position="{ of: '#mainContent' }"
      :visible.sync="loadingVisible"
      :show-indicator="true"
      :show-pane="true"
      :shading="true"
      :close-on-outside-click="false"
      shading-color="rgba(0,0,0,0.4)"
    />
  </div>
</template>
<script>
import StockInForm from "@/components/stock-in/StockInForm.vue";
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import { DxLoadPanel } from "devextreme-vue/load-panel";
import router from "../../router";
import { mapGetters, mapActions } from "vuex";
import StockInService from "@/services/StockInService";
import moment from "moment";

// import { uuid } from 'vue-uuid';

export default {
  components: {
    DxToolbar,
    DxItem,
    StockInForm,
    DxLoadPanel,
  },
  data() {
    return {
      linebreaks: "<br>",
      loadingVisible: false,
      backButtonOptions: {
        icon: "chevronleft",
        text: "Kembali",
        onClick: () => {
          this.$router.go(-1);
        },
      },
      saveButtonOptions: {
        icon: "save",
        text: "Simpan",
        onClick: () => {
          // eslint-disable-next-line no-console
          var validateResult = this.getStockInForm.validate();
          if (validateResult.isValid) {
            // var StockIn = {
            //   name : this.getStockIn.name,
            //   shortName : this.getStockIn.shortName,
            //   // uniqueId : uuid.v4(),
            //   description : this.getStockIn.description
            // };
            this.userId = window.localStorage.getItem("usrid");
            var vThis = this;
            var StockIn = this.getStockIn;

            if (StockIn) {
              if (this.getStockInStatus == "add") {
                console.log("Add StockIn ");
                this.loadingVisible = true;
                StockIn.created_by = this.userId;
                StockIn.transaction_number = this.format_date(new Date());
                StockInService.insert(
                  StockIn,
                  function () {
                    vThis.loadingVisible = false;
                    vThis.clearStockInStatus();
                    vThis.clearStockIn();
                    router.push("/stock/in");
                  },
                  function () {
                    vThis.loadingVisible = false;
                  }
                );
              } else if (this.getStockInStatus == "edit") {
                console.log("Edit StockIn StockIn ");
                StockIn.updated_by = this.userId;
                StockInService.update(
                  this.getStockIn.id,
                  StockIn,
                  function () {
                    vThis.loadingVisible = false;
                    vThis.clearStockInStatus();
                    vThis.clearStockIn();
                    router.push("/stock/in");
                  },
                  function () {
                    vThis.loadingVisible = false;
                  }
                );
              }
            }
          }
        },
      },
    };
  },
  methods: {
    ...mapActions("stockin", [
      "setStockIn",
      "clearStockIn",
      "setStockInStatus",
      "clearStockInStatus",
    ]),
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYYMMDDHHmmss");
      }
    },
  },
  computed: {
    ...mapGetters("stockin", [
      "getStockIn",
      "getStockInStatus",
      "getStockInForm",
    ]),
  },
};
</script>

<style>
.toolbar-label,
.toolbar-label > b {
  font-size: 16px;
}

#products {
  margin-top: 10px;
}

.padding-block {
  padding-left: 20px;
  padding-right: 20px;
}
</style>
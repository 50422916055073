var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"content-block dx-card responsive-paddings"},[_c('DxForm',{ref:_vm.formRef,attrs:{"form-data":_vm.getStockInDetailStatus == 'edit' ? _vm.getStockInDetail : null,"col-count":2},on:{"field-data-changed":_vm.formFieldDataChanged}},[_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"data-field":"user_id","visible":false,"editor-options":{ value: _vm.userId }}}),_c('DxSimpleItem',{attrs:{"data-field":"uuid","visible":false,"editor-options":{
            value: _vm.currentUUid,
          }}}),_c('DxSimpleItem',{attrs:{"data-field":"warehouse_id","label":{ text: 'warehouse_id' },"visible":false,"editor-options":{
            value: this.getStockInDetailTrxNum[0].warehouse_id,
          }}}),_c('DxSimpleItem',{attrs:{"data-field":"transaction_number","label":{ text: 'No Transaksi' },"visible":false,"editor-options":{
            value: this.getStockInDetailTrxNum[0].transaction_number,
          }}}),_c('DxSimpleItem',{attrs:{"data-field":"item_id","label":{ text: 'Pilih Nama Item' }},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('DxSelectBox',{ref:_vm.selectItemRef,attrs:{"data-source":_vm.GetItem,"display-expr":"item_code_name","value-expr":"id","placeholder":"Select Source","show-drop-down-button":true,"search-enabled":true,"value":_vm.getStockInDetailStatus == 'edit'
                  ? _vm.getStockInDetail.item_id
                  : null},on:{"value-changed":_vm.valueChangedItem,"selection-changed":_vm.selectionChangedItem}},[_c('DxSelectBoxButton',{attrs:{"name":"dropDown","location":"before"}}),_c('DxSelectBoxButton',{attrs:{"name":"clearButton","location":"after","options":{visible:_vm.buttonVisible, icon: 'clear',
                           type: 'danger', onClick: _vm.clearSelection }}})],1)]},proxy:true}])}),_c('DxSimpleItem',{attrs:{"data-field":"qty","editor-type":"dxNumberBox","editor-options":{
            max: '99999',
          },"label":{ text: 'Jumlah' }}},[_c('DxRequiredRule',{attrs:{"message":"Jumlah Wajib di Isi"}})],1),_c('DxSimpleItem',{attrs:{"data-field":"price","editor-type":"dxNumberBox","editor-options":{
            max: '9999999',
          },"label":{ text: 'Harga' }}},[_c('DxRequiredRule',{attrs:{"message":"Harga Wajib di Isi"}})],1)],1),_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"data-field":"batch_number","label":{ text: 'Batch number' },"editor-type":"dxTextBox","editor-options":_vm.batchNumberOptions}},[_c('DxRequiredRule',{attrs:{"message":"Batch number Wajib di Isi"}})],1),_c('DxSimpleItem',{attrs:{"data-field":"item_condition","label":{ text: 'Pilih Kondisi Item' }},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('DxSelectBox',{attrs:{"data-source":_vm.GetItemCondition,"display-expr":"name","value-expr":"id","placeholder":"Select Source","search-enabled":true,"value":_vm.getStockInDetailStatus == 'edit'
                  ? _vm.getStockInDetail.item_condition_id
                  : null},on:{"value-changed":_vm.valueChangedItemCondition}})]},proxy:true}])}),_c('DxSimpleItem',{attrs:{"data-field":"expired_date","label":{ text: 'Tanggal Expired' },"editor-type":"dxDateBox","editor-options":{ dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ss' }}},[_c('DxRequiredRule',{attrs:{"message":"Tanggal Expired Barang Wajib di Isi"}})],1)],1)],1),_c('span',{domProps:{"innerHTML":_vm._s(_vm.linebreaks)}}),_c('div',{staticClass:"buttons-demo",style:({
        display: _vm.vShow,
      })},[_c('div',{staticClass:"buttons"},[_c('div',[_c('div',{staticClass:"buttons-inbound"},[_c('div',[_c('DxButton',{attrs:{"width":500,"text":"Tambah List","type":"normal","styling-mode":"contained"},on:{"click":function($event){return _vm.onClickAddItemToList()}}})],1)])])])])],1),_c('div',{staticClass:"content-block dx-card responsive-paddings",style:({
      display: _vm.vShow,
    })},[_c('dx-data-grid',{ref:_vm.gridRef,staticClass:"dx-card wide-card",attrs:{"data-source":_vm.tempData ? _vm.tempData : _vm.dataNull,"show-borders":true,"focused-row-index":0,"focused-row-enabled":true,"column-auto-width":true,"column-hiding-enabled":true,"show-row-lines":true,"row-alternation-enabled":true},on:{"toolbar-preparing":function($event){return _vm.onToolbarPreparing($event)}},scopedSlots:_vm._u([{key:"nomor",fn:function(ref){
    var data = ref.data;
return [_vm._v(" "+_vm._s(data.rowIndex + 1)+" ")]}}])},[_c('dx-paging',{attrs:{"page-size":10}}),_c('dx-pager',{attrs:{"showPageSizeSelector":true,"allowed-page-sizes":_vm.pageSizes,"show-info":true,"showNavigationButtons":true,"infoText":"Hal {0} dari {1} ({2} items)","visible":true}}),_c('dx-filter-row',{attrs:{"visible":_vm.filterStatus}}),_c('DxEditing',{attrs:{"allow-deleting":true,"mode":"cell"}}),_c('DxSearchPanel',{attrs:{"visible":true}}),_c('DxSelection',{attrs:{"mode":"single"}}),_c('dx-paging',{attrs:{"page-size":10}}),_c('dx-pager',{attrs:{"showPageSizeSelector":true,"allowed-page-sizes":_vm.pageSizes,"show-info":true,"showNavigationButtons":true,"infoText":"Hal {0} dari {1} ({2} items)","visible":true}}),_c('dx-filter-row',{attrs:{"visible":_vm.filterStatus}}),_c('DxSelection',{attrs:{"mode":"single"}}),_c('DxColumn',{attrs:{"data-field":"no","caption":"No","width":70,"cell-template":"nomor"}}),_c('DxColumn',{attrs:{"data-field":"uuid","width":"200","visible":false,"caption":"uuid"}}),_c('DxColumn',{attrs:{"data-field":"item_name","width":"200","caption":"Nama Item"}}),_c('DxColumn',{attrs:{"data-field":"price","caption":"Harga"}}),_c('DxColumn',{attrs:{"data-field":"qty","caption":"Jumlah"}}),_c('DxColumn',{attrs:{"data-field":"batch_number","caption":"Batch Number"}}),_c('DxColumn',{attrs:{"data-field":"item_condition_name","caption":"Kondisi"}}),_c('DxColumn',{attrs:{"data-field":"expired_date","dataType":"date","format":"dd/MM/yyyy","caption":"Tanggal Expired"}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
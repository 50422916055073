const state = {
  stockindetail: null,
  stockindetailStatus: "",
  stockindetailForm: null,
  stockindetailTrxNum: null,
};

const getters = {
  getStockInDetail: (state) => {
    return state.stockindetail;
  },
  getStockInDetailStatus: (state) => {
    return state.stockindetailStatus;
  },
  getStockInDetailForm: (state) => {
    return state.stockindetailForm;
  },
  getStockInDetailTrxNum: (state) => {
    return state.stockindetailTrxNum;
  },
};

const mutations = {
  setStockInDetail: (state, stockindetail) => {
    state.stockindetail = stockindetail;
  },
  clearStockInDetail: (state) => {
    state.stockindetail = null;
  },

  setStockInDetailStatus: (state, status) => {
    state.stockindetailStatus = status;
  },
  clearStockInDetailStatus: (state) => {
    state.stockindetailStatus = "";
  },

  setStockInDetailForm: (state, status) => {
    state.stockindetailForm = status;
  },
  clearStockInDetailForm: (state) => {
    state.stockindetailForm = "";
  },

  setStockInDetailTrxNum: (state, status) => {
    state.stockindetailTrxNum = status;
  },
  clearStockInDetailTrxNum: (state) => {
    state.stockindetailTrxNum = "";
  },
};

const actions = {
  setStockInDetail({ commit }, data) {
    commit("setStockInDetail", data);
  },
  clearStockInDetail({ commit }) {
    commit("clearStockInDetail");
  },

  setStockInDetailStatus({ commit }, status) {
    commit("setStockInDetailStatus", status);
  },
  clearStockInDetailStatus({ commit }) {
    commit("clearStockInDetailStatus");
  },

  setStockInDetailForm({ commit }, data) {
    commit("setStockInDetailForm", data);
  },
  clearStockInDetailForm({ commit }) {
    commit("clearStockInDetailForm");
  },

  setStockInDetailTrxNum({ commit }, data) {
    commit("setStockInDetailTrxNum", data);
  },
  clearStockInDetailTrxNum({ commit }) {
    commit("clearStockInDetailTrxNum");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

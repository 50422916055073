<template>
  <div>
    <h2 class="content-block">Client Customer</h2>
    <clients-customer-table />
    
  </div>
</template>

<script>
// import customerTable from "@/components/customer/CustomerTable.vue";
import ClientsCustomerTable from "@/components/clients-customer/ClientsCustomerTable.vue";

export default {
  components: {
    ClientsCustomerTable
  }
};
</script>
const state = {
  stockdestroy: null,
  stockdestroyStatus: "",
  stockdestroyForm: null,
  stockdestroyTemp: null,
};

const getters = {
  getStockDestroy: (state) => {
    return state.stockdestroy;
  },
  getStockDestroyStatus: (state) => {
    return state.stockdestroyStatus;
  },
  getStockDestroyForm: (state) => {
    return state.stockdestroyForm;
  },
  getStockDestroyTemp: (state) => {
    return state.stockdestroyTemp;
  },
};

const mutations = {
  setStockDestroy: (state, stockdestroy) => {
    state.stockdestroy = stockdestroy;
  },
  clearStockDestroy: (state) => {
    state.stockdestroy = null;
  },

  setStockDestroyStatus: (state, status) => {
    state.stockdestroyStatus = status;
  },
  clearStockDestroyStatus: (state) => {
    state.stockdestroyStatus = "";
  },

  setStockDestroyForm: (state, status) => {
    state.stockdestroyForm = status;
  },
  clearStockDestroyForm: (state) => {
    state.stockdestroyForm = "";
  },

  setStockDestroyTemp: (state, status) => {
    state.stockdestroyTemp = status;
  },
  clearStockDestroyTemp: (state) => {
    state.stockdestroyTemp = "";
  },
};

const actions = {
  setStockDestroy({ commit }, data) {
    commit("setStockDestroy", data);
  },
  clearStockDestroy({ commit }) {
    commit("clearStockDestroy");
  },

  setStockDestroyStatus({ commit }, status) {
    commit("setStockDestroyStatus", status);
  },
  clearStockDestroyStatus({ commit }) {
    commit("clearStockDestroyStatus");
  },

  setStockDestroyForm({ commit }, data) {
    commit("setStockDestroyForm", data);
  },
  clearStockDestroyForm({ commit }) {
    commit("clearStockDestroyForm");
  },
      
  setStockDestroyTemp({ commit }, data) {
    commit("setStockDestroyTemp", data);
  },
  clearStockDestroyTemp({ commit }) {
    commit("clearStockDestroyTemp");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

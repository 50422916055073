<template>
  <DxForm> </DxForm>
</template>

<script>
import { DxForm } from "devextreme-vue/form";
import axios from "axios";


export default {
  props: ["company_id"],
  components: {
    DxForm,
  },
  created() {
    var AUTH_TOKEN = "Bearer " + window.localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;
    
    if (this.company_id)
      axios.get("company_branches/" + this.company_id).then((response) => {
        this.$emit("companybranchData", response.data.data);
      });
    else {
      axios.get("company_branches").then((response) => {
        // console.log(response.data.data)
        this.$emit("companybranchData", response.data.data);
      });
    }
  },
  data() {
    return {
      res: [],
    };
  },
};
</script>

<style>
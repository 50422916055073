<template>
  <div>
    <h2 class="content-block">Role Menu Table</h2>
    <role-menu-table />
  </div>
</template>

<script>
import RoleMenuTable from "@/components/role-menu/RoleMenuTable.vue";
export default {
  components: {
    RoleMenuTable,
  },
};
</script>
const state = {
  customer: null,
  customerStatus: "",
  customerForm: null,
};

const getters = {
  getCustomer: (state) => {
    return state.customer;
  },
  getCustomerStatus: (state) => {
    return state.customerStatus;
  },
  getCustomerForm: (state) => {
    return state.customerForm;
  },
};

const mutations = {
  setCustomer: (state, customer) => {
    state.customer = customer;
  },
  clearCustomer: (state) => {
    state.customer = null;
  },

  setCustomerStatus: (state, status) => {
    state.customerStatus = status;
  },
  clearCustomerStatus: (state) => {
    state.customerStatus = "";
  },

  setCustomerForm: (state, status) => {
    state.customerForm = status;
  },
  clearCustomerForm: (state) => {
    state.customerForm = "";
  },
};

const actions = {
  setCustomer({ commit }, data) {
    commit("setCustomer", data);
  },
  clearCustomer({ commit }) {
    commit("clearCustomer");
  },

  setCustomerStatus({ commit }, status) {
    commit("setCustomerStatus", status);
  },
  clearCustomerStatus({ commit }) {
    commit("clearCustomerStatus");
  },

  setCustomerForm({ commit }, data) {
    commit("setCustomerForm", data);
  },
  clearCustomerForm({ commit }) {
    commit("clearCustomerForm");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div>
    <h2 class="content-block"></h2>
    <dx-data-grid
      class="dx-card wide-card"
      :data-source="dataSource"
      :show-borders="true"
      :focused-row-index="0"
      :focused-row-enabled="true"
      :column-auto-width="true"
      :column-hiding-enabled="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :loadPanel="loadPanel"
      :selected-row-keys="selectedRowKeys"
      @toolbar-preparing="onToolbarPreparing($event)"
      @selection-changed="onSelectionChanged"
      @rowExpanding="onRowExpanding"
      @exporting="onExporting"
    >
      <dx-paging :page-size="10" />
      <dx-pager
        :showPageSizeSelector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
        :showNavigationButtons="true"
        infoText="Hal {0} dari {1} ({2} items)"
        :visible="true"
      />
      <dx-filter-row :visible="filterStatus" />
      <DxSelection mode="multiple" />
      <!-- <DxColumn data-field="role_id" caption="role_id" /> -->
      <DxColumn data-field="role_name" width="150" caption="role_name" />
      <!-- <DxColumn data-field="menu_id" caption="menu_id" /> -->
      <DxColumn data-field="menu_name" caption="menu_name" />
      <!-- <// DxExport :enabled="true" /> -->
    </dx-data-grid>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxPager,
  DxPaging,
  DxSelection,
  DxColumn,
  DxFilterRow,
  // DxExport
} from "devextreme-vue/data-grid";
import CustomStore from "devextreme/data/custom_store";
import RoleMenuService from "@/services/RoleMenuService";

import router from "../../router";
import { mapGetters, mapActions } from "vuex";
import { confirm } from "devextreme/ui/dialog";

import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";

// import axios from "axios";

const gridRef = "role-name-table";

export default {
  // props: ["CustomerId"],
  components: {
    DxDataGrid,
    DxPager,
    DxPaging,
    DxSelection,
    DxColumn,
    DxFilterRow,
    // DxExport
    // DivisionTable,
  },
  data() {
    const vthis = this;
    const dataSource = {
      store: new CustomStore({
        key: "id",
        load: async function (loadOptions) {
          var sortedBy = "";
          if (loadOptions.sort) {
            sortedBy += loadOptions.sort[0].selector;
            if (loadOptions.sort[0].desc) {
              sortedBy += " desc";
            } else {
              sortedBy += " asc";
            }
          }

          var perPage = loadOptions.take;
          var offset = loadOptions.skip;
          var page = offset / perPage + 1;

          var datax = vthis.fetch({
            perPage: perPage || 10,
            page: page || 1,
            sortedBy: sortedBy,
            parentId: "null",
          });

          return await datax;
        },
      }),
    };
    return {
      filterStatus: false,
      filterText: "Filter On",

      gridRef,
      dataSource: dataSource,
      pageSizes: [5, 10, 15],
      selectedRowKeys: [],
      loadPanel: {
        text: "Memuat...",
      },
      btnAdd: null,
      btnEdit: null,
      btnDelete: null,
      btnView: null,
      btnCustFollow: null,
      btnCustPotensial: null,
      btnCustNew: null,
      btnReActive: null,
      tipe: this.tipe,
      FormCustomer: null,
    };
  },
  methods: {
    ...mapActions("rolemenu", [
      "setRoleMenu",
      "clearRoleMenu",
      "setRoleMenuStatus",
    ]),

    async fetch(params = {}) {
      // eslint-disable-next-line no-console
      if (Object.keys(params).length === 0) {
        params = {
          perPage: 10,
          page: 1,
          // parentId: "null",
        };
      }
      // var results = await RoleMenuService.getRoleMenu(this.getCustomer.id,3,params);
      var results = await RoleMenuService.getData(params);
      // eslint-disable-next-line no-console
      var datax = await {
        data: results.data,
        // totalCount: 10,
        // totalCount: results.total,
      };
      return await datax;
    },

    onSelectionChanged({ selectedRowKeys, selectedRowsData }) {
      console.log(selectedRowsData[0]);
      if (selectedRowKeys.length > 0) {
        this.setRoleMenu(selectedRowsData[0]);
        if (selectedRowKeys.length == 1) {
          this.btnAdd.option({
            disabled: false,
          });
          this.btnEdit.option({
            disabled: false,
          });
          this.btnDelete.option({
            disabled: false,
          });
          this.btnView.option({
            disabled: false,
          });
        } else {
          this.btnAdd.option({
            disabled: true,
          });
          this.btnEdit.option({
            disabled: true,
          });
          this.btnView.option({
            disabled: true,
          });
          this.btnDelete.option({
            disabled: true,
          });
        }
      } else {
        this.clearRoleMenu();
        this.btnEdit.option({
          disabled: true,
        });
        this.btnDelete.option({
          disabled: true,
        });
        this.btnView.option({
          disabled: true,
        });
      }
    },
    onToolbarPreparing(e) {
      var vThis = this;

      e.toolbarOptions.items.unshift(
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "add",
            text: "Tambah",
            disabled: false,
            // visible: false,
            onInitialized: function (e) {
              vThis.btnAdd = e.component;
            },
            onClick: function () {
              vThis.clearRoleMenu();
              vThis.setRoleMenuStatus("add");
              console.log(vThis.getRoleMenuStatus);
              router.push("/master/rolemenu/add");
              ///agreement/add = pages/customers/ModifyCustomerFollowupPages.vue
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "edit",
            text: "Edit",
            disabled: true,
            onInitialized: function (e) {
              vThis.btnEdit = e.component;
            },
            onClick: function () {
              vThis.setRoleMenuStatus("edit");
              router.push("/master/rolemenu/edit");
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "trash",
            text: "Hapus",
            disabled: true,
            onInitialized: function (e) {
              vThis.btnDelete = e.component;
            },
            onClick: function () {
              let result = confirm(
                "<i>Anda yakin mau menghapus data ini ?</i>",
                "Hapus Customer RoleMenu"
              );
              result.then((dialogResult) => {
                if (dialogResult) {
                  var rolemenuData = vThis.getRoleMenu;
                  console.log(vThis.getRoleMenu);
                  var ids = "";
                  if (Array.isArray(rolemenuData)) {
                    rolemenuData.forEach(function (item, index) {
                      if (index == 0) {
                        ids += item.id;
                      } else {
                        ids += "," + item.id;
                      }
                    });
                  } else {
                    ids = rolemenuData.id;
                  }
                  RoleMenuService.delete(
                    ids,
                    function () {
                      vThis.clearRoleMenu();
                      vThis.dataGrid.refresh();
                    },
                    function (error) {
                      // eslint-disable-next-line no-console
                      console.log(error);
                    }
                  );
                }
              });
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "detailslayout",
            text: "Detail",
            disabled: true,
            onInitialized: function (e) {
              vThis.btnView = e.component;
            },
            onClick: function () {
              console.log("/rolemenu/view");
              router.push("/master/rolemenu/view");
              //RoleMenuViewPages.vue --> RoleMenuView.vue
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "refresh",
            text: "Muat Ulang",
            onClick: function () {
              vThis.dataGrid.refresh();
            },
          },
        },
        // Filter //
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "search",
            text: vThis.filterText,
            disabled: false,
            onInitialized: function (e) {
              vThis.btnFilter = e.component;
            },
            onClick: function () {
              if (vThis.filterStatus === true) {
                vThis.filterStatus = false;
                vThis.filterText = "Filter ON";
                // console.log("filterStatus : " + vThis.filterStatus);
              } else {
                vThis.filterStatus = true;
                vThis.filterText = "Filter Off";
                // console.log("filterStatus : " + vThis.filterStatus);
              }
            },
          },
        }
      );
    },

    onRowExpanding(e) {
      var dataGrid = e.component;
      dataGrid.collapseAll(-1);
    },

    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Main sheet");
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        customizeCell: function (options) {
          const excelCell = options;
          excelCell.font = { name: "Arial", size: 12 };
          excelCell.alignment = { horizontal: "left" };
        },
      }).then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "data-customer.xlsx"
          );
        });
      });
      e.cancel = true;
    },
  },
  computed: {
    ...mapGetters("rolemenu", ["getRoleMenu", "getRoleMenuStatus"]),

    dataGrid: function () {
      return this.$refs[gridRef].instance;
    },
  },
};
</script>

<style>
</style>
<template>
  <DxForm> </DxForm>
</template>

<script>
import {} from "devextreme-vue/data-grid";
// import CustomStore from "devextreme/data/custom_store";
import { DxForm } from "devextreme-vue/form";
// import LjrService from "@/services/LjrService";
// import { mapActions } from "vuex";
import axios from "axios";


export default {
  components: {
    DxForm,
  },
  created() {
    var AUTH_TOKEN = "Bearer " + window.localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;
    
    axios.get("job_positions").then((response) => {
      // this.res = JSON.stringify(response.data);
      // this.setProduct(response.data.data);
      // this.$emit("ljrServiceData", response.data.data);
      this.$emit("jobPositionData", response.data.data);
    });
  },
  data() {
    return {
      res: [],
    };
  },
  methods: {
    // ...mapActions("product", ["setProduct"]),
  },
  mounted() {},
};
</script>

<style>
</style>


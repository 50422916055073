import axios from "axios";
// import router from "../router";

export default () => {
  var token = window.localStorage.getItem("token");
  
  let currentUrl = window.location.origin;
  let baseUrl = "";

  if (currentUrl.includes("localhost")) {
    baseUrl = "http://127.0.0.1:84/api/";
    // baseUrl = "http://bewms.local:10080/api/";
  } else if (currentUrl.includes("10.1.10.21")) {
    baseUrl = "http://10.1.10.21:84/api/";
  } else {
    baseUrl = process.env.VUE_APP_BASE_API_URL;
  }

  var api = axios.create({
    baseURL: baseUrl,
    withCredentials: false,
    headers: {
        "Content-Type": "application/json",
        "Authorization":
          "Bearer "+token
      }
  });

  api.interceptors.request.use(function (config) {
    
    // get User Session
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

  api.interceptors.response.use(
    function(response) {
      return response;
    },
    function(error) {
      
      return Promise.reject(error);
    }
  );

  return api;
};

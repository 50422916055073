<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <DxForm
        @field-data-changed="formFieldDataChanged"
        :form-data="getCompanyBranchStatus == 'edit' ? getCompanyBranch : null"
        :ref="formRef"
        :col-count="2"
      >
        <!-- <DxGroupItem :col-count="2" css-class="group-padding"> -->
        <DxGroupItem>
          <!-- <DxSimpleItem
            data-field="user_id"
            :visible="false"
            :editor-options="{ value: userId }"
          /> -->
          <DxSimpleItem
            data-field="company_id"
            :label="{ text: 'Nama Perusahaan Induk' }"
          >
            <template #default>
              <DxSelectBox
                :data-source="companyData"
                :show-clear-button="true"
                display-expr="name"
                value-expr="id"
                :value="
                  getCompanyBranchStatus == 'edit'
                    ? getCompanyBranch.company_id
                    : null
                "
                :searchEnabled="true"
                @value-changed="CompanySelectedChanged"
              ></DxSelectBox>
            </template>
          </DxSimpleItem>

          <DxSimpleItem
            data-field="branch_name"
            :label="{ text: 'Nama Perusahaan Cabang' }"
            :editor-options="{ maxLength: '100' }"
          >
            <DxRequiredRule message="Nama CompanyBranch Wajib di Isi" />
          </DxSimpleItem>

          <DxSimpleItem
            data-field="address"
            :label="{ text: 'Alamat' }"
            :editor-options="{ maxLength: '100' }"
          >
            <DxRequiredRule message="Alamat Wajib di Isi" />
          </DxSimpleItem>

          <DxSimpleItem
            data-field="postalcode"
            :label="{ text: 'Kode Pos' }"
            :editor-options="{ maxLength: '5' }"
          >
            >
            <!-- <DxRequiredRule message="Kode Pos Wajib di Isi" /> -->
          </DxSimpleItem>

          <DxSimpleItem data-field="province_id" :label="{ text: 'Provinsi' }">
            <template #default>
              <DxSelectBox
                :data-source="provinceData"
                :show-clear-button="true"
                display-expr="name"
                value-expr="id"
                :value="
                  getCompanyBranchStatus == 'edit'
                    ? getCompanyBranch.province_id
                    : null
                "
                :searchEnabled="true"
                @value-changed="ProvinceSelectedChanged"
              ></DxSelectBox>
            </template>
          </DxSimpleItem>
        </DxGroupItem>
        <DxGroupItem>
          <DxSimpleItem data-field="city_id" :label="{ text: 'Kota' }">
            <template #default>
              <DxSelectBox
                :data-source="cityData2"
                :show-clear-button="true"
                display-expr="name"
                value-expr="id"
                :value="
                  getCompanyBranchStatus == 'edit'
                    ? getCompanyBranch.city_id
                    : null
                "
                :searchEnabled="true"
                @value-changed="CitySelectedChanged"
              ></DxSelectBox>
            </template>
          </DxSimpleItem>

          <DxSimpleItem
            data-field="phone"
            :label="{ text: 'No Telepon' }"
            :editor-options="{
              maxLength: '50',
            }"
          >
            <!-- <DxRequiredRule message="phone Wajib di Isi" /> -->
          </DxSimpleItem>

          <DxSimpleItem
            data-field="email"
            :label="{ text: 'Email' }"
            :editor-options="{ maxLength: '150' }"
          >
            <!-- <DxRequiredRule message="email Wajib di Isi" /> -->
          </DxSimpleItem>
        </DxGroupItem>

        <ProvinceData @provinceData="setProvinceData" />
        <CityData @cityData="setCityData" />
        <CompanyData @companyData="setCompanyData" />
      </DxForm>
    </div>
  </div>
</template>
<script>
import ProvinceData from "@/components/map-province/ProvinceData.vue";
import CityData from "@/components/map-city/CityData.vue";
import CompanyData from "@/components/company/CompanyData.vue";
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from "devextreme-vue/form";
import { DxSelectBox } from "devextreme-vue/select-box";
import DxTextArea from "devextreme-vue/text-area";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";

// axios.defaults.withCredentials = false;
const formRef = "companybranch-form";

export default {
  components: {
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    DxSelectBox,
    DxRequiredRule,
    // eslint-disable-next-line
    DxTextArea,
    ProvinceData,
    CityData,
    CompanyData,
  },
  data: function () {
    return {
      formRef,
      userId: null,
      provinceId: null,
      provinceData: null,
      companyData: null,
      cityId: null,
      cityData: null,
      cityData2: {},
    };
  },

  mounted() {
    this.formSet();
  },
  methods: {
    ...mapActions("companybranch", [
      "setCompanyBranch",
      "setCompanyBranchForm",
    ]),

    formFieldDataChanged(e) {
      this.setCompanyBranch(e.component.option("formData"));
    },
    formSet() {
      this.setCompanyBranchForm(this.$refs[formRef].instance);
    },
    setCompanyData(value) {
      this.companyData = value;
    },
    CompanySelectedChanged(e) {
      // console.log("provinceId : " + e.value);
      this.$refs[formRef].instance.updateData({
        company_id: e.value,
      });
    },
    setProvinceData(value) {
      this.provinceData = value;
    },
    ProvinceSelectedChanged(e) {
      // console.log("provinceId : " + e.value);
      this.$refs[formRef].instance.updateData({
        province_id: e.value,
      });
      var $province_id = e.value;
      // this.provinceId = e.value;
      console.log("province_id : " + $province_id);
      this.getCityByProvinceId($province_id);
    },

    getCityByProvinceId($province_id) {
      let currentObj = this;
      axios
        .get("master_cities/provinceid/" + $province_id)
        .then(function (response) {
          currentObj.cityData2 = response.data.data;
          // console.log("cityData2 : " + JSON.stringify(currentObj.cityData2));
        })
        .catch(function (error) {
          console.log(error);
          currentObj.output = error;
        });
    },
    setCityData(value) {
      // this.cityData = value;
      if (this.getCompanyBranchStatus == "edit") {
        this.cityData2 = value;
      } else {
        this.cityData = value;
      }
    },
    CitySelectedChanged(e) {
      this.$refs[formRef].instance.updateData({
        city_id: e.value,
      });
    },
  },
  computed: {
    ...mapGetters("companybranch", [
      "getCompanyBranch",
      "getCompanyBranchStatus",
    ]),
  },
};
</script>
<style>
.group-padding {
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.form-block {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  padding-left: 40px;
  padding-right: 40px;
}
</style>

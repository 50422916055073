import Api from "@/services/Api";

export default {
  async getData(params) {
    var results = null;

    params.withResult = true;

    await Api()
      .get("stock_in_details", {
        params: params
      })
      .then(data => {
        //console.log(data.data);
        results = data.data;
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error);
      });

    return results;
  },

  async getStockInDetailDataByTrxNumber(user_id, transaction_number, params) {
    var results = null;

    params.withResult = true;

    await Api()
      .get(
        "stock_in_details/usr/1/trx/" + transaction_number,
        {
          params: params,
        }
      )
      .then((data) => {
        results = data.data;
        // console.log(results);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });

    return results;
  },

  insert(requestBody, callback, errCallback) {
    return Api()
      .post("stock_in_details", requestBody)
      .then(data => {
        console.log(data);
        callback(data);
      })
      .catch(error => {
        errCallback(error);
      });
  },
  update(id, requestBody, callback, errCallback) {
    return Api()
      .put("stock_in_details/" + id, requestBody)
      .then(data => {
        console.log(data);
        callback(data);
      })
      .catch(error => {
        errCallback(error);
      });
  },
  delete(id, callback, errCallback) {
    return Api()
      .delete("stock_in_details/" + id)
      .then(data => {
        callback(data);
      })
      .catch(error => {
        errCallback(error);
      });
  }
};

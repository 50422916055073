import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import userlogin from "./modules/userlogin";
import users from "./modules/users";

// import vehicle from "./modules/vehicle";
// import vehiclecheck from "./modules/vehiclecheck";
// import vehiclecheckdetail from "./modules/vehiclecheckdetail";
// import vehiclecheckreport from "./modules/vehiclecheckreport";
// import vehicleitem from "./modules/vehicleitem";
// import vehicleitemstatus from "./modules/vehicleitemstatus";
// import vehiclestatus from "./modules/vehiclestatus";
// import vehiclerepair from "./modules/vehiclerepair";
// import vehiclerepairdetail from "./modules/vehiclerepairdetail";

import warehouse from "./modules/warehouse";
import customer from "./modules/customer";
import clientscustomer from "./modules/clientscustomer";
import locationtype from "./modules/locationtype";
import masteritem from "./modules/masteritem";
import stockin from "./modules/stockin";
import stockout from "./modules/stockout";
import stockreturn from "./modules/stockreturn";
import stockfirst from "./modules/stockfirst";
import stockindetail from "./modules/stockindetail";
import stockoutdetail from "./modules/stockoutdetail";
import stockreturndetail from "./modules/stockreturndetail";
import employee from "./modules/employee";
import company from "./modules/company";
import companybranch from "./modules/companybranch";
import mastermenu from "./modules/mastermenu";
import rolemenu from "./modules/rolemenu";
import stockreport from "./modules/stockreport";
import stockquarantine from "./modules/stockquarantine";
import stockdestroy from "./modules/stockdestroy";
// import questionnaire from "./modules/questionnaire";

// import createPersistedState from 'vuex-persistedstate';
// import Cookies from 'js-cookie';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
  },
  // plugins: [createPersistedState({
  //   key: 'ljr-user',
  //   paths: ['auth.loginUser'],
  //   storage: {
  //     getItem: key => Cookies.get(key),
  //     setItem: (key, value) => Cookies.set(key, value, { expires: 3, secure: false }),
  //     removeItem: key => Cookies.remove(key)
  //   }
  // })],
  mutations: {
    startLoading: (state) => (state.loading = true),
    stopLoading: (state) => (state.loading = false),
  },
  actions: {
    load({ commit }) {
      commit("startLoading");
    },
    doneLoad({ commit }) {
      commit("stopLoading");
    },
  },
  modules: {
    auth,
    userlogin,
    users,
    // vehicle,
    // vehiclecheck,
    // vehiclecheckdetail,
    // vehiclecheckreport,
    // vehiclestatus,
    // vehicleitem,
    // vehicleitemstatus,
    // vehiclerepair,
    // vehiclerepairdetail,

    warehouse,
    customer,
    clientscustomer,
    locationtype,
    masteritem,
    stockin,
    stockout,
    stockreturn,
    stockfirst,
    stockindetail,
    stockoutdetail,
    stockreturndetail,
    employee,
    company,
    companybranch,
    mastermenu,
    rolemenu,
    stockreport,
    stockquarantine,
    stockdestroy

  },
});

<template>
  <div>
    <h2 class="content-block">Stock Card</h2>
    <stock-card-table />
  </div>
</template>

<script>
import StockCardTable from "@/components/stock-report/StockCardTable.vue";
export default {
  components: {
    StockCardTable,
  },
};
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"content-block dx-card responsive-paddings"},[_c('DxForm',{ref:_vm.formRef,attrs:{"form-data":_vm.getStockQuarantine,"col-count":2},on:{"field-data-changed":_vm.formFieldDataChanged}},[_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"data-field":"user_id","visible":false,"editor-options":{ value: _vm.userId }}}),_c('DxSimpleItem',{attrs:{"data-field":"uuid","visible":false,"editor-options":{
            value: _vm.currentUUid,
          }}}),_c('DxSimpleItem',{attrs:{"data-field":"warehouse_id","label":{ text: 'warehouse_id' },"visible":false,"editor-options":{
            disabled: true,
          }}}),_c('DxSimpleItem',{attrs:{"data-field":"warehouse_name","label":{ text: 'warehouse name' },"visible":true,"editor-options":{
            disabled: true,
          }}}),_c('DxSimpleItem',{attrs:{"data-field":"transaction_number","label":{ text: 'No Transaksi' },"visible":true,"editor-options":{
            disabled: true,
          }}}),_c('DxSimpleItem',{attrs:{"data-field":"item_id","label":{ text: 'Pilih Nama Item' },"visible":false,"editor-options":{
            disabled: true,
          }}}),_c('DxSimpleItem',{attrs:{"data-field":"item_name","label":{ text: 'Nama Item' },"editor-options":{
            disabled: true,
          }}}),_c('DxSimpleItem',{attrs:{"data-field":"qty","editor-type":"dxNumberBox","editor-options":{
            max: '99999',
          },"label":{ text: 'Jumlah' }}}),_c('DxSimpleItem',{attrs:{"data-field":"price","editor-type":"dxNumberBox","editor-options":{
            max: '9999999',
            disabled: true,
          },"label":{ text: 'Harga' }}})],1),_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"data-field":"batch_number","label":{ text: 'Batch number' },"editor-options":{
            disabled: true,
          }}}),_c('DxSimpleItem',{attrs:{"data-field":"item_condition","label":{ text: 'Pilih Kondisi Item' },"visible":false,"editor-options":{
            disabled: true,
          }}}),_c('DxSimpleItem',{attrs:{"data-field":"item_condition_name","label":{ text: 'Kondisi Item' },"editor-options":{
            disabled: true,
          }}}),_c('DxSimpleItem',{attrs:{"data-field":"expired_date","label":{ text: 'Tanggal Expired' },"editor-type":"dxDateBox","editor-options":{
            disabled: true,
            dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ss'
          }}})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
// import Dashboard from "./pages/dashboard/DashboardPages.vue";
// import DashboardAdmin from "./pages/dashboard/DashboardAdmin.vue";
// import DashboardChecker from "./pages/dashboard/DashboardChecker.vue";
// import DashboardMechanic from "./pages/dashboard/DashboardMechanic.vue";
import Welcome from "./views/welcome";
import WelcomeManager from "./views/WelcomeManager.vue";
import WelcomeAdmin from "./views/WelcomeAdmin.vue";
import WelcomeSuperAdmin from "./views/WelcomeSuperAdmin.vue";
//------------MASTER--------------------------
import Warehouse from "./pages/warehouse/WarehousePages.vue";
import ModifyWarehouse from "./pages/warehouse/ModifyWarehousePages.vue";
import ViewWarehouse from "./pages/warehouse/ViewWarehousePages.vue";

//--------------------------------------
import Customer from "./pages/customer/CustomerPages.vue";
import ModifyCustomer from "./pages/customer/ModifyCustomerPages.vue";
import ViewCustomer from "./pages/customer/ViewCustomerPages.vue";

//--------------------------------------
import ClientsCustomer from "./pages/clients-customer/ClientsCustomerPages.vue";
import ModifyClientsCustomer from "./pages/clients-customer/ModifyClientsCustomerPages.vue";
import ViewClientsCustomer from "./pages/clients-customer/ViewClientsCustomerPages.vue";

//--------------------------------------
import LocationType from "./pages/location-type/LocationTypePages.vue";
import ModifyLocationType from "./pages/location-type/ModifyLocationTypePages.vue";
import ViewLocationType from "./pages/location-type/ViewLocationTypePages.vue";

//--------------------------------------
// import StockItem from "./pages/stock-item/StockItemPages.vue";
// import ModifyStockItem from "./pages/stock-item/ModifyStockItemPages.vue";
// import ViewStockItem from "./pages/stock-item/ViewStockItemPages.vue";

//--------------------------------------
import MasterItem from "./pages/master-item/MasterItemPages.vue";
import ModifyMasterItem from "./pages/master-item/ModifyMasterItemPages.vue";
import ViewMasterItem from "./pages/master-item/ViewMasterItemPages.vue";

//------------END MASTER--------------------------


//-----------------TRANSACTION--------------------------
import StockFirst from "./pages/stock-first/StockFirstPages.vue";
import ModifyStockFirst from "./pages/stock-first/ModifyStockFirstPages.vue";
import ViewStockFirst from "./pages/stock-first/ViewStockFirstPages.vue";

//--------------------------------------
import StockIn from "./pages/stock-in/StockInPages.vue";
import ModifyStockIn from "./pages/stock-in/ModifyStockInPages.vue";
import ViewStockIn from "./pages/stock-in/ViewStockInPages.vue";

import StockInDetail from "./pages/stock-in-detail/StockInDetailPages.vue";
import ModifyStockInDetail from "./pages/stock-in-detail/ModifyStockInDetailPages.vue";
import ViewStockInDetail from "./pages/stock-in-detail/ViewStockInDetailPages.vue";
//--------------------------------------
import StockOut from "./pages/stock-out/StockOutPages.vue";
import ModifyStockOut from "./pages/stock-out/ModifyStockOutPages.vue";
import ViewStockOut from "./pages/stock-out/ViewStockOutPages.vue";

import StockOutDetail from "./pages/stock-out-detail/StockOutDetailPages.vue";
import ModifyStockOutDetail from "./pages/stock-out-detail/ModifyStockOutDetailPages.vue";
import ViewStockOutDetail from "./pages/stock-out-detail/ViewStockOutDetailPages.vue";
//--------------------------------------
import StockReturn from "./pages/stock-return/StockReturnPages.vue";
import ModifyStockReturn from "./pages/stock-return/ModifyStockReturnPages.vue";
import ViewStockReturn from "./pages/stock-return/ViewStockReturnPages.vue";

import StockReturnDetail from "./pages/stock-return-detail/StockReturnDetailPages.vue";
import ModifyStockReturnDetail from "./pages/stock-return-detail/ModifyStockReturnDetailPages.vue";
import ViewStockReturnDetail from "./pages/stock-return-detail/ViewStockReturnDetailPages.vue";

//--------------------------------------
import StockQuarantine from "./pages/stock-quarantine/StockQuarantinePages.vue";
import ModifyStockQuarantine from "./pages/stock-quarantine/ModifyStockQuarantinePages.vue";
import ViewStockQuarantine from "./pages/stock-quarantine/ViewStockQuarantinePages.vue";

import StockDestroy from "./pages/stock-destroy/StockDestroyPages.vue";
import ModifyStockDestroy from "./pages/stock-destroy/ModifyStockDestroyPages.vue";
import ViewStockDestroy from "./pages/stock-destroy/ViewStockDestroyPages.vue";

import StockReport from "./pages/stock-report/StockReportPages.vue";
import ModifyStockReport from "./pages/stock-report/ModifyStockReportPages.vue";
import ViewStockReport from "./pages/stock-report/ViewStockReportPages.vue";

import StockCard from "./pages/stock-report/StockCardPages.vue";

//--------------------------------------
//--------------------------------------
//------------END TRANSACTION--------------------------

//-----------------MasterCompany---------------------
import MasterCompany from "./pages/company/MasterCompanyPages.vue";
import ModifyMasterCompany from "./pages/company/ModifyMasterCompanyPages.vue";
import ViewMasterCompany from "./pages/company/ViewMasterCompanyPages.vue";

import MasterCompanyBranch from "./pages/company-branch/MasterCompanyBranchPages.vue";
import ModifyMasterCompanyBranch from "./pages/company-branch/ModifyMasterCompanyBranchPages.vue";
import ViewMasterCompanyBranch from "./pages/company-branch/ViewMasterCompanyBranchPages.vue";

//-----------------employee---------------------
import Employee from "./pages/employee/EmployeePages.vue";
import ModifyEmployee from "./pages/employee/ModifyEmployeePages.vue";
import ViewEmployee from "./pages/employee/ViewEmployeePages.vue";

import MasterMenu from "./pages/master-menu/MasterMenuPages.vue";
import ModifyMasterMenu from "./pages/master-menu/ModifyMasterMenuPages.vue";
import ViewMasterMenu from "./pages/master-menu/ViewMasterMenuPages.vue";

import MasterRole from "./pages/master-role/MasterRolePages.vue";
import ModifyMasterRole from "./pages/master-role/ModifyMasterRolePages.vue";
import ViewMasterRole from "./pages/master-role/ViewMasterRolePages.vue";

import RoleMenu from "./pages/role-menu/RoleMenuPages.vue";
import ModifyRoleMenu from "./pages/role-menu/ModifyRoleMenuPages.vue";
import ViewRoleMenu from "./pages/role-menu/ViewRoleMenuPages.vue";
//--------------------------------------
// import Vehicle from "./pages/vehicle/VehiclePages.vue";
// import ModifyVehicle from "./pages/vehicle/ModifyVehiclePages.vue";
// import ViewVehicle from "./pages/vehicle/ViewVehiclePages.vue";
// //--------------------------------------
// import VehicleItem from "./pages/vehicle-item/VehicleItemPages.vue";
// import ModifyVehicleItem from "./pages/vehicle-item/ModifyVehicleItemPages.vue";
// import ViewVehicleItem from "./pages/vehicle-item/ViewVehicleItemPages.vue";
// //--------------------------------------
// import VehicleItemStatus from "./pages/vehicle-item-status/VehicleItemStatusPages.vue";
// import ModifyVehicleItemStatus from "./pages/vehicle-item-status/ModifyVehicleItemStatusPages.vue";
// import ViewVehicleItemStatus from "./pages/vehicle-item-status/ViewVehicleItemStatusPages.vue";
// //--------------------------------------
// import VehicleStatus from "./pages/vehicle-status/VehicleStatusPages.vue";
// import ModifyVehicleStatus from "./pages/vehicle-status/ModifyVehicleStatusPages.vue";
// import ViewVehicleStatus from "./pages/vehicle-status/ViewVehicleStatusPages.vue";
// //--------------------------------------
// import VehicleCheck from "./pages/vehicle-check/VehicleCheckPages.vue";
// import ModifyVehicleCheck from "./pages/vehicle-check/ModifyVehicleCheckPages.vue";
// import ViewVehicleCheck from "./pages/vehicle-check/ViewVehicleCheckPages.vue";
// //--------------------------------------
// import VehicleReport from "./pages/vehicle-report/VehicleReportPages.vue";
// import ModifyVehicleReport from "./pages/vehicle-report/ModifyVehicleReportPages.vue";
// import ViewVehicleReport from "./pages/vehicle-report/ViewVehicleReportPages.vue";
// //--------------------------------------
// import VehicleRepair from "./pages/vehicle-repair/VehicleRepairPages.vue";
// import ModifyVehicleRepair from "./pages/vehicle-repair/ModifyVehicleRepairPages.vue";
// import ViewVehicleRepair from "./pages/vehicle-repair/ViewVehicleRepairPages.vue";
// //--------------------------------------
// // import VehicleRepairDetail from "./pages/vehicle-repair/VehicleRepairDetailPages.vue";
// import ModifyVehicleRepairDetail from "./pages/vehicle-repair/ModifyVehicleRepairDetailPages.vue";
// // import ViewVehicleRepair from "./pages/vehicle-repair/ViewVehicleRepairDetailPages.vue";
// //--------------------------------------
// import DepartureVehicleCheck from "./pages/vehicle-check/departure/VehicleCheckPages.vue";
// import DepartureVehicleCheckHistory from "./pages/vehicle-check/departure/DepartureVehicleCheckHistoryPages.vue";
// import ArrivalVehicleCheck from "./pages/vehicle-check/arrival/VehicleCheckPages.vue";
// import ArrivalVehicleCheckHistory from "./pages/vehicle-check/arrival/ArrivalVehicleCheckHistoryPages.vue";

// import statusVehicleRepairIdle from "./pages/vehicle-repair/statusVehicleRepairIdle.vue";
// import statusVehicleRepairOnService from "./pages/vehicle-repair/statusVehicleRepairOnService.vue";
// import statusVehicleRepairFinish from "./pages/vehicle-repair/statusVehicleRepairFinish.vue";

//-----------------User---------------------

import Users from "./pages/users/UserPages.vue";
import ModifyUser from "./pages/users/ModifyUserPages.vue";
import ViewUser from "./pages/users/ViewUserPages.vue";
//--------------------------------------
import Vue from "vue";
import Router from "vue-router";
// import store from "./store/index";
// import auth from "./auth";

// import Welcome from "./views/welcome";
// import WelcomeManager from "./views/WelcomeManager.vue";
// import WelcomeAdmin from "./views/WelcomeAdmin.vue";

// import WelcomeMekanik from "./views/WelcomeMekanik.vue";
// import WelcomeChecker from "./views/WelcomeChecker.vue";


import defaultLayout from "./layouts/side-nav-inner-toolbar";
// import checkerLayout from "./layouts/side-nav-inner-toolbar-checker";
// import mekanikLayout from "./layouts/side-nav-inner-toolbar-mekanik";
// import adminLayout from "./layouts/side-nav-inner-toolbar-admin";

import simpleLayout from "./layouts/single-card";

var moment = require("moment");
Vue.use(Router);

var currentLayout = defaultLayout;
// window.location.reload()
// var authUser = JSON.parse(window.localStorage.getItem("lbUser"));
var authUser = localStorage.getItem("roleid");
console.log("Tipe User : " + authUser);
// const authUser = JSON.parse(window.localStorage.getItem("lbUser"));
// if (authUser) {
//   if (authUser == "1") {
//     // admin
//     currentLayout = adminLayout;
//     console.log("currentLayout : adminLayout");
//   } else if (authUser == "2") {
//     // manager
//     currentLayout = mekanikLayout;
//     console.log("currentLayout : mekanikLayout");
//   } else if (authUser == "5") {
//     //user
//     currentLayout = checkerLayout;
//     console.log("currentLayout : checkerLayout");
//   }
// }

const router = new Router({
  routes: [
    //---------------- login----------------
    {
      path: "/",
      redirect: "/login",
    },
    {
      path: "/recovery",
      redirect: "/login",
    },
    {
      path: "*",
      redirect: "/login",
    },
    {
      path: "/login",
      name: "login",
      meta: { requiresAuth: false },
      components: {
        layout: simpleLayout,
        // route level code-splitting
        // this generates a separate chunk (login.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        content: () =>
          import(/* webpackChunkName: "login" */ "./views/login-form"),
      },
    },
    {
      path: "/logout",
      name: "logout",
      meta: { requiresAuth: false },
      components: {
        layout: simpleLayout,
        // route level code-splitting
        // this generates a separate chunk (login.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        content: () =>
          import(/* webpackChunkName: "login" */ "./views/logout"),
      },
    },
    //---------------- welcome----------------
    {
      path: "/welcome-super-admin",
      name: "welcome-super-admin",
      meta: { requiresAuth: true, superadminAuth: true },
      components: {
        layout: currentLayout,
        // layout: currentLayout,
        content: WelcomeSuperAdmin,
      },
    },
    {
      path: "/welcome-admin",
      name: "welcome-admin",
      meta: { requiresAuth: true, adminAuth: true },
      components: {
        layout: currentLayout,
        // layout: currentLayout,
        content: WelcomeAdmin,
      },
    },

    {
      path: "/welcome-manager",
      name: "welcome-manager",
      meta: { requiresAuth: true, managerAuth: true },
      components: {
        layout: currentLayout,
        // layout: currentLayout,
        content: WelcomeManager,
      },
    },
    {
      path: "/welcome",
      name: "welcome",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: Welcome,
      },
    },
    //---------------- Dashboard ----------------
    // {
    //   path: "/dashboard/admin",
    //   name: "dashboard-admin",
    //   meta: { requiresAuth: true, adminAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: DashboardAdmin,
    //   },
    // },
    // {
    //   path: "/dashboard/checker",
    //   name: "dashboard-checker",
    //   meta: { requiresAuth: true, checkerAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: DashboardChecker,
    //   },
    // },
    // {
    //   path: "/dashboard/mechanic",
    //   name: "dashboard-mechanic",
    //   meta: { requiresAuth: true, mekanikAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: DashboardMechanic,
    //   },
    // },

    //---------------- /vehicle/check----------------

    // {
    //   path: "/departure/vehicle/check",
    //   name: "Vehicle",
    //   meta: { requiresAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: DepartureVehicleCheck,
    //   },
    // },
    // {
    //   path: "/departure/vehicle/check/history",
    //   name: "Vehicle",
    //   meta: { requiresAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: DepartureVehicleCheckHistory,
    //   },
    // },
    // {
    //   path: "/arrival/vehicle/check",
    //   name: "Vehicle",
    //   meta: { requiresAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: ArrivalVehicleCheck,
    //   },
    // },
    // {
    //   path: "/arrival/vehicle/check/history",
    //   name: "Vehicle",
    //   meta: { requiresAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: ArrivalVehicleCheckHistory,
    //   },
    // },

    //---------------- Vehicle----------------

    // {
    //   path: "/vehicle",
    //   name: "Vehicle",
    //   meta: { requiresAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: Vehicle,
    //   },
    // },

    // {
    //   path: "/vehicle/add",
    //   name: "addVehicle",
    //   meta: { requiresAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: ModifyVehicle,
    //   },
    // },
    // {
    //   path: "/vehicle/edit",
    //   name: "editVehicle",
    //   meta: { requiresAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: ModifyVehicle,
    //   },
    // },
    // {
    //   path: "/vehicle/view",
    //   name: "ViewVehicle",
    //   meta: { requiresAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: ViewVehicle,
    //   },
    // },

    //---------------- VehicleItem----------------
    // {
    //   path: "/vehicle/item",
    //   name: "VehicleItem",
    //   meta: { requiresAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: VehicleItem,
    //   },
    // },
    // {
    //   path: "/vehicle/item/add",
    //   name: "addVehicleItem",
    //   meta: { requiresAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: ModifyVehicleItem,
    //   },
    // },
    // {
    //   path: "/vehicle/item/edit",
    //   name: "editVehicleItem",
    //   meta: { requiresAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: ModifyVehicleItem,
    //   },
    // },
    // {
    //   path: "/vehicle/item/view",
    //   name: "ViewVehicleItem",
    //   meta: { requiresAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: ViewVehicleItem,
    //   },
    // },
    //---------------- vehicle Item Status----------------
    // {
    //   path: "/vehicle/item/status",
    //   name: "VehicleItemStatus",
    //   meta: { requiresAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: VehicleItemStatus,
    //   },
    // },
    // {
    //   path: "/vehicle/item/status/add",
    //   name: "addVehicleItemStatus",
    //   meta: { requiresAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: ModifyVehicleItemStatus,
    //   },
    // },
    // {
    //   path: "/vehicle/item/status/edit",
    //   name: "editVehicleItemStatus",
    //   meta: { requiresAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: ModifyVehicleItemStatus,
    //   },
    // },
    // {
    //   path: "/vehicle/item/status/view",
    //   name: "ViewVehicleItemStatus",
    //   meta: { requiresAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: ViewVehicleItemStatus,
    //   },
    // },

    //---------------- vehicle Status----------------
    // {
    //   path: "/vehicle/status",
    //   name: "VehicleStatus",
    //   meta: { requiresAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: VehicleStatus,
    //   },
    // },
    // {
    //   path: "/vehicle/status/add",
    //   name: "addVehicleStatus",
    //   meta: { requiresAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: ModifyVehicleStatus,
    //   },
    // },
    // {
    //   path: "/vehicle/status/edit",
    //   name: "editVehicleStatus",
    //   meta: { requiresAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: ModifyVehicleStatus,
    //   },
    // },
    // {
    //   path: "/vehicle/status/view",
    //   name: "ViewVehicleStatus",
    //   meta: { requiresAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: ViewVehicleStatus,
    //   },
    // },


    //---------------- VehicleCheck----------------
    // {
    //   path: "/vehicle/check",
    //   name: "VehicleCheck",
    //   // meta: { requiresAuth: true, checkerAuth: true },
    //   meta: { requiresAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: VehicleCheck,
    //   },
    // },
    // {
    //   path: "/vehicle/check/add",
    //   name: "addVehicleCheck",
    //   // meta: { requiresAuth: true, checkerAuth: true },
    //   meta: { requiresAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: ModifyVehicleCheck,
    //   },
    // },
    // {
    //   path: "/vehicle/check/edit",
    //   name: "editVehicleCheck",
    //   // meta: { requiresAuth: true, checkerAuth: true },
    //   meta: { requiresAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: ModifyVehicleCheck,
    //   },
    // },
    // {
    //   path: "/vehicle/check/view",
    //   name: "ViewVehicleCheck",
    //   // meta: { requiresAuth: true, checkerAuth: true },
    //   meta: { requiresAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: ViewVehicleCheck,
    //   },
    // },

    //---------------- VehicleRepair----------------
    // {
    //   path: "/vehicle/repair",
    //   name: "VehicleRepair",
    //   meta: { requiresAuth: true, mekanikAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: VehicleRepair,
    //   },
    // },
    // {
    //   path: "/vehicle/repair/add",
    //   name: "addVehicleRepair",
    //   meta: { requiresAuth: true, mekanikAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: ModifyVehicleRepair,
    //   },
    // },
    // {
    //   path: "/vehicle/repair/edit",
    //   name: "editVehicleRepair",
    //   meta: { requiresAuth: true, mekanikAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: ModifyVehicleRepair,
    //   },
    // },
    // {
    //   path: "/vehicle/repair/view",
    //   name: "ViewVehicleRepair",
    //   meta: { requiresAuth: true, mekanikAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: ViewVehicleRepair,
    //   },
    // },
    // {
    //   path: "/vehicle/repair/status/idle",
    //   name: "statusVehicleRepairIdle",
    //   meta: { requiresAuth: true, mekanikAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: statusVehicleRepairIdle,
    //   },
    // },
    // {
    //   path: "/vehicle/repair/status/onservice",
    //   name: "statusVehicleRepairOnService",
    //   meta: { requiresAuth: true, mekanikAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: statusVehicleRepairOnService,
    //   },
    // },
    // {
    //   path: "/vehicle/repair/status/finish",
    //   name: "statusVehicleRepairFinish",
    //   meta: { requiresAuth: true, mekanikAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: statusVehicleRepairFinish,
    //   },
    // },

    // {
    //   path: "/vehicle/repair/detail",
    //   name: "VehicleRepairDetail",
    //   meta: { requiresAuth: true, mekanikAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: ModifyVehicleRepairDetail,
    //   },
    // },
    //---------------- VehicleReport----------------
    // {
    //   path: "/vehicle/report/damage",
    //   name: "VehicleReport",
    //   meta: { requiresAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: VehicleReport,
    //   },
    // },
    // {
    //   path: "/vehicle/report/damage/add",
    //   name: "addVehicleReport",
    //   meta: { requiresAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: ModifyVehicleReport,
    //   },
    // },
    // {
    //   path: "/vehicle/report/damage/edit",
    //   name: "editVehicleReport",
    //   meta: { requiresAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: ModifyVehicleReport,
    //   },
    // },
    // {
    //   path: "/vehicle/report/damage/view",
    //   name: "ViewVehicleReport",
    //   meta: { requiresAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: ViewVehicleReport,
    //   },
    // },

    //---------------- WMS----------------

    //---------------- Warehouse----------------
    {
      path: "/warehouse",
      name: "Warehouse",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: Warehouse,
      },
    },

    {
      path: "/warehouse/add",
      name: "addWarehouse",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ModifyWarehouse,
      },
    },
    {
      path: "/warehouse/edit",
      name: "editVehicle",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ModifyWarehouse,
      },
    },
    {
      path: "/warehouse/view",
      name: "ViewWarehouse",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ViewWarehouse,
      },
    },
    //---------------- customer----------------
    {
      path: "/customer",
      name: "Customer",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: Customer,
      },
    },
    // {
    //   path: "/customer/all",
    //   name: "AllCustomer",
    //   meta: { requiresAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: AllCustomer,
    //   },
    // },
    {
      path: "/customer/add",
      name: "addcustomer",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ModifyCustomer,
      },
    },
    {
      path: "/customer/edit",
      name: "editcustomer",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ModifyCustomer,
      },
    },
    {
      path: "/customer/view",
      name: "viewcustomer",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ViewCustomer,
      },
    },
    //----------------clients customer----------------
    {
      path: "/clients-customer",
      name: "ClientsCustomer",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ClientsCustomer,
      },
    },

    {
      path: "/clients-customer/add",
      name: "addClientsCustomer",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ModifyClientsCustomer,
      },
    },
    {
      path: "/clients-customer/edit",
      name: "editClientsCustomer",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ModifyClientsCustomer,
      },
    },
    {
      path: "/clients-customer/view",
      name: "ViewClientsCustomer",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ViewClientsCustomer,
      },
    },
    //---------------- LocationType----------------
    {
      path: "/location-type",
      name: "LocationType",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: LocationType,
      },
    },
    {
      path: "/location-type/add",
      name: "addLocationType",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ModifyLocationType,
      },
    },
    {
      path: "/location-type/edit",
      name: "editLocationType",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ModifyLocationType,
      },
    },
    {
      path: "/location-type/view",
      name: "viewLocationType",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ViewLocationType,
      },
    },
    //---------------- StockItem----------------
    {
      path: "/master/item",
      name: "MasterItem",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: MasterItem,
      },
    },
    {
      path: "/master/item/add",
      name: "addMasterItem",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ModifyMasterItem,
      },
    },
    {
      path: "/master/item/edit",
      name: "editMasterItem",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ModifyMasterItem,
      },
    },
    {
      path: "/master/item/view",
      name: "ViewMasterItem",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ViewMasterItem,
      },
    },

    //---------------- StockItem----------------
    // {
    //   path: "/stock-item",
    //   name: "StockItem",
    //   meta: { requiresAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: StockItem,
    //   },
    // },
    // {
    //   path: "/stock-item/add",
    //   name: "addStockItem",
    //   meta: { requiresAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: ModifyStockItem,
    //   },
    // },
    // {
    //   path: "/stock-item/edit",
    //   name: "editStockItem",
    //   meta: { requiresAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: ModifyStockItem,
    //   },
    // },
    // {
    //   path: "/stock-item/view",
    //   name: "ViewStockItem",
    //   meta: { requiresAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     content: ViewStockItem,
    //   },
    // },
    //---------------- StockFirst----------------
    {
      path: "/stock/first",
      name: "StockFirst",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: StockFirst,
      },
    },
    {
      path: "/stock/first/add",
      name: "addStockFirst",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ModifyStockFirst,
      },
    },
    {
      path: "/stock/first/edit",
      name: "editStockFirst",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ModifyStockFirst,
      },
    },
    {
      path: "/stock/first/view",
      name: "ViewStockFirst",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ViewStockFirst,
      },
    },
    //---------------- StockIn----------------
    {
      path: "/stock/in",
      name: "StockIn",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: StockIn,
      },
    },
    {
      path: "/stock/in/add",
      name: "addStockIn",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ModifyStockIn,
      },
    },
    {
      path: "/stock/in/edit",
      name: "editStockIn",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ModifyStockIn,
      },
    },
    {
      path: "/stock/in/view",
      name: "ViewStockIn",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ViewStockIn,
      },
    },
    //---------------- StockInDetail----------------
    {
      path: "/stock/in/detail",
      name: "StockInDetail",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: StockInDetail,
      },
    },
    {
      path: "/stock/in/detail/add",
      name: "addStockInDetail",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ModifyStockInDetail,
      },
    },
    {
      path: "/stock/in/detail/edit",
      name: "editStockInDetail",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ModifyStockInDetail,
      },
    },
    {
      path: "/stock/in/detail/view",
      name: "ViewStockInDetail",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ViewStockInDetail,
      },
    },
    //---------------- StockOut----------------
    {
      path: "/stock/out",
      name: "StockOut",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: StockOut,
      },
    },
    {
      path: "/stock/out/add",
      name: "addStockOut",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ModifyStockOut,
      },
    },
    {
      path: "/stock/out/edit",
      name: "editStockOut",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ModifyStockOut,
      },
    },
    {
      path: "/stock/out/view",
      name: "ViewStockOut",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ViewStockOut,
      },
    },
    //---------------- StockOutDetail----------------
    {
      path: "/stock/out/detail",
      name: "StockOutDetail",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: StockOutDetail,
      },
    },
    {
      path: "/stock/out/detail/add",
      name: "addStockOutDetail",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ModifyStockOutDetail,
      },
    },
    {
      path: "/stock/out/detail/edit",
      name: "editStockOutDetail",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ModifyStockOutDetail,
      },
    },
    {
      path: "/stock/out/detail/view",
      name: "ViewStockOutDetail",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ViewStockOutDetail,
      },
    },
    //---------------- StockReturn----------------
    {
      path: "/stock/return",
      name: "StockReturn",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: StockReturn,
      },
    },
    {
      path: "/stock/return/add",
      name: "addStockReturn",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ModifyStockReturn,
      },
    },
    {
      path: "/stock/return/edit",
      name: "editStockReturn",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ModifyStockReturn,
      },
    },
    {
      path: "/stock/return/view",
      name: "ViewStockReturn",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ViewStockReturn,
      },
    },
    //---------------- StockReturnDetail----------------
    {
      path: "/stock/return/detail/",
      name: "StockReturnDetail",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: StockReturnDetail,
      },
    },
    {
      path: "/stock/return/detail/add",
      name: "addStockReturnDetail",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ModifyStockReturnDetail,
      },
    },
    {
      path: "/stock/return/detail/edit",
      name: "editStockReturnDetail",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ModifyStockReturnDetail,
      },
    },
    {
      path: "/stock/return/detail/view",
      name: "ViewStockReturnDetail",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ViewStockReturnDetail,
      },
    },
    //---------------- StockQuarantine----------------
    {
      path: "/stock/quarantine",
      name: "StockQuarantine",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: StockQuarantine,
      },
    },
    {
      path: "/stock/quarantine/add",
      name: "addStockQuarantine",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ModifyStockQuarantine,
      },
    },
    {
      path: "/stock/quarantine/edit",
      name: "editStockQuarantine",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ModifyStockQuarantine,
      },
    },
    {
      path: "/stock/quarantine/view",
      name: "ViewStockQuarantine",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ViewStockQuarantine,
      },
    },

    //---------------- StockDestroy----------------
    {
      path: "/stock/destroy",
      name: "StockDestroy",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: StockDestroy,
      },
    },
    {
      path: "/stock/destroy/add",
      name: "addStockDestroy",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ModifyStockDestroy,
      },
    },
    {
      path: "/stock/destroy/edit",
      name: "editStockDestroy",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ModifyStockDestroy,
      },
    },
    {
      path: "/stock/destroy/view",
      name: "ViewStockDestroy",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ViewStockDestroy,
      },
    },
    //---------------- StockCard----------------
    {
      path: "/stock/card",
      name: "StockCard",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: StockCard,
      },
    },
    //---------------- StockReport----------------
    {
      path: "/stock/report",
      name: "StockReport",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: StockReport,
      },
    },
    {
      path: "/stock/report/add",
      name: "addStockReport",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ModifyStockReport,
      },
    },
    {
      path: "/stock/report/edit",
      name: "editStockReport",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ModifyStockReport,
      },
    },
    {
      path: "/stock/report/view",
      name: "ViewStockReport",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ViewStockReport,
      },
    },
    //---------------- Users---------
    {
      path: "/users",
      name: "users",
      meta: { requiresAuth: true },
      components: {
        // layout: adminLayout,
        layout: currentLayout,
        content: Users,
      },
    },
    {
      path: "/users/add",
      name: "ModifyUserAdd",
      meta: { requiresAuth: true},
      components: {
        // layout: adminLayout,
        layout: currentLayout,
        content: ModifyUser,
      },
    },
    {
      path: "/users/edit",
      name: "UserEdit",
      meta: { requiresAuth: true },
      components: {
        // layout: adminLayout,
        layout: currentLayout,
        content: ModifyUser,
      },
    },
    {
      path: "/user/view",
      name: "ViewUser",
      meta: { requiresAuth: true },
      components: {
        // layout: adminLayout,
        layout: currentLayout,
        content: ViewUser,
      },
    },
    //
    //---------------- MasterCompany----------------
    {
      path: "/master/company",
      name: "MasterCompany",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: MasterCompany,
      },
    },
    {
      path: "/master/company/add",
      name: "AddMasterCompany",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ModifyMasterCompany,
      },
    },
    {
      path: "/master/company/edit",
      name: "EditMasterCompany",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ModifyMasterCompany,
      },
    },
    {
      path: "/master/company/view",
      name: "ViewMasterCompany",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ViewMasterCompany,
      },
    },

    //---------------- MasterCompany----------------
    {
      path: "/master/company/branch",
      name: "MasterCompanyBranch",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: MasterCompanyBranch,
      },
    },
    {
      path: "/master/company/branch/add",
      name: "AddMasterCompanyBranch",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ModifyMasterCompanyBranch,
      },
    },
    {
      path: "/master/company/branch/edit",
      name: "EditMasterCompanyBranch",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ModifyMasterCompanyBranch,
      },
    },
    {
      path: "/master/company/branch/view",
      name: "ViewMasterCompanyBranch",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ViewMasterCompanyBranch,
      },
    },

    //---------------- Employee---------
    {
      path: "/employee",
      name: "employee",
      meta: { requiresAuth: true, superadminAuth: true },
      components: {
        // layout: adminLayout,
        layout: currentLayout,
        content: Employee,
      },
    },
    {
      path: "/employee/add",
      name: "ModifyEmployeeAdd",
      meta: { requiresAuth: true, superadminAuth: true },
      components: {
        // layout: adminLayout,
        layout: currentLayout,
        content: ModifyEmployee,
      },
    },
    {
      path: "/employee/edit",
      name: "ModifyEmployeeEdit",
      meta: { requiresAuth: true, superadminAuth: true },
      components: {
        // layout: adminLayout,
        layout: currentLayout,
        content: ModifyEmployee,
      },
    },
    {
      path: "/employee/view",
      name: "ViewEmployee",
      meta: { requiresAuth: true, superadminAuth: true },
      components: {
        // layout: adminLayout,
        layout: currentLayout,
        content: ViewEmployee,
      },
    },
    //---------------- MasterMenu----------------
    {
      path: "/master/mastermenu",
      name: "MasterMenu",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: MasterMenu,
      },
    },
    {
      path: "/master/mastermenu/add",
      name: "AddMasterMenu",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ModifyMasterMenu,
      },
    },
    {
      path: "/master/mastermenu/edit",
      name: "EditMasterMenu",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ModifyMasterMenu,
      },
    },
    {
      path: "/master/mastermenu/view",
      name: "ViewMasterMenu",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ViewMasterMenu,
      },
    },

    //---------------- MasterRule----------------
    {
      path: "/master/masterrole",
      name: "MasterRole",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: MasterRole,
      },
    },
    {
      path: "/master/masterrole/add",
      name: "AddMasterRole",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ModifyMasterRole,
      },
    },
    {
      path: "/master/masterrole/edit",
      name: "EditMasterRole",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ModifyMasterRole,
      },
    },
    {
      path: "/master/masterrole/view",
      name: "ViewMasterRole",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ViewMasterRole,
      },
    },

    //---------------- RoleMenu----------------
    {
      path: "/master/rolemenu",
      name: "MasterRoleMenu",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: RoleMenu,
      },
    },
    {
      path: "/master/rolemenu/add",
      name: "AddRoleMenu",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ModifyRoleMenu,
      },
    },
    {
      path: "/master/rolemenu/edit",
      name: "EditRoleMenu",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ModifyRoleMenu,
      },
    },
    {
      path: "/master/rolemenu/view",
      name: "ViewRoleMenu",
      meta: { requiresAuth: true },
      components: {
        layout: currentLayout,
        content: ViewRoleMenu,
      },
    },
    //

  ],
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    const authUser = JSON.parse(window.localStorage.getItem("lbUser"));
    if (!authUser || !authUser.token) {
      next({ name: "login" });
    } else if (to.meta.superadminAuth) {
      const authUser = JSON.parse(window.localStorage.getItem("lbUser"));
      if (authUser.data.user.role_id == "1") {
        console.log("router role_id : " + authUser.data.user.role_id);
        next();
      } else {
        next("/welcome");
      }
    } else if (to.meta.managerAuth) {
      const authUser = JSON.parse(window.localStorage.getItem("lbUser"));
      if (
        authUser.data.user.role_id == "1" ||
        authUser.data.user.role_id == "2"
      ) {
        console.log("router role_id : " + authUser.data.user.role_id);
        next();
      } else {
        next("/welcome");
      }
    } else if (to.meta.adminAuth) {
      const authUser = JSON.parse(window.localStorage.getItem("lbUser"));
      if (authUser.data.user.role_id == "4") {
        console.log("router role_id : " + authUser.data.user.role_id);
        next();
      } else {
        next("/welcome");
      }
    } else {
      // Token Expiration Check
      const lastLoginDate = moment(authUser.last_login_date);
      const nowDate = moment();

      var duration = moment.duration(nowDate.diff(lastLoginDate));
      var hours = duration.asHours();

      if (hours > 8) {
        next({ name: "login" });
        // window.location = process.env.VUE_APP_AUTH_GATEWAY_URL+'/#/login-form?appId=5&company=62a5f1ac-8a42-11ea-88fd-3c2c30ee3c63';
      } else {
        next();
      }
    }
  } else {
    next();
  }
});

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
export default router;

<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      Item Summary
      <dx-data-grid
        class="dx-card wide-card"
        :data-source="
          dataSourceReportTmp.length > 0
            ? dataSourceReportTmp
            : dataSourceReportNull
        "
        :show-borders="true"
        :focused-row-index="0"
        :focused-row-enabled="true"
        :column-auto-width="true"
        :column-hiding-enabled="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :loadPanel="loadPanel"
        :loading="loadingVisible"
        :selected-row-keys="selectedRowKeys"
        @toolbar-preparing="onToolbarItemSummaryPreparing($event)"
        @exporting="onExportingItemSummary"
        :ref="gdRefItemSum"
      >
        <dx-paging :page-size="10" />
        <dx-pager
          :showPageSizeSelector="true"
          :allowed-page-sizes="pageSizes"
          :show-info="true"
          :showNavigationButtons="true"
          infoText="Hal {0} dari {1} ({2} items)"
          :visible="true"
        />
        <dx-filter-row :visible="filterStatus" />
        <DxSearchPanel :visible="true" />
        <!-- <DxColumn
          data-field="transaction_number"
          caption="Transaction Number"
        />
        <DxColumn data-field="batch_number" caption="batch_number" />
        <DxColumn data-field="stock_action" caption="Tipe" /> -->

        <DxColumn data-field="item_code" caption="Code" />
        <DxColumn data-field="item_name" caption="Name" />
        <DxColumn data-field="total_first_stock" caption="+FirstStock" />
        <!-- <DxColumn data-field="total_return" caption="+Return" /> -->
        <DxColumn data-field="total_item_in" caption="+Inbound" />
        <DxColumn data-field="total_item_out" caption="-Outbond" />
        <DxColumn data-field="total_item_quarantine" caption="-Quarantine" />
        <DxColumn data-field="total_item_destroy" caption="-Destroy" />
        <!-- <DxColumn data-field="total_item_good" caption="Good" /> -->
        <DxColumn data-field="total_item" caption="Sisa Stok" />
        <!-- <DxColumn data-field="item_condition_name" caption="Kondisi" />
        <DxColumn data-field="remark" caption="Catatan" /> -->
        <DxExport :enabled="true" />
        <template #titlereport>
          <div class="myfilter">Pilih Warehouse :</div>
        </template>

        <template #warehouse>
          <div class="informer">
            <span class="b">
              <DxSelectBox
                :data-source="GetWarehouse"
                :width="300"
                :show-clear-button="true"
                display-expr="name"
                value-expr="id"
                :searchEnabled="true"
                @value-changed="warehouseSelectedChanged2"
              ></DxSelectBox>
            </span>
          </div>
        </template>
      </dx-data-grid>
    </div>
    <div class="content-block dx-card responsive-paddings">
      Warehouse Activity
      <dx-data-grid
        class="dx-card wide-card"
        :data-source="dataSourceReport.length > 0 ? dataSourceReport : dataNull"
        :show-borders="true"
        :focused-row-index="0"
        :focused-row-enabled="true"
        :column-auto-width="true"
        :column-hiding-enabled="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :loadPanel="loadPanel"
        :loading="loadingVisible"
        :selected-row-keys="selectedRowKeys"
        @toolbar-preparing="onToolbarPreparing($event)"
        @exporting="onExportingWarehouseActivity"
        :ref="gdRefWA"
        :onContentReady="onContentReady1"
      >
        <dx-paging :page-size="10" />
        <dx-pager
          :showPageSizeSelector="true"
          :allowed-page-sizes="pageSizes"
          :show-info="true"
          :showNavigationButtons="true"
          infoText="Hal {0} dari {1} ({2} items)"
          :visible="true"
        />
        <dx-filter-row :visible="filterStatus" />
        <DxSearchPanel :visible="true" />
        <DxColumn data-field="transaction_number" caption="No Trx" />
        <DxColumn
          data-field="trx_date"
          dataType="date"
          format="dd/MM/yyyy"
          caption="Tgl Trx"
        />
        <DxColumn data-field="batch_number" caption="No Batch" />
        <DxColumn
          data-field="stock_action"
          caption="Tipe"
          width="70"
          :customize-text="stock_action_customizeText"
        />
        <DxColumn data-field="item_name" caption="Item" />
        <DxColumn data-field="qty" caption="Jumlah" />
        <DxColumn data-field="item_condition_name" caption="Kondisi" />
        <DxColumn data-field="remark" caption="Catatan" />
        <DxColumn
          data-field="expired_date"
          dataType="date"
          format="dd/MM/yyyy"
          caption="expired_date"
        />
        <DxExport :enabled="true" />
        <template #titlereport>
          <div class="myfilter">Pilih Warehouse :</div>
        </template>

        <template #warehouse>
          <div class="informer">
              <DxSelectBox
                :data-source="GetWarehouse"
                :width="250"
                :show-clear-button="true"
                display-expr="name"
                value-expr="id"
                :searchEnabled="true"
                @value-changed="warehouseSelectedChanged"
              ></DxSelectBox>
          </div>
        </template>

        <template #startDateBox>
          <div class="informer">
            <div class="dx-field">
              <div class="dx-field-label field-label"></div>
              <div class="dx-field-value field-date-box"> 
                <DxDateBox
                  :show-clear-button="true"
                  display-format="dd/MM/yyyy"
                  :width="150"
                  :ref="waStartDateBoxRef"
                  @value-changed="waStartDateBoxValueChanged"
                ></DxDateBox>
              </div>
            </div>
          </div>
        </template>

        <template #endDateBox>
          <div class="informer">
            <div class="dx-field">
              <div class="dx-field-label field-label">-</div>
              <div class="dx-field-value field-date-box"> 
              <DxDateBox
                :show-clear-button="true"
                display-format="dd/MM/yyyy"
                :width="150"
                :ref="waEndDateBoxRef"
                @value-changed="waEndDateBoxValueChanged"
              ></DxDateBox>
              </div>
            </div>
          </div>
        </template>

      </dx-data-grid>
    </div>

    <div class="content-block dx-card responsive-paddings">
      All Stock In/Out Transaction
      <dx-data-grid
        class="dx-card wide-card"
        :data-source="dataSource"
        :show-borders="true"
        :focused-row-index="0"
        :focused-row-enabled="true"
        :column-auto-width="true"
        :column-hiding-enabled="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :loadPanel="loadPanel"
        :loading="loadingVisible"
        @exporting="onExportingStockInOutActivity"
        @toolbar-preparing="onToolbarStockInOutPreparing($event)"
        :ref="gdRefAIO"
      >
        <dx-paging :page-size="10" />
        <dx-pager
          :showPageSizeSelector="true"
          :allowed-page-sizes="pageSizes"
          :show-info="true"
          :showNavigationButtons="true"
          infoText="Hal {0} dari {1} ({2} items)"
          :visible="true"
        />
        <dx-filter-row :visible="filterStatus" />
        <DxSearchPanel :visible="true" />
        <DxColumn data-field="warehouse_name" width="200" caption="Warehouse" />
        <DxColumn
          data-field="transaction_number"
          width="200"
          caption="No Transaksi"
        />
        <DxColumn
          data-field="trx_date"
          dataType="date"
          format="dd/MM/yyyy"
          caption="Tgl Trx"
        />
        <DxColumn
          data-field="stock_action"
          caption="Tipe"
          width="70"
          :customize-text="stock_action_customizeText"
        />
        <DxColumn data-field="item_name" width="200" caption="Nama Item" />
        <DxColumn data-field="qty" caption="Jumlah" />
        <DxColumn data-field="item_condition_name" caption="Kondisi" />
        <DxColumn data-field="remark" caption="remark" />
        <DxExport :enabled="true" />
      </dx-data-grid>
    </div>


    <div class="content-block dx-card responsive-paddings">
      Last Item Quantity By Expired Date and Batch Number
      <dx-data-grid
        class="dx-card wide-card"
        :data-source="dsLastItemStock"
        :show-borders="true"
        :focused-row-index="0"
        :focused-row-enabled="true"
        :column-auto-width="true"
        :column-hiding-enabled="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :loadPanel="loadPanel"
        :loading="loadingVisible"
        @exporting="onExportingLastItemStockActivity"
        @toolbar-preparing="onToolbarLastItemStockPreparing($event)"
        :ref="gdReflaststock"
      >
        <dx-paging :page-size="10" />
        <dx-pager
          :showPageSizeSelector="true"
          :allowed-page-sizes="pageSizes"
          :show-info="true"
          :showNavigationButtons="true"
          infoText="Hal {0} dari {1} ({2} items)"
          :visible="true"
        />
        <dx-filter-row :visible="filterStatus" />
        <DxSearchPanel :visible="true" />
        <DxColumn data-field="warehouse_name" caption="Warehouse" />
        <DxColumn data-field="item_code" caption="Kode Item" />
        <DxColumn data-field="item_name" width="200" caption="Nama Item" />
        <DxColumn data-field="batch_number" width="200" caption="Batch Number" />
        <DxColumn data-field="expired_date" 
          dataType="date" 
          format="dd/MM/yyyy" 
          caption="Tanggal Expired" />
        <DxColumn data-field="qty" caption="Jumlah" />
        <DxExport :enabled="true" />
      </dx-data-grid>
    </div>
    
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxPager,
  DxPaging,
  // DxSelection,
  DxColumn,
  DxFilterRow,
  DxExport,
  DxSearchPanel,
} from "devextreme-vue/data-grid";
// import { DxTreeList, DxHeaderFilter } from "devextreme-vue/tree-list";
import CustomStore from "devextreme/data/custom_store";
import StockReportService from "@/services/StockReportService";

// import router from "../../router";
import { mapGetters, mapActions } from "vuex";
// import { confirm } from "devextreme/ui/dialog";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { exportDataGrid } from "devextreme/excel_exporter";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxDateBox } from 'devextreme-vue/date-box';

import { Workbook } from "exceljs";
import saveAs from "file-saver";

import axios from "axios";
import moment from "moment";

axios.defaults.headers.common["Authorization"] =
  "Bearer " + window.localStorage.getItem("token");

const gdRefItemSum = "item-summary-stockreport-table";
const gdRefWA = "wa-stockreport-table";
const gdRefAIO = "aio-stockreport-table";
const gdReflaststock = "last-stockreport-table";
const waStartDateBoxRef = "waStartDateBox";
const waEndDateBoxRef = "waEndDateBox";

export default {
  // props: ["StockReportId"],
  components: {
    DxDataGrid,
    DxPager,
    DxPaging,
    // DxSelection,
    DxColumn,
    DxFilterRow,
    DxExport,
    DxSearchPanel,
    // DxTreeList,
    // DxHeaderFilter,
    DxSelectBox,
    DxDateBox
    // DxLoadPanel
  },
  data() {
    const vthis = this;
    const dataSource = {
      store: new CustomStore({
        key: "id",
        load: async function (loadOptions) {
          var sortedBy = "";
          if (loadOptions.sort) {
            sortedBy += loadOptions.sort[0].selector;
            if (loadOptions.sort[0].desc) {
              sortedBy += " desc";
            } else {
              sortedBy += " asc";
            }
          }

          var perPage = loadOptions.take;
          var offset = loadOptions.skip;
          var page = offset / perPage + 1;

          var datax = vthis.fetch({
            perPage: perPage || 10,
            page: page || 1,
            sortedBy: sortedBy,
            parentId: "null",
            startDate : vthis.aioStartDate,
            endDate : vthis.aioEndDate
          });

          return await datax;
        },
      }),
    };

    const dsLastItemStock = {
      store: new CustomStore({
        key: "id",
        load: async function () {
          var datax = vthis.fetchLastItem();

          return await datax;
        },
      }),
    };

    return {
      filterStatus: false,
      gdRefItemSum,
      gdRefWA,
      gdRefAIO,
      gdReflaststock,
      waStartDateBoxRef,
      waEndDateBoxRef,
      dataSource: dataSource,
      dsLastItemStock : dsLastItemStock,
      pageSizes: [5, 10, 15],
      selectedRowKeys: [],
      loadPanel: {
        enabled : true,
        text: "Memuat...",
      },
      loadingVisible : false,
      btnAdd: null,
      btnEdit: null,
      btnDelete: null,
      btnView: null,
      warehouse_name_map : new Map(),
      // warehouse activity
      waWarehouse_id : null,
      waStartDate : null,
      waEndDate : null,
      waStartDateObj : null,
      waEndDateObj : null,
      // All Stock In Out
      aioStartDate : null,
      aioEndDate : null,
      aioStartDateObj : null,
      aioEndDateObj : null,

      tipe: this.tipe,
      custid: null,
      mydata: null,
      FormStockReport: null,
      expandedRowKeys: [1, 2],
      // selectedRowKeys: [1, 29, 42],

      GetSourceType: [],
      GetWarehouse: [],
      // GetWarehouse2: [],
      GetOrigin: [],
      GetWarehouseDest: [],

      dataNull: [
        {
          warehouse_name: "N/A",
          transaction_number: "N/A",
          uuid: "N/A",
          batch_number: "N/A",
          stock_action: "N/A",
          item_name: "N/A",
          qty: "N/A",
          item_condition_name: "N/A",
        },
      ],

      dataSourceReportNull: [
        {
          item_code: "N/A",
          item_name: "N/A",
          total_first_stock: "N/A",
          total_return: "N/A",
          total_item_in: "N/A",
          total_item_out: "N/A",
          total_item_good: "N/A",
          total_item_quarantine: "N/A",
          total_item_destroy: "N/A",
          total_item: "N/A",
        },
      ],
      dataSourceReport: [],
      dataSourceReportTmp: [],
    };
  },
  created() {
    // this.userId = window.localStorage.getItem("usrid");
    // this.currentUUid = this.uuid;
    // axios
    //   .get( "source_types")
    //   .then((response) => {
    //     this.GetSourceType = response.data.data;
    //   });
    var vThis = this;
    var AUTH_TOKEN = "Bearer " + window.localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;

    let currentUrl = window.location.origin;

    if (currentUrl.includes("localhost")) {
      axios.defaults.baseURL = "http://127.0.0.1:84/api/";
      // axios.defaults.baseURL = "http://bewms.local:10080/api/";
    } else if (currentUrl.includes("10.1.10.21")) {
      axios.defaults.baseURL = "http://10.1.10.21:84/api/";
    } else {
      axios.defaults.baseURL = process.env.VUE_APP_AUTH_API_URL;
    }

    this.roleid = window.localStorage.getItem("roleid");
    this.warehouseId = window.localStorage.getItem("wrhid");
    if (this.roleid == 5) {
      axios
        .get(
           "warehouses/id/" + this.warehouseId
        )
        .then((response) => {
          this.GetWarehouse = response.data.data;
          this.GetWarehouse.forEach(function (item) {
            vThis.warehouse_name_map.set(item.id, item.name);
             
          });
        });
    } else {
      axios
        .get( "warehouses")
        .then((response) => {
          this.GetWarehouse = response.data.data;
          this.GetWarehouse.forEach(function (item) {
            vThis.warehouse_name_map.set(item.id, item.name);
          });
        });
    }
  },
  methods: {
    ...mapActions("stockreport", [
      "setStockReport",
      "clearStockReport",
      "setStockReportStatus",
      "clearStockReportStatus",
    ]),
    format_date(value,pattern) {
      if (value) {
        return moment(String(value)).format(pattern);
      }
    },
    warehouseSelectedChanged(e) {
      if(this.waStartDate != null && this.waEndDate != null) {
        this.myParam = {
          warehouse_id: e.value,
          startDate : this.waStartDate,
          endDate : this.waendDate
        };
      } else {
        this.myParam = {
          warehouse_id: e.value,
        };
      }
      
      this.waWarehouse_id = e.value;
      if (this.waWarehouse_id != null) {
        this.getData(this.myParam);
        this.showLoadPanel();
      } else {
         this.dataSourceReport = [];
         this.waStartDateBox.reset();
         this.waEndDateBox.reset();
         this.waStartDate = null;
         this.waStartDateObj = null;
         this.waEndDate = null;
         this.waEndDateObj = null;
      }
    },
    warehouseSelectedChanged2(e) {
      this.myParam = {
        warehouse_id: e.value,
      };
      let warehouse_id = e.value;
      
      this.getData2(warehouse_id);
      this.showLoadPanel();
    },
    showLoadPanel() {
      this.loadingVisible = true;
    },

    onContentReady1() {
      this.loadingVisible = false;
    },

    waStartDateBoxValueChanged(e) {
      if (e.value != null) {
          if (this.waWarehouse_id != null) {
              this.waStartDateObj = e.value;
              this.waStartDate = this.format_date(e.value,"yyyy-MM-DD");
            } else {
              this.waStartDateObj = null;
              this.waStartDate = null;
              e.component.reset();
              alert('Silahkan Pilih Warehouse');
            }
        } else {
           this.waStartDateObj = null;
           this.waStartDate = null;
        }

        if(this.waStartDate != null && this.waEndDate != null) {
            this.myParam = {
              warehouse_id: this.waWarehouse_id,
              startDate : this.waStartDate,
              endDate : this.waEndDate
            };
            this.getData(this.myParam);
            this.showLoadPanel();
        } else {
          if (this.waWarehouse_id != null) {
            this.myParam = {
              warehouse_id: this.waWarehouse_id,
            };
            this.getData(this.myParam);
            this.showLoadPanel();
          } else {
             this.dataSourceReport = [];
          }
        }
    },

    waEndDateBoxValueChanged(e) {
      if (e.value != null) {
        if (this.waWarehouse_id != null) {
              this.waEndDateObj = e.value;
              this.waEndDate = this.format_date(e.value,"yyyy-MM-DD");
            } else {
              this.waEndDateObj = null;
              this.waEndDate = null;
              e.component.reset();
              alert('Silahkan Pilih Warehouse');
            }
        } else {
           this.waEndDateObj = null;
           this.waEndDate = null;
        }

        if(this.waStartDate != null && this.waEndDate != null) {
            this.myParam = {
              warehouse_id: this.waWarehouse_id,
              startDate : this.waStartDate,
              endDate : this.waEndDate
            };
            this.getData(this.myParam);
            this.showLoadPanel();
        } else {
          if (this.waWarehouse_id != null) {
            this.myParam = {
              warehouse_id: this.waWarehouse_id,
            };
            this.getData(this.myParam);
            this.showLoadPanel();
          } else {
             this.dataSourceReport = [];
          }
        }
    },

    async getData(param) {
      // const param = { dateType: vDateType, yearPickup: vYearPickup };
      try {
        const response = await axios.get("stock_reports", {
          params: param,
        });
        this.dataSourceReport = response.data.data;
        // console.log(
        //   "dataSourceReport: " + JSON.stringify(this.dataSourceReport)
        // );
      } catch (error) {
        console.error(error);
      }
    },

    async getData2(warehouse_id) {
      // const param = { dateType: vDateType, yearPickup: vYearPickup };
      // console.log("param: " + JSON.stringify(param));
      try {
        const response = await axios.get("stock_reports/wrh/" + warehouse_id);
        this.dataSourceReportTmp = response.data.data;
        // console.log(
        //   "dataSourceReportTmp: " + JSON.stringify(this.dataSourceReportTmp)
        // );
      } catch (error) {
        console.error(error);
      }
    },
    stock_action_customizeText(cellInfo) {
      // if (cellInfo.value == 1) {
      //   return "In";
      // } else {
      //   return "Out";
      // }

      switch (cellInfo.value) {
        case 100:
          return "In";
        case 110:
          return "In";

        case 120:
          return "In";

        case 200:
          return "Out";

        case 210:
          return "Out";

        case 220:
          return "Out";

        default:
          return "In";
      }
    },
    async fetch(params = {}) {
      // eslint-disable-next-line no-console
      if (Object.keys(params).length === 0) {
        params = {
          perPage: 10,
          page: 1,
          // parentId: "null",
        };
      }

      if (this.roleid == 5) {
        var results;
        results = await StockReportService.getDataByWarehouseId(
          this.warehouseId,
          params
        );
      } else {
        // this.isCustomerVisible = false;
        results = await StockReportService.getData(params);
      }
      
      // var results = await StockReportService.getData(params);
      // eslint-disable-next-line no-console
      var datax = await {
        data: results.data,
        totalCount: 10,
        // totalCount: results.total,
      };
      return await datax;
    },

    async fetchLastItem(params = {}) {
      // eslint-disable-next-line no-console
      if (Object.keys(params).length === 0) {
        params = {
          perPage: 10,
          page: 1,
          // parentId: "null",
        };
      }

      var results;

      if (this.roleid == 5) {
        results = await StockReportService.getLastItemStock(this.warehouseId,params);
      } else {
        results = await StockReportService.getLastItemStock(null,params);
      }

      var datax = await {
        data: results.data,
        totalCount: 10,
        // totalCount: results.total,
      };
      return await datax;
    },

    onSelectionMenuChanged({ selectedRowKeys, selectedRowsData }) {
      // console.log("selectedRowKeys.length : " + selectedRowKeys.length);
      if (selectedRowKeys.length > 0) {
        // this.setMasterMenu(selectedRowsData);
        console.log(JSON.stringify(selectedRowsData));
        console.log(JSON.stringify(selectedRowKeys));
      }

      // // console.log(JSON.stringify(this.OfferingPrice.length));
      //  this.setOfferingLetterPrice(this.OfferingPrice);
    },
    onSelectionChanged({ selectedRowKeys, selectedRowsData }) {
      // console.log("selectedRowKeys : " + selectedRowKeys.length);
      if (selectedRowKeys.length > 0) {
        if (selectedRowKeys.length == 1) {
          this.setStockReport(selectedRowsData[0]);
          this.btnAdd.option({
            disabled: false,
          });
          this.btnEdit.option({
            disabled: false,
          });
          this.btnView.option({
            disabled: false,
          });
        } else {
          this.setStockReport(selectedRowsData);
          // console.log(selectedRowsData);
          this.btnAdd.option({
            disabled: true,
          });
          this.btnEdit.option({
            disabled: true,
          });
          this.btnView.option({
            disabled: true,
          });
        }
        this.btnDelete.option({
          disabled: false,
        });
      } else {
        this.clearStockReport();
        this.btnAdd.option({
          disabled: true,
        });
        this.btnEdit.option({
          disabled: true,
        });
        this.btnDelete.option({
          disabled: true,
        });
        this.btnView.option({
          disabled: true,
        });
      }
    },
    onToolbarPreparing(e) {
      var vThis = this;

      e.toolbarOptions.items.unshift(
        {
          location: "before",
          template: "titlereport",
        },
        {
          location: "before",
          template: "warehouse",
        },
        {
          location: "before",
          template: "startDateBox",
        },
        {
          location: "before",
          template: "endDateBox",
        },
        // {
        //   location: "after",
        //   widget: "dxDateBox",
        //   options: {
        //     displayFormat:"dd/MM/yyyy",
        //     showClearButton: true,
        //     onValueChanged : function(e) {
        //       if (e.value != null) {
        //         if (vThis.waWarehouse_id != null) {
        //           vThis.waStartDateObj = e.value;
        //           vThis.waStartDate = vThis.format_date(e.value,"yyyy-MM-DD");
        //         } else {
        //           vThis.waStartDateObj = null;
        //           vThis.waStartDate = null;
        //           this.reset();
        //           alert('Silahkan Pilih Warehouse');
        //         }
                
        //      } else {
        //         vThis.waStartDateObj = null;
        //         vThis.waStartDate = null;
        //      }
             
        //      if(vThis.waStartDate != null && vThis.waEndDate != null) {
        //           vThis.myParam = {
        //             warehouse_id: vThis.waWarehouse_id,
        //             startDate : vThis.waStartDate,
        //             endDate : vThis.waEndDate
        //           };
        //           vThis.getData(vThis.myParam);
        //           vThis.showLoadPanel();
        //       } else {
        //         if (vThis.waWarehouse_id != null) {
        //           vThis.myParam = {
        //             warehouse_id: vThis.waWarehouse_id,
        //           };
        //           vThis.getData(vThis.myParam);
        //           vThis.showLoadPanel();
        //         } else {
        //            vThis.dataSourceReport = [];
        //         }
        //       }
        //     }
        //   }
        // },
        // {
        //   location: "after",
        //   text: "-"
        // },
        // {
        //   location: "after",
        //   widget: "dxDateBox",
        //   options: {
        //     displayFormat:"dd/MM/yyyy",
        //     showClearButton: true,
        //     onValueChanged : function(e) {
        //       if (e.value != null) {
        //         if (vThis.waWarehouse_id != null) {
        //            vThis.waEndDateObj = e.value;
        //            vThis.waEndDate = vThis.format_date(e.value,"yyyy-MM-DD");
        //          } else {
        //            vThis.waEndDateObj = null;
        //            vThis.waEndDate = null;
        //            this.reset();
        //            alert('Silahkan Pilih Warehouse');
        //          }
        //       } else {
        //         vThis.waEndDateObj = null;
        //            vThis.waEndDate = null;
        //       }

        //       if(vThis.waStartDate != null && vThis.waEndDate != null) {
        //           vThis.myParam = {
        //             warehouse_id: vThis.waWarehouse_id,
        //             startDate : vThis.waStartDate,
        //             endDate : vThis.waEndDate
        //           };
        //       } else {
        //         vThis.myParam = {
        //           warehouse_id: vThis.waWarehouse_id,
        //         };
        //       }

        //       vThis.getData(vThis.myParam);
        //       vThis.showLoadPanel();
        //     }
        //   }
        // },
        {
          location: "after",
          widget: "dxButton",
          options: {
            icon: "refresh",
            text: "Refresh",
            onClick: function () {
              if (vThis.waWarehouse_id != null) {
                if(vThis.waStartDate != null && vThis.waEndDate != null) {
                    vThis.myParam = {
                      warehouse_id: vThis.waWarehouse_id,
                      startDate : vThis.waStartDate,
                      endDate : vThis.waEndDate
                    };
                } else {
                  vThis.myParam = {
                    warehouse_id: vThis.waWarehouse_id,
                  };
                }
                vThis.getData(vThis.myParam);
                vThis.showLoadPanel();
              } else {
                alert('Silahkan Pilih Warehouse');
              }
            },
          },
        }
      );
    },

    onToolbarItemSummaryPreparing(e) {
      var vThis = this;

      e.toolbarOptions.items.unshift(
        {
          location: "before",
          template: "titlereport",
        },
        {
          location: "before",
          template: "warehouse",
        },
        {
          location: "after",
          widget: "dxButton",
          options: {
            icon: "refresh",
            text: "Muat Ulang",
            onClick: function () {
              vThis.dataGridItemSummary.refresh();
            },
          },
        }
      );
    },
    onToolbarStockInOutPreparing(e) {
      var vThis = this;

      e.toolbarOptions.items.unshift(
        {
          location: "before",
          text: "Date Filter"
        },
        {
          location: "before",
          widget: "dxDateBox",
          options: {
            displayFormat:"dd/MM/yyyy",
            showClearButton: true,
            onValueChanged : function(e) {
              vThis.aioStartDateObj = e.value;
              vThis.aioStartDate = vThis.format_date(e.value,"yyyy-MM-DD");
              vThis.dataGridAIO.refresh();
            }
          }
        },
        {
          location: "before",
          text: "-"
        },
        {
          location: "before",
          widget: "dxDateBox",
          options: {
            displayFormat:"dd/MM/yyyy",
            showClearButton: true,
            onValueChanged : function(e) {
              vThis.aioEndDateObj = e.value;
              vThis.aioEndDate = vThis.format_date(e.value,"yyyy-MM-DD");
              vThis.dataGridAIO.refresh();
            }
          }
        },
        {
          location: "after",
          widget: "dxButton",
          options: {
            icon: "refresh",
            text: "Muat Ulang",
            onClick: function () {
              vThis.dataGridAIO.refresh();
            },
          },
        }
      );
    },
    onToolbarLastItemStockPreparing(e) {
      var vThis = this;

      e.toolbarOptions.items.unshift({
        location: "after",
        widget: "dxButton",
        options: {
          icon: "refresh",
          text: "Muat Ulang",
          onClick: function () {
            console.log(vThis.dataGrid);
            vThis.dataGridLastStock.refresh();
          },
        },
      });
    },
    groupChanged(e) {
      console.log("log e : " + e.value);
    },
    getDataCust() {
      let currentObj = this;
      axios
        .get("stockreports/" + this.custid)
        .then(function (response) {
          // console.log(response);
          // currentObj.success = response.data.success;
          // currentObj.mydata = response;
          currentObj.FormStockReport = response.data.data;
          // console.log("mydata = " + JSON.stringify(response.data.data));
          // console.log(
          //   "getStockReport = " + JSON.stringify(currentObj.getStockReport)
          // );
        })
        .catch(function (error) {
          console.log(error);
          currentObj.output = error;
        });
    },

    onExportingItemSummary(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Main sheet");
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        customizeCell: function (options) {
          const excelCell = options;
          excelCell.font = { name: "Arial", size: 12 };
          excelCell.alignment = { horizontal: "left" };
        },
      }).then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "Item-Summary.xlsx"
          );
        });
      });
      e.cancel = true;
    },

    onExportingWarehouseActivity(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Warehouse Activity");

      let warehouse_name = this.warehouse_name_map.get(this.waWarehouse_id);

      let periode = null;
      let fileName = "Warehouse-Activity-"+warehouse_name+".xlsx";

      if (this.waStartDateObj != null && this.waEndDateObj != null) {
         periode = this.format_date(this.waStartDateObj,"DD/MM/yyyy")+" - "+this.format_date(this.waEndDateObj,"DD/MM/yyyy");
         fileName = "Warehouse-Activity-"+warehouse_name+"-"+this.format_date(this.waStartDateObj,"yyyyMMDD")+"-"+this.format_date(this.waEndDateObj,"yyyyMMDD")+".xlsx";
      }

      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        topLeftCell: { row: 5, column: 1 },
        customizeCell: function (options) {
          const excelCell = options;
          excelCell.font = { name: "Arial", size: 12 };
          excelCell.alignment = { horizontal: "left" };
        },
      }).then((cellRange) => {
          // header
          const headerRow = worksheet.getRow(2);
          const headerRow2 = worksheet.getRow(3);

          headerRow.height = 30;
          worksheet.mergeCells(2, 1, 2, 9);

          headerRow.getCell(1).value = "Warehouse Activity";
          headerRow.getCell(1).font = { name: "Segoe UI Light", size: 22 };
          headerRow.getCell(1).alignment = { horizontal: "left" };

          headerRow2.getCell(1).value = "Warehouse Name : "+warehouse_name;
          headerRow2.getCell(1).font = { name: "Segoe UI Light", size: 14 };
          headerRow2.getCell(1).alignment = { horizontal: "left" };

          if (periode != null) {
            headerRow2.getCell(7).value = "Periode : "+periode;
            headerRow2.getCell(7).font = { name: "Segoe UI Light", size: 14 };
            headerRow2.getCell(7).alignment = { horizontal: "left" };
          }

          // footer
          const footerRowIndex = cellRange.to.row + 2;
          const footerRow = worksheet.getRow(footerRowIndex);
          worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 8);

          footerRow.getCell(1).value = "";
          footerRow.getCell(1).font = {
            color: { argb: "BFBFBF" },
            italic: true,
          };
          footerRow.getCell(1).alignment = { horizontal: "right" };
       }).then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            fileName
          );
        });
      });
      e.cancel = true;
    },

    onExportingStockInOutActivity(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Main sheet");

      let periode = null;
      let fileName = "Stock-In-Out.xlsx";
      let topLeftCell = { row: 4, column: 1 };

      if (this.aioStartDateObj != null && this.aioEndDateObj != null) {
         periode = this.format_date(this.aioStartDateObj,"DD/MM/yyyy")+" - "+this.format_date(this.aioEndDateObj,"DD/MM/yyyy");
         fileName = "Stock-In-Out-"+this.format_date(this.aioStartDateObj,"yyyyMMDD")+"-"+this.format_date(this.aioEndDateObj,"yyyyMMDD")+".xlsx";
         topLeftCell = { row: 5, column: 1 };
      }

      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        topLeftCell: topLeftCell,
        customizeCell: function (options) {
          const excelCell = options;
          excelCell.font = { name: "Arial", size: 12 };
          excelCell.alignment = { horizontal: "left" };
        }
      }).then((cellRange) => {
          // header
          const headerRow = worksheet.getRow(2);
          const headerRow2 = worksheet.getRow(3);

          headerRow.height = 30;
          worksheet.mergeCells(2, 1, 2, 7);

          headerRow.getCell(1).value = "All Stock In/Out Transaction ";
          headerRow.getCell(1).font = { name: "Segoe UI Light", size: 22 };
          headerRow.getCell(1).alignment = { horizontal: "left" };

          if (periode != null) {
            headerRow2.getCell(1).value = "Periode : "+periode;
            headerRow2.getCell(1).font = { name: "Segoe UI Light", size: 14 };
            headerRow2.getCell(1).alignment = { horizontal: "left" };
          }

          // footer
          const footerRowIndex = cellRange.to.row + 2;
          const footerRow = worksheet.getRow(footerRowIndex);
          worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 8);

          footerRow.getCell(1).value = "";
          footerRow.getCell(1).font = {
            color: { argb: "BFBFBF" },
            italic: true,
          };
          footerRow.getCell(1).alignment = { horizontal: "right" };
       }).then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            fileName
          );
        });
      });
      e.cancel = true;
    },
    onExportingLastItemStockActivity(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Item Last Stock");
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        topLeftCell: { row: 4, column: 1 },
        customizeCell: function (options) {
          const excelCell = options;
          excelCell.font = { name: "Arial", size: 12 };
          excelCell.alignment = { horizontal: "left" };
        },
      }).then((cellRange) => {
          // header
          const headerRow = worksheet.getRow(2);
          
          headerRow.height = 30;
          worksheet.mergeCells(2, 1, 2, 8);

          headerRow.getCell(1).value = "Item Last Stock By Expired Date and Batch Number";
          headerRow.getCell(1).font = { name: "Segoe UI Light", size: 22 };
          headerRow.getCell(1).alignment = { horizontal: "left" };

          // footer
          const footerRowIndex = cellRange.to.row + 2;
          const footerRow = worksheet.getRow(footerRowIndex);
          worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 8);

          footerRow.getCell(1).value = "";
          footerRow.getCell(1).font = {
            color: { argb: "BFBFBF" },
            italic: true,
          };
          footerRow.getCell(1).alignment = { horizontal: "right" };
       })
       .then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "Item-Last-Stock-By-ED-and-BN.xlsx"
          );
        });
      });
      e.cancel = true;
    }
  },
  computed: {
    ...mapGetters("stockreport", [
      "getStockReport",
      "getStockReportStatus",
      "getStockReportForm",
    ]),
    dataGridItemSummary: function () {
      return this.$refs[gdRefItemSum].instance;
    },
    dataGridWA: function () {
      return this.$refs[gdRefWA].instance;
    },
    dataGridAIO: function () {
      return this.$refs[gdRefAIO].instance;
    },
    dataGridLastStock: function () {
      return this.$refs[gdReflaststock].instance;
    },
    waStartDateBox: function() {
      return this.$refs[waStartDateBoxRef].instance;
    },
    waEndDateBox: function() {
      return this.$refs[waEndDateBoxRef].instance;
    }
  },
};
</script>

<style>
/* .informer {
  height: 40px;
  width: 100px;
  text-align: center;
  display: inline-block;
} */

.myfilter {
  height: 80px;
  /* display: inline-block; */
  /* padding-top: 10px; */
  padding-left: 20px;
  padding-top: 10px;
  /* width: 150px; */
  /* vertical-align: middle; */
}
.informer {
  height: 70px;
  /* padding-top: 10px; */
  padding-left: 10px;
  /* width: 800px; */
  /* display: inline-block; */
}
.mytitle {
  /* height: 80px; */
  display: inline-block;
  padding-left: 20px;
  /* padding-top: 20px; */
  width: 100px;
  vertical-align: middle;
}
span.b {
  display: inline-block;
  width: 120px;
  height: 50px;
  padding: 0 5px;
  vertical-align: middle;
  box-sizing: content-box;
}
.count {
  padding-top: 15px;
  line-height: 27px;
  margin: 0;
}
.field-label {
   width: 10% !important;
}

.field-date-box {
   width: 90% !important;
}
</style>
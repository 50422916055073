<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <DxForm
        @field-data-changed="formFieldDataChanged"
        :form-data="getStockFirstStatus == 'edit' ? getStockFirst : null"
        :ref="formRef"
        :col-count="2"
      >
        <DxGroupItem>
          <DxSimpleItem
            data-field="user_id"
            :visible="false"
            :editor-options="{ value: userId }"
          />
          <DxSimpleItem
            data-field="uuid"
            :visible="false"
            :editor-options="{
              value: currentUUid,
            }"
          />
          <DxSimpleItem
            data-field="warehouse_id"
            :label="{ text: 'Pilih Warehouse' }"
          >
            <template #default>
              <DxSelectBox
                :data-source="GetWarehouse"
                display-expr="name"
                value-expr="id"
                placeholder="Select Warehouse"
                @value-changed="valueChangedWarehouse"
                :search-enabled="true"
                :value="
                  getStockFirstStatus == 'edit'
                    ? getStockFirst.warehouse_id
                    : null
                "
              >
              </DxSelectBox>
            </template>
          </DxSimpleItem>

          <DxSimpleItem data-field="item_id" :label="{ text: 'Pilih Item' }">
            <template #default>
              <DxSelectBox
                :ref="selectItemRef"
                :data-source="GetItem"
                display-expr="item_code_name"
                value-expr="id"
                placeholder="Select Item"
                @value-changed="valueChangedItem"
                @selection-changed="selectionChangedItem"
                :show-drop-down-button="true"
                :search-enabled="true"
                :value="
                  getStockFirstStatus == 'edit' ? getStockFirst.item_id : null
                "
              >
                <DxSelectBoxButton
                  name="dropDown"
                  location="before"
                />
                <DxSelectBoxButton
                  name="clearButton"
                  location="after"
                  :options="{visible:buttonVisible, icon: 'clear',
                             type: 'danger', onClick: clearSelection }"
                />
              </DxSelectBox>
            </template>
          </DxSimpleItem>

          <DxSimpleItem data-field="qty" :label="{ text: 'Jumlah' }">
            <DxRequiredRule message="Please fill Item Stock" />
          </DxSimpleItem>
        </DxGroupItem>

        <DxGroupItem>
          <DxSimpleItem
            data-field="item_condition_id"
            :label="{ text: 'Select Item Condition' }"
          >
            <template #default>
              <DxSelectBox
                :data-source="GetItemCondition"
                display-expr="name"
                value-expr="id"
                placeholder="Select Item Condition"
                @value-changed="valueChangedItemCondition"
                :search-enabled="true"
                :value="
                  getStockFirstStatus == 'edit'
                    ? getStockFirst.item_condition_id
                    : null
                "
              >
              </DxSelectBox>
            </template>
          </DxSimpleItem>

          <DxSimpleItem
            data-field="batch_number"
            :label="{ text: 'Batch Number' }"
            editor-type="dxTextBox"
            :editor-options = batchNumberOptions
          >
            <DxRequiredRule message="Please fill batch number" />
          </DxSimpleItem>

          <DxSimpleItem
            data-field="expired_date"
            :label="{ text: 'Expired Date' }"
            editor-type="dxDateBox"
            :editor-options="{ dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ss' }"
          >
          </DxSimpleItem>
        </DxGroupItem>
      </DxForm>
    </div>
  </div>
</template>
<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from "devextreme-vue/form";
import DxTextArea from "devextreme-vue/text-area";
import { mapGetters, mapActions } from "vuex";
import {DxSelectBox, DxButton as DxSelectBoxButton}  from "devextreme-vue/select-box";
import axios from "axios";
import { uuid } from "vue-uuid";
// import DxDateBox from "devextreme-vue/date-box";
const formRef = "stockfirst-form";
const selectItemRef = "stockfirst-selectboxItem";

export default {
  components: {
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    // eslint-disable-next-line
    DxTextArea,
    DxRequiredRule,
    DxSelectBox,
    DxSelectBoxButton
    // DxDateBox,
  },
  data: function () {
    return {
      userId: null,
      formRef,
      selectItemRef,
      GetItem: [],
      GetWarehouse: [],
      GetItemCondition: [],
      GetExpiredMonth: [],
      GetExpiredYear: [],
      uuid: uuid.v1(),
      warehouseId: null,
      buttonVisible : false,
      batchNumberOptions : {
        buttons : [
           {
            location : 'after',
            name: 'generateButtons',
            options : {
              icon: 'preferences',
              onClick: () => {
                let item_code = this.getStockFirst.item_code;
                if (typeof(item_code) !== 'undefined' && item_code != null) {
                   this.$refs[formRef].instance.updateData({
                    batch_number: 'BN-'+item_code,
                   });
                } else {
                  alert("Silahkan Pilih Nama Item terlebih dahulu")
                }
              }
            }
           }
        ]
      },
    };
  },
  created() {
    this.userId = window.localStorage.getItem("usrid");
    this.currentUUid = this.uuid;

    this.roleid = window.localStorage.getItem("roleid");
    this.warehouseId = window.localStorage.getItem("wrhid");
    if (this.roleid == 5) {
      axios
        .get(
           "warehouses/id/" + this.warehouseId
        )
        .then((response) => {
          this.GetWarehouse = response.data.data;
        });
    } else {
      axios
        .get( "warehouses")
        .then((response) => {
          this.GetWarehouse = response.data.data;
        });
    }

    axios
      .get( "master_items")
      .then((response) => {
        //console.log(JSON.stringify(response.data));
        var tempData = response.data.data;
        var itemData = [];
        tempData.forEach(function (item) {
            item.item_code_name = item.item_code +" "+item.item_name;
            itemData.push(item);
        });
        this.GetItem = itemData;
      });

    axios
      .get( "item_conditions")
      .then((response) => {
        //console.log(JSON.stringify(response.data));
        this.GetItemCondition = response.data.data;
      });
  },
  mounted() {
    this.formSet();
  },
  methods: {
    ...mapActions("stockfirst", [
      "setStockFirst",
      "clearStockFirst",
      "setStockFirstStatus",
      "clearStockFirstStatus",
      "setStockFirstForm",
    ]),
    valueChangedWarehouse(e) {
      this.$refs[formRef].instance.updateData({
        warehouse_id: e.value,
      });
    },
    valueChangedItem(e) {
      this.$refs[formRef].instance.updateData({
        item_id: e.value,
      });
    },
    selectionChangedItem(e) {
      let itemDetail = e.selectedItem;
      if (typeof(itemDetail) !== 'undefined' && itemDetail != null) {
        this.$refs[formRef].instance.updateData({
          item_code: itemDetail.item_code,
        });
        this.buttonVisible = true;
      } else {
         this.$refs[formRef].instance.updateData({
          item_code: null,
        });
      }
      
    },
    clearSelection() {
       this.buttonVisible = false;
       this.$refs[formRef].instance.updateData({
          item_id : null,
          item_code: null,
      });
      this.$refs[selectItemRef].instance.reset();
    },
    valueChangedExpiredDate(e) {
      //console.log(this.format_date(e.value));
      this.$refs[formRef].instance.updateData({
        expired_date: this.format_date(e.value),
      });
    },
    /* valueChangedEndDate(e) {
      //console.log(this.format_date(e.value));
      this.$refs[formRef].instance.updateData({
        end_date : this.format_date(e.value),
      });
    }, */
    valueChangedItemCondition(e) {
      this.$refs[formRef].instance.updateData({
        item_condition_id: e.value,
      });
    },
    formFieldDataChanged(e) {
      // console.log(e.component.option("formData"));
      this.setStockFirst(e.component.option("formData"));
    },
    formSet() {
      this.setStockFirstForm(this.$refs[formRef].instance);
    },
  },

  computed: {
    ...mapGetters("stockfirst", [
      "getStockFirst",
      "getStockFirstStatus",
      "getStockFirstForm",
    ]),
  },
};
</script>
<style>
.group-padding {
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.form-block {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  padding-left: 40px;
  padding-right: 40px;
}
</style>

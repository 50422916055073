const state = {
  stockout: null,
  stockoutStatus: "",
  stockoutForm: null,
};

const getters = {
  getStockOut: (state) => {
    return state.stockout;
  },
  getStockOutStatus: (state) => {
    return state.stockoutStatus;
  },
  getStockOutForm: (state) => {
    return state.stockoutForm;
  },
};

const mutations = {
  setStockOut: (state, stockout) => {
    state.stockout = stockout;
  },
  clearStockOut: (state) => {
    state.stockout = null;
  },

  setStockOutStatus: (state, status) => {
    state.stockoutStatus = status;
  },
  clearStockOutStatus: (state) => {
    state.stockoutStatus = "";
  },

  setStockOutForm: (state, status) => {
    state.stockoutForm = status;
  },
  clearStockOutForm: (state) => {
    state.stockoutForm = "";
  },
};

const actions = {
  setStockOut({ commit }, data) {
    commit("setStockOut", data);
  },
  clearStockOut({ commit }) {
    commit("clearStockOut");
  },

  setStockOutStatus({ commit }, status) {
    commit("setStockOutStatus", status);
  },
  clearStockOutStatus({ commit }) {
    commit("clearStockOutStatus");
  },

  setStockOutForm({ commit }, data) {
    commit("setStockOutForm", data);
  },
  clearStockOutForm({ commit }) {
    commit("clearStockOutForm");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

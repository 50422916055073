var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"content-block dx-card responsive-paddings"},[_c('DxForm',{ref:_vm.formRef,attrs:{"form-data":_vm.getCompanyBranchStatus == 'edit' ? _vm.getCompanyBranch : null,"col-count":2},on:{"field-data-changed":_vm.formFieldDataChanged}},[_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"data-field":"company_id","label":{ text: 'Nama Perusahaan Induk' }},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('DxSelectBox',{attrs:{"data-source":_vm.companyData,"show-clear-button":true,"display-expr":"name","value-expr":"id","value":_vm.getCompanyBranchStatus == 'edit'
                  ? _vm.getCompanyBranch.company_id
                  : null,"searchEnabled":true},on:{"value-changed":_vm.CompanySelectedChanged}})]},proxy:true}])}),_c('DxSimpleItem',{attrs:{"data-field":"branch_name","label":{ text: 'Nama Perusahaan Cabang' },"editor-options":{ maxLength: '100' }}},[_c('DxRequiredRule',{attrs:{"message":"Nama CompanyBranch Wajib di Isi"}})],1),_c('DxSimpleItem',{attrs:{"data-field":"address","label":{ text: 'Alamat' },"editor-options":{ maxLength: '100' }}},[_c('DxRequiredRule',{attrs:{"message":"Alamat Wajib di Isi"}})],1),_c('DxSimpleItem',{attrs:{"data-field":"postalcode","label":{ text: 'Kode Pos' },"editor-options":{ maxLength: '5' }}},[_vm._v(" > ")]),_c('DxSimpleItem',{attrs:{"data-field":"province_id","label":{ text: 'Provinsi' }},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('DxSelectBox',{attrs:{"data-source":_vm.provinceData,"show-clear-button":true,"display-expr":"name","value-expr":"id","value":_vm.getCompanyBranchStatus == 'edit'
                  ? _vm.getCompanyBranch.province_id
                  : null,"searchEnabled":true},on:{"value-changed":_vm.ProvinceSelectedChanged}})]},proxy:true}])})],1),_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"data-field":"city_id","label":{ text: 'Kota' }},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('DxSelectBox',{attrs:{"data-source":_vm.cityData2,"show-clear-button":true,"display-expr":"name","value-expr":"id","value":_vm.getCompanyBranchStatus == 'edit'
                  ? _vm.getCompanyBranch.city_id
                  : null,"searchEnabled":true},on:{"value-changed":_vm.CitySelectedChanged}})]},proxy:true}])}),_c('DxSimpleItem',{attrs:{"data-field":"phone","label":{ text: 'No Telepon' },"editor-options":{
            maxLength: '50',
          }}}),_c('DxSimpleItem',{attrs:{"data-field":"email","label":{ text: 'Email' },"editor-options":{ maxLength: '150' }}})],1),_c('ProvinceData',{on:{"provinceData":_vm.setProvinceData}}),_c('CityData',{on:{"cityData":_vm.setCityData}}),_c('CompanyData',{on:{"companyData":_vm.setCompanyData}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
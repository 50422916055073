<template>
  <div>
    <!-- <h2 class="content-block">Daftar Branch Customer</h2> -->
    <!-- <h2 class="content-block">Customer Branch List</h2> -->
    <user-table />
  </div>
</template>
<script>
import userTable from "@/components/users/UserTable.vue";
export default {
  components: {
    userTable
  }
};
</script>
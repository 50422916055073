<template>
  <div>
    <h2 class="content-block">Cabang Perusahaan</h2>
    <company-branch-table />
  </div>
</template>

<script>
import CompanyBranchTable from "@/components/company-branch/CompanyBranchTable.vue";
export default {
  components: {
    CompanyBranchTable,
  },
};
</script>
<template>
  <div>
        <h2 class="content-block">Tipe Lokasi</h2>
    <location-type-table />
  </div>
</template>

<script>

import LocationTypeTable from "@/components/location-type/LocationTypeTable.vue";
export default {
  components: {
    LocationTypeTable,
  },
};
</script>
<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <dx-data-grid
        class="dx-card wide-card"
        :data-source="
          GetDestroyItemList.length > 0 ? GetDestroyItemList : dataNull
        "
        :show-borders="true"
        :focused-row-index="0"
        :focused-row-enabled="true"
        :column-auto-width="true"
        :column-hiding-enabled="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :loadPanel="loadPanel"
        :selected-row-keys="selectedRowKeys"
        @toolbar-preparing="onToolbarPreparing($event)"
        @selection-changed="onSelectionChanged"
        @rowExpanding="onRowExpanding"
        @exporting="onExporting"
        :ref="gridRef"
      >
        <dx-paging :page-size="10" />
        <dx-pager
          :showPageSizeSelector="true"
          :allowed-page-sizes="pageSizes"
          :show-info="true"
          :showNavigationButtons="true"
          infoText="Hal {0} dari {1} ({2} items)"
          :visible="true"
        />
        <dx-filter-row :visible="filterStatus" />
        <DxSelection mode="multiple" />
        <DxColumn data-field="warehouse_name" caption="Warehouse" />
        <DxColumn
          data-field="transaction_number"
          caption="No.Transaksi"
          :width="140"
        />

        <DxColumn data-field="item_name" caption="Nama Item" />
        <DxColumn data-field="qty" caption="Jumlah" />
        <!-- <DxColumn data-field="price" caption="Harga" /> -->
        <!-- <DxColumn data-field="price" caption="Harga" /> -->
        <DxColumn data-field="batch_number" caption="Batch Number" />
        <DxColumn data-field="item_condition_name" caption="Kondisi Barang" />
        <DxColumn
          data-field="expired_date"
          caption="Tanggal Expired"
          dataType="date"
          format="dd/MM/yyyy"
          :width="160"
        />
        <DxExport :enabled="true" />
        <template #titlereport>
          <div class="myfilter">Pilih Warehouse :</div>
        </template>

        <template #warehouse>
          <div class="informer">
            <span class="b">
              <DxSelectBox
                :data-source="GetWarehouse"
                :width="200"
                :show-clear-button="true"
                display-expr="name"
                value-expr="id"
                :searchEnabled="true"
                @value-changed="warehouseSelectedChanged3"
              ></DxSelectBox>
            </span>
          </div>
        </template>
      </dx-data-grid>
    </div>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxPager,
  DxPaging,
  DxSelection,
  DxColumn,
  DxFilterRow,
  DxExport,
} from "devextreme-vue/data-grid";
import CustomStore from "devextreme/data/custom_store";
import StockDestroyService from "@/services/StockDestroyService";
import router from "../../router";
import { mapGetters, mapActions } from "vuex";
import { confirm } from "devextreme/ui/dialog";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { DxSelectBox } from "devextreme-vue/select-box";
import axios from "axios";

const gridRef = "stockout-table";

export default {
  // props: ["StockDestroyId"],
  components: {
    DxDataGrid,
    DxPager,
    DxPaging,
    DxSelection,
    DxColumn,
    DxFilterRow,
    DxExport,
    DxSelectBox,
  },
  data() {
    const vthis = this;
    const dataSource = {
      store: new CustomStore({
        key: "id",
        load: async function (loadOptions) {
          var sortedBy = "";
          if (loadOptions.sort) {
            sortedBy += loadOptions.sort[0].selector;
            if (loadOptions.sort[0].desc) {
              sortedBy += " desc";
            } else {
              sortedBy += " asc";
            }
          }

          var perPage = loadOptions.take;
          var offset = loadOptions.skip;
          var page = offset / perPage + 1;

          var datax = vthis.fetch({
            perPage: perPage || 10,
            page: page || 1,
            sortedBy: sortedBy,
            parentId: "null",
          });

          return await datax;
        },
      }),
    };

    return {
      filterStatus: false,
      gridRef,
      dataSource: dataSource,
      pageSizes: [5, 10, 15],
      selectedRowKeys: [],
      loadPanel: {
        text: "Memuat...",
      },
      btnAdd: null,
      btnEdit: null,
      btnDelete: null,
      btnView: null,

      tipe: this.tipe,
      custid: null,
      mydata: null,
      FormStockDestroy: null,

      GetWarehouse: [],
      GetDestroyItemList: [],
      dataNull: [
        {
          item_name: "N/A",
          last_item_qty: "N/A",
          item_condition_name: "N/A",
          batch_number: "N/A",
          expired_date: "N/A",
        },
      ],
    };
  },
  created() {
    this.userId = window.localStorage.getItem("usrid");
    this.warehouseId = window.localStorage.getItem("wrhid");
    this.roleid = window.localStorage.getItem("roleid");
    this.currentUUid = this.uuid;
    if (this.roleid == 5) {
      axios
        .get(
           "warehouses/id/" + this.warehouseId
        )
        .then((response) => {
          this.GetWarehouse = response.data.data;
        });
    } else {
      axios
        .get( "warehouses")
        .then((response) => {
          this.GetWarehouse = response.data.data;
        });
    }
  },
  methods: {
    ...mapActions("stockdestroy", [
      "setStockDestroy",
      "clearStockDestroy",
      "setStockDestroyStatus",
      "clearStockDestroyStatus",
      "setStockDestroyForm",
      "setStockDestroyTemp",
      "clearStockDestroyTemp",
    ]),

    warehouseSelectedChanged3(e) {
      console.log("warehouse e : " + e.value);
      var warehouse_id = e.value;
      // var addParam;
      // addParam = {
      //   warehouse_id: warehouse_id,
      // };
      // var wrhObject = {};
      // Object.assign(wrhObject, addParam);
      // this.setStockQuarantineTemp("2");

      var tmp = [];
      var my_json = {
        warehouse_id: warehouse_id,
      };
      tmp.push(my_json);
      this.setStockDestroyTemp(tmp);

      console.log(
        "getStockQuarantineTemp : " +
          JSON.stringify(this.getStockQuarantineTemp)
      );
      this.getDestroyItem(warehouse_id);
    },

    getDestroyItem(warehouse_id) {
      axios
        .get(
          
            "stock_destroys/wrh/" +
            warehouse_id
        )
        .then((response) => {
          //console.log(JSON.stringify(response.data));
          this.GetDestroyItemList = response.data.data;
        });
    },

    async fetch(params = {}) {
      // eslint-disable-next-line no-console
      if (Object.keys(params).length === 0) {
        params = {
          perPage: 10,
          page: 1,
          // parentId: "null",
        };
      }
      // var results = await StockDestroyService.getData(params);

      if (this.roleid == 5) {
        var results = await StockDestroyService.getDataByWarehouseId(
          this.warehouseId,
          params
        );
      } else {
        results = await StockDestroyService.getData(params);
      }
      // eslint-disable-next-line no-console
      var datax = await {
        data: results.data,
        totalCount: 10,
        // totalCount: results.total,
      };
      return await datax;
    },

    onSelectionChanged({ selectedRowKeys, selectedRowsData }) {
      // console.log("selectedRowKeys : " + selectedRowKeys.length);
      if (selectedRowKeys.length > 0) {
        if (selectedRowKeys.length == 1) {
          this.setStockDestroy(selectedRowsData[0]);
          // this.btnAdd.option({
          //   disabled: false,
          // });
          this.btnEdit.option({
            disabled: false,
          });
          this.btnView.option({
            disabled: false,
          });
        } else {
          this.setStockDestroy(selectedRowsData);
          // console.log(selectedRowsData);
          // this.btnAdd.option({
          //   disabled: true,
          // });
          this.btnEdit.option({
            disabled: true,
          });
          this.btnView.option({
            disabled: true,
          });
        }
        this.btnDelete.option({
          disabled: false,
        });
      } else {
        this.clearStockDestroy();
        // this.btnAdd.option({
        //   disabled: true,
        // });
        this.btnEdit.option({
          disabled: true,
        });
        this.btnDelete.option({
          disabled: true,
        });
        this.btnView.option({
          disabled: true,
        });
      }
    },
    onToolbarPreparing(e) {
      var vThis = this;

      e.toolbarOptions.items.unshift(
        {
          location: "before",
          template: "titlereport",
        },
        {
          location: "before",
          template: "warehouse",
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "add",
            text: "Tambah",
            visible: false,
            onInitialized: function (e) {
              vThis.btnAdd = e.component;
            },
            onClick: function () {
              vThis.setStockDestroyStatus("add");
              router.push("/stock/destroy/add");
            },
          },
        },
        {
          location: "after",
          widget: "dxButton",
          options: {
            icon: "edit",
            text: "Edit",
            disabled: true,
            visible: false,
            onInitialized: function (e) {
              vThis.btnEdit = e.component;
            },
            onClick: function () {
              vThis.setStockDestroyStatus("edit");
              router.push("/stock/destroy/edit");
            },
          },
        },
        {
          location: "after",
          widget: "dxButton",
          options: {
            icon: "trash",
            text: "Hapus",
            disabled: true,
            onInitialized: function (e) {
              vThis.btnDelete = e.component;
            },
            onClick: function () {
              let result = confirm(
                "<i>Anda yakin mau menghapus data ini ?</i>",
                "Hapus StockDestroy StockDestroy"
              );
              result.then((dialogResult) => {
                if (dialogResult) {
                  var StockDestroyData = vThis.getStockDestroy;
                  console.log(vThis.getStockDestroy);
                  var ids = "";
                  if (Array.isArray(StockDestroyData)) {
                    StockDestroyData.forEach(function (item, index) {
                      if (index == 0) {
                        ids += item.id;
                      } else {
                        ids += "," + item.id;
                      }
                    });
                  } else {
                    ids = StockDestroyData.id;
                  }

                  vThis.deleteReport(StockDestroyData.uuid);

                  StockDestroyService.delete(
                    ids,
                    function () {
                      vThis.clearStockDestroy();
                      vThis.dataGrid.refresh();
                    },
                    function (error) {
                      // eslint-disable-next-line no-console
                      console.log(error);
                    }
                  );
                }
              });
            },
          },
        },
        {
          location: "after",
          widget: "dxButton",
          options: {
            icon: "detailslayout",
            text: "Detail",
            disabled: true,
            onInitialized: function (e) {
              vThis.btnView = e.component;
            },
            onClick: function () {
              router.push("/stock/destroy/view");
            },
          },
        },
        {
          location: "after",
          widget: "dxButton",
          options: {
            icon: "refresh",
            text: "Muat Ulang",
            onClick: function () {
              vThis.dataGrid.refresh();
            },
          },
        },
        // Filter //
        {
          location: "after",
          widget: "dxButton",
          options: {
            icon: "search",
            text: vThis.filterText,
            disabled: false,
            onInitialized: function (e) {
              vThis.btnFilter = e.component;
            },
            onClick: function () {
              if (vThis.filterStatus === true) {
                vThis.filterStatus = false;
                vThis.filterText = "Filter ON";
                // console.log("filterStatus : " + vThis.filterStatus);
              } else {
                vThis.filterStatus = true;
                vThis.filterText = "Filter Off";
                // console.log("filterStatus : " + vThis.filterStatus);
              }
            },
          },
        }
      );
    },
    onRowExpanding(e) {
      var dataGrid = e.component;
      dataGrid.collapseAll(-1);
    },
    getDataCust() {
      let currentObj = this;
      axios
        .get("stockouts/" + this.custid)
        .then(function (response) {
          // console.log(response);
          // currentObj.success = response.data.success;
          // currentObj.mydata = response;
          currentObj.FormStockDestroy = response.data.data;
          // console.log("mydata = " + JSON.stringify(response.data.data));
          // console.log(
          //   "getStockDestroy = " + JSON.stringify(currentObj.getStockDestroy)
          // );
        })
        .catch(function (error) {
          console.log(error);
          currentObj.output = error;
        });
    },
    async deleteReport(uuid) {
      var vThis = this;
      // var vuuid = uuid;
      /////////////////////////
      try {
        const response1 = await axios.get("stock_reports?uuid=" + uuid);
        var reportId = JSON.stringify(response1.data.data[0].id);
        // console.log(
        //   "report_id : " +
        //     JSON.stringify("reportId :" + response1.data.data[0].id)
        // );
      } catch (error) {
        console.error(error);
      }
      /////////////////////////
      try {
        const response2 = await axios.delete("stock_reports/" + reportId);
        // console.log(response2);
        vThis.success = response2.data.success;
      } catch (error) {
        console.error(error);
      }
    },
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Workbook");
      // const worksheet = workbook
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        topLeftCell: { row: 4, column: 1 },
      })
        .then((cellRange) => {
          // header
          const headerRow = worksheet.getRow(2);
          headerRow.height = 30;
          worksheet.mergeCells(2, 1, 2, 8);

          headerRow.getCell(1).value = "Data Destroy Item";
          headerRow.getCell(1).font = { name: "Segoe UI Light", size: 22 };
          headerRow.getCell(1).alignment = { horizontal: "left" };

          // footer
          const footerRowIndex = cellRange.to.row + 2;
          const footerRow = worksheet.getRow(footerRowIndex);
          worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 8);

          footerRow.getCell(1).value = "";
          footerRow.getCell(1).font = {
            color: { argb: "BFBFBF" },
            italic: true,
          };
          footerRow.getCell(1).alignment = { horizontal: "right" };
        })
        .then(function () {
          workbook.xlsx.writeBuffer().then(function (buffer) {
            saveAs(
              new Blob([buffer], { type: "application/octet-stream" }),
              "WMS-Data-Destroy-Item.xlsx"
            );
          });
        });
      e.cancel = true;
    },
  },
  computed: {
    ...mapGetters("stockdestroy", [
      "getStockDestroy",
      "getStockDestroyStatus",
      "getStockDestroyForm",
      "getStockDestroyTemp",
    ]),
    dataGrid: function () {
      return this.$refs[gridRef].instance;
    },
  },
};
</script>

<style>
</style>
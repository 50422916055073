const state = {
  stockoutdetail: null,
  stockoutdetailStatus: "",
  stockoutdetailForm: null,
  stockoutdetailTemp: null,
  stockoutdetailTrxNum: null,
};

const getters = {
  getStockOutDetail: (state) => {
    return state.stockoutdetail;
  },
  getStockOutDetailStatus: (state) => {
    return state.stockoutdetailStatus;
  },
  getStockOutDetailTemp: (state) => {
    return state.stockoutdetailTemp;
  },
  getStockOutDetailForm: (state) => {
    return state.stockoutdetailForm;
  },
  getStockOutDetailTrxNum: (state) => {
    return state.stockoutdetailTrxNum;
  },
};

const mutations = {
  setStockOutDetail: (state, stockoutdetail) => {
    state.stockoutdetail = stockoutdetail;
  },
  clearStockOutDetail: (state) => {
    state.stockoutdetail = null;
  },

  setStockOutDetailStatus: (state, status) => {
    state.stockoutdetailStatus = status;
  },
  clearStockOutDetailStatus: (state) => {
    state.stockoutdetailStatus = "";
  },

  setStockOutDetailForm: (state, status) => {
    state.stockoutdetailForm = status;
  },
  clearStockOutDetailForm: (state) => {
    state.stockoutdetailForm = "";
  },

  setStockOutDetailTemp: (state, status) => {
    state.stockoutdetailTemp = status;
  },
  clearStockOutDetailTemp: (state) => {
    state.stockoutdetailTemp = "";
  },

  setStockOutDetailTrxNum: (state, status) => {
    state.stockoutdetailTrxNum = status;
  },
  clearStockOutDetailTrxNum: (state) => {
    state.stockoutdetailTrxNum = "";
  },
};

const actions = {
  setStockOutDetail({ commit }, data) {
    commit("setStockOutDetail", data);
  },
  clearStockOutDetail({ commit }) {
    commit("clearStockOutDetail");
  },

  setStockOutDetailStatus({ commit }, status) {
    commit("setStockOutDetailStatus", status);
  },
  clearStockOutDetailStatus({ commit }) {
    commit("clearStockOutDetailStatus");
  },

  setStockOutDetailForm({ commit }, data) {
    commit("setStockOutDetailForm", data);
  },
  clearStockOutDetailForm({ commit }) {
    commit("clearStockOutDetailForm");
  },
  
  setStockOutDetailTemp({ commit }, data) {
    commit("setStockOutDetailTemp", data);
  },
  clearStockOutDetailTemp({ commit }) {
    commit("clearStockOutDetailTemp");
  },

  setStockOutDetailTrxNum({ commit }, data) {
    commit("setStockOutDetailTrxNum", data);
  },
  clearStockOutDetailTrxNum({ commit }) {
    commit("clearStockOutDetailTrxNum");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

const state = {
  warehouse: null,
  warehouseStatus: "",
  warehouseForm: null,
};

const getters = {
  getWarehouse: (state) => {
    return state.warehouse;
  },
  getWarehouseStatus: (state) => {
    return state.warehouseStatus;
  },
  getWarehouseForm: (state) => {
    return state.warehouseForm;
  },
};

const mutations = {
  setWarehouse: (state, warehouse) => {
    state.warehouse = warehouse;
  },
  clearWarehouse: (state) => {
    state.warehouse = null;
  },

  setWarehouseStatus: (state, status) => {
    state.warehouseStatus = status;
  },
  clearWarehouseStatus: (state) => {
    state.warehouseStatus = "";
  },

  setWarehouseForm: (state, status) => {
    state.warehouseForm = status;
  },
  clearWarehouseForm: (state) => {
    state.warehouseForm = "";
  },
};

const actions = {
  setWarehouse({ commit }, data) {
    commit("setWarehouse", data);
  },
  clearWarehouse({ commit }) {
    commit("clearWarehouse");
  },

  setWarehouseStatus({ commit }, status) {
    commit("setWarehouseStatus", status);
  },
  clearWarehouseStatus({ commit }) {
    commit("clearWarehouseStatus");
  },

  setWarehouseForm({ commit }, data) {
    commit("setWarehouseForm", data);
  },
  clearWarehouseForm({ commit }) {
    commit("clearWarehouseForm");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div>
       <h2 class="content-block">Stock In Detail</h2>
    <stock-in-detail-table />
  </div>
</template>

<script>

import StockInDetailTable from "@/components/stock-in-detail/StockInDetailTable.vue";
export default {
  components: {
    StockInDetailTable,
  },
};
</script>
const state = {
  clientscustomer: null,
  clientscustomerStatus: "",
  clientscustomerForm: null,
};

const getters = {
  getClientsCustomer: (state) => {
    return state.clientscustomer;
  },
  getClientsCustomerStatus: (state) => {
    return state.clientscustomerStatus;
  },
  getClientsCustomerForm: (state) => {
    return state.clientscustomerForm;
  },
};

const mutations = {
  setClientsCustomer: (state, clientscustomer) => {
    state.clientscustomer = clientscustomer;
  },
  clearClientsCustomer: (state) => {
    state.clientscustomer = null;
  },

  setClientsCustomerStatus: (state, status) => {
    state.clientscustomerStatus = status;
  },
  clearClientsCustomerStatus: (state) => {
    state.clientscustomerStatus = "";
  },

  setClientsCustomerForm: (state, status) => {
    state.clientscustomerForm = status;
  },
  clearClientsCustomerForm: (state) => {
    state.clientscustomerForm = "";
  },
};

const actions = {
  setClientsCustomer({ commit }, data) {
    commit("setClientsCustomer", data);
  },
  clearClientsCustomer({ commit }) {
    commit("clearClientsCustomer");
  },

  setClientsCustomerStatus({ commit }, status) {
    commit("setClientsCustomerStatus", status);
  },
  clearClientsCustomerStatus({ commit }) {
    commit("clearClientsCustomerStatus");
  },

  setClientsCustomerForm({ commit }, data) {
    commit("setClientsCustomerForm", data);
  },
  clearClientsCustomerForm({ commit }) {
    commit("clearClientsCustomerForm");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"content-block dx-card responsive-paddings"},[_c('DxForm',{ref:_vm.formRef,attrs:{"form-data":_vm.getStockQuarantine ? _vm.getStockQuarantine : null,"col-count":2},on:{"field-data-changed":_vm.formFieldDataChanged}},[_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"data-field":"user_id","visible":false,"editor-options":{ value: _vm.userId }}}),_c('DxSimpleItem',{attrs:{"data-field":"uuid","visible":false,"editor-options":{
            value: this.getStockQuarantine
              ? this.getStockQuarantine.uuid
              : null,
          }}}),_c('DxSimpleItem',{attrs:{"data-field":"warehouse_id","label":{ text: 'warehouse_id' },"visible":false,"editor-options":{
            value: this.getStockQuarantineTemp,
          }}}),_c('DxSimpleItem',{attrs:{"data-field":"item_name","label":{ text: 'Nama Item' },"visible":true,"editor-options":{
            disabled: true,
            value: this.getStockQuarantine
              ? this.getStockQuarantine.item_name
              : null,
          }}}),_c('DxSimpleItem',{attrs:{"data-field":"batch_number","label":{ text: 'Batch number' },"visible":true,"editor-options":{
            disabled: true,
            value: this.getStockQuarantine
              ? this.getStockQuarantine.batch_number
              : null,
          }}}),_c('DxSimpleItem',{attrs:{"data-field":"reference_number","label":{ text: 'Reference Number' },"visible":true,"editor-options":{
            value: this.getStockQuarantine
              ? this.getStockQuarantine.reference_number
              : null,
          }}}),_c('DxSimpleItem',{attrs:{"data-field":"item_id","label":{ text: 'item_id' },"visible":false,"editor-options":{
            disabled: true,
            value: this.getStockQuarantine
              ? this.getStockQuarantine.item_id
              : null,
          }}}),_c('DxSimpleItem',{attrs:{"data-field":"item_condition_id","label":{ text: 'Pilih Kondisi Item' },"visible":false,"editor-options":{
            disabled: true,
            value: this.getStockQuarantine
              ? this.getStockQuarantine.item_condition_id
              : null,
          }}})],1),_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"data-field":"expired_date","label":{ text: 'Tanggal Expired' },"editor-type":"dxDateBox","editor-options":{
            disabled: true,
            dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ss',
            value: this.getStockQuarantine
              ? this.getStockQuarantine.expired_date
              : null,
          }}}),_c('DxSimpleItem',{attrs:{"data-field":"last_item_qty","label":{ text: 'Stok Tersedia' },"editor-options":{
            disabled: true,
            value: this.getStockQuarantine
              ? this.getStockQuarantine.total_item_qty_by_batchnum
              : null,
          },"editor-type":"dxNumberBox"}}),_c('DxSimpleItem',{attrs:{"data-field":"qty","label":{ text: 'Jumlah item yang dikirim' },"visible":false,"editor-options":{
            value: this.getStockQuarantine
              ? this.getStockQuarantine.qty
              : null,
          }},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('DxNumberBox',{attrs:{"read-only":false,"value":_vm.getStockQuarantine ? _vm.getStockQuarantine.qty : null},on:{"value-changed":_vm.valueChangedQty}},[_c('DxValidator',[_c('DxCustomRule',{attrs:{"validation-callback":_vm.validateNumber,"message":"Stok keluar lebih besar dari persediaan"}})],1)],1)]},proxy:true}])},[_c('DxRequiredRule',{attrs:{"message":"Jumlah Item yang akan dikirim di Isi"}})],1),_c('DxSimpleItem',{attrs:{"data-field":"free","label":{ text: 'Free' },"editor-type":"dxNumberBox","visible":false}})],1)],1),_c('span',{domProps:{"innerHTML":_vm._s(_vm.linebreaks)}}),_c('div',{staticClass:"buttons-demo"},[_c('div',{staticClass:"buttons"},[_c('div',[_c('div',{staticClass:"buttons-offering-letter"},[_c('div',[_c('DxButton',{attrs:{"width":500,"text":"Pilih Item","type":"normal","styling-mode":"contained"},on:{"click":function($event){return _vm.onClickShowItem()}}})],1)]),_c('div',{staticClass:"buttons-offering-letter"},[_c('div',[_c('DxButton',{attrs:{"width":500,"text":"Tambah List","type":"normal","styling-mode":"contained"},on:{"click":function($event){return _vm.onClickAddItemToList()}}})],1)])])])])],1),_c('div',{staticClass:"content-block dx-card responsive-paddings"},[_c('dx-data-grid',{ref:_vm.gridRef,staticClass:"dx-card wide-card",attrs:{"data-source":_vm.tempData,"show-borders":true,"focused-row-index":0,"focused-row-enabled":true,"column-auto-width":true,"column-hiding-enabled":true,"show-row-lines":true,"row-alternation-enabled":true},on:{"toolbar-preparing":function($event){return _vm.onToolbarPreparing($event)}},scopedSlots:_vm._u([{key:"nomor",fn:function(ref){
          var data = ref.data;
return [_vm._v(" "+_vm._s(data.rowIndex + 1)+" ")]}}])},[_c('dx-paging',{attrs:{"page-size":10}}),_c('dx-pager',{attrs:{"showPageSizeSelector":true,"allowed-page-sizes":_vm.pageSizes,"show-info":true,"showNavigationButtons":true,"infoText":"Hal {0} dari {1} ({2} items)","visible":true}}),_c('dx-filter-row',{attrs:{"visible":_vm.filterStatus}}),_c('DxEditing',{attrs:{"allow-deleting":true,"mode":"cell"}}),_c('DxSearchPanel',{attrs:{"visible":true}}),_c('DxSelection',{attrs:{"mode":"single"}}),_c('dx-paging',{attrs:{"page-size":10}}),_c('dx-pager',{attrs:{"showPageSizeSelector":true,"allowed-page-sizes":_vm.pageSizes,"show-info":true,"showNavigationButtons":true,"infoText":"Hal {0} dari {1} ({2} items)","visible":true}}),_c('dx-filter-row',{attrs:{"visible":_vm.filterStatus}}),_c('DxSelection',{attrs:{"mode":"single"}}),_c('DxColumn',{attrs:{"data-field":"no","caption":"No","width":70,"cell-template":"nomor"}}),_c('DxColumn',{attrs:{"data-field":"uuid","width":"200","visible":false,"caption":"uuid"}}),_c('DxColumn',{attrs:{"data-field":"item_name","width":"200","caption":"Nama Item"}}),_c('DxColumn',{attrs:{"data-field":"qty","caption":"Jumlah"}}),_c('DxColumn',{attrs:{"data-field":"batch_number","caption":"Batch Number"}}),_c('DxColumn',{attrs:{"data-field":"reference_number","caption":"Ref Number"}}),_c('DxColumn',{attrs:{"data-field":"expired_date","dataType":"date","format":"dd/MM/yyyy","caption":"Tanggal Expired"}})],1)],1),_c('DxPopup',{attrs:{"visible":_vm.popupShowItem,"drag-enabled":false,"show-title":true,"width":1000,"height":680,"showCloseButton":false,"title":"Pilih Item"}},[_c('dx-data-grid',{staticClass:"dx-card wide-card",attrs:{"data-source":_vm.GetItemList,"show-borders":true,"focused-row-index":0,"focused-row-enabled":true,"column-auto-width":true,"column-hiding-enabled":true,"show-row-lines":true,"row-alternation-enabled":true},on:{"selection-changed":_vm.onSelectionChangedItem},scopedSlots:_vm._u([{key:"nomor",fn:function(ref){
          var data = ref.data;
return [_vm._v(" "+_vm._s(data.rowIndex + 1)+" ")]}}])},[_c('dx-paging',{attrs:{"page-size":5}}),_c('dx-pager',{attrs:{"showPageSizeSelector":true,"allowed-page-sizes":_vm.pageSizes,"show-info":true,"showNavigationButtons":true,"infoText":"Hal {0} dari {1} ({2} items)","visible":true}}),_c('dx-filter-row',{attrs:{"visible":_vm.filterStatus}}),_c('DxSearchPanel',{attrs:{"visible":true}}),_c('DxSelection',{attrs:{"mode":"single"}}),_c('DxColumn',{attrs:{"data-field":"no","caption":"No","width":70,"cell-template":"nomor"}}),_c('DxColumn',{attrs:{"data-field":"item_id","visible":false}}),_c('DxColumn',{attrs:{"data-field":"item_name","width":"300","caption":"Nama Item"}}),_c('DxColumn',{attrs:{"data-field":"total_item_qty_by_batchnum","width":"150","caption":"Jumlah"}}),_c('DxColumn',{attrs:{"data-field":"item_condition_id","visible":false}}),_c('DxColumn',{attrs:{"data-field":"item_condition_name","caption":"Kondisi"}}),_c('DxColumn',{attrs:{"data-field":"batch_number","caption":"batch number"}}),_c('DxColumn',{attrs:{"data-field":"expired_date","dataType":"date","format":"dd/MM/yyyy","caption":"Tanggal Expired"}})],1),_c('span',{domProps:{"innerHTML":_vm._s(_vm.linebreaks)}}),_c('DxButton',{attrs:{"width":120,"text":"Close","type":"default","styling-mode":"contained"},on:{"click":function($event){return _vm.onButtonCloseClick($event)}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
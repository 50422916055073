<template>
  <div id="DeptForm">
    <h2 class="content-block">
      {{ getStockOutDetailStatus == "add" ? "Tambah" : "Ubah" }}
      Stock Out Detail
    </h2>
    <DxToolbar class="padding-block">
      <DxItem
        :options="backButtonOptions"
        widget="dxButton"
        location="before"
      />
      <!-- <DxItem
        :options="saveButtonOptions"
        widget="dxButton"
        location="before"
        :disabled="saveStatus"
      /> -->
    </DxToolbar>
    <StockOutDetailForm @save_status="setSaveStatus" />
    <span v-html="linebreaks"></span>
    <DxToolbar class="padding-block">
      <DxItem
        :options="backButtonOptions"
        widget="dxButton"
        location="before"
      />
      <!-- <DxItem
        :options="saveButtonOptions"
        widget="dxButton"
        location="before"
        :disabled="saveStatus"
      /> -->
    </DxToolbar>
    <DxLoadPanel
      :position="{ of: '#mainContent' }"
      :visible.sync="loadingVisible"
      :show-indicator="true"
      :show-pane="true"
      :shading="true"
      :close-on-outside-click="false"
      shading-color="rgba(0,0,0,0.4)"
    />
  </div>
</template>
<script>
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import { DxLoadPanel } from "devextreme-vue/load-panel";
import router from "../../router";
import { mapGetters, mapActions } from "vuex";
// import { formatDate } from "devextreme/localization";
import StockOutDetailForm from "@/components/stock-out-detail/StockOutDetailForm.vue";
import StockOutDetailService from "@/services/StockOutDetailService";
import moment from "moment";
import axios from "axios";
// import { uuid } from 'vue-uuid';
// const vthis = this;
export default {
  components: {
    DxToolbar,
    DxItem,
    StockOutDetailForm,
    DxLoadPanel,
  },
  created() {
    // var vthis = this;
  },
  data() {
    // const vthis = this;
    return {
      linebreaks: "<br>",
      loadingVisible: false,
      saveStatus: false,
      saveText: "woi",
      report_id: null,

      backButtonOptions: {
        icon: "chevronleft",
        text: "Kembali",
        onClick: () => {
          this.$router.go(-1);
        },
      },
      saveButtonOptions: {
        icon: "save",
        text: "Simpan",
        onClick: () => {
          // eslint-disable-next-line no-console
          var validateResult = this.getStockOutDetailForm.validate();
          if (validateResult.isValid) {
            // var StockOut = {
            //   name : this.getStockOut.name,
            //   shortName : this.getStockOut.shortName,
            //   // uniqueId : uuid.v4(),
            //   description : this.getStockOut.description
            // };
            this.userId = window.localStorage.getItem("usrid");
            var vThis = this;
            var StockOutDetail = this.getStockOutDetail;

            if (StockOutDetail) {
              if (vThis.getStockOutDetailStatus == "add") {
                console.log("Add StockOut Detail");
                vThis.loadingVisible = true;
                StockOutDetail.created_by = this.userId;
                // StockOut.transaction_number = this.format_date(new Date());
                var expired_date = StockOutDetail.expired_date;

                //code ini untuk menjaga tanggal tidak berubah pada waktu konversi ke format JSON
                // var os = expired_date.getTimezoneOffset();
                // expired_date = new Date(
                //   expired_date.getTime() - os * 60 * 1000
                // );
                // expired_date = formatDate(expired_date, "yyyy/MM/dd HH:mm");
                // console.log("expired_date : " + expired_date);
                // StockOutDetail.transaction_number = this.format_date(new Date());
                // input data ke tabel t_stock_report dahulu
                let formData = new FormData();
                formData.append("warehouse_id", StockOutDetail.warehouse_id);
                formData.append("uuid", StockOutDetail.uuid);
                formData.append(
                  "transaction_number",
                  StockOutDetail.transaction_number
                );
                formData.append("stock_action", 200); //outbound
                formData.append("batch_number", StockOutDetail.batch_number);
                formData.append("item_id", StockOutDetail.item_id);
                formData.append("qty", StockOutDetail.qty);
                formData.append(
                  "item_condition_id",
                  StockOutDetail.item_condition_id
                );
                formData.append("remark", "stockout");
                formData.append("expired_date", expired_date);
                axios
                  .post("stock_reports", formData)
                  .then(function (response) {
                    console.log(response);
                    vThis.success = response.data.success;
                  })
                  .catch(function (error) {
                    console.log(error);
                    vThis.output = error;
                  });
                // selanjutanya input data ke tabel t_stockin_detail
                StockOutDetailService.insert(
                  StockOutDetail,
                  function () {
                    vThis.loadingVisible = false;
                    vThis.clearStockOutDetailStatus();
                    vThis.clearStockOutDetail();
                    // router.push("/stock/out/detail");
                    router.push("/stock/out");
                  },
                  function () {
                    vThis.loadingVisible = false;
                  }
                );
              } else if (vThis.getStockOutDetailStatus == "edit") {
                console.log("Edit StockOut Detail ");
                StockOutDetail.updated_by = vThis.userId;

                const param = {
                  warehouse_id: StockOutDetail.warehouse_id,
                  uuid: StockOutDetail.uuid,
                  transaction_number: StockOutDetail.transaction_number,
                  stock_action: StockOutDetail.stock_action,
                  batch_number: StockOutDetail.batch_number,
                  item_id: StockOutDetail.item_id,
                  qty: StockOutDetail.qty,
                  item_condition_id: StockOutDetail.item_condition_id,
                  remark: StockOutDetail.remark,
                  expired_date: StockOutDetail.expired_date,
                };

                vThis.updateReport(StockOutDetail.uuid, param);

                StockOutDetailService.update(
                  this.getStockOut.id,
                  StockOutDetail,
                  function () {
                    vThis.loadingVisible = false;
                    vThis.clearStockOutDetailStatus();
                    vThis.clearStockOutDetail();
                    // router.push("/stock/out/detail");
                    router.push("/stock/out");
                  },
                  function () {
                    vThis.loadingVisible = false;
                  }
                );
              }
            }
          }
        },
      },
    };
  },
  methods: {
    ...mapActions("stockout", [
      "setStockOut",
      "clearStockOut",
      "setStockOutStatus",
      "clearStockOutStatus",
    ]),
    ...mapActions("stockoutdetail", [
      "setStockOutDetail",
      "clearStockOutDetail",
      "setStockOutDetailStatus",
      "clearStockOutDetailStatus",
    ]),
    async updateReport(uuid, param) {
      // this.vReportId = this.getVehicleRepair.vehicle_report_id;
      var vThis = this;
      // var vuuid = uuid;

      /////////////////////////
      try {
        const response1 = await axios.get("stock_reports?uuid=" + uuid);
        var reportId = JSON.stringify(response1.data.data[0].id);
        console.log(
          "report_id : " +
            JSON.stringify("reportId :" + response1.data.data[0].id)
        );
      } catch (error) {
        console.error(error);
      }
      /////////////////////////
      try {
        const response2 = await axios.put("stock_reports/" + reportId, param);
        console.log(response2);
        vThis.success = response2.data.success;
      } catch (error) {
        console.error(error);
      }

      // const param = { report_status: "In Repair" };
      // const patchresult = await axios.patch(
      //   "vehicle_damage_reports/" + reportId,
      //   param
      // );
      // console.log("patchresult : " + JSON.stringify(patchresult));
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYYMMDDHHmmss");
      }
    },
    setSaveStatus(value) {
      console.log("setSaveStatus = " + value);
      this.saveStatus = value;
      // if (value == 1) {
      //   this.saveStatus = true;
      // } else {
      //   this.saveStatus = false;
      // }
    },
  },
  computed: {
    ...mapGetters("stockout", [
      "getStockOut",
      "getStockOutStatus",
      "getStockOutForm",
    ]),
    ...mapGetters("stockoutdetail", [
      "getStockOutDetail",
      "getStockOutDetailStatus",
      "getStockOutDetailForm",
      "getStockOutDetailTrxNum",
    ]),
  },
};
</script>

<style>
.toolbar-label,
.toolbar-label > b {
  font-size: 16px;
}

#products {
  margin-top: 10px;
}

.padding-block {
  padding-left: 20px;
  padding-right: 20px;
}
</style>
<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <DxForm
        class="form-block"
        :form-data="getStockIn"
        :read-only="true"
        :ref="formRef"
        :col-count="2"
      >
        <DxGroupItem>
          <DxSimpleItem
            data-field="transaction_number"
            :label="{ text: 'Nomor Transaksi' }"
          >
          </DxSimpleItem>

          <DxSimpleItem
            data-field="received_date"
            :label="{ text: 'Tanggal Terima Barang' }"
            editor-type="dxDateBox"
          >
          </DxSimpleItem>
          <DxSimpleItem
            data-field="mutation_number"
            :label="{ text: 'Nomor Mutasi' }"
          >
          </DxSimpleItem>

          <DxSimpleItem
            data-field="origin_type_name"
            :label="{ text: 'Tipe Asal' }"
          >
          </DxSimpleItem>
        </DxGroupItem>
        <DxGroupItem>
          <DxSimpleItem data-field="origin_name" :label="{ text: 'Asal' }">
          </DxSimpleItem>

          <DxSimpleItem
            data-field="destination_name"
            :label="{ text: 'Tujuan' }"
          >
          </DxSimpleItem>

          <DxSimpleItem data-field="remark" :label="{ text: 'Catatan' }">
          </DxSimpleItem>
        </DxGroupItem>
      </DxForm>
    </div>
    <div class="content-block dx-card responsive-paddings">
      <dx-data-grid
        class="dx-card wide-card"
        :data-source="stockInDetailData"
        :show-borders="true"
        :focused-row-index="0"
        :focused-row-enabled="true"
        :column-auto-width="true"
        :column-hiding-enabled="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :loadPanel="loadPanel"
        :selected-row-keys="selectedRowKeys"
        @exporting="onExporting"
        :ref="gridRef"
      >
        <dx-paging :page-size="10" />
        <dx-pager
          :showPageSizeSelector="true"
          :allowed-page-sizes="pageSizes"
          :show-info="true"
          :showNavigationButtons="true"
          infoText="Hal {0} dari {1} ({2} items)"
          :visible="true"
        />
        <dx-filter-row :visible="filterStatus" />
        <DxSelection mode="multiple" />
        <DxColumn
          data-field="no"
          caption="No"
          :width="70"
          cell-template="nomor"
        />
        <template #nomor="{ data }">
          {{ data.rowIndex + 1 }}
        </template>

        <DxColumn
          data-field="transaction_number"
          width="200"
          caption="No Transaksi"
        />
        <DxColumn data-field="item_name" width="200" caption="Nama Item" />
        <DxColumn data-field="qty" caption="Jumlah" />
        <DxColumn data-field="price" caption="Harga" />
        <DxColumn data-field="batch_number" caption="Batch Number" />
        <DxColumn data-field="item_condition_name" caption="Kondisi" />
        <!-- <DxColumn
        data-field="item_condition"
        caption="Item Condition"
        :customize-text="customizeText"
      /> -->
        <DxColumn
          data-field="expired_date"
          dataType="date"
          format="dd/MM/yyyy"
          caption="Tanggal Expired"
        />
      </dx-data-grid>
    </div>
  </div>
</template>
<script>
import {
  DxDataGrid,
  DxPager,
  DxPaging,
  DxSelection,
  DxColumn,
  DxFilterRow,
  // DxExport
} from "devextreme-vue/data-grid";
import { DxForm, DxSimpleItem, DxGroupItem } from "devextreme-vue/form";
// import DxTextArea from "devextreme-vue/text-area";
import { mapGetters, mapActions } from "vuex";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import axios from "axios";
const formRef = "stockin-form";
const gridRef = "stockindetail-table";
export default {
  components: {
    //Form Components :
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    // DxTextArea,

    //Datagrid Components :
    DxDataGrid,
    DxPager,
    DxPaging,
    DxSelection,
    DxColumn,
    DxFilterRow,
  },
  data: function () {
    return {
      gridRef,
      formRef,
      pageSizes: [5, 10, 15],
      selectedRowKeys: [],
      loadPanel: {
        text: "Memuat...",
      },
      filterStatus: false,
      filterText: "Filter On",
      stockInDetailData: [],
    };
  },
  created() {
    axios
      .get(
        
          "stock_in_details/usr/1/trx/" +
          this.getStockIn.transaction_number
      )
      .then((response) => {
        //console.log(JSON.stringify(response.data));
        this.stockInDetailData = response.data.data;
      });
  },
  methods: {
    ...mapActions("stockin", [
      "setStockIn",
      "clearStockIn",
      "setStockInStatus",
      "clearStockInStatus",
    ]),
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Main sheet");
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        customizeCell: function (options) {
          const excelCell = options;
          excelCell.font = { name: "Arial", size: 12 };
          excelCell.alignment = { horizontal: "left" };
        },
      }).then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "data-stock-in-detail.xlsx"
          );
        });
      });
      e.cancel = true;
    },
  },
  computed: {
    ...mapGetters("stockin", [
      "getStockIn",
      "getStockInStatus",
      "getStockInForm",
    ]),
  },
};
</script>
<style>
.group-padding {
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.form-block {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  padding-left: 40px;
  padding-right: 40px;
}
</style>

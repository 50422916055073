const state = {
  stockfirst: null,
  stockfirstStatus: "",
  stockfirstForm: null,
};

const getters = {
  getStockFirst: (state) => {
    return state.stockfirst;
  },
  getStockFirstStatus: (state) => {
    return state.stockfirstStatus;
  },
  getStockFirstForm: (state) => {
    return state.stockfirstForm;
  },
};

const mutations = {
  setStockFirst: (state, stockfirst) => {
    state.stockfirst = stockfirst;
  },
  clearStockFirst: (state) => {
    state.stockfirst = null;
  },

  setStockFirstStatus: (state, status) => {
    state.stockfirstStatus = status;
  },
  clearStockFirstStatus: (state) => {
    state.stockfirstStatus = "";
  },

  setStockFirstForm: (state, status) => {
    state.stockfirstForm = status;
  },
  clearStockFirstForm: (state) => {
    state.stockfirstForm = "";
  },
};

const actions = {
  setStockFirst({ commit }, data) {
    commit("setStockFirst", data);
  },
  clearStockFirst({ commit }) {
    commit("clearStockFirst");
  },

  setStockFirstStatus({ commit }, status) {
    commit("setStockFirstStatus", status);
  },
  clearStockFirstStatus({ commit }) {
    commit("clearStockFirstStatus");
  },

  setStockFirstForm({ commit }, data) {
    commit("setStockFirstForm", data);
  },
  clearStockFirstForm({ commit }) {
    commit("clearStockFirstForm");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div>
    <!-- <div
      class="content-block dx-card responsive-paddings"
      v-bind:style="{
        display: vShow,
      }"
    >
      FIRST-STOCK : Stok Mendekati Kadaluarsa
      <dx-data-grid
        class="dx-card wide-card"
        :data-source="
          GetItemFirstStockList.length > 0 ? GetItemFirstStockList : dataNull
        "
        :show-borders="true"
        :focused-row-index="0"
        :focused-row-enabled="true"
        :column-auto-width="true"
        :column-hiding-enabled="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :loadPanel="loadPanel"
        :selected-row-keys="selectedRowKeys"
        @toolbar-preparing="onToolbarFirstItemNearExpired($event)"
        @selection-changed="onSelectionItemChanged"
        @rowExpanding="onRowExpanding"
        @exporting="onExporting"
        :ref="gridRef"
      >
        <dx-paging :page-size="10" />
        <dx-pager
          :showPageSizeSelector="true"
          :allowed-page-sizes="pageSizes"
          :show-info="true"
          :showNavigationButtons="true"
          infoText="Hal {0} dari {1} ({2} items)"
          :visible="true"
        />
        <dx-filter-row :visible="filterStatus" />
        <DxSelection mode="single" />
        <DxColumn
          data-field="no"
          caption="No"
          :width="70"
          cell-template="nomor"
        />
        <template #nomor="{ data }">
          {{ data.rowIndex + 1 }}
        </template>
        <DxColumn data-field="warehouse_id" :visible="false" />
        <DxColumn data-field="transaction_number" :visible="false" />
        <DxColumn data-field="qty" :visible="false" />
        <DxColumn data-field="item_name" width="300" caption="Nama Item" />
        <DxColumn data-field="last_item_qty" width="150" caption="Jumlah" />
        <DxColumn data-field="item_condition_id" :visible="false" />
        <DxColumn data-field="item_condition_name" caption="Kondisi" />
        <DxColumn data-field="batch_number" caption="batch number" />
        <DxColumn
          data-field="expired_date"
          dataType="date"
          format="dd/MM/yyyy"
          caption="Tanggal Expired"
        />
        <DxExport :enabled="true" />
        <template #titlereport>
          <div class="myfilter">Pilih Warehouse :</div>
        </template>

        <template #warehouse>
          <div class="informer">
            <span class="b">
              <DxSelectBox
                :data-source="GetWarehouse"
                :width="200"
                :show-clear-button="true"
                display-expr="name"
                value-expr="id"
                :searchEnabled="true"
                @value-changed="warehouseSelectedChanged1"
              ></DxSelectBox>
            </span>
          </div>
        </template>
      </dx-data-grid>
    </div> -->

    <!-- <div
      class="content-block dx-card responsive-paddings"
      v-bind:style="{
        display: vShow,
      }"
    >
      INBOUND : Stok Mendekati Kadaluarsa
      <dx-data-grid
        class="dx-card wide-card"
        :data-source="
          GetItemStockInList.length > 0 ? GetItemStockInList : dataNull
        "
        :show-borders="true"
        :focused-row-index="0"
        :focused-row-enabled="true"
        :column-auto-width="true"
        :column-hiding-enabled="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :loadPanel="loadPanel"
        :selected-row-keys="selectedRowKeys"
        @toolbar-preparing="onToolbarItemNearExpired($event)"
        @selection-changed="onSelectionItemChanged"
        @rowExpanding="onRowExpanding"
        @exporting="onExporting"
        :ref="gridRef"
      >
        <dx-paging :page-size="10" />
        <dx-pager
          :showPageSizeSelector="true"
          :allowed-page-sizes="pageSizes"
          :show-info="true"
          :showNavigationButtons="true"
          infoText="Hal {0} dari {1} ({2} items)"
          :visible="true"
        />
        <dx-filter-row :visible="filterStatus" />
        <DxSelection mode="single" />
        <DxColumn
          data-field="no"
          caption="No"
          :width="70"
          cell-template="nomor"
        />
        <template #nomor="{ data }">
          {{ data.rowIndex + 1 }}
        </template>
        <DxColumn data-field="warehouse_id" :visible="false" />
        <DxColumn data-field="transaction_number" :visible="false" />
        <DxColumn data-field="qty" :visible="false" />
        <DxColumn data-field="item_name" width="300" caption="Nama Item" />
        <DxColumn data-field="last_item_qty" width="150" caption="Jumlah" />
        <DxColumn data-field="item_condition_id" :visible="false" />
        <DxColumn data-field="item_condition_name" caption="Kondisi" />
        <DxColumn data-field="batch_number" caption="batch number" />
        <DxColumn
          data-field="expired_date"
          dataType="date"
          format="dd/MM/yyyy"
          caption="Tanggal Expired"
        />
        <DxExport :enabled="true" />
        <template #titlereport>
          <div class="myfilter">Pilih Warehouse :</div>
        </template>

        <template #warehouse>
          <div class="informer">
            <span class="b">
              <DxSelectBox
                :data-source="GetWarehouse"
                :width="200"
                :show-clear-button="true"
                display-expr="name"
                value-expr="id"
                :searchEnabled="true"
                @value-changed="warehouseSelectedChanged2"
              ></DxSelectBox>
            </span>
          </div>
        </template>
      </dx-data-grid>
    </div> -->

    <div class="content-block dx-card responsive-paddings">
      Stock Expired / Stok Kadaluarsa
      <dx-data-grid
        class="dx-card wide-card"
        :data-source="
          GetQuarantineItemList.length > 0 ? GetQuarantineItemList : dataNull
        "
        :show-borders="true"
        :focused-row-index="0"
        :focused-row-enabled="true"
        :column-auto-width="true"
        :column-hiding-enabled="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :loadPanel="loadPanel"
        :selected-row-keys="selectedRowKeys"
        @toolbar-preparing="onToolbarItemExpired($event)"
        @selection-changed="onSelectionQuarantineChanged"
        @rowExpanding="onRowExpanding"
        @exporting="onExporting"
        :ref="gridRef"
      >
        <dx-paging :page-size="10" />
        <dx-pager
          :showPageSizeSelector="true"
          :allowed-page-sizes="pageSizes"
          :show-info="true"
          :showNavigationButtons="true"
          infoText="Hal {0} dari {1} ({2} items)"
          :visible="true"
        />
        <dx-filter-row :visible="filterStatus" />
        <DxSelection mode="single" />
        <DxColumn
          data-field="no"
          caption="No"
          :width="70"
          cell-template="nomor"
        />
        <template #nomor="{ data }">
          {{ data.rowIndex + 1 }}
        </template>
        <DxColumn data-field="id" :visible="false" />
        <DxColumn data-field="warehouse_id" :visible="false" />
        <DxColumn data-field="item_id" :visible="false" />
        <DxColumn data-field="item_name" width="300" caption="Nama Item" />

        <DxColumn data-field="qty" width="150" caption="Jumlah" />
        <DxColumn data-field="item_condition_id" :visible="false" />
        <DxColumn data-field="item_condition_name" caption="Kondisi" />
        <DxColumn data-field="batch_number" caption="Batch number" />
        <DxColumn data-field="reference_number" caption="Ref number" />
        <DxColumn
          data-field="expired_date"
          dataType="date"
          format="dd/MM/yyyy"
          caption="Tanggal Expired"
        />
        <DxExport :enabled="true" />
        <template #titlereport>
          <div class="myfilter">Pilih Warehouse :</div>
        </template>

        <template #warehouse>
          <div class="informer">
            <span class="b">
              <DxSelectBox
                :data-source="GetWarehouse"
                :width="200"
                :show-clear-button="true"
                display-expr="name"
                value-expr="id"
                :searchEnabled="true"
                @value-changed="warehouseSelectedChanged3"
              ></DxSelectBox>
            </span>
          </div>
        </template>
      </dx-data-grid>
    </div>
    <!-- <div class="content-block dx-card responsive-paddings">
      Stock Quarantine
      <dx-data-grid
        class="dx-card wide-card"
        :data-source="dataSource"
        :show-borders="true"
        :focused-row-index="0"
        :focused-row-enabled="true"
        :column-auto-width="true"
        :column-hiding-enabled="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :loadPanel="loadPanel"
        :selected-row-keys="selectedRowKeys"
        @toolbar-preparing="onToolbarPreparing($event)"
        @rowExpanding="onRowExpanding"
        @exporting="onExporting"
        :ref="gridRef"
      >
        <dx-paging :page-size="10" />
        <dx-pager
          :showPageSizeSelector="true"
          :allowed-page-sizes="pageSizes"
          :show-info="true"
          :showNavigationButtons="true"
          infoText="Hal {0} dari {1} ({2} items)"
          :visible="true"
        />
        <dx-filter-row :visible="filterStatus" />
        <DxSelection mode="multiple" />
        <DxColumn data-field="warehouse_name" caption="Warehouse" />
        <DxColumn
          data-field="transaction_number"
          caption="No.Transaksi"
          :width="140"
        />

        <DxColumn data-field="item_name" caption="Nama Item" />
        <DxColumn data-field="qty" caption="Jumlah" />
        <DxColumn data-field="price" caption="Harga" />
        <DxColumn data-field="batch_number" caption="Batch Number" />
        <DxColumn data-field="item_condition_name" caption="Kondisi Barang" />
        <DxColumn
          data-field="expired_date"
          caption="Tanggal Expired"
          dataType="date"
          format="dd/MM/yyyy"
          :width="160"
        />
        <DxExport :enabled="true" />
      </dx-data-grid>
    </div> -->
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxPager,
  DxPaging,
  DxSelection,
  DxColumn,
  DxFilterRow,
  DxExport,
} from "devextreme-vue/data-grid";
import CustomStore from "devextreme/data/custom_store";
import StockQuarantineService from "@/services/StockQuarantineService";
import StockDestroyService from "@/services/StockDestroyService";

import router from "../../router";
import { mapGetters, mapActions } from "vuex";
import { confirm } from "devextreme/ui/dialog";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { exportDataGrid } from "devextreme/excel_exporter";
import { DxSelectBox } from "devextreme-vue/select-box";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { uuid } from "vue-uuid";
import moment from "moment";
import axios from "axios";

axios.defaults.headers.common["Authorization"] =
  "Bearer " + window.localStorage.getItem("token");
const gridRef = "stockquarantine-table";

export default {
  // props: ["StockQuarantineId"],
  components: {
    DxDataGrid,
    DxPager,
    DxPaging,
    DxSelection,
    DxColumn,
    DxFilterRow,
    DxExport,
    DxSelectBox,
  },
  data() {
    const vthis = this;
    const dataSource = {
      store: new CustomStore({
        key: "id",
        load: async function (loadOptions) {
          var sortedBy = "";
          if (loadOptions.sort) {
            sortedBy += loadOptions.sort[0].selector;
            if (loadOptions.sort[0].desc) {
              sortedBy += " desc";
            } else {
              sortedBy += " asc";
            }
          }

          var perPage = loadOptions.take;
          var offset = loadOptions.skip;
          var page = offset / perPage + 1;

          var datax = vthis.fetch({
            perPage: perPage || 10,
            page: page || 1,
            sortedBy: sortedBy,
            parentId: "null",
          });

          return await datax;
        },
      }),
    };

    return {
      filterStatus: false,
      gridRef,
      dataSource: dataSource,
      pageSizes: [5, 10, 15],
      selectedRowKeys: [],
      selectedRowItemKeys: [],
      selectedRowQuarantineKeys: [],
      loadPanel: {
        text: "Memuat...",
      },
      uuid: uuid.v1(),
      vShow: "none",

      btnAdd: null,
      btnEdit: null,
      btnDelete: null,
      btnView: null,

      tipe: this.tipe,
      custid: null,
      mydata: null,
      FormStockQuarantine: null,

      GetSourceType: [],
      GetWarehouse: [],
      GetOrigin: [],
      GetItem: [],
      GetItemCondition: [],
      GetItemStockInList: [],
      GetItemFirstStockList: [],
      GetQuarantineItemList: [],

      warehouseId: null,
      lastItemQty: null,
      itemName: "",
      warehouseName: "",
      dataNull: [
        {
          item_name: "N/A",
          last_item_qty: "N/A",
          item_condition_name: "N/A",
          batch_number: "N/A",
          reference_number: "N/A",
          expired_date: "N/A",
        },
      ],

      dataSourceReportNull: [
        {
          item_code: "N/A",
          item_name: "N/A",
          total_first_stock: "N/A",
          total_return: "N/A",
          total_item_in: "N/A",
          total_item_out: "N/A",
          total_item_good: "N/A",
          total_item_quarantine: "N/A",
          total_item_destroy: "N/A",
          total_item: "N/A",
        },
      ],
    };
  },
  created() {
    this.currentUUid = this.uuid;
    this.roleid = window.localStorage.getItem("roleid");
    this.warehouseId = window.localStorage.getItem("wrhid");
    if (this.roleid == 5) {
      axios
        .get(
           "warehouses/id/" + this.warehouseId
        )
        .then((response) => {
          this.GetWarehouse = response.data.data;
        });
    } else {
      axios
        .get( "warehouses")
        .then((response) => {
          this.GetWarehouse = response.data.data;
        });
    }
    // axios
    //   .get( "warehouses")
    //   .then((response) => {
    //     //console.log(JSON.stringify(response.data));
    //     this.GetWarehouse = response.data.data;
    //   });
  },
  methods: {
    ...mapActions("stockquarantine", [
      "setStockQuarantine",
      "clearStockQuarantine",
      "setStockQuarantineStatus",
      "clearStockQuarantineStatus",
      "setStockQuarantineForm",
      "setStockQuarantineTemp",
    ]),
    ...mapActions("stockdestroy", ["setStockDestroy", "clearStockDestroy"]),
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYYMMDDHHmmss");
      }
    },
    // warehouseSelectedChanged1(e) {
    //   console.log("e : " + e.value);
    //   var warehouse_id = e.value;
    //   this.getFirstItemNearExpired(warehouse_id);
    // },
    // warehouseSelectedChanged2(e) {
    //   console.log("e : " + e.value);
    //   var warehouse_id = e.value;
    //   // this.setStockQuarantineTemp(e.value);
    //   // console.log("getStockQuarantineTemp : " + this.getStockQuarantineTemp);
    //   this.getStockInItemNearExpired(warehouse_id);
    // },
    warehouseSelectedChanged3(e) {
      console.log("warehouse e : " + e.value);
      var warehouse_id = e.value;
      // var addParam;
      // addParam = {
      //   warehouse_id: warehouse_id,
      // };
      // var wrhObject = {};
      // Object.assign(wrhObject, addParam);
      // this.setStockQuarantineTemp("2");

      var tmp = [];
      if (warehouse_id != null) {
        var my_json = {
          warehouse_id: warehouse_id,
        };
        tmp.push(my_json);
        this.setStockQuarantineTemp(tmp);
      } else {
        this.setStockQuarantineTemp(null);
      } 

      console.log("getStockQuarantineTemp : " + JSON.stringify(this.getStockQuarantineTemp));
      this.getQuarantineItem(warehouse_id);
    },

    getFirstItemNearExpired(warehouse_id) {
      axios
        .get(
          
            "stocks/getitemlist/" +
            warehouse_id
        )
        .then((response) => {
          //console.log(JSON.stringify(response.data));
          this.GetItemFirstStockList = response.data.data;
        });
    },

    getStockInItemNearExpired(warehouse_id) {
      axios
        .get(
          
            "stock_in_details/getitemlist/" +
            warehouse_id
        )
        .then((response) => {
          //console.log(JSON.stringify(response.data));
          this.GetItemStockInList = response.data.data;
        });
    },

    getQuarantineItem(warehouse_id) {
      axios
        .get(
          
            "stock_quarantines/wrh/" +
            warehouse_id
        )
        .then((response) => {
          //console.log(JSON.stringify(response.data));
          this.GetQuarantineItemList = response.data.data;
        });
    },
    async fetch(params = {}) {
      // eslint-disable-next-line no-console
      if (Object.keys(params).length === 0) {
        params = {
          perPage: 10,
          page: 1,
          // parentId: "null",
        };
      }
      var results = await StockQuarantineService.getData(params);
      // eslint-disable-next-line no-console
      var datax = await {
        data: results.data,
        totalCount: 10,
        // totalCount: results.total,
      };
      return await datax;
    },
    onSelectionItemChanged({ selectedRowKeys, selectedRowsData }) {
      // console.log("selectedRowKeys : " + selectedRowKeys.length);
      if (selectedRowKeys.length > 0) {
        if (selectedRowKeys.length == 1) {
          this.setStockQuarantine(selectedRowsData[0]);
          console.log(
            "setStockNearExpired : " + JSON.stringify(this.getStockQuarantine)
          );
          if (this.getStockQuarantine.last_item_qty > 0) {
            this.btnStockQuarantine.option({
              disabled: false,
            });
          } else {
            this.btnStockQuarantine.option({
              disabled: true,
            });
          }

          // this.btnStockDestroy.option({
          //   disabled: false,
          // });
        } else {
          this.setStockQuarantine(selectedRowsData);
          // console.log(selectedRowsData);
          this.btnStockQuarantine.option({
            disabled: true,
          });
          // this.btnStockDestroy.option({
          //   disabled: true,
          // });
        }
      } else {
        this.clearStockQuarantine();
        this.btnStockQuarantine.option({
          disabled: true,
        });
        // this.btnStockDestroy.option({
        //   disabled: true,
        // });
      }
    },
    onSelectionQuarantineChanged({ selectedRowKeys, selectedRowsData }) {
      // console.log("selectedRowKeys : " + selectedRowKeys.length);
      if (selectedRowKeys.length > 0) {
        if (selectedRowKeys.length == 1) {
          this.setStockDestroy(selectedRowsData[0]);
          console.log(
            "setStockDestroy : " + JSON.stringify(this.getStockDestroy)
          );

          if (this.getStockDestroy.qty > 0) {
            this.btnStockDestroy.option({
              disabled: false,
            });
            this.btnDelete.option({
              disabled: false,
            });
          } else {
            this.btnStockDestroy.option({
              disabled: true,
            });
            this.btnDelete.option({
              disabled: true,
            });
          }
        } else {
          this.setStockDestroy(selectedRowsData);
          this.btnStockDestroy.option({
            disabled: true,
          });
        }

        // this.btnDelete.option({
        //   disabled: false,
        // });
      } else {
        this.clearStockQuarantine();
        this.btnStockDestroy.option({
          disabled: true,
        });
        this.btnDelete.option({
          disabled: true,
        });
      }
    },
    onToolbarFirstItemNearExpired(e) {
      var vThis = this;
      e.toolbarOptions.items.unshift(
        {
          location: "before",
          template: "titlereport",
        },
        {
          location: "before",
          template: "warehouse",
        },
        // Stock Quarantine //
        {
          location: "center",
          widget: "dxButton",
          options: {
            icon: "lock",
            text: "Karantina",
            disabled: true,
            // visible: false,
            onInitialized: function (e) {
              vThis.btnStockQuarantine = e.component;
            },
            // onClick: function () {
            //   vThis.setStockQuarantineStatus("addFromStockIn");
            //   router.push("/stock/quarantine/edit");
            // },
            onClick: function () {
              let result = confirm(
                "<i>Anda yakin mengkarantina item ini ?</i>",
                "Karantina"
              );
              result.then((dialogResult) => {
                if (dialogResult) {
                  var StockQuarantine = vThis.getStockQuarantine;
                  console.log(vThis.getStockQuarantine);
                  if (StockQuarantine) {
                    console.log("Add StockQuarantine");
                    vThis.loadingVisible = true;

                    StockQuarantine.transaction_number = vThis.format_trx_date(
                      new Date()
                    );
                    StockQuarantine.uuid = vThis.currentUUid;
                    StockQuarantine.created_by = vThis.userId;
                    StockQuarantine.user_id = vThis.userId;
                    StockQuarantine.quarantine_date = new Date();
                    StockQuarantine.qty = StockQuarantine.last_item_qty;
                    ///////////////////////////////
                    let formData = new FormData();
                    formData.append(
                      "warehouse_id",
                      StockQuarantine.warehouse_id
                    );
                    formData.append("uuid", vThis.currentUUid);
                    formData.append(
                      "transaction_number",
                      StockQuarantine.transaction_number
                    );
                    formData.append("stock_action", 210); // quarantine
                    formData.append(
                      "batch_number",
                      StockQuarantine.batch_number
                    );
                    formData.append("item_id", StockQuarantine.item_id);
                    formData.append("qty", StockQuarantine.last_item_qty);
                    formData.append(
                      "item_condition_id",
                      StockQuarantine.item_condition_id
                    );
                    formData.append("remark", "stock-quarantine");
                    formData.append(
                      "expired_date",
                      StockQuarantine.expired_date
                    );
                    axios
                      .post("stock_reports", formData)
                      .then(function (response) {
                        console.log(response);
                        vThis.success = response.data.success;
                      })
                      .catch(function (error) {
                        console.log(error);
                        vThis.output = error;
                      });
                    ///////////////////////////////

                    StockQuarantineService.insert(
                      StockQuarantine,
                      function () {
                        vThis.loadingVisible = false;
                        vThis.clearStockQuarantineStatus();
                        vThis.clearStockQuarantine();
                        // router.push("/stock/quarantine");
                      },
                      function () {
                        vThis.loadingVisible = false;
                      }
                    );
                  }
                }
              });
            },
          },
        }
      );
    },
    onToolbarItemNearExpired(e) {
      var vThis = this;
      e.toolbarOptions.items.unshift(
        {
          location: "before",
          template: "titlereport",
        },
        {
          location: "before",
          template: "warehouse",
        },

        // Stock Quarantine //
        {
          location: "center",
          widget: "dxButton",
          options: {
            icon: "lock",
            text: "Karantina",
            disabled: true,
            // visible: false,
            onInitialized: function (e) {
              vThis.btnStockQuarantine = e.component;
            },
            // onClick: function () {
            //   vThis.setStockQuarantineStatus("addFromStockIn");
            //   router.push("/stock/quarantine/edit");
            // },
            onClick: function () {
              let result = confirm(
                "<i>Anda yakin mengkarantina item ini ?</i>",
                "Karantina"
              );
              result.then((dialogResult) => {
                if (dialogResult) {
                  var StockQuarantine = vThis.getStockQuarantine;
                  console.log(vThis.getStockQuarantine);
                  if (StockQuarantine) {
                    console.log("Add StockQuarantine");
                    vThis.loadingVisible = true;

                    StockQuarantine.transaction_number = vThis.format_trx_date(
                      new Date()
                    );
                    StockQuarantine.uuid = vThis.currentUUid;
                    StockQuarantine.created_by = vThis.userId;
                    StockQuarantine.user_id = vThis.userId;
                    StockQuarantine.quarantine_date = new Date();
                    StockQuarantine.qty = StockQuarantine.last_item_qty;
                    ///////////////////////////////
                    let formData = new FormData();
                    formData.append(
                      "warehouse_id",
                      StockQuarantine.warehouse_id
                    );
                    formData.append("uuid", vThis.currentUUid);
                    formData.append(
                      "transaction_number",
                      StockQuarantine.transaction_number
                    );
                    formData.append("stock_action", 210); // quarantine
                    formData.append(
                      "batch_number",
                      StockQuarantine.batch_number
                    );
                    formData.append("item_id", StockQuarantine.item_id);
                    formData.append("qty", StockQuarantine.last_item_qty);
                    formData.append(
                      "item_condition_id",
                      StockQuarantine.item_condition_id
                    );
                    formData.append("remark", "stock-quarantine");
                    formData.append(
                      "expired_date",
                      StockQuarantine.expired_date
                    );
                    axios
                      .post("stock_reports", formData)
                      .then(function (response) {
                        console.log(response);
                        vThis.success = response.data.success;
                      })
                      .catch(function (error) {
                        console.log(error);
                        vThis.output = error;
                      });
                    ///////////////////////////////

                    StockQuarantineService.insert(
                      StockQuarantine,
                      function () {
                        vThis.loadingVisible = false;
                        vThis.clearStockQuarantineStatus();
                        vThis.clearStockQuarantine();
                        // router.push("/stock/quarantine");
                      },
                      function () {
                        vThis.loadingVisible = false;
                      }
                    );
                  }
                }
              });
            },
          },
        }
      );
    },

    onToolbarItemExpired(e) {
      var vThis = this;
      e.toolbarOptions.items.unshift(
        {
          location: "before",
          template: "titlereport",
        },
        {
          location: "before",
          template: "warehouse",
        },
        {
          location: "after",
          widget: "dxButton",
          options: {
            icon: "checkmark1",
            text: "Pilih Item",
            onClick: function () {
              console.log(vThis.getStockQuarantineTemp);
              if (vThis.getStockQuarantineTemp != null) {
                vThis.setStockQuarantineStatus("add");
                vThis.clearStockQuarantine();
                router.push("/stock/quarantine/add");
              } else {
                alert('Silahkan Pilih Warehouse')
              }
            },
          },
        },
        // Stock Quarantine //
        {
          location: "after",
          widget: "dxButton",
          options: {
            icon: "cancel1",
            text: "Destroy",
            disabled: true,
            // visible: false,
            onInitialized: function (e) {
              vThis.btnStockDestroy = e.component;
            },
            // onClick: function () {
            //   vThis.setStockQuarantineStatus("addFromStockIn");
            //   router.push("/stock/quarantine/edit");
            // },
            onClick: function () {
              let result = confirm(
                "<i>Anda yakin menghancurkan item ini ?</i>",
                "Destroy Item"
              );
              result.then((dialogResult) => {
                if (dialogResult) {
                  var StockDestroy = vThis.getStockDestroy;
                  var StockQuarantineData = vThis.getStockDestroy;
                  var QuarantineUuid = StockDestroy.uuid;
                  console.log(
                    "QuarantineUuid :" + JSON.stringify(QuarantineUuid)
                  );
                  console.log(
                    "getStockDestroy :" + JSON.stringify(vThis.getStockDestroy)
                  );

                  if (StockDestroy) {
                    console.log("Add StockDestroy");
                    vThis.loadingVisible = true;

                    StockDestroy.uuid = vThis.uuid;
                    StockDestroy.created_by = vThis.userId;
                    StockDestroy.user_id = vThis.userId;
                    StockDestroy.destroy_date = new Date();
                    StockDestroy.destroy_location = 1;
                    StockDestroy.transaction_number = vThis.format_trx_date(
                      new Date()
                    );
                    ///////////////////////////////
                    let formData = new FormData();
                    formData.append("warehouse_id", StockDestroy.warehouse_id);
                    formData.append("uuid", vThis.uuid);
                    formData.append(
                      "transaction_number",
                      StockDestroy.transaction_number
                    );
                    formData.append("stock_action", 220); // destroy
                    formData.append("batch_number", StockDestroy.batch_number);
                    formData.append("item_id", StockDestroy.item_id);
                    formData.append("qty", StockDestroy.qty);
                    formData.append(
                      "item_condition_id",
                      StockDestroy.item_condition_id
                    );
                    formData.append("remark", "stock-destroy");
                    formData.append("expired_date", StockDestroy.expired_date);
                    axios
                      .post("stock_reports", formData)
                      .then(function (response) {
                        console.log(response);
                        vThis.success = response.data.success;
                      })
                      .catch(function (error) {
                        console.log(error);
                        vThis.output = error;
                      });
                    ///////////////////////////////

                    StockDestroyService.insert(
                      StockDestroy,
                      function () {
                        vThis.loadingVisible = false;
                        vThis.clearStockDestroyStatus();
                        vThis.clearStockDestroy();
                        // router.push("/stock/quarantine");
                      },
                      function () {
                        vThis.loadingVisible = false;
                      }
                    );
                    ///////////////////////////////
                    var ids = "";
                    if (Array.isArray(StockQuarantineData)) {
                      StockQuarantineData.forEach(function (item, index) {
                        if (index == 0) {
                          ids += item.id;
                        } else {
                          ids += "," + item.id;
                        }
                      });
                    } else {
                      ids = StockQuarantineData.id;
                    }

                    vThis.deleteReport(QuarantineUuid);

                    StockQuarantineService.delete(
                      ids,
                      function () {
                        vThis.clearStockQuarantine();
                        vThis.dataGrid.refresh();
                        router.push("/stock/destroy");
                      },
                      function (error) {
                        // eslint-disable-next-line no-console
                        console.log(error);
                      }
                    );
                  }
                }
              });
            },
          },
        },

        {
          location: "after",
          widget: "dxButton",
          options: {
            icon: "trash",
            text: "Hapus",
            disabled: true,
            visible: this.roleid == 5 ? false : true, //jika role bukan user maka dihide
            onInitialized: function (e) {
              vThis.btnDelete = e.component;
            },
            onClick: function () {
              let result = confirm(
                "<i>Anda yakin mau menghapus data ini ?</i>",
                "Hapus Stock Quarantine"
              );
              result.then((dialogResult) => {
                if (dialogResult) {
                  // var StockQuarantineData = vThis.getStockQuarantine;
                  var StockQuarantineData = vThis.getStockDestroy;
                  console.log(vThis.getStockQuarantine);
                  var ids = "";
                  if (Array.isArray(StockQuarantineData)) {
                    StockQuarantineData.forEach(function (item, index) {
                      if (index == 0) {
                        ids += item.id;
                      } else {
                        ids += "," + item.id;
                      }
                    });
                  } else {
                    ids = StockQuarantineData.id;
                  }
                  StockQuarantineService.delete(
                    ids,
                    function () {
                      vThis.clearStockQuarantine();
                      vThis.dataGrid.refresh();
                    },
                    function (error) {
                      // eslint-disable-next-line no-console
                      console.log(error);
                    }
                  );
                }
              });
            },
          },
        },
        {
          location: "after",
          widget: "dxButton",
          options: {
            icon: "refresh",
            text: "Muat Ulang",
            onClick: function () {
              vThis.dataGrid.refresh();
            },
          },
        }
      );
    },

    onToolbarPreparing(e) {
      var vThis = this;

      e.toolbarOptions.items.unshift(
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "add",
            text: "Tambah",
            visible: false,
            onInitialized: function (e) {
              vThis.btnAdd = e.component;
            },
            onClick: function () {
              vThis.setStockQuarantineStatus("add");
              router.push("/stock/quarantine/add");
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "edit",
            text: "Edit",
            disabled: true,
            onInitialized: function (e) {
              vThis.btnEdit = e.component;
            },
            onClick: function () {
              vThis.setStockQuarantineStatus("edit");
              router.push("/stock/quarantine/edit");
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "trash",
            text: "Hapus",
            disabled: true,
            onInitialized: function (e) {
              vThis.btnDelete = e.component;
            },
            onClick: function () {
              let result = confirm(
                "<i>Anda yakin mau menghapus data ini ?</i>",
                "Hapus StockQuarantine StockQuarantine"
              );
              result.then((dialogResult) => {
                if (dialogResult) {
                  var StockQuarantineData = vThis.getStockQuarantine;
                  console.log(vThis.getStockQuarantine);
                  var ids = "";
                  if (Array.isArray(StockQuarantineData)) {
                    StockQuarantineData.forEach(function (item, index) {
                      if (index == 0) {
                        ids += item.id;
                      } else {
                        ids += "," + item.id;
                      }
                    });
                  } else {
                    ids = StockQuarantineData.id;
                  }
                  StockQuarantineService.delete(
                    ids,
                    function () {
                      vThis.clearStockQuarantine();
                      vThis.dataGrid.refresh();
                    },
                    function (error) {
                      // eslint-disable-next-line no-console
                      console.log(error);
                    }
                  );
                }
              });
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "detailslayout",
            text: "Detail",
            disabled: true,
            onInitialized: function (e) {
              vThis.btnView = e.component;
            },
            onClick: function () {
              router.push("/stock/quarantine/view");
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "refresh",
            text: "Muat Ulang",
            onClick: function () {
              vThis.dataGrid.refresh();
            },
          },
        },
        // Filter //
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "search",
            text: vThis.filterText,
            disabled: false,
            onInitialized: function (e) {
              vThis.btnFilter = e.component;
            },
            onClick: function () {
              if (vThis.filterStatus === true) {
                vThis.filterStatus = false;
                vThis.filterText = "Filter ON";
                // console.log("filterStatus : " + vThis.filterStatus);
              } else {
                vThis.filterStatus = true;
                vThis.filterText = "Filter Off";
                // console.log("filterStatus : " + vThis.filterStatus);
              }
            },
          },
        }
      );
    },
    onRowExpanding(e) {
      var dataGrid = e.component;
      dataGrid.collapseAll(-1);
    },
    async deleteReport(uuid) {
      var vThis = this;
      // var vuuid = uuid;
      /////////////////////////
      try {
        const response1 = await axios.get("stock_reports/getid/" + uuid);
        var reportId = JSON.stringify(response1.data.data[0].id);
        // console.log(
        //   "report_id : " +
        //     JSON.stringify("reportId :" + response1.data.data[0].id)
        // );
      } catch (error) {
        console.error(error);
      }
      /////////////////////////
      try {
        const response2 = await axios.delete("stock_reports/" + reportId);
        // console.log(response2);
        vThis.success = response2.data.success;
      } catch (error) {
        console.error(error);
      }
    },
    getDataCust() {
      let currentObj = this;
      axios
        .get("stockquarantines/" + this.custid)
        .then(function (response) {
          // console.log(response);
          // currentObj.success = response.data.success;
          // currentObj.mydata = response;
          currentObj.FormStockQuarantine = response.data.data;
          // console.log("mydata = " + JSON.stringify(response.data.data));
          // console.log(
          //   "getStockQuarantine = " + JSON.stringify(currentObj.getStockQuarantine)
          // );
        })
        .catch(function (error) {
          console.log(error);
          currentObj.output = error;
        });
    },
    format_trx_date(value) {
      if (value) {
        return moment(String(value)).format("YYYYMMDDHHmmss");
      }
    },
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Workbook");
      // const worksheet = workbook
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        topLeftCell: { row: 4, column: 1 },
      })
        .then((cellRange) => {
          // header
          const headerRow = worksheet.getRow(2);
          headerRow.height = 30;
          worksheet.mergeCells(2, 1, 2, 8);

          headerRow.getCell(1).value = "Data Stock Quarantine";
          headerRow.getCell(1).font = { name: "Segoe UI Light", size: 22 };
          headerRow.getCell(1).alignment = { horizontal: "left" };

          // footer
          const footerRowIndex = cellRange.to.row + 2;
          const footerRow = worksheet.getRow(footerRowIndex);
          worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 8);

          footerRow.getCell(1).value = "";
          footerRow.getCell(1).font = {
            color: { argb: "BFBFBF" },
            italic: true,
          };
          footerRow.getCell(1).alignment = { horizontal: "right" };
        })
        .then(function () {
          workbook.xlsx.writeBuffer().then(function (buffer) {
            saveAs(
              new Blob([buffer], { type: "application/octet-stream" }),
              "WMS-Data-Stock-Quarantine.xlsx"
            );
          });
        });
      e.cancel = true;
    },
  },
  computed: {
    ...mapGetters("stockquarantine", [
      "getStockQuarantine",
      "getStockQuarantineStatus",
      "getStockQuarantineForm",
      "getStockQuarantineTemp",
    ]),
    ...mapGetters("stockdestroy", ["getStockDestroy"]),
    dataGrid: function () {
      return this.$refs[gridRef].instance;
    },
  },
};
</script>

<style>
</style>
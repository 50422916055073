<template>
  <div>
    <h2 class="content-block">Stock Quarantine</h2>
    <stock-quarantine-table />
  </div>
</template>

<script>
import StockQuarantineTable from "@/components/stock-quarantine/StockQuarantineTable.vue";
export default {
  components: {
    StockQuarantineTable,
  },
};
</script>
<template>
  <div>
    <h2 class="content-block">Daftar Perusahaan</h2>
    <company-table />
  </div>
</template>

<script>
import CompanyTable from "@/components/company/CompanyTable.vue";
export default {
  components: {
    CompanyTable,
  },
};
</script>
<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <div class="my-title">Tabel Menu</div>
      <DxForm
        @field-data-changed="formFieldDataChanged"
        :ref="formRef"
        :read-only="false"
        :visible="true"
      >
        <DxGroupItem>
          <DxSimpleItem data-field="role_id" :label="{ text: 'Role Name' }">
            <template #default>
              <DxSelectBox
                :data-source="roleData"
                :show-clear-button="true"
                display-expr="name"
                value-expr="role_number"
                :value="
                  getRoleMenuStatus == 'edit' ? getRoleMenu.role_id : null
                "
                :searchEnabled="true"
                @value-changed="RoleSelectedChanged"
              ></DxSelectBox>
            </template>
          </DxSimpleItem>
        </DxGroupItem>
      </DxForm>
      <RoleData @roleData="setRoleData" />
      <ParentMenu @menuData="setParentData" :level="1" />
      <ChildMenu @menuData="setChildData" :level="2" />
      <br />
      <DxTreeList
        id="tasks"
        :data-source="MenuData"
        :show-borders="true"
        :column-auto-width="true"
        :word-wrap-enabled="true"
        :expanded-row-keys="expandedRowKeys"
        :selected-row-keys="selectedRowKeys"
        @selection-changed="onSelectionMenuChanged"
        key-expr="id"
        parent-id-expr="parent_id"
      >
        <!-- <DxSearchPanel :visible="true" :width="250" /> -->
        <DxHeaderFilter :visible="true" />
        <DxSelection mode="multiple" />
        <!-- <DxColumnChooser :enabled="true" /> -->

        <DxColumn :width="300" data-field="menu_text" />
      </DxTreeList>
    </div>
  </div>
</template>
<script>
import // DxDataGrid,
// DxPager,
// DxPaging,
// DxSelection,
// DxColumn,
// DxFilterRow,
// DxExport
"devextreme-vue/data-grid";
import RoleData from "@/components/master-role/RoleData.vue";
import ParentMenu from "@/components/master-menu/MasterMenuData.vue";
import ChildMenu from "@/components/master-menu/MasterMenuData.vue";
import { DxForm, DxSimpleItem, DxGroupItem } from "devextreme-vue/form";
import DxTextArea from "devextreme-vue/text-area";
import { mapGetters, mapActions } from "vuex";
import { DxSelectBox } from "devextreme-vue/select-box";
import axios from "axios";
import {
  DxTreeList,
  DxColumn,
  // DxColumnChooser,
  DxHeaderFilter,
  // DxSearchPanel,
  DxSelection,
  // DxLookup,
} from "devextreme-vue/tree-list";

import { tasks, employees, priorities } from "./data.js";
// import EmployeeCell from "./EmployeeCell.vue";

const statuses = [
  "Not Started",
  "Need Assistance",
  "In Progress",
  "Deferred",
  "Completed",
];

const formRef = "customer-form";

export default {
  components: {
    // DxDataGrid,
    // DxPager,
    // DxPaging,
    // DxSelection,
    // DxColumn,
    // DxFilterRow,
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    // eslint-disable-next-line
    DxTextArea,
    DxSelectBox,
    RoleData,
    ParentMenu,
    ChildMenu,

    DxTreeList,
    DxColumn,
    // DxColumnChooser,
    DxHeaderFilter,
    // DxSearchPanel,
    DxSelection,
    // DxLookup,
    // EmployeeCell,
  },
  data: function () {
    return {
      loadPanel: {
        text: "Memuat...",
      },
      // selectedRowKeys: [],
      formRef,
      roleData: null,
      RoleData: null,
      menuData: null,
      MenuData: null,
      ParentData: null,
      ChildData: null,
      RoleMenu: [],
      pageSizes: [5, 10, 15],

      expandedRowKeys: [1, 2],
      selectedRowKeys: [1, 29, 42],
      employees,
      priorities,
      statuses,
      roleId: null,
    };
  },
  created() {
    this.getAllMenu();
  },
  mounted() {
    this.formSet();
  },
  methods: {
    ...mapActions("rolemenu", [
      "setRoleMenu",
      "setRoleMenuForm",
      "clearRoleMenu",
    ]),
    ...mapActions("mastermenu", [
      "setMasterMenu",
      "clearMasterMenu",
      "setMasterMenuStatus",
    ]),
    formFieldDataChanged() {
      // this.setRoleMenu(e.component.option("formData"));
      // console.log(
      //   "setRoleMenu : " + JSON.stringify(e.component.option("formData"))
      // );
    },
    formSet() {
      this.setRoleMenuForm(this.$refs[formRef].instance);
    },
    /////////////////////////////////////
    setRoleData(value) {
      this.roleData = value;
    },
    RoleSelectedChanged(e) {
      // console.log("provinceId : " + e.value);
      this.$refs[formRef].instance.updateData({
        role_id: e.value,
      });
      this.roleId = e.value;
    },
    /////////////////////////////////////
    setMenuData(value) {
      this.menuData = value;
    },
    setParentData(value) {
      this.ParentData = value;
    },
    setChildData(value) {
      this.ChildData = value;
    },
    ParentMenuSelectedChanged(e) {
      this.$refs[formRef].instance.updateData({
        parent_id: e.value,
      });
      var $parent_id = e.value;
      this.getChildMenuByParentId($parent_id);
    },

    getChildMenuByParentId($parent_id) {
      let currentObj = this;
      axios
        .get("master_menus", {
          params: {
            parent_id: $parent_id,
          },
        })
        .then(function (response) {
          // console.log(
          //   "response master_menus :" + JSON.stringify(response.data.data)
          // );
          currentObj.ChildData = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
          currentObj.output = error;
        });
    },

    ChildMenuSelectedChanged(e) {
      this.$refs[formRef].instance.updateData({
        menu_id: e.value,
      });
    },

    getAllMenu() {
      // // console.log("getDataCustomer : " + param);
      var AUTH_TOKEN = "Bearer " + window.localStorage.getItem("token");
      axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;
      let currentObj = this;
      axios
        .get("master_menus")
        .then(function (response) {
          currentObj.MenuData = response.data.data;
        })
        .catch(function (error) {
          // console.log(error);
          currentObj.output = error;
        });
    },

    onSelectionMenuChanged({ selectedRowKeys, selectedRowsData }) {
      // console.log("selectedRowKeys.length : " + selectedRowKeys.length);
      if (selectedRowKeys.length > 0) {
        // this.setMasterMenu(selectedRowsData);
        this.RoleMenu = [];
        var i;
        for (i = 0; i < selectedRowKeys.length; i++) {
          // var userid = parseInt(window.localStorage.getItem("usrid"));
          // var offering_letter_uuid = this.uuid;
          var menu_id = selectedRowsData[i].id;
          var my_json = {
            role_id: this.roleId,
            menu_id: menu_id,
          };
          this.RoleMenu.push(my_json);
          this.setRoleMenu(this.RoleMenu);
          console.log(JSON.stringify(this.RoleMenu));
        }
      } else {
        //jika tidak diklik
        console.log('ClearRoleMenu 2')
        this.clearRoleMenu();
      }
      // // console.log(JSON.stringify(this.OfferingPrice.length));
      //  this.setOfferingLetterPrice(this.OfferingPrice);
    },

    customizeTaskCompletionText(cellInfo) {
      return `${cellInfo.valueText}%`;
    },
  },
  computed: {
    ...mapGetters("rolemenu", ["getRoleMenu", "getRoleMenuStatus"]),
    ...mapGetters("mastermenu", ["getMasterMenu", "getMasterMenuStatus"]),
    dataSource() {
      return tasks.map(function (task) {
        employees.forEach(function (employee) {
          if (task.Task_Assigned_Employee_ID === employee.ID) {
            task.Task_Assigned_Employee = employee;
          }
        });
        // console.log(JSON.stringify(task));
        return task;
        // console.log(JSON.stringify(this.OfferingPrice.length));
      });
    },
  },
};
</script>
<style>
.group-padding {
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.form-block {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  padding-left: 40px;
  padding-right: 40px;
}
</style>

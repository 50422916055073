<template>
  <DxForm
    class="form-block"
    :form-data="getStockDestroy"
    :read-only="true"
    :ref="formRef"
    :col-count="2"
  >
    <DxGroupItem>
      <DxSimpleItem
        data-field="delivery_date"
        :label="{ text: 'Tanggal Pengiriman' }"
        editor-type="dxDateBox"
      >
      </DxSimpleItem>

      <DxSimpleItem data-field="invoice_number" :label="{ text: 'No invoice' }">
      </DxSimpleItem>

      <DxSimpleItem
        data-field="origin_name"
        :label="{ text: 'Asal Pengiriman' }"
      >
      </DxSimpleItem>
    </DxGroupItem>
    <DxGroupItem>
      <DxSimpleItem
        data-field="destination_type_name"
        :label="{ text: 'Tipe Tujuan' }"
      >
      </DxSimpleItem>

      <DxSimpleItem
        data-field="destination_name"
        :label="{ text: 'Tujuan Pengiriman' }"
      >
      </DxSimpleItem>

      <DxSimpleItem data-field="remark" :label="{ text: 'Catatan' }">
      </DxSimpleItem>
    </DxGroupItem>
  </DxForm>
</template>
<script>
import { DxForm, DxSimpleItem, DxGroupItem } from "devextreme-vue/form";
import DxTextArea from "devextreme-vue/text-area";
import { mapGetters, mapActions } from "vuex";

const formRef = "stockdestroy-form";

export default {
  components: {
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    // eslint-disable-next-line
    DxTextArea,
  },
  data: function () {
    return {
      formRef,
    };
  },

  methods: {
    ...mapActions("stockdestroy", [
      "setStockDestroy",
      "clearStockDestroy",
      "setStockDestroyStatus",
      "clearStockDestroyStatus",
    ]),
  },
  computed: {
    ...mapGetters("stockdestroy", [
      "getStockDestroy",
      "getStockDestroyStatus",
      "getStockDestroyForm",
    ]),
  },
};
</script>
<style>
.group-padding {
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.form-block {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  padding-left: 40px;
  padding-right: 40px;
}
</style>

<template>
  <div>
    <h2 class="content-block">Master Item</h2>
    <master-item-table />
  </div>
</template>

<script>
import MasterItemTable from "@/components/master-item/MasterItemTable.vue";
export default {
  components: {
    MasterItemTable,
  },
};
</script>
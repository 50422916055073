<template>
  <div>
    <!-- <h2>
      Selamat Datang <strong>{{ useremail }}</strong>
    </h2> -->
    <dx-data-grid
      class="dx-card wide-card"
      :data-source="dataSource"
      :show-borders="true"
      :focused-row-index="0"
      :focused-row-enabled="true"
      :column-auto-width="true"
      :column-hiding-enabled="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :loadPanel="loadPanel"
      :selected-row-keys="selectedRowKeys"
      @toolbar-preparing="onToolbarPreparing($event)"
      @selection-changed="onSelectionChanged"
      @rowExpanding="onRowExpanding"
      @exporting="onExporting"
      :ref="gridRef"
    >
      <dx-paging :page-size="10" />
      <dx-pager
        :showPageSizeSelector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
        :showNavigationButtons="true"
        infoText="Hal {0} dari {1} ({2} items)"
        :visible="true"
      />
      <dx-filter-row :visible="filterStatus" />
      <DxSelection mode="multiple" />
      <DxColumn data-field="branch_name" caption="Nama" />
      <DxColumn data-field="address" caption="Alamat" />
      <DxColumn data-field="province_name" caption="Provinsi" />
      <DxColumn data-field="city_name" caption="Kota" />
      <!-- <DxColumn data-field="country_name" caption="Negara" /> -->
      <DxColumn data-field="postalcode" caption="Kode Pos" />
      <DxColumn data-field="phone" caption="No Telepon" />
      <!-- <DxColumn data-field="fax" caption="No Fax" /> -->
      <DxColumn data-field="email" caption="Email" />
      <!-- <DxColumn data-field="website" caption="Website" /> -->
      <!-- <DxColumn data-field="business_type_name" caption="Tipe Perusahaan" /> -->
      <DxExport :enabled="true" />

      <!-- <DxMasterDetail :enabled="true" template="masterDetailTemplate" /> -->
      <!-- <template #masterDetailTemplate="{ data: companybranch }">
        <DivisionTable :template-data="companybranch" />
      </template> -->
    </dx-data-grid>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxPager,
  DxPaging,
  DxSelection,
  DxColumn,
  DxExport,
  DxFilterRow,
} from "devextreme-vue/data-grid";
import CustomStore from "devextreme/data/custom_store";
import Service from "@/services/CompanyBranchService";
import router from "../../router";
import { mapGetters, mapActions } from "vuex";
import { confirm } from "devextreme/ui/dialog";
// import "devextreme/dist/css/dx.common.css";
// import "devextreme/dist/css/dx.light.css";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";

// const gridRef = "departement-table";
const gridRef = "companybranch-table";

export default {
  // props: ["CompanyBranchType"],
  components: {
    DxDataGrid,
    DxPager,
    DxPaging,
    DxSelection,
    DxColumn,
    DxExport,
    DxFilterRow,
    // DivisionTable,
  },
  data() {
    const vthis = this;
    const dataSource = {
      store: new CustomStore({
        key: "id",
        load: async function () {
          // var sortedBy = "";
          // if (loadOptions.sort) {
          //   sortedBy += loadOptions.sort[0].selector;
          //   if (loadOptions.sort[0].desc) {
          //     sortedBy += " desc";
          //   } else {
          //     sortedBy += " asc";
          //   }
          // }

          // var perPage = loadOptions.take;
          // var offset = loadOptions.skip;
          // var page = offset / perPage + 1;

          var datax = vthis.fetch({
            // perPage: perPage || 10,
            // page: page || 1,
            // sortedBy: sortedBy,
            // parentId: "null",
          });

          return await datax;
        },
      }),
    };
    return {
      filterStatus: false,
      filterText: "Filter On",

      useremail: localStorage.getItem("useremail"),
      user: [],
      gridRef,
      dataSource: dataSource,
      pageSizes: [5, 10, 15],
      selectedRowKeys: [],
      loadPanel: {
        text: "Memuat...",
      },
      btnAdd: null,
      btnEdit: null,
      btnDelete: null,
      btnView: null,

      btnCustFollow: null,
      btnCustFollowHistory: null,

      btnCustPotensial: null,
      btnCustPotensialHistory: null,

      btnCustNew: null,
      btnCustNewHistory: null,

      btnCustBranch: null,
      btnCustContact: null,

      // btnFollowHistory: null,
      tipe: this.tipe,
    };
  },
  created() {},
  methods: {
    ...mapActions("companybranch", [
      "setCompanyBranch",
      "setCompanyBranchStatus",
      "clearCompanyBranch",
    ]),

    async fetch(params = {}) {
      // eslint-disable-next-line no-console
      if (Object.keys(params).length === 0) {
        params = {
          // perPage: 10,
          // page: 1,
          // parentId: "null",
        };
      }

      // var userid = window.localStorage.getItem("usrid");
      // console.log(userid);
      var results = await Service.getData(params);
      console.log("results : " + JSON.stringify(results));

      // eslint-disable-next-line no-console
      var datax = await {
        data: results.data,
        totalCount: 10,
        // totalCount: results.total,
      };

      return await datax;
    },
    onSelectionChanged({ selectedRowKeys, selectedRowsData }) {
      // console.log("selectedRowKeys.length : " + selectedRowKeys.length);
      if (selectedRowKeys.length > 0) {
        if (selectedRowKeys.length == 1) {
          // masukkan data companybranch yg diklik ke map
          this.setCompanyBranch(selectedRowsData[0]);
          // console.log("getCompanyBranch" + this.getCompanyBranch);
          this.btnEdit.option({
            disabled: false,
          });
          this.btnDelete.option({
            disabled: false,
          });
          this.btnView.option({
            disabled: false,
          });
          this.btnCustBranch.option({
            // visible: true,
            disabled: false,
          });
          this.btnCustContact.option({
            // visible: true,
            disabled: false,
          });
        } else {
          this.setCompanyBranch(selectedRowsData);
          // console.log(selectedRowsData);
          this.btnEdit.option({
            disabled: true,
          });
          this.btnView.option({
            disabled: true,
          });
          this.btnDelete.option({
            disabled: true,
          });

          this.btnCustBranch.option({
            disabled: true,
          });
          this.btnCustContact.option({
            disabled: true,
          });
        }
      } else {
        this.clearCompanyBranch();
        this.btnEdit.option({
          disabled: true,
        });
        this.btnView.option({
          disabled: true,
        });
        this.btnDelete.option({
          disabled: true,
        });

        this.btnCustBranch.option({
          disabled: true,
        });
        this.btnCustContact.option({
          disabled: true,
        });
      }
    },

    onToolbarPreparing(e) {
      var vThis = this;

      e.toolbarOptions.items.unshift(
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "add",
            text: "Tambah",
            onInitialized: function (e) {
              vThis.btnAdd = e.component;
            },
            onClick: function () {
              vThis.setCompanyBranchStatus("add");
              router.push("/master/company/branch/add");
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "edit",
            text: "Edit",
            disabled: true,
            onInitialized: function (e) {
              vThis.btnEdit = e.component;
            },
            onClick: function () {
              vThis.setCompanyBranchStatus("edit");
              router.push("/master/company/branch/edit");
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "trash",
            text: "Hapus",
            disabled: true,
            onInitialized: function (e) {
              vThis.btnDelete = e.component;
            },
            onClick: function () {
              let result = confirm(
                "<i>Anda yakin mau menghapus companybranch ?</i>",
                "Hapus CompanyBranch"
              );
              result.then((dialogResult) => {
                if (dialogResult) {
                  var companybranch = vThis.getCompanyBranch;
                  console.log(vThis.getCompanyBranch);
                  var ids = "";
                  if (Array.isArray(companybranch)) {
                    companybranch.forEach(function (item, index) {
                      if (index == 0) {
                        ids += item.id;
                      } else {
                        ids += "," + item.id;
                      }
                    });
                  } else {
                    ids = companybranch.id;
                  }
                  Service.delete(
                    ids,
                    function () {
                      vThis.clearCompanyBranch();
                      vThis.dataGrid.refresh();
                    },
                    function (error) {
                      // eslint-disable-next-line no-console
                      console.log(error);
                    }
                  );
                }
              });
            },
          },
        },
        // {
        //   location: "before",
        //   widget: "dxButton",
        //   options: {
        //     icon: "search",
        //     text: "Cari",
        //     onClick: function () {},
        //   },
        // },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "detailslayout",
            text: "Tampilkan Detail",
            disabled: true,
            onInitialized: function (e) {
              vThis.btnView = e.component;
            },
            onClick: function () {
              router.push("/master/company/branch/view");
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "refresh",
            text: "Muat Ulang",
            onClick: function () {
              vThis.dataGrid.refresh();
            },
          },
        },
        // Filter //
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "search",
            text: vThis.filterText,
            disabled: false,
            onInitialized: function (e) {
              vThis.btnFilter = e.component;
            },
            onClick: function () {
              if (vThis.filterStatus === true) {
                vThis.filterStatus = false;
                vThis.filterText = "Filter ON";
                // console.log("filterStatus : " + vThis.filterStatus);
              } else {
                vThis.filterStatus = true;
                vThis.filterText = "Filter Off";
                // console.log("filterStatus : " + vThis.filterStatus);
              }
            },
          },
        },

      );
    },
    onRowExpanding(e) {
      var dataGrid = e.component;
      dataGrid.collapseAll(-1);
    },

    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Main sheet");
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        customizeCell: function (options) {
          const excelCell = options;
          excelCell.font = { name: "Arial", size: 12 };
          excelCell.alignment = { horizontal: "left" };
        },
      }).then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "data-companybranch.xlsx"
          );
        });
      });
      e.cancel = true;
    },
  },
  computed: {
    ...mapGetters("companybranch", ["getCompanyBranch", "getCompanyBranchStatus", "getCompanyBranchForm"]),
    ...mapGetters("user", ["getUser", "getUserStatus"]),
    dataGrid: function () {
      return this.$refs[gridRef].instance;
    },
  },
};
</script>

<style>
</style>
<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <DxForm
        @field-data-changed="formFieldDataChanged"
        :form-data="getEmployee"
        :ref="formRef"
        :read-only="false"
        :visible="true"
        :col-count="2"
      >
        <!-- <DxGroupItem :col-count="2" css-class="group-padding"> -->
        <DxGroupItem>
          <DxSimpleItem
            data-field="full_name"
            :label="{ text: 'Nama Karyawan' }"
            :editor-options="{ maxLength: '100' }"
          >
            <DxRequiredRule message="Nama Karyawan Wajib di Isi" />
          </DxSimpleItem>
          <DxSimpleItem
            data-field="user_name"
            :label="{ text: 'Nama User' }"
            :editor-options="{ maxLength: '100' }"
          >
            <DxRequiredRule message="Nama User Wajib di Isi" />
          </DxSimpleItem>
          <DxSimpleItem
            data-field="address"
            :label="{ text: 'Alamat' }"
            :editor-options="{ maxLength: '150' }"
          >
            <!-- <DxRequiredRule message="email Wajib di Isi" /> -->
          </DxSimpleItem>

          <DxSimpleItem data-field="province_id" :label="{ text: 'Provinsi' }">
            <template #default>
              <DxSelectBox
                :data-source="provinceData"
                :show-clear-button="true"
                display-expr="name"
                value-expr="id"
                :value="
                  getEmployeeStatus == 'edit' ? getEmployee.province_id : null
                "
                :searchEnabled="true"
                @value-changed="ProvinceSelectedChanged"
              ></DxSelectBox>
            </template>
          </DxSimpleItem>

          <DxSimpleItem data-field="city_id" :label="{ text: 'Kota' }">
            <template #default>
              <DxSelectBox
                :data-source="cityData"
                :show-clear-button="true"
                display-expr="name"
                value-expr="id"
                :value="
                  getEmployeeStatus == 'edit' ? getEmployee.city_id : null
                "
                :searchEnabled="true"
                @value-changed="CitySelectedChanged"
              ></DxSelectBox>
            </template>
          </DxSimpleItem>

          <DxSimpleItem
            data-field="phone"
            :label="{ text: 'Telepon' }"
            :editor-options="{ maxLength: '150' }"
          >
            <!-- <DxRequiredRule message="email Wajib di Isi" /> -->
          </DxSimpleItem>
        </DxGroupItem>
        <DxGroupItem>
          <DxSimpleItem
            data-field="npwp"
            :label="{ text: 'NPWP' }"
            :editor-options="{ maxLength: '150' }"
          >
            <!-- <DxRequiredRule message="email Wajib di Isi" /> -->
          </DxSimpleItem>

          <DxSimpleItem
            data-field="department_id"
            :label="{ text: 'Departemen' }"
          >
            <template #default>
              <DxSelectBox
                :data-source="departmentData"
                :show-clear-button="true"
                display-expr="name"
                value-expr="id"
                :value="
                  getEmployeeStatus == 'edit' ? getEmployee.department_id : null
                "
                :searchEnabled="true"
                @value-changed="DepartmentSelectedChanged"
              ></DxSelectBox>
            </template>
          </DxSimpleItem>

          <DxSimpleItem
            data-field="job_position_id"
            :label="{ text: 'Jabatan' }"
          >
            <template #default>
              <DxSelectBox
                :data-source="jobPositionData"
                :show-clear-button="true"
                display-expr="name"
                value-expr="id"
                :value="
                  getEmployeeStatus == 'edit'
                    ? getEmployee.job_position_id
                    : null
                "
                :searchEnabled="true"
                @value-changed="JobPositionSelectedChanged"
              ></DxSelectBox>
            </template>
          </DxSimpleItem>

          <DxSimpleItem
            data-field="company_id"
            :label="{ text: 'Nama Perusahaan Induk' }"
          >
            <template #default>
              <DxSelectBox
                :data-source="companyData"
                :show-clear-button="true"
                display-expr="name"
                value-expr="id"
                :value="
                  getEmployeeStatus == 'edit' ? getEmployee.company_id : null
                "
                :searchEnabled="true"
                @value-changed="CompanySelectedChanged"
              ></DxSelectBox>
            </template>
          </DxSimpleItem>

          <DxSimpleItem
            data-field="location_office_id"
            :label="{ text: 'Lokasi Perusahaan' }"
          >
            <template #default>
              <DxSelectBox
                :data-source="officeLocation"
                :show-clear-button="true"
                display-expr="name"
                value-expr="val"
                :value="
                  getEmployeeStatus == 'edit'
                    ? getEmployee.location_office_id
                    : null
                "
                :searchEnabled="true"
                @value-changed="OfficeLocationSelectedChanged"
              ></DxSelectBox>
            </template>
          </DxSimpleItem>

          <DxSimpleItem
            data-field="company_branch_id"
            :label="{ text: 'Nama Perusahaan Cabang' }"
          >
            <template #default>
              <DxSelectBox
                :data-source="companybranchData"
                :show-clear-button="true"
                display-expr="branch_name"
                value-expr="id"
                :value="
                  getEmployeeStatus == 'edit'
                    ? getEmployee.company_branch_id
                    : null
                "
                :searchEnabled="true"
                @value-changed="CompanyBranchSelectedChanged"
              ></DxSelectBox>
            </template>
          </DxSimpleItem>

          <DxSimpleItem
            data-field="photo_path"
            :label="{ text: 'Foto Karyawan' }"
          >
            <template #default>
              <!-- <form @submit="formSubmit" enctype="multipart/form-data"> -->
              <!-- <input type="file" class="form-control" v-on:change="onFileChange"> -->
              <!-- <input type="file" @change="onFileChange" /> -->
              <!-- <input
                  type="file"
                  class="form-control"
                  @change="UploadFileSelectedChanged"
                /> -->
              <!-- <img src="" height="100" alt="Image preview..." /> -->
              <!-- <br /><span class="note">Max file size: <span>1 MB</span></span
                ><br />
                <span class="note"
                  >Allowed file extensions: <span>.pdf</span></span
                > -->
              <!-- <button class="btn btn-success">Submit</button> -->
              <image-uploader
                :preview="true"
                :quality="0.5"
                :maxWidth="300"
                :maxHeight="300"
                :className="['fileinput', { 'fileinput--loaded': hasImage }]"
                capture="environment"
                :debug="1"
                doNotResize="gif"
                :autoRotate="true"
                outputFormat="verbose"
                @input="setImage"
              ></image-uploader>
              <!-- </form> -->
            </template>
          </DxSimpleItem>
         
        </DxGroupItem>

        <JobPositionData @jobPositionData="setJobPositionData" />
        <DepartmentData @departmentData="setDepartmentData" />
        <ProvinceData @provinceData="setProvinceData" />
        <CityData @cityData="setCityData" />
        <CompanyData @companyData="setCompanyData" />
        <CompanyBranchData @companybranchData="setCompanyBranchData" />
      </DxForm>
    </div>
  </div>
</template>
<script>
// import { DxSelectBox } from "devextreme-vue/select-box";
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from "devextreme-vue/form";
import { mapGetters, mapActions } from "vuex";
import JobPositionData from "@/components/job-position/JobPositionData.vue";
import DepartmentData from "@/components/department/DepartmentListData.vue";
import ProvinceData from "@/components/map-province/ProvinceData.vue";
import CityData from "@/components/map-city/CityData.vue";
import CompanyData from "@/components/company/CompanyData.vue";
import CompanyBranchData from "@/components/company-branch/CompanyBranchData.vue";
import { DxSelectBox } from "devextreme-vue/select-box";
import ImageUploader from "vue-image-upload-resize";

import axios from "axios";

// axios.defaults.withCredentials = false;
const formRef = "user-form";
// const reader = new FileReader();
const today = new Date();
// const vdate = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
const vtime =
  today.getHours() + "_" + today.getMinutes() + "_" + today.getSeconds();
export default {
  components: {
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    DxSelectBox,
    DxRequiredRule,
    JobPositionData,
    DepartmentData,
    ProvinceData,
    CityData,
    CompanyData,
    CompanyBranchData,
    ImageUploader,
  },
  data: function () {
    return {
      formRef,
      cityData2: [],
      TargetTypeData: [],
      vEmployeeData: [],
      cityData: null,
      provinceData: null,
      companyData: null,
      companybranchData: null,
      branchVisible: false,
      jobPositionData: null,
      departmentData: null,
      statuskaryawan: ["Aktif", "Tidak Aktif"],
      userId: null,
      officeLocation: [
        { name: "Pusat", val: 1 },
        { name: "Cabang", val: 2 },
      ],
      imgPath: "",
      imgFile: null,
      imgBase64: "",
      cImgName: "",

      scale: 50,
      quality: 50,
      msg: "Vue Image Upload and Resize Demo",
      hasImage: false,
      image: null,
    };
  },
  created() {
    if (this.getEmployeeStatus == "edit") {
      this.branchVisible = true;
    }
  },
  mounted() {
    // console.log(
    //   "CustomerEmployeeForm : getCustomer = " +
    //     JSON.stringify(this.getCustomer)
    // );
    // console.log(
    //   "CustomerEmployeeForm : getCustomerStatus = " + this.getStatus
    // );
    this.formSet();
  },
  methods: {
    // ...mapActions("customer", ["setForm"]),
    ...mapActions("employee", [
      "setEmployee",
      "setEmployeeForm",
      "setEmployeeStatus",
    ]),
    ///////////////////

    setImage(file) {
      this.hasImage = true;
      this.image = file;
      console.log("file", file);
      console.log("file info", file.info);
      console.log("file name", file.info.name);
      console.log("file type", file.info.type);
      console.log("file Exif", file.exif);

      this.imgBase64 = file.dataUrl;
      // console.log("imgBase64 : " + this.imgBase64);
      var Img64File = file.dataUrl;
      this.imgFile = file;
      this.fileName = file.info.name;
      var arr = this.fileName.split(".");
      var vfile = arr[0];
      var ext = arr[arr.length - 1];
      // this.file = e.target.files[0];
      console.log("uploadFile : ", vfile + "_" + vtime + "." + ext);

      var cUploadFile = vfile + "_" + vtime + "." + ext;
      this.cImgName = vfile + "_" + vtime + "." + ext;

      // console.log("file : ", file);
      // console.log("cUploadFile: ", cUploadFile);
      // console.log("Img64File: ", Img64File);

      this.$emit("uploadFile", file);
      this.$emit("cUploadFile", cUploadFile);
      this.$emit("Img64File", Img64File);

      this.$refs[formRef].instance.updateData({
        photo_path: cUploadFile,
      });
    },

    setSignImage(file) {
      this.hasImage = true;
      this.image = file;
      console.log("file", file);
      console.log("file info", file.info);
      console.log("file name", file.info.name);
      console.log("file type", file.info.type);
      console.log("file Exif", file.exif);

      this.imgBase64 = file.dataUrl;
      // console.log("imgBase64 : " + this.imgBase64);
      var signImg64File = file.dataUrl;
      this.imgFile = file;
      this.fileName = file.info.name;

      var arr = this.fileName.split(".");
      var vfile = arr[0];
      var ext = arr[arr.length - 1];
      // this.file = e.target.files[0];
      console.log("uploadFile : ", vfile + "_" + vtime + "." + ext);

      var signFileName = vfile + "_" + vtime + "." + ext;
      this.cImgName = vfile + "_" + vtime + "." + ext;

      // console.log("file : ", file);
      // console.log("cUploadFile: ", cUploadFile);
      // console.log("Img64File: ", Img64File);

      this.$emit("signFile", file);
      this.$emit("signFileName", signFileName);
      this.$emit("signImg64File", signImg64File);

      this.$refs[formRef].instance.updateData({
        sign_img_path: signFileName,
      });
    },

    setCompanyData(value) {
      this.companyData = value;
    },
    CompanySelectedChanged(e) {
      // console.log("provinceId : " + e.value);
      this.$refs[formRef].instance.updateData({
        company_id: e.value,
      });
    },
    ///////////////////////
    setCompanyBranchData(value) {
      this.companybranchData = value;
    },
    CompanyBranchSelectedChanged(e) {
      // console.log("provinceId : " + e.value);
      this.$refs[formRef].instance.updateData({
        company_branch_id: e.value,
      });
    },
    ///////////////////////
    setRoleData(value) {
      this.RoleData = value;
      // console.log("ljrServiceData = " + this.ljrServiceData);
    },
    RoleSelectedChanged(e) {
      console.log("role_id = " + e.value);
      this.$refs[formRef].instance.updateData({
        role_id: e.value,
      });
    },
    ///////////////////////
    setJobPositionData(value) {
      this.jobPositionData = value;
      // console.log("jobPositionData : " + JSON.stringify(this.jobPositionData));
    },
    JobPositionSelectedChanged(e) {
      // console.log("badanusaha_id : " + e.value);
      this.$refs[formRef].instance.updateData({
        job_position_id: e.value,
      });
    },

    ///////////////////////
    setDepartmentData(val) {
      this.departmentData = val;
      // console.log("jobPositionData : " + JSON.stringify(value));
    },
    DepartmentSelectedChanged(e) {
      // console.log("badanusaha_id : " + e.value);
      this.$refs[formRef].instance.updateData({
        department_id: e.value,
      });
    },

    ///////////////////////
    OfficeLocationSelectedChanged(e) {
      // console.log("OfficeLocation : " + e.value);
      this.$refs[formRef].instance.updateData({
        location_office_id: e.value,
      });
      if (e.value == 2) {
        this.branchVisible = true;
      } else {
        this.branchVisible = false;
      }
    },
    setProvinceData(value) {
      this.provinceData = value;
    },
    ProvinceSelectedChanged(e) {
      // console.log("provinceId : " + e.value);
      this.$refs[formRef].instance.updateData({
        province_id: e.value,
      });
      var $province_id = e.value;
      console.log("province_id : " + $province_id);
      this.getCityByProvinceId($province_id);
    },

    getCityByProvinceId($province_id) {
      let currentObj = this;
      axios
        .get("master_cities/provinceid/" + $province_id)
        .then(function (response) {
          currentObj.cityData = response.data.data;
          // console.log("cityData2 : " + JSON.stringify(currentObj.cityData2));
        })
        .catch(function (error) {
          console.log(error);
          currentObj.output = error;
        });
    },

    setCityData(value) {
      // this.cityData = value;
      if (this.setEmployeeStatus == "edit") {
        this.cityData2 = value;
      } else {
        this.cityData = value;
      }
    },

    CitySelectedChanged(e) {
      this.$refs[formRef].instance.updateData({
        city_id: e.value,
      });
    },

    formFieldDataChanged(e) {
      this.setEmployee(e.component.option("formData"));
    },
    formSet() {
      this.setEmployeeForm(this.$refs[formRef].instance);
    },
  },
  computed: {
    ...mapGetters("employee", [
      "getEmployee",
      "getEmployeeStatus",
      "getEmployeeStatus",
    ]),
  },
};
</script>
<style>
.group-padding {
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.form-block {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  padding-left: 40px;
  padding-right: 40px;
}
</style>

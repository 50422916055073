<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <DxForm
        @field-data-changed="formFieldDataChanged"
        :form-data="getMasterMenu"
        :ref="formRef"
        :read-only="false"
        :visible="true"
      >
        <DxGroupItem :col-count="2" css-class="group-padding">
          <DxGroupItem>
            <DxSimpleItem
              data-field="menu_text"
              :label="{ text: 'Nama Menu' }"
              :editor-options="{ maxLength: '50' }"
            />
            <DxSimpleItem
              data-field="menu_icon"
              :label="{ text: 'Menu Icon' }"
              :editor-options="{ maxLength: '50' }"
            />
            <DxSimpleItem
              data-field="menu_path"
              :label="{ text: 'Menu Path' }"
              :editor-options="{ maxLength: '50' }"
            />

            <DxSimpleItem
              data-field="level"
              :label="{ text: 'level' }"
              :editor-options="{ maxLength: '50' }"
            />
            <!-- <DxSimpleItem
              data-field="parent_id"
              :label="{ text: 'Parent Menu' }"
              :editor-options="{ maxLength: '50' }"
            /> -->
            <DxSimpleItem
              data-field="parent_id"
              :label="{ text: 'Parent Name' }"
            >
              <template #default>
                <DxSelectBox
                  :data-source="menuData"
                  :show-clear-button="true"
                  display-expr="menu_text"
                  value-expr="id"
                  :searchEnabled="true"
                  @value-changed="MenuSelectedChanged"
                ></DxSelectBox>
              </template>
            </DxSimpleItem>
          </DxGroupItem>
        </DxGroupItem>
      </DxForm>
      <MenuData @menuData="setMenuData" :level="1" />
    </div>
  </div>
</template>
<script>
import MenuData from "@/components/master-menu/MasterMenuData.vue";
import { DxForm, DxSimpleItem, DxGroupItem } from "devextreme-vue/form";
// import DxTextArea from "devextreme-vue/text-area";
import { mapGetters, mapActions } from "vuex";
import { DxSelectBox } from "devextreme-vue/select-box";
const formRef = "customer-form";

export default {
  components: {
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    DxSelectBox,
    // DxTextArea,
    MenuData,
  },
  data: function () {
    return {
      formRef,
      menuData: null,
      MenuData: null,
    };
  },

  mounted() {
    this.formSet();
  },
  methods: {
    ...mapActions("mastermenu", ["setMasterMenu", "setMasterMenuForm"]),

    formFieldDataChanged(e) {
      this.setMasterMenu(e.component.option("formData"));
    },
    formSet() {
      this.setMasterMenuForm(this.$refs[formRef].instance);
    },
    /////////////////////////////////////
    setMenuData(value) {
      this.menuData = value;
    },
    MenuSelectedChanged(e) {
      this.$refs[formRef].instance.updateData({
        menu_id: e.value,
      });
    },
    /////////////////////////////////////
  },
  computed: {
    ...mapGetters("mastermenu", ["getMasterMenu", "getMasterMenuStatus"]),
  },
};
</script>
<style>
.group-padding {
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.form-block {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  padding-left: 40px;
  padding-right: 40px;
}
</style>

const state = {
  RoleMenu: {},
  RoleMenuStatus: null,
  RoleMenuForm: null,
};

const getters = {
  getRoleMenu: (state) => {
    return state.RoleMenu;
  },
  getRoleMenuStatus: (state) => {
    return state.RoleMenuStatus;
  },
  getRoleMenuForm: (state) => {
    return state.RoleMenuForm;
  },
};

const mutations = {
  setRoleMenu: (state, RoleMenu) => {
    state.RoleMenu = RoleMenu;
  },
  clearRoleMenu: (state) => {
    state.RoleMenu = {};
  },

  setRoleMenuStatus: (state, RoleMenuStatus) => {
    state.RoleMenuStatus = RoleMenuStatus;
  },
  clearRoleMenuStatus: (state) => {
    state.RoleMenuStatus = null;
  },

  setRoleMenuForm: (state, RoleMenuForm) => {
    state.RoleMenuForm = RoleMenuForm;
  },
  clearRoleMenuForm: (state) => {
    state.RoleMenuForm = null;
  },
};

const actions = {
  setRoleMenu({ commit }, RoleMenu) {
    commit("setRoleMenu", RoleMenu);
  },
  clearRoleMenu({ commit }) {
    commit("clearRoleMenu");
  },
  setRoleMenuStatus({ commit }, RoleMenuStatus) {
    commit("setRoleMenuStatus", RoleMenuStatus);
  },
  clearRoleMenuStatus({ commit }) {
    commit("clearRoleMenuStatus");
  },
  setRoleMenuForm({ commit }, RoleMenuForm) {
    commit("setRoleMenuForm", RoleMenuForm);
  },
  clearRoleMenuForm({ commit }) {
    commit("clearRoleMenuForm");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

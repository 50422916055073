<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <DxForm
        @field-data-changed="formFieldDataChanged"
        :form-data="
          getStockDestroyStatus == 'edit' ||
          getStockDestroyStatus == 'addFromStockIn'
            ? getStockDestroy
            : null
        "
        :ref="formRef"
        :col-count="2"
      >
        <DxGroupItem>
          <DxSimpleItem
            data-field="user_id"
            :visible="false"
            :editor-options="{ value: userId }"
          />
          <DxSimpleItem
            data-field="uuid"
            :visible="false"
            :editor-options="{
              value: currentUUid,
            }"
          />
          <DxSimpleItem
            data-field="warehouse_id"
            :label="{ text: 'warehouse_id' }"
            :visible="false"
            :editor-options="{
              disabled: true,
            }"
          />
          <DxSimpleItem
            data-field="warehouse_name"
            :label="{ text: 'warehouse names' }"
            :visible="true"
            :editor-options="{
              disabled: true,
            }"
          />
          <DxSimpleItem
            data-field="transaction_number"
            :label="{ text: 'No Transaksi' }"
            :visible="true"
            :editor-options="{
              disabled: true,
            }"
          />

          <!-- <DxSimpleItem data-field="item_id" :label="{ text: 'Nama Item' }">
            <DxRequiredRule message="Nama Item Wajib di Isi" />
          </DxSimpleItem> -->

          <DxSimpleItem
            data-field="item_id"
            :label="{ text: 'Pilih Nama Item' }"
            :visible="false"
            :editor-options="{
              disabled: true,
            }"
          />

          <DxSimpleItem
            data-field="item_name"
            :label="{ text: 'Nama Item' }"
            :editor-options="{
              disabled: true,
            }"
          />
          <DxSimpleItem
            data-field="qty"
            editor-type="dxNumberBox"
            :editor-options="{
              disabled: true,
            }"
            :label="{ text: 'Jumlah' }"
          >
            <DxRequiredRule message="Jumlah Wajib di Isi" />
          </DxSimpleItem>

          <DxSimpleItem
            data-field="price"
            editor-type="dxNumberBox"
            :visible="false"
            :editor-options="{
              max: '9999999',
              disabled: true,
            }"
            :label="{ text: 'Harga' }"
          />
        </DxGroupItem>
        <DxGroupItem>
          <DxSimpleItem
            data-field="batch_number"
            :label="{ text: 'Batch number' }"
            :editor-options="{
              disabled: true,
            }"
          />

          <DxSimpleItem
            data-field="item_condition"
            :label="{ text: 'Pilih Kondisi Item' }"
            :visible="false"
            :editor-options="{
              disabled: true,
            }"
          />

          <DxSimpleItem
            data-field="item_condition_name"
            :label="{ text: 'Kondisi Item' }"
            :editor-options="{
              disabled: true,
            }"
          />

          <DxSimpleItem
            data-field="expired_date"
            :label="{ text: 'Tanggal Expired' }"
            editor-type="dxDateBox"
            :editor-options="{
              disabled: true,
              dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ss'
            }"
          />
        </DxGroupItem>
      </DxForm>
    </div>
  </div>
</template>
<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from "devextreme-vue/form";
// import {
//   DxDataGrid,
//   DxPager,
//   DxPaging,
//   DxSelection,
//   DxColumn,
//   DxFilterRow,
// } from "devextreme-vue/data-grid";
// import CustomStore from "devextreme/data/custom_store";
// import StockDestroyService from "@/services/StockDestroyService";
import DxTextArea from "devextreme-vue/text-area";
import { mapGetters, mapActions } from "vuex";
// import DxSelectBox from "devextreme-vue/select-box";
import { uuid } from "vue-uuid";
import axios from "axios";
const formRef = "StockDestroyDetail-form";
const gridRef = "gridRef";
export default {
  components: {
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    // eslint-disable-next-line
    DxTextArea,
    DxRequiredRule,
    // DxSelectBox,
    // DxDataGrid,
    // DxPager,
    // DxPaging,
    // DxSelection,
    // DxColumn,
    // DxFilterRow,
  },
  data: function () {
    return {
      userId: null,
      filterStatus: false,
      gridRef,
      // dataSource: dataSource,
      pageSizes: [5, 10, 15],
      selectedRowKeys: [],
      loadPanel: {
        text: "Memuat...",
      },
      formRef,
      GetSourceType: [],
      GetWarehouse: [],
      GetOrigin: [],
      GetWarehouseDest: [],
      GetItem: [],
      GetItemCondition: [],
      uuid: uuid.v1(),
    };
  },
  created() {
    this.userId = window.localStorage.getItem("usrid");
    this.currentUUid = this.uuid;

    let currentUrl = window.location.origin;

    if (currentUrl.includes("localhost")) {
      axios.defaults.baseURL = "http://127.0.0.1:84/api/";
      // axios.defaults.baseURL = "http://bewms.local:10080/api/";
    } else if (currentUrl.includes("10.1.10.21")) {
      axios.defaults.baseURL = "http://10.1.10.21:84/api/";
    } else {
      axios.defaults.baseURL = process.env.VUE_APP_AUTH_API_URL;
    }

    axios
      .get( "master_items")
      .then((response) => {
        this.GetItem = response.data.data;
      });
    axios
      .get( "item_conditions")
      .then((response) => {
        //console.log(JSON.stringify(response.data));
        this.GetItemCondition = response.data.data;
      });
  },
  mounted() {
    this.formSet();
  },
  methods: {
    ...mapActions("stockdestroy", [
      "setStockDestroy",
      "clearStockDestroy",
      "setStockDestroyStatus",
      "clearStockDestroyStatus",
      "setStockDestroyForm",
    ]),

    valueChangedItem(e) {
      this.$refs[formRef].instance.updateData({
        item_id: e.value,
      });
    },
    valueChangedItemCondition(e) {
      this.$refs[formRef].instance.updateData({
        item_condition_id: e.value,
      });
    },
    formFieldDataChanged(e) {
      console.log(JSON.stringify(e.component.option("formData")));
      this.setStockDestroy(e.component.option("formData"));
    },
    formSet() {
      this.setStockDestroyForm(this.$refs[formRef].instance);
    },
  },

  computed: {
    ...mapGetters("stockdestroy", [
      "getStockDestroy",
      "getStockDestroyStatus",
      "getStockDestroyForm",
    ]),
  },
};
</script>
<style>
.group-padding {
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.form-block {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  padding-left: 40px;
  padding-right: 40px;
}
</style>

<template>
  <div id="DeptForm">
    <h2 class="content-block">
      {{ getStockInDetailStatus == "add" ? "Tambah" : "Ubah" }}
      Stock In Detail
    </h2>
    <DxToolbar class="padding-block">
      <DxItem
        :options="backButtonOptions"
        widget="dxButton"
        location="before"
      />
      <DxItem
        :options="saveButtonOptions"
        widget="dxButton"
        location="before"
        :visible="saveStatus"
      />
    </DxToolbar>
    <StockInDetailForm @save_status="setSaveStatus" />
    <span v-html="linebreaks"></span>
    <DxToolbar class="padding-block">
      <DxItem
        :options="backButtonOptions"
        widget="dxButton"
        location="before"
      />
      <DxItem
        :options="saveButtonOptions"
        widget="dxButton"
        location="before"
        :visible="saveStatus"
      />
    </DxToolbar>
    <DxLoadPanel
      :position="{ of: '#mainContent' }"
      :visible.sync="loadingVisible"
      :show-indicator="true"
      :show-pane="true"
      :shading="true"
      :close-on-outside-click="false"
      shading-color="rgba(0,0,0,0.4)"
    />
  </div>
</template>
<script>
import StockInDetailForm from "@/components/stock-in-detail/StockInDetailForm.vue";
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import { DxLoadPanel } from "devextreme-vue/load-panel";
import router from "../../router";
import { mapGetters, mapActions } from "vuex";
import { formatDate } from "devextreme/localization";
import StockInDetailService from "@/services/StockInDetailService";
import moment from "moment";
import axios from "axios";
// import { uuid } from 'vue-uuid';

export default {
  components: {
    DxToolbar,
    DxItem,
    StockInDetailForm,
    DxLoadPanel,
  },
  data() {
    return {
      linebreaks: "<br>",
      loadingVisible: false,
      saveStatus: true,
      backButtonOptions: {
        icon: "chevronleft",
        text: "Kembali",
        onClick: () => {
          this.$router.go(-1);
        },
      },
      saveButtonOptions: {
        icon: "save",
        text: "Simpan",
        onClick: () => {
          // eslint-disable-next-line no-console
          var validateResult = this.getStockInDetailForm.validate();
          if (validateResult.isValid) {
            // var StockInDetail = {
            //   name : this.getStockInDetail.name,
            //   shortName : this.getStockInDetail.shortName,
            //   // uniqueId : uuid.v4(),
            //   description : this.getStockInDetail.description
            // };
            this.userId = window.localStorage.getItem("usrid");
            var vThis = this;
            var StockInDetail = this.getStockInDetail;

            if (StockInDetail) {
              if (this.getStockInDetailStatus == "add") {
                console.log("Add StockInDetail ");
                this.loadingVisible = true;
                StockInDetail.created_by = this.userId;
                var expired_date = StockInDetail.expired_date;

                //code ini untuk menjaga tanggal tidak berubah pada waktu konversi ke format JSON
                var os = expired_date.getTimezoneOffset();
                expired_date = new Date(
                  expired_date.getTime() - os * 60 * 1000
                );
                expired_date = formatDate(expired_date, "yyyy/MM/dd HH:mm");
                console.log("expired_date : " + expired_date);
                // StockInDetail.transaction_number = this.format_date(new Date());
                // input data ke tabel t_stock_report dahulu
                let formData = new FormData();
                formData.append("warehouse_id", StockInDetail.warehouse_id);
                formData.append("uuid", StockInDetail.uuid);
                formData.append(
                  "transaction_number",
                  StockInDetail.transaction_number
                );
                formData.append("stock_action", 110); // inbound-StockInDetail
                formData.append("batch_number", StockInDetail.batch_number);
                formData.append("item_id", StockInDetail.item_id);
                formData.append("qty", StockInDetail.qty);
                formData.append(
                  "item_condition_id",
                  StockInDetail.item_condition_id
                );
                formData.append("remark", "stockin");
                formData.append("expired_date", expired_date);
                axios
                  .post("stock_reports", formData)
                  .then(function (response) {
                    console.log(response);
                    vThis.success = response.data.success;
                  })
                  .catch(function (error) {
                    console.log(error);
                    vThis.output = error;
                  });
                // selanjutanya input data ke tabel t_stockin_detail
                StockInDetailService.insert(
                  StockInDetail,
                  function () {
                    vThis.loadingVisible = false;
                    vThis.clearStockInDetailStatus();
                    vThis.clearStockInDetail();
                    // router.push("/stock/in/detail");
                    router.push("/stock/in");
                  },
                  function () {
                    vThis.loadingVisible = false;
                  }
                );
              } else if (this.getStockInDetailStatus == "edit") {
                console.log("Edit StockInDetail StockInDetail ");
                StockInDetail.updated_by = this.userId;

                const param = {
                  warehouse_id: StockInDetail.warehouse_id,
                  uuid: StockInDetail.uuid,
                  transaction_number: StockInDetail.transaction_number,
                  stock_action: StockInDetail.stock_action,
                  batch_number: StockInDetail.batch_number,
                  item_id: StockInDetail.item_id,
                  qty: StockInDetail.qty,
                  item_condition_id: StockInDetail.item_condition_id,
                  remark: StockInDetail.remark,
                  expired_date: StockInDetail.expired_date,
                };

                vThis.updateReport(StockInDetail.uuid, param);

                StockInDetailService.update(
                  this.getStockInDetail.id,
                  StockInDetail,
                  function () {
                    vThis.loadingVisible = false;
                    vThis.clearStockInDetailStatus();
                    vThis.clearStockInDetail();
                    // router.push("/stock/in/detail");
                    router.push("/stock/in");
                  },
                  function () {
                    vThis.loadingVisible = false;
                  }
                );
              }
            }
          }
        },
      },
    };
  },
  methods: {
    ...mapActions("stockindetail", [
      "setStockInDetail",
      "clearStockInDetail",
      "setStockInDetailStatus",
      "clearStockInDetailStatus",
    ]),
    setSaveStatus(value) {
      console.log("setSaveStatus = " + value);
      this.saveStatus = value;
    },
    async updateReport(uuid, param) {
      // this.vReportId = this.getVehicleRepair.vehicle_report_id;
      var vThis = this;
      // var vuuid = uuid;

      /////////////////////////
      try {
        const response1 = await axios.get("stock_reports?uuid=" + uuid);
        var reportId = JSON.stringify(response1.data.data[0].id);
        console.log(
          "report_id : " +
            JSON.stringify("reportId :" + response1.data.data[0].id)
        );
      } catch (error) {
        console.error(error);
      }
      /////////////////////////
      try {
        const response2 = await axios.put("stock_reports/" + reportId, param);
        console.log(response2);
        vThis.success = response2.data.success;
      } catch (error) {
        console.error(error);
      }

      // const param = { report_status: "In Repair" };
      // const patchresult = await axios.patch(
      //   "vehicle_damage_reports/" + reportId,
      //   param
      // );
      // console.log("patchresult : " + JSON.stringify(patchresult));
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYYMMDDHHmmss");
      }
    },
  },
  computed: {
    ...mapGetters("stockindetail", [
      "getStockInDetail",
      "getStockInDetailStatus",
      "getStockInDetailForm",
    ]),
  },
};
</script>

<style>
.toolbar-label,
.toolbar-label > b {
  font-size: 16px;
}

#products {
  margin-top: 10px;
}

.padding-block {
  padding-left: 20px;
  padding-right: 20px;
}
</style>
<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <DxForm
        @field-data-changed="formFieldDataChanged"
        :form-data="getStockQuarantine ? getStockQuarantine : null"
        :ref="formRef"
        :col-count="2"
      >
        <DxGroupItem>
          <DxSimpleItem
            data-field="user_id"
            :visible="false"
            :editor-options="{ value: userId }"
          />
          <DxSimpleItem
            data-field="uuid"
            :visible="false"
            :editor-options="{
              value: this.getStockQuarantine
                ? this.getStockQuarantine.uuid
                : null,
            }"
          />
          <DxSimpleItem
            data-field="warehouse_id"
            :label="{ text: 'warehouse_id' }"
            :visible="false"
            :editor-options="{
              value: this.getStockQuarantineTemp,
            }"
          />
          <!-- <DxSimpleItem
            data-field="transaction_number"
            :label="{ text: 'No Transaksi' }"
            :visible="true"
            :editor-options="{
              disabled: true,
              value: this.getStockQuarantineTrxNum[0].transaction_number,
            }"
         /> -->

          <DxSimpleItem
            data-field="item_name"
            :label="{ text: 'Nama Item' }"
            :visible="true"
            :editor-options="{
              disabled: true,
              value: this.getStockQuarantine
                ? this.getStockQuarantine.item_name
                : null,
            }"
          />
          <DxSimpleItem
            data-field="batch_number"
            :label="{ text: 'Batch number' }"
            :visible="true"
            :editor-options="{
              disabled: true,
              value: this.getStockQuarantine
                ? this.getStockQuarantine.batch_number
                : null,
            }"
          />
          <DxSimpleItem
            data-field="reference_number"
            :label="{ text: 'Reference Number' }"
            :visible="true"
            :editor-options="{
              value: this.getStockQuarantine
                ? this.getStockQuarantine.reference_number
                : null,
            }"
          />
          <DxSimpleItem
            data-field="item_id"
            :label="{ text: 'item_id' }"
            :visible="false"
            :editor-options="{
              disabled: true,
              value: this.getStockQuarantine
                ? this.getStockQuarantine.item_id
                : null,
            }"
          />

          <DxSimpleItem
            data-field="item_condition_id"
            :label="{ text: 'Pilih Kondisi Item' }"
            :visible="false"
            :editor-options="{
              disabled: true,
              value: this.getStockQuarantine
                ? this.getStockQuarantine.item_condition_id
                : null,
            }"
          />
        </DxGroupItem>

        <DxGroupItem>
          <DxSimpleItem
            data-field="expired_date"
            :label="{ text: 'Tanggal Expired' }"
            editor-type="dxDateBox"
            :editor-options="{
              disabled: true,
              dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ss',
              value: this.getStockQuarantine
                ? this.getStockQuarantine.expired_date
                : null,
            }"
          />

          <DxSimpleItem
            data-field="last_item_qty"
            :label="{ text: 'Stok Tersedia' }"
            :editor-options="{
              disabled: true,
              value: this.getStockQuarantine
                ? this.getStockQuarantine.total_item_qty_by_batchnum
                : null,
            }"
            editor-type="dxNumberBox"
          />

          <DxSimpleItem
            data-field="qty"
            :label="{ text: 'Jumlah item yang dikirim' }"
            :visible="false"
            :editor-options="{
              value: this.getStockQuarantine
                ? this.getStockQuarantine.qty
                : null,
            }"
          >
            <template #default>
              <DxNumberBox
                :read-only="false"
                @value-changed="valueChangedQty"
                :value="getStockQuarantine ? getStockQuarantine.qty : null"
              >
                <DxValidator>
                  <DxCustomRule
                    :validation-callback="validateNumber"
                    message="Stok keluar lebih besar dari persediaan"
                  />
                </DxValidator>
              </DxNumberBox>
            </template>
            <DxRequiredRule message="Jumlah Item yang akan dikirim di Isi" />
          </DxSimpleItem>

          <DxSimpleItem
            data-field="free"
            :label="{ text: 'Free' }"
            editor-type="dxNumberBox"
            :visible="false"
          >
          </DxSimpleItem>
        </DxGroupItem>
      </DxForm>
      <span v-html="linebreaks"></span>
      <div class="buttons-demo">
        <div class="buttons">
          <div>
            <div class="buttons-offering-letter">
              <div>
                <DxButton
                  :width="500"
                  text="Pilih Item"
                  type="normal"
                  styling-mode="contained"
                  @click="onClickShowItem()"
                />
              </div>
            </div>
            <div class="buttons-offering-letter">
              <div>
                <DxButton
                  :width="500"
                  text="Tambah List"
                  type="normal"
                  styling-mode="contained"
                  @click="onClickAddItemToList()"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="content-block dx-card responsive-paddings">
      <dx-data-grid
        class="dx-card wide-card"
        :data-source="tempData"
        :show-borders="true"
        :focused-row-index="0"
        :focused-row-enabled="true"
        :column-auto-width="true"
        :column-hiding-enabled="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :ref="gridRef"
        @toolbar-preparing="onToolbarPreparing($event)"
      >
        <dx-paging :page-size="10" />
        <dx-pager
          :showPageSizeSelector="true"
          :allowed-page-sizes="pageSizes"
          :show-info="true"
          :showNavigationButtons="true"
          infoText="Hal {0} dari {1} ({2} items)"
          :visible="true"
        />
        <dx-filter-row :visible="filterStatus" />
        <DxEditing :allow-deleting="true" mode="cell" />
        <DxSearchPanel :visible="true" />
        <DxSelection mode="single" />
        <dx-paging :page-size="10" />
        <dx-pager
          :showPageSizeSelector="true"
          :allowed-page-sizes="pageSizes"
          :show-info="true"
          :showNavigationButtons="true"
          infoText="Hal {0} dari {1} ({2} items)"
          :visible="true"
        />
        <dx-filter-row :visible="filterStatus" />
        <DxSelection mode="single" />
        <DxColumn
          data-field="no"
          caption="No"
          :width="70"
          cell-template="nomor"
        />
        <template #nomor="{ data }">
          {{ data.rowIndex + 1 }}
        </template>
        <!-- <DxColumn
          data-field="transaction_number"
          width="200"
          caption="No Transaksi"
        /> -->
        <DxColumn
          data-field="uuid"
          width="200"
          :visible="false"
          caption="uuid"
        />
        <DxColumn data-field="item_name" width="200" caption="Nama Item" />
        <DxColumn data-field="qty" caption="Jumlah" />
        <DxColumn data-field="batch_number" caption="Batch Number" />
        <DxColumn data-field="reference_number" caption="Ref Number" />
        <!-- <DxColumn data-field="item_condition_name" caption="Kondisi" /> -->
        <DxColumn
          data-field="expired_date"
          dataType="date"
          format="dd/MM/yyyy"
          caption="Tanggal Expired"
        />
      </dx-data-grid>
    </div>

    <!-- ShowItem -->
    <DxPopup
      :visible="popupShowItem"
      :drag-enabled="false"
      :show-title="true"
      :width="1000"
      :height="680"
      :showCloseButton="false"
      title="Pilih Item"
    >
      <dx-data-grid
        class="dx-card wide-card"
        :data-source="GetItemList"
        :show-borders="true"
        :focused-row-index="0"
        :focused-row-enabled="true"
        :column-auto-width="true"
        :column-hiding-enabled="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        @selection-changed="onSelectionChangedItem"
      >
        <dx-paging :page-size="5" />
        <dx-pager
          :showPageSizeSelector="true"
          :allowed-page-sizes="pageSizes"
          :show-info="true"
          :showNavigationButtons="true"
          infoText="Hal {0} dari {1} ({2} items)"
          :visible="true"
        />
        <dx-filter-row :visible="filterStatus" />
        <DxSearchPanel :visible="true" />
        <DxSelection mode="single" />
        <DxColumn
          data-field="no"
          caption="No"
          :width="70"
          cell-template="nomor"
        />
        <template #nomor="{ data }">
          {{ data.rowIndex + 1 }}
        </template>
        <DxColumn data-field="item_id" :visible="false" />
        <DxColumn data-field="item_name" width="300" caption="Nama Item" />
        <DxColumn
          data-field="total_item_qty_by_batchnum"
          width="150"
          caption="Jumlah"
        />
        <DxColumn data-field="item_condition_id" :visible="false" />
        <DxColumn data-field="item_condition_name" caption="Kondisi" />
        <DxColumn data-field="batch_number" caption="batch number" />
        <DxColumn
          data-field="expired_date"
          dataType="date"
          format="dd/MM/yyyy"
          caption="Tanggal Expired"
        />
      </dx-data-grid>
      <span v-html="linebreaks"></span>

      <DxButton
        :width="120"
        text="Close"
        type="default"
        styling-mode="contained"
        @click="onButtonCloseClick($event)"
      />
    </DxPopup>
  </div>
</template>
<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from "devextreme-vue/form";
import {
  DxDataGrid,
  DxPager,
  DxPaging,
  DxSelection,
  DxColumn,
  DxFilterRow,
  // DxExport,
  DxSearchPanel,
  // DxFormItem,
  DxEditing,
  // DxLookup,
} from "devextreme-vue/data-grid";
// import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import DxTextArea from "devextreme-vue/text-area";
import { mapGetters, mapActions } from "vuex";
// import DxSelectBox from "devextreme-vue/select-box";
// import { DxTextBox } from "devextreme-vue/text-box";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import DxNumberBox from "devextreme-vue/number-box";
import DxButton from "devextreme-vue/button";
import { DxPopup } from "devextreme-vue/popup";
import DxValidator, { DxCustomRule } from "devextreme-vue/validator";
import { uuid } from "vue-uuid";
import router from "../../router";
import { confirm } from "devextreme/ui/dialog";
import moment from "moment";
import notify from "devextreme/ui/notify";
import axios from "axios";
const formRef = "StockQuarantineDetail-form";
const gridRef = "gridRef";
export default {
  components: {
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    // eslint-disable-next-line
    DxTextArea,
    DxRequiredRule,
    // DxSelectBox,

    DxValidator,
    DxCustomRule,
    DxNumberBox,

    DxDataGrid,
    DxPager,
    DxPaging,
    DxSelection,
    DxColumn,
    DxFilterRow,
    DxSearchPanel,
    // DxLookup,
    // DxFormItem,
    DxEditing,
    // DxExport,
    DxButton,
    DxPopup,
    // DxItem,
    // DxToolbar,
    // DxItem,
    // ItemDropDownBoxComponent,
  },
  props: {
    item_id: {
      type: Number,
      default: null,
    },
  },
  data: function () {
    return {
      userId: null,
      filterStatus: false,
      gridRef,
      vShow: "none",
      // dataSource: dataSource,
      pageSizes: [5],
      selectedRowKeys: [],
      loadPanel: {
        text: "Memuat...",
      },
      formRef,
      saveStatus: false,

      GetSourceType: [],
      GetWarehouse: [],
      GetOrigin: [],
      GetWarehouseDest: [],
      GetItem: [],
      GetItemCondition: [],
      GetBatchNumber: [],
      GetBatchNumberDetail: [],
      dataTempOutBound: [],
      dataFormOutBound: [],
      dataTemp: [],
      tempData: [],
      arrNum: 0,
      ItemData: null,

      uuid: uuid.v1(),
      batch_item_id: null,
      batch_item: null,
      batch_item_name: null,
      batch_actual_qty: null,
      batch_expired_date: null,
      batch_item_condition_id: null,
      batch_item_condition: null,
      GetItemList: [],
      linebreaks: "<br>",

      popupShowItem: false,
      saveButtonOptions: {
        icon: "save",
        text: "Simpan",
      },
      itemButtonOptions: {
        icon: "list2",
        text: "Pilih Item",
      },
      dataNull: [
        {
          no: "N/A",
          transaction_number: "N/A",
          uuid: "N/A",
          item_name: "N/A",
          qty: "N/A",
          batch_number: "N/A",
          reference_number: "N/A",
          item_condition_name: "N/A",
          expired_date: "N/A",
        },
      ],
      // item_send:null,
    };
  },

  created() {
    this.userId = window.localStorage.getItem("usrid");
    this.currentUUid = this.uuid;
    if (this.getStockQuarantineStatus == "edit") {
      this.vShow = "none";
    } else {
      this.vShow = "none";
    }

    let currentUrl = window.location.origin;

    if (currentUrl.includes("localhost")) {
      axios.defaults.baseURL = "http://127.0.0.1:84/api/";
      // axios.defaults.baseURL = "http://bewms.local:10080/api/";
    } else if (currentUrl.includes("10.1.10.21")) {
      axios.defaults.baseURL = "http://10.1.10.21:84/api/";
    } else {
      axios.defaults.baseURL = process.env.VUE_APP_AUTH_API_URL;
    }
  },

  methods: {
    ...mapActions("stockquarantine", [
      "setStockQuarantine",
      "clearStockQuarantine",
      "setStockQuarantineStatus",
      "clearStockQuarantineStatus",
      "setStockQuarantineForm",
      "setStockQuarantineTemp",
      "clearStockQuarantineTemp",
    ]),
    // onValueChanged(value, cellInfo) {
    //   cellInfo.setValue(value);
    //   cellInfo.component.updateDimensions();
    // },

    calculateCellValue() {
      return this.ItemData;
    },
    setItemData(value) {
      console.log("ItemData : " + value);
      this.ItemData = value;
    },
    getBatch() {
      if (this.ItemData) {
        return this.ItemData;
      } else {
        return "halo";
      }
    },

    validateNumber(e) {
      // console.log(
      //   "getStockQuarantine.last_item_qty :" +
      //     this.getStockQuarantine.last_item_qty
      // );
      if (this.getStockQuarantine.last_item_qty - e.value >= 0) {
        this.$emit("save_status", false);
        return this.getStockQuarantine.last_item_qty - e.value >= 0;
      } else {
        this.$emit("save_status", true);
        return this.getStockQuarantine.last_item_qty - e.value >= 0;
      }

      // return this.batch_actual_qty - e.value >= 0;
      // return this.getStockQuarantineTemp.last_item_qty - e.value >= 0;
    },
    valueChangedItem(e) {
      this.$refs[formRef].instance.updateData({
        item_id: e.value,
      });
    },
    valueChangedQty(e) {
      this.$refs[formRef].instance.updateData({
        qty: e.value,
      });
    },
    valueChangedItemCondition(e) {
      this.$refs[formRef].instance.updateData({
        item_condition_id: e.value,
      });
    },

    valueChangedBatchNumber(e) {
      // var batch_num = e.value;
      this.$refs[formRef].instance.updateData({
        batch_number: e.value,
      });
      this.getData(e.value);
      // this.batch_item = e.value;
      // console.log(
      //   "GetBatchNumber.item_name " + JSON.stringify(this.batch_item_name)
      // );
    },

    onClickShowItem() {
      // this.setStockQuarantineForm(this.dataFormOutBound);
      // console.log(
      //   "setStockQuarantineForm : " + JSON.stringify(this.getStockQuarantineForm)
      // );

      this.getItemData();
      this.popupShowItem = true;
      this.getStockQuarantineTemp[0].warehouse_id;
      console.log(
        "warehouse_id :" + this.getStockQuarantineTemp[0].warehouse_id
      );
      // console.log("tempData : " + JSON.stringify(this.tempData));
    },
    onToolbarPreparing(e) {
      var vThis = this;
      e.toolbarOptions.items.unshift({
        location: "before",
        widget: "dxButton",
        options: {
          icon: "save",
          text: "Simpan",
          disabled: false,
          // visible: false,
          onInitialized: function (e) {
            vThis.btnAdd = e.component;
          },
          onClick: function () {
            let result = confirm(
              "Apakah data ini sudah sesuai ?",
              "Proses Data Karantina"
            );
            result.then((dialogResult) => {
              if (dialogResult) {
                vThis.setQuarantineStockJson(vThis.tempData);
              }
            });
          },
        },
      });
    },
    cekTempData() {
      // console.log("tempData : " + this.tempData);
      console.log("this.tempData : " + JSON.stringify(this.tempData));
    },
    onSelectionChangedItem({ selectedRowKeys, selectedRowsData }) {
      // console.log("selectedRowKeys : " + selectedRowKeys.length);
      if (selectedRowKeys.length > 0) {
        if (selectedRowKeys.length == 1) {
          //send data to form
          this.setStockQuarantine(selectedRowsData[0]);
          // console.log("selectedRowsData[0] : " + selectedRowsData[0]);
          // var tes = {};
          this.popupShowItem = false;
          // Object.assign(tes, selectedRowsData[0]);
          // console.log("tes :" + JSON.stringify(tes));
          // this.tempData.push(tes);
          console.log("tempData :" + JSON.stringify(this.tempData));
        } else {
          this.setStockQuarantine(selectedRowsData);
          // console.log(selectedRowsData);
        }
      }
    },

    onClickAddItemToList() {
      var vThis = this;
      var tes = {};
      Object.assign(tes, vThis.getStockQuarantine);
      // console.log("tes :" + JSON.stringify(tes));
      vThis.tempData.push(tes);
      console.log("this.tempData : " + JSON.stringify(vThis.tempData));

      this.$refs[formRef].instance.resetValues();
    },

    async setQuarantineStockJson(outdata) {
      var transaction_number = this.format_trx_date(new Date());
      var tempOutboundData = [];
      var tempOutdata = [];
      var addParam;
      var newId;
      var keyCount = Object.keys(outdata).length;

      // const resWarehouse = await axios.get("warehouses");
      // console.log(
      //   "resWarehouse = " + JSON.stringify(resWarehouse.data.data)
      // );

      // const resItem = await axios.get("master_items");
      // console.log("resItem = " + JSON.stringify(resItem.data.data));

      // this.warehouseIdx = "WRH002";
      for (var n = 0; n < keyCount; n++) {
        newId = uuid.v1(n);
        // console.log("newId = " + newId);

        // let warehouse_code = outdata[n].warehouse_code;
        // let elWarehouse = resWarehouse.data.data.find(
        //   (el) => el.code === warehouse_code
        // );
        // const resWarehouse = await axios.get(
        //   "warehouses?code=" + warehouse_code
        // );
        // this.warehouseIdx = elWarehouse.id;
        let warehouse_id = this.getStockQuarantineTemp[0].warehouse_id;
        // let transaction_number =
        //   this.getStockQuarantineTrxNum[0].transaction_number;
        // let item_code = outdata[n].item_code;
        // let elItem = resItem.data.data.find((el) => el.item_code === item_code);
        // const resItem = await axios.get("master_items?item_code=" + item_code);
        // this.itemIdx = elItem.id;

        let item_id = outdata[n].item_id;
        // let exp_date = this.formatDate(outdata[n].exp_date);
        let expired_date = this.formatDate(outdata[n].expired_date);
        // let received_date = this.format_rcv_date(new Date());
        let quarantine_date = this.format_rcv_date(new Date());

        tempOutdata[n] = outdata[n];
        addParam = {
          user_id: this.userId,
          uuid: newId,
          warehouse_id: warehouse_id,
          item_id: item_id,
          item_condition_id: 1,
          transaction_number: transaction_number,
          stock_action: 210,
          remark: "stock-quarantine",
          quarantine_date: quarantine_date,
          expired_date: expired_date,
        };
        Object.assign(tempOutdata[n], addParam);
        // console.log(
        //   "tempOutdata[" + n + "]: " + JSON.stringify(tempOutdata[n])
        // );

        tempOutboundData.push(tempOutdata[n]);
        console.log("tempOutboundData" + JSON.stringify(tempOutboundData));
        // this.firstStockImport = tempData;
      }
      // this.firstStockImport = tempData;
      var vThis = this;
      axios
        .post("stock_quarantines/post/1", tempOutboundData)
        .then(function (response) {
          console.log(response);
          vThis.success = response.data.success;
        })
        .catch(function (error) {
          console.log(error);
          vThis.output = error;
        });

      axios
        .post("stock_reports/post/1", tempOutboundData)
        .then(function (response) {
          console.log(response);
          vThis.success = response.data.success;
        })
        .catch(function (error) {
          console.log(error);
          vThis.output = error;
        });
      notify("Data Berhasil Disimpan");
      console.log("Finished!");
      router.push("/stock/quarantine");
    },
    format_trx_date(value) {
      if (value) {
        return moment(String(value)).format("YYYYMMDDHHmmss");
      }
    },
    formatDate(numb) {
      const time = new Date((numb - 1) * 24 * 3600000 + 1);
      time.setYear(time.getFullYear() - 70);
      return time;
    },
    format_rcv_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD HH:mm:ss");
      }
    },
    closeOnOutsideClick() {
      // this.popupContactVisible = false;
      this.popupShowItem = false;
      return false;
    },
    onButtonCloseClick() {
      this.popupShowItem = false;
    },
    onRowExpanding(e) {
      var dataGrid = e.component;
      dataGrid.collapseAll(-1);
    },

    // onSelectionTempItemChanged({ selectedRowKeys, selectedRowsData }) {
    //   console.log("selectedRowKeys.length : " + selectedRowKeys.length);
    //   if (selectedRowKeys.length == 1) {
    //     // this.setPublishPrice(selectedRowsData);
    //     this.setStockQuarantineStatus("edit");
    //     this.setStockQuarantine(selectedRowsData[0]);
    //     console.log(
    //       "getStockQuarantine :" + JSON.stringify(this.getStockQuarantine)
    //     );
    //     this.popupShowItem = false;
    //   }
    // },

    async getItemData() {
      try {
        const response = await axios.get(
          "stock_reports/getitemlist/" +
            this.getStockQuarantineTemp[0].warehouse_id
        );
        this.GetItemList = response.data.data;
        console.log(
          "GetItemList " +
            JSON.stringify(this.GetItemList)
        );
      } catch (error) {
        console.error(error);
      }
    },
    async getData(batch_num) {
      try {
        const response = await axios.get(
          "stock_in_details/getdetail/" + batch_num
        );
        this.GetBatchNumberDetail = response.data.data;
        // console.log(
        //   "GetBatchNumberDetail item_name " +
        //     JSON.stringify(this.GetBatchNumberDetail[0].item_name)
        // );
        // console.log(
        //   "GetBatchNumberDetail qty" +
        //     JSON.stringify(this.GetBatchNumberDetail[0].qty)
        // );
        // console.log(
        //   "GetBatchNumberDetail expired_date" +
        //     JSON.stringify(this.GetBatchNumberDetail[0].expired_date)
        // );
        // console.log(
        //   "GetBatchNumberDetail item_condition_name " +
        //     JSON.stringify(this.GetBatchNumberDetail[0].item_condition_name)
        // );
        this.batch_item_id = response.data.data[0].item_id;
        this.batch_item_name = response.data.data[0].item_name;
        // console.log(JSON.stringify(this.batch_item_name));
        this.batch_actual_qty = response.data.data[0].qty;
        // console.log(JSON.stringify(this.batch_actual_qty));
        this.batch_expired_date = response.data.data[0].expired_date;
        // console.log(JSON.stringify(this.batch_expired_date));
        this.batch_item_condition_id = response.data.data[0].item_condition_id;
        this.batch_item_condition = response.data.data[0].item_condition_name;
      } catch (error) {
        console.error(error);
      }
    },

    // async getDataBatchNumber() {
    //   try {
    //     const response = await axios.get(
    //       "stock_in_details/getbatchnumber/" +
    //         this.getStockQuarantineTemp
    //     );
    //     this.GetBatchNumber = response.data.data;
    //     console.log("GetBatchNumber " + JSON.stringify(this.GetBatchNumber));
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },

    getDetailBatchNumber(batch_num) {
      axios
        .get(
          
            "stock_in_details/getdetail/" +
            batch_num
        )
        .then((response) => {
          this.batch_item_name = response.data.data[0].item_name;
          // console.log(JSON.stringify(this.batch_item_name));
          this.batch_actual_qty = response.data.data[0].qty;
          // console.log(JSON.stringify(this.batch_actual_qty));
          this.batch_expired_date = response.data.data[0].expired_date;
          // console.log(JSON.stringify(this.batch_expired_date));
          this.batch_item_condition = response.data.data[0].item_condition_name;
        });
    },

    formFieldDataChanged(e) {
      this.setStockQuarantine(e.component.option("formData"));
      // console.log(
      //   "getStockQuarantineTemp :" + JSON.stringify(this.getStockQuarantineTemp)
      // );
    },
    formSet() {
      this.setStockQuarantineForm(this.$refs[formRef].instance);
    },
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Workbook");
      // const worksheet = workbook
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        topLeftCell: { row: 4, column: 1 },
      })
        .then((cellRange) => {
          // header
          const headerRow = worksheet.getRow(2);
          headerRow.height = 30;
          worksheet.mergeCells(2, 1, 2, 8);

          headerRow.getCell(1).value = "Data Stock Outbound";
          headerRow.getCell(1).font = { name: "Segoe UI Light", size: 22 };
          headerRow.getCell(1).alignment = { horizontal: "left" };

          // footer
          const footerRowIndex = cellRange.to.row + 2;
          const footerRow = worksheet.getRow(footerRowIndex);
          worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 8);

          footerRow.getCell(1).value = "";
          footerRow.getCell(1).font = {
            color: { argb: "BFBFBF" },
            italic: true,
          };
          footerRow.getCell(1).alignment = { horizontal: "right" };
        })
        .then(function () {
          workbook.xlsx.writeBuffer().then(function (buffer) {
            saveAs(
              new Blob([buffer], { type: "application/octet-stream" }),
              "WMS-Data-Stock-Outbound-detail.xlsx"
            );
          });
        });
      e.cancel = true;
    },
  },
  mounted() {
    this.formSet();
    // this.$emit("save_status", 1);
  },

  computed: {
    ...mapGetters("stockquarantine", [
      "getStockQuarantine",
      "getStockQuarantineStatus",
      "getStockQuarantineForm",
      "getStockQuarantineTemp",
    ]),
    dataGrid: function () {
      return this.$refs[gridRef].instance;
    },
    // getBatch() {
    //   if (this.ItemData) {
    //     return this.ItemData;
    //   } else {
    //     return "halo";
    //   }
    // },
  },
  watch: {
    // getBatch() {
    //   if (this.ItemData) {
    //     console.log("ItemData =" + this.ItemData);
    //   } else {
    //     console.log("ItemData = halo");
    //   }
    // },
  },
};
</script>
<style>
.my-title {
  font-size: 30px;
  /* margin-top: 20px; */
  margin-bottom: 20px;
  color: #736f6f;
  font-family: "Helvetica Neue", "Segoe UI", Helvetica, Verdana, sans-serif;
}
.group-padding {
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.form-block {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  padding-left: 40px;
  padding-right: 40px;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  width: 200px;
  float: right;
  margin-right: 120px;
  color: forestgreen;
}
/* .dx-button-content {
  background-color: #4caf50;
  color: rgb(255, 255, 255);
} */
.buttons > div {
  width: 200px;
  flex-wrap: nowrap;
  display: flex;
}

/* .buttons-column > div {
  width: 150px;
  height: 50px;
  text-align: center;
}

.buttons-column {
  width: 150px;
  justify-content: center;
}

.buttons-column > .column-header {
  flex-grow: 0;
  width: 150px;
  height: 35px;
  font-size: 130%;
  opacity: 0.6;
  text-align: center;
  padding: 20px;
}

.buttons-column-custom {
  width: 120px;
  height: 40px;
  padding-left: 10px;
} */
.buttons-offering-letter {
  width: 150px;
  height: 40px;
  padding-left: 10px;
}
/* .buttons-save-outbound {
  width: 150px;
  height: 40px;
  padding-left: 100px;
  float: right;
} */
.buttons-demo {
  /* width: 500px; */
  align-self: center;
}
.buttons-demo {
  /* width: 500px; */
  align-self: center;
}
</style>

<template>
  <div>
    <dx-data-grid
      class="dx-card wide-card"
      :data-source="dataSource"
      :show-borders="true"
      :focused-row-index="0"
      :focused-row-enabled="true"
      :column-auto-width="true"
      :column-hiding-enabled="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :loadPanel="loadPanel"
      :selected-row-keys="selectedRowKeys"
      @toolbar-preparing="onToolbarPreparing($event)"
      @selection-changed="onSelectionChanged"
      @rowExpanding="onRowExpanding"
      @exporting="onExporting"
      :ref="gridRef"
    >
      <dx-paging :page-size="10" />
      <dx-pager
        :showPageSizeSelector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
        :showNavigationButtons="true"
        infoText="Hal {0} dari {1} ({2} items)"
      />
      <dx-filter-row :visible="filterStatus" />
      <DxSelection mode="multiple" />
      <DxColumn data-field="code" caption="Customer Code" />
      <DxColumn data-field="name" caption="Customer Name" />
      <DxColumn data-field="phone" caption="Customer Phone" />
      <DxColumn
        data-field="address"
        caption="Customer Address"
        :width="450"
      />
      <DxColumn data-field="desc" caption="Customer Desc" />
      <DxExport :enabled="true" />
    </dx-data-grid>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxPager,
  DxPaging,
  DxSelection,
  DxColumn,
  DxExport,
  DxFilterRow,
} from "devextreme-vue/data-grid";
import CustomStore from "devextreme/data/custom_store";
import "devextreme/data/odata/store";
import CustomerService from "@/services/CustomerService";
// import Service from "@/services/customerService";
// import DivisionTable from "./DivisionTable.vue";
import router from "../../router";
import { mapGetters, mapActions } from "vuex";
import { confirm } from "devextreme/ui/dialog";

import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
// const gridRef = "departement-table";
// import axios from "axios";

const gridRef = "customer-table";

export default {
  props: ["CustomerType"],
  components: {
    DxDataGrid,
    DxPager,
    DxPaging,
    DxSelection,
    DxColumn,
    DxExport,
    DxFilterRow,
  },

  data() {
    const vthis = this;
    const dataSource = {
      store: new CustomStore({
        key: "id",
        load: async function (loadOptions) {
          var sortedBy = "";
          if (loadOptions.sort) {
            sortedBy += loadOptions.sort[0].selector;
            if (loadOptions.sort[0].desc) {
              sortedBy += " desc";
            } else {
              sortedBy += " asc";
            }
          }
          var perPage = loadOptions.take;
          var offset = loadOptions.skip;
          var page = offset / perPage + 1;

          var datax = vthis.fetch({
            perPage: perPage || 10,
            page: page || 1,
            sortedBy: sortedBy,
            parentId: "null",
          });
          return await datax;
        },
      }),
    };
    return {
      isCustomerVisible: false,

      filterStatus: false,
      filterText: "Filter On",

      showFilterRow: true,
      showHeaderFilter: true,

      gridRef,
      dataSource: dataSource,
      pageSizes: [5, 10, 15],
      selectedRowKeys: [],
      loadPanel: {
        text: "Memuat...",
      },
      btnAdd: null,
      btnEdit: null,
      btnDelete: null,
      btnView: null,

      btnCustFollow: null,
      btnCustFollowHistory: null,

      btnCustPotensial: null,
      btnCustPotensialHistory: null,

      btnCustNew: null,
      btnCustNewHistory: null,

      // btnFollowHistory: null,
      tipe: this.tipe,
      roleid: null,
    };
  },
  created() {
    this.roleid = window.localStorage.getItem("roleid");
    // axios
    //   .get( "customers")
    //   .then((response) => {
    //     this.dataSource = response.data.data;
    //   });
  },
  methods: {
    ...mapActions("customer", [
      "setCustomer",
      "setCustomerStatus",
      "clearCustomer",
      "clearCustomerStatus",
    ]),

    async fetch(params = {}) {
      // eslint-disable-next-line no-console
      if (Object.keys(params).length === 0) {
        params = {
          perPage: 10,
          page: 1,
          // parentId: "null",
        };
      }
      var results = await CustomerService.getData(params);
      // eslint-disable-next-line no-console
      var datax = await {
        data: results.data,
        totalCount: 10,
        // totalCount: results.total,
      };
      return await datax;
    },

    onSelectionChanged({ selectedRowKeys, selectedRowsData }) {
      console.log("selectedRowKeys.length : " + selectedRowKeys.length);
      if (selectedRowKeys.length > 0) {
        if (selectedRowKeys.length == 1) {
          // masukkan data customer yg diklik ke map
          this.setCustomer(selectedRowsData[0]);
          // console.log("CustomerType : " + this.CustomerType);
          this.btnAdd.option({
            disabled: false,
          });
          this.btnEdit.option({
            disabled: false,
          });
          this.btnDelete.option({
            disabled: false,
          });
          this.btnView.option({
            disabled: false,
          });
        } else {
          this.setCustomer(selectedRowsData);
          // console.log(selectedRowsData);
          this.btnAdd.option({
            disabled: true,
          });
          this.btnEdit.option({
            disabled: true,
          });
          this.btnView.option({
            disabled: true,
          });
        }
        this.btnDelete.option({
          disabled: false,
        });
      } else {
        this.clearCustomer();
        this.btnEdit.option({
          disabled: true,
        });
        this.btnView.option({
          disabled: true,
        });
        this.btnDelete.option({
          disabled: true,
        });
      }
    },

    onToolbarPreparing(e) {
      var vThis = this;

      e.toolbarOptions.items.unshift(
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "add",
            text: "Tambah",
            // disabled: this.CustomerType == 1 ? false : true,
            onInitialized: function (e) {
              vThis.btnAdd = e.component;
            },
            onClick: function () {
              vThis.setCustomerStatus("add");
              router.push("/customer/add");
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "edit",
            text: "Edit",
            disabled: true,
            onInitialized: function (e) {
              vThis.btnEdit = e.component;
            },
            onClick: function () {
              vThis.setCustomerStatus("edit");
              router.push("/customer/edit");
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "trash",
            text: "Hapus",
            disabled: true,
            onInitialized: function (e) {
              vThis.btnDelete = e.component;
            },
            onClick: function () {
              let result = confirm(
                "<i>Anda yakin mau menghapus customer ?</i>",
                "Hapus Customer"
              );
              result.then((dialogResult) => {
                if (dialogResult) {
                  var customer = vThis.getCustomer;
                  // console.log(vThis.getCustomer);
                  var ids = "";
                  if (Array.isArray(customer)) {
                    customer.forEach(function (item, index) {
                      if (index == 0) {
                        ids += item.id;
                      } else {
                        ids += "," + item.id;
                      }
                    });
                  } else {
                    ids = customer.id;
                  }
                  CustomerService.delete(
                    ids,
                    function () {
                      vThis.clearCustomer();
                      vThis.dataGrid.refresh();
                    },
                    function (error) {
                      // eslint-disable-next-line no-console
                      console.log(error);
                    }
                  );
                }
              });
            },
          },
        },
        // {
        //   location: "before",
        //   widget: "dxButton",
        //   options: {
        //     icon: "search",
        //     text: "Cari",
        //     onClick: function () {},
        //   },
        // },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "detailslayout",
            text: "Detail",
            disabled: true,
            onInitialized: function (e) {
              vThis.btnView = e.component;
            },
            onClick: function () {
              router.push("/customer/view");
              // "./pages/customers/ViewCustomer.vue"
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "refresh",
            text: "Muat Ulang",
            onClick: function () {
              vThis.dataGrid.refresh();
            },
          },
        },
        // Filter //
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "search",
            text: vThis.filterText,
            disabled: false,
            onInitialized: function (e) {
              vThis.btnFilter = e.component;
            },
            onClick: function () {
              if (vThis.filterStatus === true) {
                vThis.filterStatus = false;
                vThis.filterText = "Filter ON";
                // console.log("filterStatus : " + vThis.filterStatus);
              } else {
                vThis.filterStatus = true;
                vThis.filterText = "Filter Off";
                // console.log("filterStatus : " + vThis.filterStatus);
              }
            },
          },
        }
      );
    },
    onRowExpanding(e) {
      var dataGrid = e.component;
      dataGrid.collapseAll(-1);
    },

    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Workbook");
      // const worksheet = workbook
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        topLeftCell: { row: 4, column: 1 },
      })
        .then((cellRange) => {
          // header
          const headerRow = worksheet.getRow(2);
          headerRow.height = 30;
          worksheet.mergeCells(2, 1, 2, 8);

          headerRow.getCell(1).value = "Daftar Customer";
          headerRow.getCell(1).font = { name: "Segoe UI Light", size: 22 };
          headerRow.getCell(1).alignment = { horizontal: "center" };

          // footer
          const footerRowIndex = cellRange.to.row + 2;
          const footerRow = worksheet.getRow(footerRowIndex);
          worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 8);

          footerRow.getCell(1).value = "";
          footerRow.getCell(1).font = {
            color: { argb: "BFBFBF" },
            italic: true,
          };
          footerRow.getCell(1).alignment = { horizontal: "right" };
        })
        .then(function () {
          workbook.xlsx.writeBuffer().then(function (buffer) {
            saveAs(
              new Blob([buffer], { type: "application/octet-stream" }),
              "data-customer.xlsx"
            );
          });
        });
      e.cancel = true;
    },
  },
  computed: {
    ...mapGetters("customer", [
      "getCustomer",
      "getCustomerStatus",
      "getCustomerForm",
    ]),
    dataGrid: function () {
      return this.$refs[gridRef].instance;
    },
  },
};
</script>

<style>
</style>
<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <DxForm
        @field-data-changed="formFieldDataChanged"
        :form-data="getStockInDetailStatus == 'edit' ? getStockInDetail : null"
        :ref="formRef"
        :col-count="2"
      >
        <DxGroupItem>
          <DxSimpleItem
            data-field="user_id"
            :visible="false"
            :editor-options="{ value: userId }"
          />
          <DxSimpleItem
            data-field="uuid"
            :visible="false"
            :editor-options="{
              value: currentUUid,
            }"
          />
          <DxSimpleItem
            data-field="warehouse_id"
            :label="{ text: 'warehouse_id' }"
            :visible="false"
            :editor-options="{
              value: this.getStockInDetailTrxNum[0].warehouse_id,
            }"
          />
          <DxSimpleItem
            data-field="transaction_number"
            :label="{ text: 'No Transaksi' }"
            :visible="false"
            :editor-options="{
              value: this.getStockInDetailTrxNum[0].transaction_number,
            }"
          >
            <!-- <DxRequiredRule message="'No Transaksi Wajib di Isi" /> -->
          </DxSimpleItem>

          <!-- <DxSimpleItem data-field="item_id" :label="{ text: 'Nama Item' }">
            <DxRequiredRule message="Nama Item Wajib di Isi" />
          </DxSimpleItem> -->

          <DxSimpleItem
            data-field="item_id"
            :label="{ text: 'Pilih Nama Item' }"
          >
            <template #default>
              <DxSelectBox
                :ref="selectItemRef"
                :data-source="GetItem"
                display-expr="item_code_name"
                value-expr="id"
                placeholder="Select Source"
                @value-changed="valueChangedItem"
                @selection-changed="selectionChangedItem"
                :show-drop-down-button="true"
                :search-enabled="true"
                :value="
                  getStockInDetailStatus == 'edit'
                    ? getStockInDetail.item_id
                    : null
                "
              >
                <DxSelectBoxButton
                  name="dropDown"
                  location="before"
                />
                <DxSelectBoxButton
                  name="clearButton"
                  location="after"
                  :options="{visible:buttonVisible, icon: 'clear',
                             type: 'danger', onClick: clearSelection }"
                />
              </DxSelectBox>
            </template>
          </DxSimpleItem>

          <DxSimpleItem
            data-field="qty"
            editor-type="dxNumberBox"
            :editor-options="{
              max: '99999',
            }"
            :label="{ text: 'Jumlah' }"
          >
            <DxRequiredRule message="Jumlah Wajib di Isi" />
          </DxSimpleItem>

          <DxSimpleItem
            data-field="price"
            editor-type="dxNumberBox"
            :editor-options="{
              max: '9999999',
            }"
            :label="{ text: 'Harga' }"
          >
            <DxRequiredRule message="Harga Wajib di Isi" />
          </DxSimpleItem>
        </DxGroupItem>
        <DxGroupItem>
          <DxSimpleItem
            data-field="batch_number"
            :label="{ text: 'Batch number' }"
            editor-type="dxTextBox"
            :editor-options = batchNumberOptions
          >
            <DxRequiredRule message="Batch number Wajib di Isi" />
          </DxSimpleItem>

          <DxSimpleItem
            data-field="item_condition"
            :label="{ text: 'Pilih Kondisi Item' }"
          >
            <template #default>
              <DxSelectBox
                :data-source="GetItemCondition"
                display-expr="name"
                value-expr="id"
                placeholder="Select Source"
                @value-changed="valueChangedItemCondition"
                :search-enabled="true"
                :value="
                  getStockInDetailStatus == 'edit'
                    ? getStockInDetail.item_condition_id
                    : null
                "
              >
              </DxSelectBox>
            </template>
          </DxSimpleItem>

          <DxSimpleItem
            data-field="expired_date"
            :label="{ text: 'Tanggal Expired' }"
            editor-type="dxDateBox"
            :editor-options="{ dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ss' }"
          >
            <DxRequiredRule message="Tanggal Expired Barang Wajib di Isi" />
          </DxSimpleItem>
        </DxGroupItem>
      </DxForm>
      <span v-html="linebreaks"></span>
      <div
        class="buttons-demo"
        v-bind:style="{
          display: vShow,
        }"
      >
        <div class="buttons">
          <div>
            <div class="buttons-inbound">
              <div>
                <DxButton
                  :width="500"
                  text="Tambah List"
                  type="normal"
                  styling-mode="contained"
                  @click="onClickAddItemToList()"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="content-block dx-card responsive-paddings"
      v-bind:style="{
        display: vShow,
      }"
    >
      <dx-data-grid
        class="dx-card wide-card"
        :data-source="tempData ? tempData : dataNull"
        :show-borders="true"
        :focused-row-index="0"
        :focused-row-enabled="true"
        :column-auto-width="true"
        :column-hiding-enabled="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :ref="gridRef"
        @toolbar-preparing="onToolbarPreparing($event)"
      >
        <dx-paging :page-size="10" />
        <dx-pager
          :showPageSizeSelector="true"
          :allowed-page-sizes="pageSizes"
          :show-info="true"
          :showNavigationButtons="true"
          infoText="Hal {0} dari {1} ({2} items)"
          :visible="true"
        />
        <dx-filter-row :visible="filterStatus" />
        <DxEditing :allow-deleting="true" mode="cell" />
        <DxSearchPanel :visible="true" />
        <DxSelection mode="single" />
        <dx-paging :page-size="10" />
        <dx-pager
          :showPageSizeSelector="true"
          :allowed-page-sizes="pageSizes"
          :show-info="true"
          :showNavigationButtons="true"
          infoText="Hal {0} dari {1} ({2} items)"
          :visible="true"
        />
        <dx-filter-row :visible="filterStatus" />
        <DxSelection mode="single" />
        <DxColumn
          data-field="no"
          caption="No"
          :width="70"
          cell-template="nomor"
        />
        <template #nomor="{ data }">
          {{ data.rowIndex + 1 }}
        </template>
        <!-- <DxColumn
          data-field="transaction_number"
          width="200"
          caption="No Transaksi"
        /> -->
        <DxColumn
          data-field="uuid"
          width="200"
          :visible="false"
          caption="uuid"
        />
        <DxColumn data-field="item_name" width="200" caption="Nama Item" />
        <DxColumn data-field="price" caption="Harga" />
        <DxColumn data-field="qty" caption="Jumlah" />
        <DxColumn data-field="batch_number" caption="Batch Number" />
        <DxColumn data-field="item_condition_name" caption="Kondisi" />
        <DxColumn
          data-field="expired_date"
          dataType="date"
          format="dd/MM/yyyy"
          caption="Tanggal Expired"
        />
      </dx-data-grid>
    </div>
  </div>
</template>
<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from "devextreme-vue/form";
import {
  DxDataGrid,
  DxPager,
  DxPaging,
  DxSelection,
  DxColumn,
  DxFilterRow,
  DxSearchPanel,
  DxEditing,
} from "devextreme-vue/data-grid";
// import CustomStore from "devextreme/data/custom_store";
// import StockInDetailService from "@/services/StockInDetailService";
import DxTextArea from "devextreme-vue/text-area";
import { mapGetters, mapActions } from "vuex";
import {DxSelectBox, DxButton as DxSelectBoxButton}  from "devextreme-vue/select-box";
import DxButton from "devextreme-vue/button";
import { uuid } from "vue-uuid";
import router from "../../router";
import { confirm } from "devextreme/ui/dialog";
import moment from "moment";
import notify from "devextreme/ui/notify";
import axios from "axios";
const formRef = "StockInDetailDetail-form";
const selectItemRef = "stockindetail-selectboxItem";
const gridRef = "gridRef";
export default {
  components: {
    //Form Components :
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    // eslint-disable-next-line
    DxTextArea,
    DxRequiredRule,
    DxSelectBox,
    DxButton,
    DxSelectBoxButton,
    //Datagrid Components :

    DxDataGrid,
    DxPager,
    DxPaging,
    DxSelection,
    DxColumn,
    DxFilterRow,
    DxSearchPanel,
    DxEditing,
  },
  data: function () {
    return {
      userId: null,
      filterStatus: false,
      gridRef,
      // dataSource: dataSource,
      pageSizes: [5, 10, 15],
      selectedRowKeys: [],
      loadPanel: {
        text: "Memuat...",
      },
      formRef,
      selectItemRef,
      GetSourceType: [],
      GetWarehouse: [],
      GetOrigin: [],
      GetWarehouseDest: [],
      GetItem: [],
      GetItemCondition: [],
      tempData: [],
      uuid: uuid.v1(),
      linebreaks: "<br>",
      vItemName: "",
      dataNull: [
        {
          no: "N/A",
          transaction_number: "N/A",
          uuid: "N/A",
          item_name: "N/A",
          qty: "N/A",
          batch_number: "N/A",
          item_condition_name: "N/A",
          expired_date: "N/A",
        },
      ],
      vShow: "none",
      buttonVisible : false,
      batchNumberOptions : {
        buttons : [
           {
            location : 'after',
            name: 'generateButtons',
            options : {
              icon: 'preferences',
              type: 'default',
              onClick: () => {
                let item_code = this.getStockInDetail.item_code;
                if (typeof(item_code) !== 'undefined') {
                   this.$refs[formRef].instance.updateData({
                    batch_number: 'BN-'+item_code,
                   });
                } else {
                  alert("Silahkan Pilih Nama Item terlebih dahulu")
                }
              }
            }
            
           }
        ]
      }
      
    };
  },
  created() {
    this.userId = window.localStorage.getItem("usrid");
    this.currentUUid = this.uuid;
    if (this.getStockInDetailStatus == "edit") {
      this.vShow = "none";
      this.$emit("save_status", true);
    } else {
      this.vShow = "show";
      this.$emit("save_status", false);
    }
    axios
      .get( "master_items")
      .then((response) => {
        var tempData = response.data.data;
        var itemData = [];
        tempData.forEach(function (item) {
            item.item_code_name = item.item_code +" "+item.item_name;
            itemData.push(item);
        });
        this.GetItem = itemData;
      });
    axios
      .get( "item_conditions")
      .then((response) => {
        //console.log(JSON.stringify(response.data));
        this.GetItemCondition = response.data.data;
      });
  },
  mounted() {
    this.formSet();
  },
  methods: {
    ...mapActions("stockindetail", [
      "setStockInDetail",
      "clearStockInDetail",
      "setStockInDetailStatus",
      "clearStockInDetailStatus",
      "setStockInDetailForm",
    ]),
    onToolbarPreparing(e) {
      var vThis = this;
      e.toolbarOptions.items.unshift({
        location: "before",
        widget: "dxButton",
        options: {
          icon: "save",
          text: "Simpan",
          disabled: false,
          // visible: false,
          onInitialized: function (e) {
            vThis.btnAdd = e.component;
          },
          onClick: function () {
            let result = confirm(
              "Apakah data ini sudah sesuai ?",
              "Proses Data iNbound"
            );
            result.then((dialogResult) => {
              if (dialogResult) {
                vThis.setInboundStockJson(vThis.tempData);
              }
            });
          },
        },
      });
    },

    async onClickAddItemToList() {
      var vThis = this;
      var addParam;

      const resItem = await axios.get("master_items");
      let elItem = resItem.data.data.find(
        (el) => el.id === vThis.getStockInDetail.item_id
      );
      console.log("item_name = " + JSON.stringify(elItem.item_name));

      const resItemCond = await axios.get("item_conditions");
      let elItemCond = resItemCond.data.data.find(
        (el) => el.id === vThis.getStockInDetail.item_condition_id
      );
      console.log("Item Cond name = " + JSON.stringify(elItemCond.name));
      // this.vItemName = elItem.item_name;
      // vThis.getItemName(vThis.getStockInDetail.item_id);
      // item_name = vThis.vItemName;
      // item_name = vThis.getItemName(vThis.getStockInDetail.item_id);
      addParam = {
        item_condition_name: elItemCond.name,
        item_name: elItem.item_name,
      };
      Object.assign(vThis.getStockInDetail, addParam);
      console.log(
        "getStockInDetail :" + JSON.stringify(vThis.getStockInDetail)
      );

      var tes = {};
      Object.assign(tes, vThis.getStockInDetail);
      // console.log("tes :" + JSON.stringify(tes));
      vThis.tempData.push(tes);
      console.log("this.tempData : " + JSON.stringify(vThis.tempData));

      this.$refs[formRef].instance.resetValues();
      // this.$refs[formRef].instance.updateData({
      //   qty: 0,
      //   item_name: "",
      //   batch_number: "",
      //   item_condition_name: "",
      //   expired_date: "",
      //   last_item_qty: 0,
      // });
    },
    async getItemName(item_id) {
      const resItem = await axios.get("master_items");
      // console.log("resItem = " + JSON.stringify(resItem.data.data));
      let elItem = resItem.data.data.find((el) => el.id === item_id);
      console.log("item_name = " + JSON.stringify(elItem.item_name));
      this.vItemName = elItem.item_name;
      // return this.vItemName;
    },
    async setInboundStockJson(outdata) {
      // var transaction_number = this.format_trx_date(new Date());
      var tempInboundData = [];
      var tempInData = [];
      var addParam;
      var newId;
      var keyCount = Object.keys(outdata).length;

      // const resWarehouse = await axios.get("warehouses");
      // console.log(
      //   "resWarehouse = " + JSON.stringify(resWarehouse.data.data)
      // );

      // const resItem = await axios.get("master_items");
      // console.log("resItem = " + JSON.stringify(resItem.data.data));

      // this.warehouseIdx = "WRH002";
      for (var n = 0; n < keyCount; n++) {
        newId = uuid.v1(n);
        // console.log("newId = " + newId);

        // let warehouse_code = outdata[n].warehouse_code;
        // let elWarehouse = resWarehouse.data.data.find(
        //   (el) => el.code === warehouse_code
        // );
        // const resWarehouse = await axios.get(
        //   "warehouses?code=" + warehouse_code
        // );
        // this.warehouseIdx = elWarehouse.id;
        let warehouse_id = this.getStockInDetailTrxNum[0].warehouse_id;
        let transaction_number =
          this.getStockInDetailTrxNum[0].transaction_number;
        // let item_code = outdata[n].item_code;
        // let elItem = resItem.data.data.find((el) => el.item_code === item_code);
        // const resItem = await axios.get("master_items?item_code=" + item_code);
        // this.itemIdx = elItem.id;

        let item_id = outdata[n].item_id;
        let price = outdata[n].price;
        console.log("price: " + price);
        // let exp_date = this.formatDate(outdata[n].exp_date);
        // let expired_date = this.formatDate(outdata[n].expired_date);
        let expired_date = outdata[n].expired_date;
        let received_date = this.format_rcv_date(new Date());
        
        tempInData[n] = outdata[n];
        addParam = {
          user_id: this.userId,
          uuid: newId,
          warehouse_id: warehouse_id,
          item_id: item_id,
          item_condition_id: 1,
          price: price,
          transaction_number: transaction_number,
          stock_action: 110,
          remark: "stock-in",
          received_date: received_date,
          expired_date: expired_date,
        };
        Object.assign(tempInData[n], addParam);
        // console.log(
        //   "tempOutdata[" + n + "]: " + JSON.stringify(tempOutdata[n])
        // );

        tempInboundData.push(tempInData[n]);
        console.log("tempOutboundData" + JSON.stringify(tempInboundData));
        // this.firstStockImport = tempData;
      }
      // this.firstStockImport = tempData;
      var vThis = this;
      axios
        .post("stock_in_details/post/1", tempInboundData)
        .then(function (response) {
          console.log(response);
          vThis.success = response.data.success;
        })
        .catch(function (error) {
          console.log(error);
          vThis.output = error;
        });

      axios
        .post("stock_reports/post/1", tempInboundData)
        .then(function (response) {
          console.log(response);
          vThis.success = response.data.success;
        })
        .catch(function (error) {
          console.log(error);
          vThis.output = error;
        });
      notify("Data Berhasil Disimpan");
      console.log("Finished!");
      router.push("/stock/in");
    },

    format_trx_date(value) {
      if (value) {
        return moment(String(value)).format("YYYYMMDDHHmmss");
      }
    },

    formatDate(numb) {
      const time = new Date((numb - 1) * 24 * 3600000 + 1);
      time.setYear(time.getFullYear() - 70);
      return time;
    },

    format_rcv_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD HH:mm:ss");
      }
    },

    valueChangedItem(e) {
      this.$refs[formRef].instance.updateData({
        item_id: e.value,
      });
    },
    selectionChangedItem(e) {
      let itemDetail = e.selectedItem;
      if (typeof(itemDetail) !== 'undefined' && itemDetail != null) {
        this.$refs[formRef].instance.updateData({
          item_code: itemDetail.item_code,
        });
        this.buttonVisible = true;
      } else {
         this.$refs[formRef].instance.updateData({
          item_code: null,
        });
      }
    },
    clearSelection() {
       this.buttonVisible = false;
       this.$refs[formRef].instance.updateData({
          item_id : null,
          item_code: null,
      });
      this.$refs[selectItemRef].instance.reset();
    },
    valueChangedItemCondition(e) {
      this.$refs[formRef].instance.updateData({
        item_condition_id: e.value,
      });
    },
    formFieldDataChanged(e) {
      console.log(JSON.stringify(e.component.option("formData")));
      this.setStockInDetail(e.component.option("formData"));
    },
    formSet() {
      this.setStockInDetailForm(this.$refs[formRef].instance);
    },
  },

  computed: {
    ...mapGetters("stockin", ["getStockIn"]),
    ...mapGetters("stockindetail", [
      "getStockInDetail",
      "getStockInDetailStatus",
      "getStockInDetailForm",
      "getStockInDetailTrxNum",
    ]),
  },
};
</script>
<style>
.buttons-inbound {
  width: 150px;
  height: 40px;
  padding-left: 10px;
}
.group-padding {
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.form-block {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  padding-left: 40px;
  padding-right: 40px;
}
</style>

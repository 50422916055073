<template>
  <div>
    <h2 class="content-block">MasterRoleTable</h2>
    <master-role-table />
  </div>
</template>

<script>
import MasterRoleTable from "@/components/master-role/MasterRoleTable.vue";
export default {
  components: {
    MasterRoleTable,
  },
};
</script>
<template>
  <div>
       <h2 class="content-block">Stock In</h2>
    <stock-in-table />
  </div>
</template>
<script>

import StockInTable from "@/components/stock-in/StockInTable.vue";
export default {
  components: {
    StockInTable,
  },
};
</script>
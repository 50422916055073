<template>
  <div>
    <!-- <h2 class="content-block">Daftar Branch Customer</h2> -->
    <h2 class="content-block">Daftar Karyawan</h2>
    <employee-table />
  </div>
</template>
<script>
import employeeTable from "@/components/employee/EmployeeTable.vue";
export default {
  components: {
    employeeTable
  }
};
</script>
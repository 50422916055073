<template>
  <DxForm
    class="form-block"
    :form-data="getLocationType"
    :read-only="true"
    :ref="formRef"
  >
    <DxGroupItem :col-count="2" css-class="group-padding">
      <DxGroupItem>
        <DxSimpleItem
          data-field="name"
          :label="{ text: 'Nama Lokasi' }"
        />
      </DxGroupItem>

    </DxGroupItem>
  </DxForm>
</template>
<script>
import { DxForm, DxSimpleItem, DxGroupItem } from "devextreme-vue/form";
import DxTextArea from "devextreme-vue/text-area";
import { mapGetters, mapActions } from "vuex";

const formRef = "locationtype-form";

export default {
  components: {
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    // eslint-disable-next-line
    DxTextArea,
  },
  data: function () {
    return {
      formRef,
    };
  },

  methods: {
    ...mapActions("locationtype", [
      "setLocationType",
      "clearLocationType",
      "setLocationTypeStatus",
      "clearLocationTypeStatus",
    ]),
  },
  computed: {
    ...mapGetters("locationtype", [
      "getLocationType",
      "getLocationTypeStatus",
      "getLocationTypeForm",
    ]),
  },
};
</script>
<style>
.group-padding {
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.form-block {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  padding-left: 40px;
  padding-right: 40px;
}
</style>

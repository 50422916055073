<template>
  <div id="DeptForm">
    <h2 class="content-block">LocationType View</h2>
    <DxToolbar class="padding-block">
      <DxItem
        :options="backButtonOptions"
        widget="dxButton"
        location="before"
      />
    </DxToolbar>
    <LocationTypeView />
    <DxLoadPanel
      :position="{ of: '#mainContent' }"
      :visible.sync="loadingVisible"
      :show-indicator="true"
      :show-pane="true"
      :shading="true"
      :close-on-outside-click="false"
      shading-color="rgba(0,0,0,0.4)"
    />
  </div>
</template>

<script>
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import { DxLoadPanel } from "devextreme-vue/load-panel";
// import notify from 'devextreme/ui/notify';
import LocationTypeView from "@/components/location-type/LocationTypeView.vue";
// import router from "../../router";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    DxToolbar,
    DxItem,
    // FormView,
    DxLoadPanel,
    LocationTypeView
  },
  data() {
    return {
      loadingVisible: false,
      backButtonOptions: {
        icon: "chevronleft",
        text: "Kembali",
        onClick: () => {
          // this.clearLocationTypeStatus();
          // this.clearLocationType();
          this.$router.go(-1);
        },
      },
    };
  },

  methods: {
    ...mapActions("locationtype", [
      "setLocationType",
      "clearLocationType",
      "setLocationTypeItemStatus",
      "clearLocationTypeItemStatus",
    ]),
  },
  computed: {
    ...mapGetters("locationtype", [
      "getLocationType",
      "getLocationTypeItemStatus",
      "getLocationTypeForm",
    ]),
  },
};
</script>

<style>
.toolbar-label,
.toolbar-label > b {
  font-size: 16px;
}

#products {
  margin-top: 10px;
}

.padding-block {
  padding-left: 20px;
  padding-right: 20px;
}
</style>
<template>
  <div>
    <div
      class="content-block dx-card responsive-paddings"
      v-bind:style="{
        display: vShow,
      }"
    >
      <span>
        <input
          class="input-file"
          type="file"
          @change="importDataXlsx"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
        <br /><span class="note">Import File Excel</span><br />
        <!-- <button @click="btnClick">ImportEXCEL</button> -->
        <dx-data-grid
          class="dx-card wide-card"
          :data-source="firstStockImport"
          :show-borders="true"
          :focused-row-index="0"
          :focused-row-enabled="true"
          :column-auto-width="true"
          :column-hiding-enabled="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :loadPanel="loadPanel"
          :selected-row-keys="selectedRowKeys"
          @rowExpanding="onRowExpanding"
          @exporting="onExporting"
          :ref="gridRef"
        >
          <dx-paging :page-size="10" />
          <dx-pager
            :showPageSizeSelector="true"
            :allowed-page-sizes="pageSizes"
            :show-info="true"
            :showNavigationButtons="true"
            infoText="Hal {0} dari {1} ({2} items)"
            :visible="true"
          />
          <dx-filter-row :visible="filterStatus" />
          <DxSelection mode="multiple" />
          <!-- <DxFilterRow :visible="true" /> -->
          <DxColumn data-field="uuid" :visible="false" caption="uuid" />
          <DxColumn
            data-field="transaction_number"
            caption="Transaction Number"
          />
          <DxColumn data-field="stock_action" caption="stock_action" />
          <!-- <DxColumn data-field="warehouse_name" caption="Warehouse Name" /> -->
          <!-- <DxColumn data-field="item_name" caption="Item Name" /> -->
          <DxColumn data-field="item_id" caption="Item Id" />
          <DxColumn data-field="qty" caption="Amount" />
          <DxColumn data-field="batch_number" caption="Batch Number" />
          <!-- <DxColumn data-field="remark" caption="remark" /> -->
          <!-- <DxColumn data-field="item_condition_id" :visible="false" /> -->
          <!-- <DxColumn data-field="item_condition_name" caption="Item Condition" /> -->
          <DxColumn
            data-field="expired_date"
            dataType="date"
            format="dd/MM/yyyy"
            caption="Expired Date"
          />
          <DxColumn data-field="remark" caption="remark" />
          <DxExport :enabled="true" />
        </dx-data-grid>
      </span>
    </div>
    <div class="content-block dx-card responsive-paddings">
      <dx-data-grid
        class="dx-card wide-card"
        :data-source="dataSource"
        :show-borders="true"
        :focused-row-index="0"
        :focused-row-enabled="true"
        :column-auto-width="true"
        :column-hiding-enabled="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :loadPanel="loadPanel"
        :selected-row-keys="selectedRowKeys"
        @toolbar-preparing="onToolbarPreparing($event)"
        @selection-changed="onSelectionChanged"
        @rowExpanding="onRowExpanding"
        @exporting="onExporting"
        :ref="gridRef"
      >
        <dx-paging :page-size="10" />
        <dx-pager
          :showPageSizeSelector="true"
          :allowed-page-sizes="pageSizes"
          :show-info="true"
          :showNavigationButtons="true"
          infoText="Hal {0} dari {1} ({2} items)"
          :visible="true"
        />
        <dx-filter-row :visible="filterStatus" />
        <DxSelection mode="multiple" />
        <!-- <DxFilterRow :visible="true" /> -->
        <DxColumn data-field="uuid" :visible="false" caption="uuid" />
        <DxColumn
          data-field="transaction_number"
          caption="Transaction Number"
        />
        <DxColumn data-field="warehouse_name" caption="Warehouse Name" />
        <DxColumn data-field="item_name" caption="Item Name" />
        <DxColumn data-field="qty" caption="Amount" />
        <DxColumn data-field="batch_number" caption="Batch Number" />
        <DxColumn data-field="item_condition_id" :visible="false" />
        <DxColumn data-field="item_condition_name" caption="Item Condition" />
        <DxColumn
          data-field="expired_date"
          dataType="date"
          format="dd/MM/yyyy"
          caption="Expired Date"
        />
        <DxExport :enabled="true" />
      </dx-data-grid>
    </div>
  </div>
</template>

<script>
import XLSX from "xlsx";
import {
  DxDataGrid,
  DxPager,
  DxPaging,
  DxSelection,
  DxColumn,
  DxFilterRow,
  DxExport,
} from "devextreme-vue/data-grid";
import CustomStore from "devextreme/data/custom_store";
import StockFirstService from "@/services/StockFirstService";
import router from "../../router";
import { mapGetters, mapActions } from "vuex";
import { confirm } from "devextreme/ui/dialog";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { uuid } from "vue-uuid";
import moment from "moment";
import axios from "axios";

const gridRef = "stockfirst-table";
// const fruitsToGet = ["apple", "grape", "pear"];

export default {
  // props: ["StockFirstId"],
  name: "HelloWorld",
  props: {
    type: String,
    // default: "Select excel file",
  },
  components: {
    DxDataGrid,
    DxPager,
    DxPaging,
    DxSelection,
    DxColumn,
    DxFilterRow,
    DxExport,
  },
  data() {
    const vthis = this;
    const dataSource = {
      store: new CustomStore({
        key: "id",
        load: async function (loadOptions) {
          var sortedBy = "";
          if (loadOptions.sort) {
            sortedBy += loadOptions.sort[0].selector;
            if (loadOptions.sort[0].desc) {
              sortedBy += " desc";
            } else {
              sortedBy += " asc";
            }
          }

          var perPage = loadOptions.take;
          var offset = loadOptions.skip;
          var page = offset / perPage + 1;

          var datax = vthis.fetch({
            perPage: perPage || 10,
            page: page || 1,
            sortedBy: sortedBy,
            parentId: "null",
          });

          return await datax;
        },
      }),
    };
    // const getNumFruit = (fruit) => {
    //   return fruitsToGet[fruit];
    // };
    // const forLoop = async (_) => {
    //   console.log("Start");

    //   for (let index = 0; index < fruitsToGet.length; index++) {
    //     const fruit = fruitsToGet[index];
    //     const numFruit = await getNumFruit(fruit);
    //     console.log(numFruit);
    //   }

    //   console.log("End");
    // };
    return {
      filterStatus: false,
      gridRef,
      dataSource: dataSource,
      pageSizes: [5, 10, 15],
      selectedRowKeys: [],
      loadPanel: {
        text: "Memuat...",
      },
      btnAdd: null,
      btnEdit: null,
      btnDelete: null,
      btnView: null,

      tipe: this.tipe,
      custid: null,
      mydata: null,
      FormStockFirst: null,
      warehouseId: null,
      warehouseIdx: null,
      itemIdx: null,
      uuid: uuid.v1(),
      firstStockImport: [],
      vShow: "none",
    };
  },
  created() {
    this.userId = window.localStorage.getItem("usrid");
    this.warehouseId = window.localStorage.getItem("wrhid");
    this.roleid = window.localStorage.getItem("roleid");
    // this.currentUUid = this.uuid;
    var AUTH_TOKEN = "Bearer " + window.localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;

    if (this.roleid == 1) {
      this.vShow = "show";
    } else {
      this.vShow = "none";
    }
  },
  methods: {
    ...mapActions("stockfirst", [
      "setStockFirst",
      "clearStockFirst",
      "setStockFirstStatus",
      "clearStockFirstStatus",
    ]),

    btnClick() {
      document.querySelector(".input-file").click();
    },
    customizeText(cellInfo) {
      if (cellInfo.value == 1) {
        return "Good";
      } else {
        return "Not Good";
      }
    },

    importDataXlsx(event) {
      if (!event.currentTarget.files.length) {
        return;
      }
      const vThis = this;
      // Get the file object
      var f = event.currentTarget.files[0];
      // Use FileReader to read
      var reader = new FileReader();
      // Rewrite the readAsBinaryString method on FileReader
      FileReader.prototype.readAsBinaryString = function (f) {
        var binary = "";
        var wb; // Read the completed data
        var outdata; // the data you need
        var reader = new FileReader();
        reader.onload = function () {
          // Read into Uint8Array and convert to Unicode encoding (Unicode takes two bytes)
          var bytes = new Uint8Array(reader.result);
          var length = bytes.byteLength;
          for (var i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          // Next is xlsx, see the plugin api for details
          wb = XLSX.read(binary, {
            type: "binary",
          });
          outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
          // count JSON objects
          var keyCount = Object.keys(outdata).length;
          console.log("keyCount = " + JSON.stringify(keyCount));

          // find object by no
          var data = outdata;
          var indek = data.findIndex((obj) => obj.itemname == "Shower Puff");
          console.log("indek :" + indek);

          vThis.setFirstStockJson(outdata);

          vThis.$emit("getResult", outdata);
        };
        reader.readAsArrayBuffer(f);
      };
      reader.readAsBinaryString(f);
    },

    async setFirstStockJson(outdata) {
      var transaction_number = this.format_trx_date(new Date());
      var tempData = [];
      var tempOutdata = [];
      var addParam;
      var newId;
      var keyCount = Object.keys(outdata).length;
      // for (const [idx, url] of urls.entries()) {
      //   const todo = await fetch(url);
      //   console.log(`Received Todo ${idx + 1}:`, todo);
      // }
      const resWarehouse = await axios.get("warehouses");
      // console.log(
      //   "resWarehouse = " + JSON.stringify(resWarehouse.data.data)
      // );
      // let elWarehouse = resWarehouse.data.data.find(
      //   (el) => el.code === "WRH002"
      // );
      // console.log("elWarehouse = " + JSON.stringify(elWarehouse));
      // console.log("wrhs_id = " + JSON.stringify(elWarehouse.id));

      const resItem = await axios.get("master_items");
      // console.log("resItem = " + JSON.stringify(resItem.data.data));
      // let elItem = resItem.data.data.find((el) => el.item_code === "BY0035");
      // console.log("elItem = " + JSON.stringify(elItem));
      // console.log("elItem_id = " + JSON.stringify(elItem.id));

      // this.warehouseIdx = "WRH002";
      for (var n = 0; n < keyCount; n++) {
        newId = uuid.v1(n);
        // console.log("newId = " + newId);

        let warehouse_code = outdata[n].warehouse_code;
        let elWarehouse = resWarehouse.data.data.find(
          (el) => el.code === warehouse_code
        );
        // const resWarehouse = await axios.get(
        //   "warehouses?code=" + warehouse_code
        // );
        this.warehouseIdx = elWarehouse.id;

        let item_code = outdata[n].item_code;
        let elItem = resItem.data.data.find((el) => el.item_code === item_code);
        // const resItem = await axios.get("master_items?item_code=" + item_code);
        this.itemIdx = elItem.id;

        let exp_date = this.formatDate(outdata[n].exp_date);
        let received_date = this.format_rcv_date(new Date());

        tempOutdata[n] = outdata[n];
        addParam = {
          user_id: this.userId,
          uuid: newId,
          warehouse_id: this.warehouseIdx,
          item_id: this.itemIdx,
          item_condition_id: 1,
          transaction_number: transaction_number,
          stock_action: 100,
          remark: "import-first-stock",
          received_date: received_date,
          expired_date: exp_date,
        };
        Object.assign(tempOutdata[n], addParam);
        // console.log(
        //   "tempOutdata[" + n + "]: " + JSON.stringify(tempOutdata[n])
        // );

        tempData.push(tempOutdata[n]);
        console.log("tempData" + JSON.stringify(tempData));
        // this.firstStockImport = tempData;
      }
      this.firstStockImport = tempData;
      axios
        .post("stocks/post/1", tempData)
        .then(function (response) {
          console.log(response);
          this.success = response.data.success;
        })
        .catch(function (error) {
          console.log(error);
          this.output = error;
        });

      axios
        .post("stock_reports/post/1", tempData)
        .then(function (response) {
          console.log(response);
          this.success = response.data.success;
        })
        .catch(function (error) {
          console.log(error);
          this.output = error;
        });

      console.log("Finished!");
    },

    // excel reads the time format of 2018/01/01 is to replace it with a number similar to 46254.1545151415 numb is the integer number passed over
    formatDate(numb) {
      const time = new Date((numb - 1) * 24 * 3600000 + 1);
      time.setYear(time.getFullYear() - 70);
      return time;
    },

    // async getDataKu() {
    //   return await Promise.resolve("I made it!");
    // },

    format_rcv_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD HH:mm:ss");
      }
    },
    format_trx_date(value) {
      if (value) {
        return moment(String(value)).format("YYYYMMDDHHmmss");
      }
    },
    async fetch(params = {}) {
      // eslint-disable-next-line no-console
      if (Object.keys(params).length === 0) {
        params = {
          perPage: 10,
          page: 1,
          // parentId: "null",
        };
      }
      // var results = await StockFirstService.getData(params);

      if (this.roleid == 5) {
        var results;
        results = await StockFirstService.getDataByWarehouseId(
          this.warehouseId,
          params
        );
      } else {
        this.isCustomerVisible = false;
        results = await StockFirstService.getData(params);
      }
      // eslint-disable-next-line no-console
      var datax = await {
        data: results.data,
        totalCount: 10,
        // totalCount: results.total,
      };
      return await datax;
    },

    onSelectionChanged({ selectedRowKeys, selectedRowsData }) {
      // console.log("selectedRowKeys : " + selectedRowKeys.length);
      if (selectedRowKeys.length > 0) {
        if (selectedRowKeys.length == 1) {
          this.setStockFirst(selectedRowsData[0]);
          console.log("getStockFirst : " + JSON.stringify(this.getStockFirst));
          this.btnAdd.option({
            disabled: false,
          });
          this.btnEdit.option({
            disabled: false,
          });
          this.btnView.option({
            disabled: false,
          });
        } else {
          this.setStockFirst(selectedRowsData);
          // console.log(selectedRowsData);
          this.btnAdd.option({
            disabled: true,
          });
          this.btnEdit.option({
            disabled: true,
          });
          this.btnView.option({
            disabled: true,
          });
        }
        this.btnDelete.option({
          disabled: false,
        });
      } else {
        this.clearStockFirst();
        this.btnAdd.option({
          disabled: true,
        });
        this.btnEdit.option({
          disabled: true,
        });
        this.btnDelete.option({
          disabled: true,
        });
        this.btnView.option({
          disabled: true,
        });
      }
    },
    onToolbarPreparing(e) {
      var vThis = this;

      e.toolbarOptions.items.unshift(
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "add",
            text: "Tambah",
            onInitialized: function (e) {
              vThis.btnAdd = e.component;
            },
            onClick: function () {
              vThis.setStockFirstStatus("add");
              router.push("/stock/first/add");
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "edit",
            text: "Edit",
            disabled: true,
            visible: this.roleid == 5 ? false : true, //jika role bukan admin/manager maka dihide
            onInitialized: function (e) {
              vThis.btnEdit = e.component;
            },
            onClick: function () {
              vThis.setStockFirstStatus("edit");
              router.push("/stock/first/edit");
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "trash",
            text: "Hapus",
            disabled: true,
            visible: this.roleid == 5 ? false : true, //jika role bukan user maka dihide
            onInitialized: function (e) {
              vThis.btnDelete = e.component;
            },
            onClick: function () {
              let result = confirm(
                "<i>Anda yakin mau menghapus data ini ?</i>",
                "Hapus StockFirst"
              );
              result.then((dialogResult) => {
                if (dialogResult) {
                  var StockFirst = vThis.getStockFirst;
                  console.log(vThis.getStockFirst);
                  var ids = "";
                  if (Array.isArray(StockFirst)) {
                    StockFirst.forEach(function (item, index) {
                      if (index == 0) {
                        ids += item.id;
                      } else {
                        ids += "," + item.id;
                      }
                    });
                  } else {
                    ids = StockFirst.id;
                  }

                  vThis.deleteReport(StockFirst.uuid);

                  StockFirstService.delete(
                    ids,
                    function () {
                      vThis.clearStockFirst();
                      vThis.dataGrid.refresh();
                    },
                    function (error) {
                      // eslint-disable-next-line no-console
                      console.log(error);
                    }
                  );
                }
              });
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "detailslayout",
            text: "Detail",
            disabled: true,
            onInitialized: function (e) {
              vThis.btnView = e.component;
            },
            onClick: function () {
              router.push("/stock/first/view");
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "refresh",
            text: "Muat Ulang",
            onClick: function () {
              vThis.dataGrid.refresh();
            },
          },
        },
        // Filter //
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "search",
            text: vThis.filterText,
            disabled: false,
            onInitialized: function (e) {
              vThis.btnFilter = e.component;
            },
            onClick: function () {
              if (vThis.filterStatus === true) {
                vThis.filterStatus = false;
                vThis.filterText = "Filter ON";
                // console.log("filterStatus : " + vThis.filterStatus);
              } else {
                vThis.filterStatus = true;
                vThis.filterText = "Filter Off";
                // console.log("filterStatus : " + vThis.filterStatus);
              }
            },
          },
        }
      );
    },
    onRowExpanding(e) {
      var dataGrid = e.component;
      dataGrid.collapseAll(-1);
    },
    async deleteReport(uuid) {
      var vThis = this;
      // var vuuid = uuid;
      /////////////////////////
      try {
        const response1 = await axios.get("stock_reports/getid/" + uuid);
        var reportId = JSON.stringify(response1.data.data[0].id);
        // console.log(
        //   "report_id : " +
        //     JSON.stringify("reportId :" + response1.data.data[0].id)
        // );
      } catch (error) {
        console.error(error);
      }
      /////////////////////////
      try {
        const response2 = await axios.delete("stock_reports/" + reportId);
        // console.log(response2);
        vThis.success = response2.data.success;
      } catch (error) {
        console.error(error);
      }
    },
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Workbook");
      // const worksheet = workbook
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        topLeftCell: { row: 4, column: 1 },
      })
        .then((cellRange) => {
          // header
          const headerRow = worksheet.getRow(2);
          headerRow.height = 30;
          worksheet.mergeCells(2, 1, 2, 8);

          headerRow.getCell(1).value = "Data Stock Awal";
          headerRow.getCell(1).font = { name: "Segoe UI Light", size: 22 };
          headerRow.getCell(1).alignment = { horizontal: "left" };

          // footer
          const footerRowIndex = cellRange.to.row + 2;
          const footerRow = worksheet.getRow(footerRowIndex);
          worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 8);

          footerRow.getCell(1).value = "";
          footerRow.getCell(1).font = {
            color: { argb: "BFBFBF" },
            italic: true,
          };
          footerRow.getCell(1).alignment = { horizontal: "right" };
        })
        .then(function () {
          workbook.xlsx.writeBuffer().then(function (buffer) {
            saveAs(
              new Blob([buffer], { type: "application/octet-stream" }),
              "WMS-Data-Stock-Awal.xlsx"
            );
          });
        });
      e.cancel = true;
    },
  },
  computed: {
    ...mapGetters("stockfirst", [
      "getStockFirst",
      "getStockFirstStatus",
      "getStockFirstForm",
    ]),
    dataGrid: function () {
      return this.$refs[gridRef].instance;
    },
    getNewId: function () {
      // `this` points to the vm instance
      // const newID = uuid.v1();
      // return uuid.v1();
      return this.$uuid.v4();
    },
  },
};
</script>

<style>
</style>
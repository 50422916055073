<template>
  <DxForm
    class="form-block"
    :form-data="getStockFirst"
    :read-only="true"
    :ref="formRef"
    :col-count="2"
  >
    <DxGroupItem>
      <DxSimpleItem
        data-field="warehouse_name"
        :label="{ text: 'Nama Warehouse' }"
      />
      <DxSimpleItem data-field="item_name" :label="{ text: 'Nama Item' }" />
      <DxSimpleItem data-field="item_stock" :label="{ text: 'Jumlah' }" />
    </DxGroupItem>

    <DxGroupItem>
      <DxSimpleItem
        data-field="item_condition_name"
        :label="{ text: 'Select Item Condition' }"
      />

      <DxSimpleItem
        data-field="batch_number"
        :label="{ text: 'Batch Number' }"
      />

      <DxSimpleItem
        data-field="expired_date"
        :label="{ text: 'Expired Date' }"
        editor-type="dxDateBox"
      />
    </DxGroupItem>
  </DxForm>
</template>
<script>
import { DxForm, DxSimpleItem, DxGroupItem } from "devextreme-vue/form";
import DxTextArea from "devextreme-vue/text-area";
import { mapGetters, mapActions } from "vuex";

const formRef = "stockfirst-form";

export default {
  components: {
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    // eslint-disable-next-line
    DxTextArea,
  },
  data: function () {
    return {
      formRef,
    };
  },

  methods: {
    ...mapActions("stockfirst", [
      "setStockFirst",
      "clearStockFirst",
      "setStockFirstStatus",
      "clearStockFirstStatus",
    ]),
  },
  computed: {
    ...mapGetters("stockfirst", [
      "getStockFirst",
      "getStockFirstStatus",
      "getStockFirstForm",
    ]),
  },
};
</script>
<style>
.group-padding {
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.form-block {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  padding-left: 40px;
  padding-right: 40px;
}
</style>

import Api from "@/services/Api";

export default {
  async getData(params) {
    var results = null;

    params.withResult = true;

    await Api()
      .get("users", {
        params: params,
      })
      .then((data) => {
        results = data.data;
        // console.log(results);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });

    return results;
  },

  insert(requestBody, callback, errCallback) {
    return Api()
      .post("users", requestBody)
      .then((data) => {
        callback(data);
      })
      .catch((error) => {
        errCallback(error);
      });
  },
  update(id, requestBody, callback, errCallback) {
    return Api()
      .put("users/" + id, requestBody)
      .then((data) => {
        callback(data);
      })
      .catch((error) => {
        errCallback(error);
      });
  },
  delete(id, callback, errCallback) {
    return Api()
      .delete("users/" + id)
      .then((data) => {
        callback(data);
      })
      .catch((error) => {
        errCallback(error);
      });
  },
};

<template>
  <div id="DeptForm">
    <h2 class="content-block">StockQuarantine View</h2>
    <DxToolbar class="padding-block">
      <DxItem
        :options="backButtonOptions"
        widget="dxButton"
        location="before"
      />
    </DxToolbar>
    <StockQuarantineView />
    <DxLoadPanel
      :position="{ of: '#mainContent' }"
      :visible.sync="loadingVisible"
      :show-indicator="true"
      :show-pane="true"
      :shading="true"
      :close-on-quarantineside-click="false"
      shading-color="rgba(0,0,0,0.4)"
    />
  </div>
</template>

<script>
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import { DxLoadPanel } from "devextreme-vue/load-panel";
// import notify from 'devextreme/ui/notify';
import StockQuarantineView from "@/components/stock-quarantine/StockQuarantineView.vue";
// import rquarantineer from "../../rquarantineer";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    DxToolbar,
    DxItem,
    // FormView,
    DxLoadPanel,
    StockQuarantineView,
  },
  data() {
    return {
      loadingVisible: false,
      backButtonOptions: {
        icon: "chevronleft",
        text: "Kembali",
        onClick: () => {
          this.clearStockQuarantineStatus();
          this.clearStockQuarantine();
          this.$rquarantineer.go(-1);
        },
      },
    };
  },

  methods: {
    ...mapActions("stockquarantine", [
      "setStockQuarantine",
      "clearStockQuarantine",
      "setStockQuarantineStatus",
      "clearStockQuarantineStatus",
    ]),
  },
  computed: {
    ...mapGetters("stockquarantine", [
      "getStockQuarantine",
      "getStockQuarantineStatus",
      "getStockQuarantineForm",
    ]),
  },
};
</script>

<style>
.toolbar-label,
.toolbar-label > b {
  font-size: 16px;
}

#products {
  margin-top: 10px;
}

.padding-block {
  padding-left: 20px;
  padding-right: 20px;
}
</style>
<!---JourneypView -->
<template>
  <DxForm class="form-block" :form-data="getMasterMenu" :read-only="true">
    <DxGroupItem :col-count="2" css-class="group-padding">
      <DxGroupItem>
        <DxSimpleItem data-field="menu_text" :label="{ text: 'Nama Menu' }" />
        <DxSimpleItem data-field="menu_path" :label="{ text: 'Menu Path' }" />
        <DxSimpleItem data-field="menu_icon" :label="{ text: 'Menu Icon' }" />
        <DxSimpleItem data-field="level" :label="{ text: 'level' }" />
        <DxSimpleItem data-field="parent_id" :label="{ text: 'Parent Menu' }" />
      </DxGroupItem>
    </DxGroupItem>
  </DxForm>
</template>
<script>
import { DxForm, DxSimpleItem, DxGroupItem } from "devextreme-vue/form";
import DxTextArea from "devextreme-vue/text-area";
import { mapGetters } from "vuex";
// import axios from "axios";


export default {
  components: {
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    // eslint-disable-next-line
    DxTextArea,
  },
  data: function () {
    return {
      mastermenu: [],
      // formRef,
    };
  },
  computed: {
    ...mapGetters("mastermenu", ["getMasterMenu"]),
  },
};
</script>
<style>
.group-padding {
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.form-block {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  padding-left: 40px;
  padding-right: 40px;
}
</style>

<template>
  <div>
    <h2 class="content-block">MasterMenuTable</h2>
    <master-menu-table />
  </div>
</template>

<script>
import MasterMenuTable from "@/components/master-menu/MasterMenuTable.vue";
export default {
  components: {
    MasterMenuTable,
  },
};
</script>
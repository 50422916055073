const state = {
  stockreturn: null,
  stockreturnStatus: "",
  stockreturnForm: null,
};

const getters = {
  getStockReturn: (state) => {
    return state.stockreturn;
  },
  getStockReturnStatus: (state) => {
    return state.stockreturnStatus;
  },
  getStockReturnForm: (state) => {
    return state.stockreturnForm;
  },
};

const mutations = {
  setStockReturn: (state, stockreturn) => {
    state.stockreturn = stockreturn;
  },
  clearStockReturn: (state) => {
    state.stockreturn = null;
  },

  setStockReturnStatus: (state, status) => {
    state.stockreturnStatus = status;
  },
  clearStockReturnStatus: (state) => {
    state.stockreturnStatus = "";
  },

  setStockReturnForm: (state, status) => {
    state.stockreturnForm = status;
  },
  clearStockReturnForm: (state) => {
    state.stockreturnForm = "";
  },
};

const actions = {
  setStockReturn({ commit }, data) {
    commit("setStockReturn", data);
  },
  clearStockReturn({ commit }) {
    commit("clearStockReturn");
  },

  setStockReturnStatus({ commit }, status) {
    commit("setStockReturnStatus", status);
  },
  clearStockReturnStatus({ commit }) {
    commit("clearStockReturnStatus");
  },

  setStockReturnForm({ commit }, data) {
    commit("setStockReturnForm", data);
  },
  clearStockReturnForm({ commit }) {
    commit("clearStockReturnForm");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div id="DeptForm">
    <h2 class="content-block">
      {{ getStockOutStatus == "add" ? "Tambah" : "Ubah" }}
      Stock Out
    </h2>
    <DxToolbar class="padding-block">
      <DxItem
        :options="backButtonOptions"
        widget="dxButton"
        location="before"
      />
      <DxItem
        :options="saveButtonOptions"
        widget="dxButton"
        location="before"
      />
    </DxToolbar>
    <StockOutForm />
    <span v-html="linebreaks"></span>
    <DxToolbar class="padding-block">
      <DxItem
        :options="backButtonOptions"
        widget="dxButton"
        location="before"
      />
      <DxItem
        :options="saveButtonOptions"
        widget="dxButton"
        location="before"
      />
    </DxToolbar>
    <DxLoadPanel
      :position="{ of: '#mainContent' }"
      :visible.sync="loadingVisible"
      :show-indicator="true"
      :show-pane="true"
      :shading="true"
      :close-on-outside-click="false"
      shading-color="rgba(0,0,0,0.4)"
    />
  </div>
</template>
<script>
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import { DxLoadPanel } from "devextreme-vue/load-panel";
import router from "../../router";
import { mapGetters, mapActions } from "vuex";
import StockOutForm from "@/components/stock-out/StockOutForm.vue";
import StockOutService from "@/services/StockOutService";
import moment from "moment";
// import { uuid } from 'vue-uuid';

export default {
  components: {
    DxToolbar,
    DxItem,
    StockOutForm,
    DxLoadPanel,
  },
  data() {
    return {
      linebreaks: "<br>",
      loadingVisible: false,
      backButtonOptions: {
        icon: "chevronleft",
        text: "Kembali",
        onClick: () => {
          this.$router.go(-1);
        },
      },
      saveButtonOptions: {
        icon: "save",
        text: "Simpan",
        onClick: () => {
          // eslint-disable-next-line no-console
          var validateResult = this.getStockOutForm.validate();
          if (validateResult.isValid) {
            // var StockOut = {
            //   name : this.getStockOut.name,
            //   shortName : this.getStockOut.shortName,
            //   // uniqueId : uuid.v4(),
            //   description : this.getStockOut.description
            // };
            this.userId = window.localStorage.getItem("usrid");
            var vThis = this;
            var StockOut = this.getStockOut;

            if (StockOut) {
              if (this.getStockOutStatus == "add") {
                console.log("Add StockOut");
                this.loadingVisible = true;
                StockOut.created_by = this.userId;
                StockOut.transaction_number = this.format_date(new Date());
                StockOutService.insert(
                  StockOut,
                  function () {
                    vThis.loadingVisible = false;
                    vThis.clearStockOutStatus();
                    vThis.clearStockOut();
                    router.push("/stock/out");
                  },
                  function () {
                    vThis.loadingVisible = false;
                  }
                );
              } else if (this.getStockOutStatus == "edit") {
                console.log("Edit StockOut ");
                StockOut.updated_by = this.userId;
                StockOutService.update(
                  this.getStockOut.id,
                  StockOut,
                  function () {
                    vThis.loadingVisible = false;
                    vThis.clearStockOutStatus();
                    vThis.clearStockOut();
                    router.push("/stock/out");
                  },
                  function () {
                    vThis.loadingVisible = false;
                  }
                );
              }
            }
          }
        },
      },
    };
  },
  methods: {
    ...mapActions("stockout", [
      "setStockOut",
      "clearStockOut",
      "setStockOutStatus",
      "clearStockOutStatus",
    ]),
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYYMMDDHHmmss");
      }
    },
  },
  computed: {
    ...mapGetters("stockout", [
      "getStockOut",
      "getStockOutStatus",
      "getStockOutForm",
    ]),
  },
};
</script>

<style>
.toolbar-label,
.toolbar-label > b {
  font-size: 16px;
}

#products {
  margin-top: 10px;
}

.padding-block {
  padding-left: 20px;
  padding-right: 20px;
}
</style>
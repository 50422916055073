<template>
  <div id="DeptForm">
    <h2 class="content-block">
      {{ getCustomerStatus == "add" ? "Tambah Calon" : "Ubah" }} Customer
    </h2>
    <DxToolbar class="padding-block">
      <DxItem
        :options="backButtonOptions"
        widget="dxButton"
        location="before"
      />
      <DxItem
        :options="saveButtonOptions"
        widget="dxButton"
        location="before"
      />
    </DxToolbar>
    <CustomerForm />
    <span v-html="linebreaks"></span>
    <DxToolbar class="padding-block">
      <DxItem
        :options="backButtonOptions"
        widget="dxButton"
        location="before"
      />
      <DxItem
        :options="saveButtonOptions"
        widget="dxButton"
        location="before"
      />
    </DxToolbar>
    <DxLoadPanel
      :position="{ of: '#mainContent' }"
      :visible.sync="loadingVisible"
      :show-indicator="true"
      :show-pane="true"
      :shading="true"
      :close-on-outside-click="false"
      shading-color="rgba(0,0,0,0.4)"
    />
  </div>
</template>

<script>
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import { DxLoadPanel } from "devextreme-vue/load-panel";
// import CustomerForm from "@/components/Customers/CustomerForm.vue";
import CustomerForm from "@/components/customer/CustomerForm.vue";
import router from "../../router";
import { mapGetters, mapActions } from "vuex";
import Service from "@/services/CustomerService";
// import { uuid } from 'vue-uuid';
// import axios from "axios";


export default {
  components: {
    DxToolbar,
    DxItem,
    CustomerForm,
    DxLoadPanel,
  },
  created() {},
  data() {
    return {
      linebreaks: "<br>",
      loadingVisible: false,
      backButtonOptions: {
        icon: "chevronleft",
        text: "Kembali",
        onClick: () => {
          this.clearCustomerStatus();
          this.clearCustomer();
          router.push("/customer");
        },
      },
      saveButtonOptions: {
        icon: "save",
        text: "Simpan",
        onClick: () => {
          // eslint-disable-next-line no-console
          var validateResult = this.getCustomerForm.validate();

          if (validateResult.isValid) {
            // var Customer = {
            //   name : this.getCustomer.name,
            //   shortName : this.getCustomer.shortName,
            //   // uniqueId : uuid.v4(),
            //   description : this.getCustomer.description
            // };
            this.userId = window.localStorage.getItem("usrid");
            var Customer = this.getCustomer;
            Customer.type_id = 1; //type_id : 1 =  prospek
            // console.log(
            //   "ModifyCustomerProspek: getCustomer = " +
            //     JSON.stringify(this.getCustomer)
            // );
            // console.log(
            //   "ModifyCustomersProspek: getCustomerStatus = " + this.getCustomerStatus
            // );

            var vThis = this;
            if (this.getCustomerStatus == "add") {
              console.log("getCustomerStatus = " + this.getCustomerStatus);
              this.loadingVisible = true;
              Customer.created_by = this.userId;
              Service.insert(
                Customer,
                function () {
                  vThis.loadingVisible = false;
                  vThis.clearCustomerStatus();
                  vThis.clearCustomer();
                  router.push("/customer");
                },
                function () {
                  vThis.loadingVisible = false;
                }
              );

              //catat tanggal prospek ke tabel customer_history
              // var token = window.localStorage.getItem("token");
              // const config = {
              //   headers: {
              //     Authorization: "Bearer " + token,
              //   },
              // };
              // let formData = new FormData();
              // var dateNow = new Date();
              // formData.append("user_id", this.userId);
              // formData.append("customer_id", this.getCustomer.id);
              // formData.append("prospect_date", dateNow);
              // axios
              //   .post("customer_histories", formData, config)
              //   .then(function (response) {
              //     console.log("response post customer_histories : " + response);
              //     vThis.success = response.data.success;
              //   })
              //   .catch(function (error) {
              //     console.log(error);
              //     vThis.output = error;
              //   });
              /////////////////////
            } else if (this.getCustomerStatus == "edit") {
              console.log("getCustomerStatus = " + this.getCustomerStatus);
              this.loadingVisible = true;
              Customer.updated_by = this.userId;
              Service.update(
                this.getCustomer.id,
                Customer,
                function () {
                  vThis.loadingVisible = false;
                  vThis.clearCustomerStatus();
                  vThis.clearCustomer();
                  router.push("/customer");
                },
                function () {
                  vThis.loadingVisible = false;
                }
              );
            } else {
              this.setStatus = "add";
              Customer.created_by = this.userId;
              Service.insert(
                Customer,
                function () {
                  vThis.loadingVisible = false;
                  vThis.clearCustomerStatus();
                  vThis.clearCustomer();
                  router.push("/customer");
                },
                function () {
                  vThis.loadingVisible = false;
                }
              );
            }
          }
        },
      },
    };
  },
  methods: {
    settypeId(value) {
      this.typeid = value;
      console.log("typeId = " + value);
    },
    // setprovinceId(value) {
    //   this.provinceId = value;
    //   console.log("provinceId = " + value);
    // },
    // setcityId(value) {
    //   this.cityId = value;
    //   console.log("cityId = " + value);
    // },
    // setcountryId(value) {
    //   this.countryId = value;
    //   console.log("countryId = " + value);
    // },
    ...mapActions("customer", ["clearCustomer", "setStatus", "clearCustomerStatus"]),
  },
  computed: {
    ...mapGetters("customer", ["getCustomer", "getCustomerStatus", "getCustomerForm"]),
  },
};
</script>

<style>
.toolbar-label,
.toolbar-label > b {
  font-size: 16px;
}

#products {
  margin-top: 10px;
}

.padding-block {
  padding-left: 20px;
  padding-right: 20px;
}
</style>
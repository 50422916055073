const state = {
  masteritem: null,
  masteritemStatus: "",
  masteritemForm: null,
};

const getters = {
  getMasterItem: (state) => {
    return state.masteritem;
  },
  getMasterItemStatus: (state) => {
    return state.masteritemStatus;
  },
  getMasterItemForm: (state) => {
    return state.masteritemForm;
  },
};

const mutations = {
  setMasterItem: (state, masteritem) => {
    state.masteritem = masteritem;
  },
  clearMasterItem: (state) => {
    state.masteritem = null;
  },

  setMasterItemStatus: (state, status) => {
    state.masteritemStatus = status;
  },
  clearMasterItemStatus: (state) => {
    state.masteritemStatus = "";
  },

  setMasterItemForm: (state, status) => {
    state.masteritemForm = status;
  },
  clearMasterItemForm: (state) => {
    state.masteritemForm = "";
  },
};

const actions = {
  setMasterItem({ commit }, data) {
    commit("setMasterItem", data);
  },
  clearMasterItem({ commit }) {
    commit("clearMasterItem");
  },

  setMasterItemStatus({ commit }, status) {
    commit("setMasterItemStatus", status);
  },
  clearMasterItemStatus({ commit }) {
    commit("clearMasterItemStatus");
  },

  setMasterItemForm({ commit }, data) {
    commit("setMasterItemForm", data);
  },
  clearMasterItemForm({ commit }) {
    commit("clearMasterItemForm");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

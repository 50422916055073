<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <DxForm
        @field-data-changed="formFieldDataChanged"
        :form-data="getLocationType"
        :ref="formRef"
        :read-only="false"
        :visible="true"
      >
        <DxGroupItem :col-count="2" css-class="group-padding">
          <DxGroupItem>
            <DxSimpleItem
              data-field="name"
              :label="{ text: 'Nama Lokasi' }"
            />
          </DxGroupItem>
        </DxGroupItem>
      </DxForm>
    </div>
  </div>
</template>
<script>
import { DxForm, DxSimpleItem, DxGroupItem } from "devextreme-vue/form";
import DxTextArea from "devextreme-vue/text-area";
import { mapGetters, mapActions } from "vuex";

const formRef = "locationtype-form";

export default {
  components: {
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    // eslint-disable-next-line
    DxTextArea,
  },
  data: function () {
    return {
      formRef,
    };
  },

  mounted() {
    this.formSet();
  },
  methods: {
    ...mapActions("locationtype", [
      "setLocationType",
      "clearLocationType",
      "setLocationTypeStatus",
      "clearLocationTypeStatus",
      "setLocationTypeForm"
    ]),

    formFieldDataChanged(e) {
      this.setLocationType(e.component.option("formData"));
    },
    formSet() {
      this.setLocationTypeForm(this.$refs[formRef].instance);
    },
  },

  computed: {
    ...mapGetters("locationtype", [
      "getLocationType",
      "getLocationTypeStatus",
      "getLocationTypeForm",
    ]),
  },
};
</script>
<style>
.group-padding {
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.form-block {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  padding-left: 40px;
  padding-right: 40px;
}
</style>

<template>
  <div>
    <h2 class="content-block">Customer</h2>
    <customer-table :CustomerType=1 />
    
  </div>
</template>

<script>
import customerTable from "@/components/customer/CustomerTable.vue";


export default {
  components: {
    customerTable
  }
};
</script>
<template>
  <div id="DeptForm">
    <h2 class="content-block">
      {{ getStockQuarantineStatus == "add" ? "Tambah" : "Ubah" }}
      Stock Quarantine
    </h2>
    <DxToolbar class="padding-block">
      <DxItem
        :options="backButtonOptions"
        widget="dxButton"
        location="before"
      />
      <!-- <DxItem
        :options="saveButtonOptions"
        widget="dxButton"
        location="before"
      /> -->
    </DxToolbar>
    <StockQuarantineForm />
    <span v-html="linebreaks"></span>
    <DxToolbar class="padding-block">
      <DxItem
        :options="backButtonOptions"
        widget="dxButton"
        location="before"
      />
      <!-- <DxItem
        :options="saveButtonOptions"
        widget="dxButton"
        location="before"
      /> -->
    </DxToolbar>
    <DxLoadPanel
      :position="{ of: '#mainContent' }"
      :visible.sync="loadingVisible"
      :show-indicator="true"
      :show-pane="true"
      :shading="true"
      :close-on-quarantineside-click="false"
      shading-color="rgba(0,0,0,0.4)"
    />
  </div>
</template>
<script>
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import { DxLoadPanel } from "devextreme-vue/load-panel";
import router from "../../router";
import { mapGetters, mapActions } from "vuex";
import StockQuarantineForm from "@/components/stock-quarantine/StockQuarantineForm.vue";
import StockQuarantineService from "@/services/StockQuarantineService";
import moment from "moment";
import axios from "axios";
// import { uuid } from 'vue-uuid';

export default {
  components: {
    DxToolbar,
    DxItem,
    StockQuarantineForm,
    DxLoadPanel,
  },
  data() {
    return {
      linebreaks: "<br>",
      loadingVisible: false,
      backButtonOptions: {
        icon: "chevronleft",
        text: "Kembali",
        onClick: () => {
          this.$router.go(-1);
        },
      },
      saveButtonOptions: {
        icon: "save",
        text: "Simpan",
        onClick: () => {
          // eslint-disable-next-line no-console
          var validateResult = this.getStockQuarantineForm.validate();
          if (validateResult.isValid) {
            // var StockQuarantine = {
            //   name : this.getStockQuarantine.name,
            //   shortName : this.getStockQuarantine.shortName,
            //   // uniqueId : uuid.v4(),
            //   description : this.getStockQuarantine.description
            // };
            this.userId = window.localStorage.getItem("usrid");
            var vThis = this;
            var StockQuarantine = this.getStockQuarantine;

            if (StockQuarantine) {
              if (this.getStockQuarantineStatus == "add") {
                console.log("Add StockQuarantine");
                this.loadingVisible = true;
                StockQuarantine.created_by = this.userId;
                StockQuarantine.transaction_number = this.format_date(
                  new Date()
                );
                StockQuarantineService.insert(
                  StockQuarantine,
                  function () {
                    vThis.loadingVisible = false;
                    vThis.clearStockQuarantineStatus();
                    vThis.clearStockQuarantine();
                    router.push("/stock/quarantine");
                  },
                  function () {
                    vThis.loadingVisible = false;
                  }
                );
              } else if (this.getStockQuarantineStatus == "edit") {
                console.log("Edit StockQuarantine ");
                StockQuarantine.updated_by = this.userId;

                const param = {
                  warehouse_id: StockQuarantine.warehouse_id,
                  uuid: StockQuarantine.uuid,
                  transaction_number: StockQuarantine.transaction_number,
                  stock_action: StockQuarantine.stock_action,
                  batch_number: StockQuarantine.batch_number,
                  item_id: StockQuarantine.item_id,
                  qty: StockQuarantine.qty,
                  item_condition_id: StockQuarantine.item_condition_id,
                  remark: StockQuarantine.remark,
                  expired_date: StockQuarantine.expired_date,
                };

                vThis.updateReport(StockQuarantine.uuid, param);

                StockQuarantineService.update(
                  this.getStockQuarantine.id,
                  StockQuarantine,
                  function () {
                    vThis.loadingVisible = false;
                    vThis.clearStockQuarantineStatus();
                    vThis.clearStockQuarantine();
                    router.push("/stock/quarantine");
                  },
                  function () {
                    vThis.loadingVisible = false;
                  }
                );
              } else if (this.getStockQuarantineStatus == "addFromStockIn") {
                console.log("Add StockQuarantine");
                this.loadingVisible = true;
                StockQuarantine.created_by = this.userId;
                /////////////////////
                let formData = new FormData();
                formData.append("warehouse_id", StockQuarantine.warehouse_id);
                formData.append("uuid", StockQuarantine.uuid);
                formData.append(
                  "transaction_number",
                  StockQuarantine.transaction_number
                );
                formData.append("stock_action", 210); //Quarantine
                formData.append("batch_number", StockQuarantine.batch_number);
                formData.append("item_id", StockQuarantine.item_id);
                formData.append("qty", StockQuarantine.qty);
                formData.append(
                  "item_condition_id",
                  StockQuarantine.item_condition_id
                );
                formData.append("remark", "stock-quarantine");
                formData.append("expired_date", StockQuarantine.expired_date);
                axios
                  .post("stock_reports", formData)
                  .then(function (response) {
                    console.log(response);
                    vThis.success = response.data.success;
                  })
                  .catch(function (error) {
                    console.log(error);
                    vThis.output = error;
                  });
                // selanjutanya input data ke tabel t_stockin_detail
                StockQuarantineService.insert(
                  StockQuarantine,
                  function () {
                    vThis.loadingVisible = false;
                    vThis.clearStockQuarantineStatus();
                    vThis.clearStockQuarantine();
                    router.push("/stock/quarantine");
                  },
                  function () {
                    vThis.loadingVisible = false;
                  }
                );
              }
            }
          }
        },
      },
    };
  },
  methods: {
    ...mapActions("stockquarantine", [
      "setStockQuarantine",
      "clearStockQuarantine",
      "setStockQuarantineStatus",
      "clearStockQuarantineStatus",
    ]),
    async updateReport(uuid, param) {
      // this.vReportId = this.getVehicleRepair.vehicle_report_id;
      var vThis = this;
      // var vuuid = uuid;

      /////////////////////////
      try {
        const response1 = await axios.get("stock_reports?uuid=" + uuid);
        var reportId = JSON.stringify(response1.data.data[0].id);
        console.log(
          "report_id : " +
            JSON.stringify("reportId :" + response1.data.data[0].id)
        );
      } catch (error) {
        console.error(error);
      }
      /////////////////////////
      try {
        const response2 = await axios.put("stock_reports/" + reportId, param);
        console.log(response2);
        vThis.success = response2.data.success;
      } catch (error) {
        console.error(error);
      }

      // const param = { report_status: "In Repair" };
      // const patchresult = await axios.patch(
      //   "vehicle_damage_reports/" + reportId,
      //   param
      // );
      // console.log("patchresult : " + JSON.stringify(patchresult));
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYYMMDDHHmmss");
      }
    },
  },
  computed: {
    ...mapGetters("stockquarantine", [
      "getStockQuarantine",
      "getStockQuarantineStatus",
      "getStockQuarantineForm",
    ]),
  },
};
</script>

<style>
.toolbar-label,
.toolbar-label > b {
  font-size: 16px;
}

#products {
  margin-top: 10px;
}

.padding-block {
  padding-left: 20px;
  padding-right: 20px;
}
</style>
<template>
  <div>
    <h2 class="content-block"></h2>
    <dx-data-grid
      class="dx-card wide-card"
      :data-source="dataSource"
      :show-borders="true"
      :focused-row-index="0"
      :focused-row-enabled="true"
      :column-auto-width="true"
      :column-hiding-enabled="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :loadPanel="loadPanel"
      :selected-row-keys="selectedRowKeys"
      @toolbar-preparing="onToolbarPreparing($event)"
      @selection-changed="onSelectionChanged"
      @rowExpanding="onRowExpanding"
      :ref="gridRef"
    >
      <dx-paging :page-size="10" />
      <dx-pager
        :showPageSizeSelector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
        :showNavigationButtons="true"
        infoText="Hal {0} dari {1} ({2} items)"
        :visible="true"
      />
      <dx-filter-row :visible="filterStatus" />
      <DxSelection mode="multiple" />
      <DxColumn data-field="full_name" caption="Name" />
      <!-- <DxColumn data-field="user_id" caption="user id" /> -->
      <!-- <DxColumn data-field="address" caption="Alamat" />
      <DxColumn data-field="phone" caption="phone" />
      <DxColumn data-field="email" caption="email" />
      <DxColumn data-field="npwp" caption="npwp" /> -->
      <DxColumn data-field="department_name" caption="Department" />
      <DxColumn data-field="job_position_name" caption="Position" />
      <DxColumn
        data-field="location_office_id"
        caption="Office"
        :calculate-cell-value="nEmployee"
      />
      <!-- <DxColumn
        data-field="photo_path"
        caption="Foto Karyawan"
        cell-template="imgPath"
      />
      <template #imgPath="{ data }">
        <div>
          <a class="text-link" :href="imgUrl + data.value" :target="'_blank'">
            <img :src="imgUrl + data.value" class="imgpart"
          /></a>
        </div>
      </template>
      <DxColumn
        data-field="sign_img_path"
        caption="Foto TTD"
        cell-template="imgSignPath"
      />
      <template #imgSignPath="{ data }">
        <div>
          <a class="text-link" :href="imgUrl + data.value" :target="'_blank'">
            <img :src="imgUrl + data.value" class="imgpart"
          /></a>
        </div>
      </template> -->
    </dx-data-grid>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxPager,
  DxPaging,
  DxSelection,
  DxColumn,
  DxFilterRow,
} from "devextreme-vue/data-grid";
import CustomStore from "devextreme/data/custom_store";

import EmployeeService from "@/services/EmployeeService";
// import CustomerService from "@/services/CustomerService";

import router from "../../router";
import { mapGetters, mapActions } from "vuex";
import { confirm } from "devextreme/ui/dialog";

import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
// import { exportDataGrid } from "devextreme/excel_exporter";
// import { Workbook } from "exceljs";
// import saveAs from "file-saver";

// import axios from "axios";

const gridRef = "employee-table";
let base_url = process.env.VUE_APP_BASE_URL;

export default {
  // props: ["CustomerId"],
  components: {
    DxDataGrid,
    DxPager,
    DxPaging,
    DxSelection,
    DxColumn,
    DxFilterRow,
  },
  data() {
    const vthis = this;

    let currentUrl = window.location.origin;

    if (currentUrl.includes("localhost")) {
      base_url = "http://127.0.0.1:84";
      // base_url = "http://bewms.local:10080/api/";
    } else if (currentUrl.includes("10.1.10.21")) {
      base_url = "http://10.1.10.21:84";
    } else {
      base_url = process.env.VUE_APP_BASE_URL;
    }

    const dataSource = {
      store: new CustomStore({
        key: "id",
        load: async function (loadOptions) {
          var sortedBy = "";
          if (loadOptions.sort) {
            sortedBy += loadOptions.sort[0].selector;
            if (loadOptions.sort[0].desc) {
              sortedBy += " desc";
            } else {
              sortedBy += " asc";
            }
          }

          var perPage = loadOptions.take;
          var offset = loadOptions.skip;
          var page = offset / perPage + 1;

          var datax = vthis.fetch({
            perPage: perPage || 10,
            page: page || 1,
            sortedBy: sortedBy,
            parentId: "null",
          });

          return await datax;
        },
      }),
    };

    return {
      filterStatus: false,
      filterText: "Filter On",

      gridRef,
      dataSource: dataSource,
      pageSizes: [5, 10, 15],
      selectedRowKeys: [],
      loadPanel: {
        text: "Memuat...",
      },
      btnAdd: null,
      btnEdit: null,
      btnDelete: null,
      btnView: null,
      btnCustFollow: null,
      btnCustPotensial: null,
      btnCustNew: null,
      // btnWhitelist: null,
      tipe: this.tipe,
      custid: null,
      mydata: null,
      FormCustomer: null,
      // imgPath: "",
      // imgSignPath: "",
      imgFile: null,
      imgBase64: "",
      cImgName: "",
      currentImgPathIndex: [],
      imgUrl: base_url + "/storage/upload/",
    };
  },
  methods: {
    ...mapActions("employee", [
      "setEmployee",
      "clearEmployee",
      "setEmployeeStatus",
    ]),
    nEmployee(data) {
      if (data.location_office_id == 1) {
        return "Pusat";
      } else {
        return "Cabang";
      }
    },

    // customizeText(cellInfo) {
    //   if (cellInfo.value == null) {
    //     return "0";
    //   } else {
    //     return cellInfo.value + "";
    //   }
    // },
    ///
    // setCellValue(newData, value, currentRowData) {
    //   newData.actual_sales = value;
    //   newData.TotalPrice = currentRowData.Price * value;
    //   console.log("value : " + value);
    // },
    //////////////////////////////////////
    // calculateCellValue(data) {
    //   var targetSales = data.target_amount;
    //   var actualSales = data.actual_sales;
    //   return Math.ceil((actualSales / targetSales) * 100) + " %";
    // },
    //////////////////////////////////////
    async fetch(params = {}) {
      // eslint-disable-next-line no-console
      if (Object.keys(params).length === 0) {
        params = {
          perPage: 10,
          page: 1,
          // parentId: "null",
        };
      }
      var results = await EmployeeService.getData(params);
      // eslint-disable-next-line no-console
      var datax = await {
        data: results.data,
        totalCount: 10,
        // totalCount: results.total,
      };

      return await datax;
    },

    onSelectionChanged({ selectedRowKeys, selectedRowsData }) {
      // console.log("selectedRowKeys : " + selectedRowKeys.length);
      if (selectedRowKeys.length > 0) {
        if (selectedRowKeys.length == 1) {
          this.setEmployee(selectedRowsData[0]);
          // console.log(
          //   "selectedRowKeys : " + JSON.stringify(selectedRowsData[0])
          // );
          this.btnEdit.option({
            disabled: false,
          });
          this.btnView.option({
            disabled: false,
          });
        } else {
          this.setEmployee(selectedRowsData);
          // console.log(selectedRowsData);
          this.btnEdit.option({
            disabled: true,
          });
          this.btnView.option({
            disabled: true,
          });
        }
        this.btnDelete.option({
          disabled: false,
        });
      } else {
        this.clearEmployee();
        this.btnEdit.option({
          disabled: true,
        });
        this.btnDelete.option({
          disabled: true,
        });
        this.btnView.option({
          disabled: true,
        });
      }
    },
    onToolbarPreparing(e) {
      var vThis = this;

      e.toolbarOptions.items.unshift(
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "add",
            text: "Tambah",
            onInitialized: function (e) {
              vThis.btnAdd = e.component;
            },
            onClick: function () {
              vThis.clearEmployee();
              vThis.setEmployeeStatus("add");
              router.push("/employee/add");
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "edit",
            text: "Edit",
            disabled: true,
            onInitialized: function (e) {
              vThis.btnEdit = e.component;
            },
            onClick: function () {
              vThis.setEmployeeStatus("edit");
              router.push("/employee/edit");
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "trash",
            text: "Hapus",
            disabled: true,
            onInitialized: function (e) {
              vThis.btnDelete = e.component;
            },
            onClick: function () {
              let result = confirm(
                "<i>Anda yakin mau menghapus data ini ?</i>",
                "Hapus Employee"
              );
              result.then((dialogResult) => {
                if (dialogResult) {
                  var employeeData = vThis.getEmployee;
                  console.log(vThis.getEmployee);
                  var ids = "";
                  if (Array.isArray(employeeData)) {
                    employeeData.forEach(function (item, index) {
                      if (index == 0) {
                        ids += item.id;
                      } else {
                        ids += "," + item.id;
                      }
                    });
                  } else {
                    ids = employeeData.id;
                  }
                  EmployeeService.delete(
                    ids,
                    function () {
                      vThis.clearEmployee();
                      vThis.dataGrid.refresh();
                    },
                    function (error) {
                      // eslint-disable-next-line no-console
                      console.log(error);
                    }
                  );
                }
              });
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "detailslayout",
            text: "Detail",
            disabled: true,
            onInitialized: function (e) {
              vThis.btnView = e.component;
            },
            onClick: function () {
              router.push("/employee/view");
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "refresh",
            text: "Muat Ulang",
            onClick: function () {
              vThis.dataGrid.refresh();
            },
          },
        },
        // Filter //
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "search",
            text: vThis.filterText,
            disabled: false,
            onInitialized: function (e) {
              vThis.btnFilter = e.component;
            },
            onClick: function () {
              if (vThis.filterStatus === true) {
                vThis.filterStatus = false;
                vThis.filterText = "Filter ON";
                // console.log("filterStatus : " + vThis.filterStatus);
              } else {
                vThis.filterStatus = true;
                vThis.filterText = "Filter Off";
                // console.log("filterStatus : " + vThis.filterStatus);
              }
            },
          },
        }
      );
    },
    onRowExpanding(e) {
      var dataGrid = e.component;
      dataGrid.collapseAll(-1);
    },
  },

  computed: {
    ...mapGetters("employee", ["getEmployee", "getEmployeeStatus"]),
    dataGrid: function () {
      return this.$refs[gridRef].instance;
    },
  },
};
</script>

<style>
.imgpart {
  /* display: block;
  text-align: left; */
  float: left;
  /* margin-left: auto;
  margin-right: auto; */
  width: 200px;
  height: 200px;
}
</style>
<template>
  <div id="DeptForm">
    <h2 class="content-block">
      {{ getRoleMenuStatus == "add" ? "Tambah" : "Ubah" }}
      Role Menu
    </h2>
    <DxToolbar class="padding-block">
      <DxItem
        :options="backButtonOptions"
        widget="dxButton"
        location="before"
      />
      <DxItem
        :options="saveButtonOptions"
        widget="dxButton"
        location="before"
      />
    </DxToolbar>
    <RoleMenuForm />
    <span v-html="linebreaks"></span>
    <DxToolbar class="padding-block">
      <DxItem
        :options="backButtonOptions"
        widget="dxButton"
        location="before"
      />
      <DxItem
        :options="saveButtonOptions"
        widget="dxButton"
        location="before"
      />
    </DxToolbar>
    <DxLoadPanel
      :position="{ of: '#mainContent' }"
      :visible.sync="loadingVisible"
      :show-indicator="true"
      :show-pane="true"
      :shading="true"
      :close-on-outside-click="false"
      shading-color="rgba(0,0,0,0.4)"
    />
  </div>
</template>
<script>
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import { DxLoadPanel } from "devextreme-vue/load-panel";
import RoleMenuForm from "@/components/role-menu/RoleMenuForm.vue";
import router from "../../router";
import { mapGetters, mapActions } from "vuex";
import RoleMenuService from "@/services/RoleMenuService";
// import { uuid } from 'vue-uuid';
import axios from "axios";


export default {
  components: {
    DxToolbar,
    DxItem,
    DxLoadPanel,
    RoleMenuForm,
  },
  data() {
    return {
      PriceData: [],
      linebreaks: "<br>",
      loadingVisible: false,
      backButtonOptions: {
        icon: "chevronleft",
        text: "Kembali",
        onClick: () => {
          // this.clearCustomer();
          // this.clearStatus();
          this.clearRoleMenuStatus();

          // console.log(
          //   "ModifyRoleMenuPages : RoleMenu = " +
          //     JSON.stringify(this.getRoleMenu.description)
          // );
          // this.clearRoleMenu();
          // router.push("/master/rolemenu");
          this.$router.go(-1);
        },
      },
      saveButtonOptions: {
        icon: "save",
        text: "Simpan",
        onClick: () => {
          // eslint-disable-next-line no-console
          var validateResult = this.getRoleMenuForm.validate();
          if (validateResult.isValid) {
            // var Customer = {
            //   name : this.getCustomer.name,
            //   shortName : this.getCustomer.shortName,
            //   // uniqueId : uuid.v4(),
            //   description : this.getCustomer.description
            // };

            // var Customer = this.getCustomer;
            this.userId = window.localStorage.getItem("usrid");
            var RoleMenu = this.getRoleMenu;
            // console.log(
            //   "ModifyRoleMenuPages : getCustomer = " +
            //     JSON.stringify(Customer)
            // );
            // console.log(
            //   "ModifyRoleMenuPages : getRoleMenuStatus = " +
            //     JSON.stringify(this.getRoleMenuStatus)
            // );
            // console.log(
            //   "ModifyRoleMenuPages : getRoleMenu = " +
            //     JSON.stringify(RoleMenu)
            // );
            var vThis = this;

            if (RoleMenu) {
              console.log("RoleMenu = " + JSON.stringify(RoleMenu));
              // vThis.setContactStatus("add");

              if (this.getRoleMenuStatus == "add") {
                this.loadingVisible = true;

                // var RoleMenu = this.getRoleMenu;
                console.log("RoleMenu = " + JSON.stringify(RoleMenu));

                axios
                  .post("role_menus", {
                    RoleMenu,
                  })
                  .then((res) => {
                    console.log(res);
                    if (res.data.success) {
                      vThis.loadingVisible = false;
                      vThis.clearRoleMenuStatus();
                      vThis.clearRoleMenu();
                      router.push("/master/rolemenu");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });

                // RoleMenu.created_by = this.userId;
                // RoleMenuService.insert(
                //   RoleMenu,
                //   function () {
                //     vThis.loadingVisible = false;
                //     vThis.clearRoleMenuStatus();
                //     vThis.clearRoleMenu();
                //     router.push("/master/rolemenu");
                //   },
                //   function () {
                //     vThis.loadingVisible = false;
                //   }
                // );
              } else if (this.getRoleMenuStatus == "edit") {
                RoleMenu.updated_by = this.userId;
                RoleMenuService.update(
                  this.getRoleMenu.id,
                  RoleMenu,
                  function () {
                    vThis.loadingVisible = false;
                    vThis.clearRoleMenuStatus();
                    vThis.clearRoleMenu();
                    router.push("/master/rolemenu");
                  },
                  function () {
                    vThis.loadingVisible = false;
                  }
                );
              }
            } else {
              router.push("/master/rolemenu");
            }
          }
        },
      },
    };
  },
  methods: {
    ...mapActions("rolemenu", [
      "setRoleMenu",
      "setRoleMenuStatus",
      "clearRoleMenu",
      "clearRoleMenuStatus",
    ]),
  },
  computed: {
    ...mapGetters("rolemenu", [
      "getRoleMenu",
      "getRoleMenuStatus",
      "getRoleMenuForm",
    ]),
  },
};
</script>

<style>
.toolbar-label,
.toolbar-label > b {
  font-size: 16px;
}

#products {
  margin-top: 10px;
}

.padding-block {
  padding-left: 20px;
  padding-right: 20px;
}
</style>
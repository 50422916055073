<template>
  <div id="DeptForm">
    <h2 class="content-block">
      {{ getUserStatus == "add" ? "Tambah" : "Ubah" }}
      Keterangan User
    </h2>
    <DxToolbar class="padding-block">
      <DxItem
        :options="backButtonOptions"
        widget="dxButton"
        location="before"
      />
      <DxItem
        :options="saveButtonOptions"
        widget="dxButton"
        location="before"
      />
    </DxToolbar>

    <UserForm />

    <span v-html="linebreaks"></span>
    <DxToolbar class="padding-block">
      <DxItem
        :options="backButtonOptions"
        widget="dxButton"
        location="before"
      />
      <DxItem
        :options="saveButtonOptions"
        widget="dxButton"
        location="before"
      />
    </DxToolbar>
    <DxLoadPanel
      :position="{ of: '#mainContent' }"
      :visible.sync="loadingVisible"
      :show-indicator="true"
      :show-pane="true"
      :shading="true"
      :close-on-outside-click="false"
      shading-color="rgba(0,0,0,0.4)"
    />
  </div>
</template>
<script>
import UserForm from "@/components/users/UserForm.vue";

import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import { DxLoadPanel } from "devextreme-vue/load-panel";

import router from "../../router";
import { mapGetters, mapActions } from "vuex";
// import CustomerService from "@/services/CustomerService";
import UserService from "@/services/UserService";
import RegisterUserService from "@/services/RegisterUserService";
// import { uuid } from 'vue-uuid';

export default {
  components: {
    DxToolbar,
    DxItem,
    UserForm,
    DxLoadPanel,
  },
  data() {
    return {
      linebreaks: "<br>",
      loadingVisible: false,
      backButtonOptions: {
        icon: "chevronleft",
        text: "Kembali",
        onClick: () => {
          router.push("/users");
        },
      },
      saveButtonOptions: {
        icon: "save",
        text: "Simpan",
        onClick: () => {
          // eslint-disable-next-line no-console
          var validateResult = this.getUserForm.validate();
          if (validateResult.isValid) {
            // var Customer = {
            //   name : this.getCustomer.name,
            //   shortName : this.getCustomer.shortName,
            //   // uniqueId : uuid.v4(),
            //   description : this.getCustomer.description
            // };
            this.userId = window.localStorage.getItem("usrid");
            var User = this.getUser;
            console.log("Add User : " + JSON.stringify(this.getUser));
            var vThis = this;

            if (this.getUserStatus == "add") {
              console.log("Add User ");
              this.loadingVisible = true;
              // User.created_by = this.userId;
              RegisterUserService.insert(
                User,
                function () {
                  vThis.loadingVisible = false;
                  vThis.clearUserStatus();
                  vThis.clearUser();
                  router.push("/users");
                },
                function () {
                  vThis.loadingVisible = false;
                }
              );
            } else if (this.getUserStatus == "edit") {
              console.log("Edit User ");
              User.updated_by = this.userId;
              UserService.update(
                this.getUser.id,
                User,
                function () {
                  vThis.loadingVisible = false;
                  vThis.clearUserStatus();
                  vThis.clearUser();
                  router.push("/users");
                },
                function () {
                  vThis.loadingVisible = false;
                }
              );
            }
            // }
          }
        },
      },
    };
  },
  methods: {
    ...mapActions("users", [
      "setUser",
      "setUserStatus",
      "clearUser",
      "clearUserStatus",
    ]),
  },
  computed: {
    ...mapGetters("users", ["getUser", "getUserStatus", "getUserForm"]),
  },
};
</script>

<style>
.toolbar-label,
.toolbar-label > b {
  font-size: 16px;
}

#products {
  margin-top: 10px;
}

.padding-block {
  padding-left: 20px;
  padding-right: 20px;
}
</style>
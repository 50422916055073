<template>
  <div id="DeptForm">
    <h2 class="content-block">
      {{ getClientsCustomerStatus == "add" ? "Tambah " : "Ubah " }} Clients
      Customer
    </h2>
    <DxToolbar class="padding-block">
      <DxItem
        :options="backButtonOptions"
        widget="dxButton"
        location="before"
      />
      <DxItem
        :options="saveButtonOptions"
        widget="dxButton"
        location="before"
      />
    </DxToolbar>
    <ClientsCustomerForm />
    <span v-html="linebreaks"></span>
    <DxToolbar class="padding-block">
      <DxItem
        :options="backButtonOptions"
        widget="dxButton"
        location="before"
      />
      <DxItem
        :options="saveButtonOptions"
        widget="dxButton"
        location="before"
      />
    </DxToolbar>
    <DxLoadPanel
      :position="{ of: '#mainContent' }"
      :visible.sync="loadingVisible"
      :show-indicator="true"
      :show-pane="true"
      :shading="true"
      :close-on-outside-click="false"
      shading-color="rgba(0,0,0,0.4)"
    />
  </div>
</template>

<script>
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import { DxLoadPanel } from "devextreme-vue/load-panel";
// import CustomerForm from "@/components/Customers/CustomerForm.vue";
// import CustomerForm from "@/components/customer/CustomerForm.vue";
import ClientsCustomerForm from "@/components/clients-customer/ClientsCustomerForm.vue";
import router from "../../router";
import { mapGetters, mapActions } from "vuex";
import ClientsCustomerService from "@/services/ClientsCustomerService";
// import { uuid } from 'vue-uuid';
// import axios from "axios";


export default {
  components: {
    DxToolbar,
    DxItem,
    ClientsCustomerForm,
    DxLoadPanel,
  },
  created() {},
  data() {
    return {
      linebreaks: "<br>",
      loadingVisible: false,
      backButtonOptions: {
        icon: "chevronleft",
        text: "Kembali",
        onClick: () => {
          this.$router.go(-1);
        },
      },
      saveButtonOptions: {
        icon: "save",
        text: "Simpan",
        onClick: () => {
          // eslint-disable-next-line no-console
          var validateResult = this.getClientsCustomerForm.validate();

          if (validateResult.isValid) {
            // var Customer = {
            //   name : this.getCustomer.name,
            //   shortName : this.getCustomer.shortName,
            //   // uniqueId : uuid.v4(),
            //   description : this.getCustomer.description
            // };
            this.userId = window.localStorage.getItem("usrid");
            var ClientsCustomer = this.getClientsCustomer;

            // console.log(
            //   "ModifyCustomerProspek: getCustomer = " +
            //     JSON.stringify(this.getCustomer)
            // );

            var vThis = this;
            if (this.getClientsCustomerStatus == "add") {
              console.log("getStatus = " + this.getClientsCustomerStatus);
              this.loadingVisible = true;
              ClientsCustomer.created_by = this.userId;
              ClientsCustomerService.insert(
                ClientsCustomer,
                function () {
                  vThis.loadingVisible = false;
                  vThis.clearClientsCustomerStatus();
                  vThis.clearClientsCustomer();
                  router.push("/clients-customer");
                },
                function () {
                  vThis.loadingVisible = false;
                }
              );
            } else if (this.getClientsCustomerStatus == "edit") {
              console.log("getStatus = " + this.getClientsCustomerStatus);
              this.loadingVisible = true;
              ClientsCustomer.updated_by = this.userId;
              ClientsCustomerService.update(
                this.getClientsCustomer.id,
                ClientsCustomer,
                function () {
                  vThis.loadingVisible = false;
                  vThis.clearClientsCustomerStatus();
                  vThis.clearClientsCustomer();
                  router.push("/clients-customer");
                },
                function () {
                  vThis.loadingVisible = false;
                }
              );
            }
          }
        },
      },
    };
  },
  methods: {
    settypeId(value) {
      this.typeid = value;
      console.log("typeId = " + value);
    },

    ...mapActions("clientscustomer", [
      "clearClientsCustomer",
      "setClientsCustomerStatus",
      "clearClientsCustomerStatus",
    ]),
  },
  computed: {
    ...mapGetters("clientscustomer", [
      "getClientsCustomer",
      "getClientsCustomerStatus",
      "getClientsCustomerForm",
    ]),
  },
};
</script>

<style>
.toolbar-label,
.toolbar-label > b {
  font-size: 16px;
}

#products {
  margin-top: 10px;
}

.padding-block {
  padding-left: 20px;
  padding-right: 20px;
}
</style>
<template>
  <DxForm
    class="form-block"
    :form-data="getStockOutDetail"
    :read-only="true"
    :ref="formRef"
    :col-count="2"
  >
    <DxGroupItem>
      <DxSimpleItem
        data-field="transaction_number"
        :label="{ text: 'No Transaksi' }"
        :visible="false"
        :editor-options="{ value: this.getStockOutDetailTrxNum }"
      >
      </DxSimpleItem>

      <DxSimpleItem data-field="item_name" :label="{ text: 'Nama Item' }">
      </DxSimpleItem>

      <DxSimpleItem data-field="qty" :label="{ text: 'Jumlah' }">
      </DxSimpleItem>

      <DxSimpleItem data-field="free" :label="{ text: 'Free' }"> </DxSimpleItem>
    </DxGroupItem>

    <DxGroupItem>
      <DxSimpleItem data-field="batch_number" :label="{ text: 'Batch number' }">
      </DxSimpleItem>

      <DxSimpleItem
        data-field="item_condition_name"
        :label="{ text: 'Kondisi Item' }"
      >
      </DxSimpleItem>

      <DxSimpleItem
        data-field="expired_date"
        :label="{ text: 'Tanggal Expired' }"
        editor-type="dxDateBox"
      >
      </DxSimpleItem>
    </DxGroupItem>
  </DxForm>
</template>
<script>
import { DxForm, DxSimpleItem, DxGroupItem } from "devextreme-vue/form";
import DxTextArea from "devextreme-vue/text-area";
import { mapGetters, mapActions } from "vuex";

const formRef = "stockout-form";

export default {
  components: {
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    // eslint-disable-next-line
    DxTextArea,
  },
  data: function () {
    return {
      formRef,
    };
  },

  methods: {
    ...mapActions("stockoutdetail", [
      "setStockOutDetail",
      "clearStockOutDetail",
      "setStockOutDetailStatus",
      "clearStockOutDetailStatus",
      "setStockOutDetailForm",
    ]),
  },
  computed: {
    ...mapGetters("stockoutdetail", [
      "getStockOutDetail",
      "getStockOutDetailStatus",
      "getStockOutDetailForm",
      "getStockOutDetailTrxNum",
    ]),
  },
};
</script>
<style>
.group-padding {
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.form-block {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  padding-left: 40px;
  padding-right: 40px;
}
</style>

const state = {
  locationtype: null,
  locationtypeStatus: "",
  locationtypeForm: null,
};

const getters = {
  getLocationType: (state) => {
    return state.locationtype;
  },
  getLocationTypeStatus: (state) => {
    return state.locationtypeStatus;
  },
  getLocationTypeForm: (state) => {
    return state.locationtypeForm;
  },
};

const mutations = {
  setLocationType: (state, locationtype) => {
    state.locationtype = locationtype;
  },
  clearLocationType: (state) => {
    state.locationtype = null;
  },

  setLocationTypeStatus: (state, status) => {
    state.locationtypeStatus = status;
  },
  clearLocationTypeStatus: (state) => {
    state.locationtypeStatus = "";
  },

  setLocationTypeForm: (state, status) => {
    state.locationtypeForm = status;
  },
  clearLocationTypeForm: (state) => {
    state.locationtypeForm = "";
  },
};

const actions = {
  setLocationType({ commit }, data) {
    commit("setLocationType", data);
  },
  clearLocationType({ commit }) {
    commit("clearLocationType");
  },

  setLocationTypeStatus({ commit }, status) {
    commit("setLocationTypeStatus", status);
  },
  clearLocationTypeStatus({ commit }) {
    commit("clearLocationTypeStatus");
  },

  setLocationTypeForm({ commit }, data) {
    commit("setLocationTypeForm", data);
  },
  clearLocationTypeForm({ commit }) {
    commit("clearLocationTypeForm");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"content-block dx-card responsive-paddings"},[_c('DxForm',{ref:_vm.formRef,attrs:{"form-data":_vm.getStockReturnDetailStatus == 'edit' ? _vm.getStockReturnDetail : null,"col-count":2},on:{"field-data-changed":_vm.formFieldDataChanged}},[_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"data-field":"user_id","visible":false,"editor-options":{ value: _vm.userId }}}),_c('DxSimpleItem',{attrs:{"data-field":"uuid","visible":false,"editor-options":{
            value: _vm.currentUUid,
          }}}),_c('DxSimpleItem',{attrs:{"data-field":"warehouse_id","label":{ text: 'warehouse_id' },"visible":false,"editor-options":{
            value: this.getStockReturnDetailTrxNum[0].warehouse_id,
          }}}),_c('DxSimpleItem',{attrs:{"data-field":"transaction_number","label":{ text: 'No Transaksi' },"visible":false,"editor-options":{
            value: this.getStockReturnDetailTrxNum[0].transaction_number,
          }}}),_c('DxSimpleItem',{attrs:{"data-field":"item_id","label":{ text: 'Pilih Nama Item' }},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('DxSelectBox',{attrs:{"data-source":_vm.GetItem,"display-expr":"item_name","value-expr":"id","placeholder":"Select Source","search-enabled":true,"value":_vm.getStockReturnDetailStatus == 'edit'
                  ? _vm.getStockReturnDetail.item_id
                  : null},on:{"value-changed":_vm.valueChangedItem}})]},proxy:true}])}),_c('DxSimpleItem',{attrs:{"data-field":"qty","label":{ text: 'Jumlah' }}},[_c('DxRequiredRule',{attrs:{"message":"Jumlah Wajib di Isi"}})],1),_c('DxSimpleItem',{attrs:{"data-field":"price","label":{ text: 'Harga' }}},[_c('DxRequiredRule',{attrs:{"message":"Harga Wajib di Isi"}})],1)],1),_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"data-field":"batch_number","label":{ text: 'Batch number' }}},[_c('DxRequiredRule',{attrs:{"message":"Batch number Wajib di Isi"}})],1),_c('DxSimpleItem',{attrs:{"data-field":"item_condition","label":{ text: 'Pilih Kondisi Item' }},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('DxSelectBox',{attrs:{"data-source":_vm.GetItemCondition,"display-expr":"name","value-expr":"id","placeholder":"Select Source","search-enabled":true,"value":_vm.getStockReturnDetailStatus == 'edit'
                  ? _vm.getStockReturnDetail.item_condition_id
                  : null},on:{"value-changed":_vm.valueChangedItemCondition}})]},proxy:true}])}),_c('DxSimpleItem',{attrs:{"data-field":"expired_date","label":{ text: 'Tanggal Expired' },"editor-type":"dxDateBox","editor-options":{ dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ss' }}},[_c('DxRequiredRule',{attrs:{"message":"Tanggal Expired Barang Wajib di Isi"}})],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
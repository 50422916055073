<template>
  <div id="DeptForm">
    <h2 class="content-block">
      {{ getLocationTypeStatus == "add" ? "Tambah" : "Ubah" }}
      Kendaraaan
    </h2>
    <DxToolbar class="padding-block">
      <DxItem
        :options="backButtonOptions"
        widget="dxButton"
        location="before"
      />
      <DxItem
        :options="saveButtonOptions"
        widget="dxButton"
        location="before"
      />
    </DxToolbar>
    <LocationTypeForm />
    <span v-html="linebreaks"></span>
    <DxToolbar class="padding-block">
      <DxItem
        :options="backButtonOptions"
        widget="dxButton"
        location="before"
      />
      <DxItem
        :options="saveButtonOptions"
        widget="dxButton"
        location="before"
      />
    </DxToolbar>
    <DxLoadPanel
      :position="{ of: '#mainContent' }"
      :visible.sync="loadingVisible"
      :show-indicator="true"
      :show-pane="true"
      :shading="true"
      :close-on-outside-click="false"
      shading-color="rgba(0,0,0,0.4)"
    />
  </div>
</template>
<script>
import LocationTypeForm from "@/components/location-type//LocationTypeForm.vue";
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import { DxLoadPanel } from "devextreme-vue/load-panel";
import router from "../../router";
import { mapGetters, mapActions } from "vuex";
import LocationTypeService from "@/services/LocationTypeService";

// import { uuid } from 'vue-uuid';

export default {
  components: {
    DxToolbar,
    DxItem,
    LocationTypeForm,
    DxLoadPanel,
  },
  data() {
    return {
      linebreaks: "<br>",
      loadingVisible: false,
      backButtonOptions: {
        icon: "chevronleft",
        text: "Kembali",
        onClick: () => {
          this.$router.go(-1);
        },
      },
      saveButtonOptions: {
        icon: "save",
        text: "Simpan",
        onClick: () => {
          // eslint-disable-next-line no-console
          var validateResult = this.getLocationTypeForm.validate();
          if (validateResult.isValid) {
            // var LocationType = {
            //   name : this.getLocationType.name,
            //   shortName : this.getLocationType.shortName,
            //   // uniqueId : uuid.v4(),
            //   description : this.getLocationType.description
            // };
            this.userId = window.localStorage.getItem("usrid");
            var vThis = this;
            var LocationType = this.getLocationType;

            if (LocationType) {
              if (this.getLocationTypeStatus == "add") {
                console.log("Add LocationType LocationType ");
                this.loadingVisible = true;
                LocationType.created_by = this.userId;
                LocationTypeService.insert(
                  LocationType,
                  function () {
                    vThis.loadingVisible = false;
                    vThis.clearLocationTypeStatus();
                    vThis.clearLocationType();
                    router.push("/location-type");
                  },
                  function () {
                    vThis.loadingVisible = false;
                  }
                );
              } else if (this.getLocationTypeStatus == "edit") {
                console.log("Edit LocationType LocationType ");
                LocationType.updated_by = this.userId;
                LocationTypeService.update(
                  this.getLocationType.id,
                  LocationType,
                  function () {
                    vThis.loadingVisible = false;
                    vThis.clearLocationTypeStatus();
                    vThis.clearLocationType();
                    router.push("/location-type");
                  },
                  function () {
                    vThis.loadingVisible = false;
                  }
                );
              }
            }
          }
        },
      },
    };
  },
  methods: {
    ...mapActions("locationtype", [
      "setLocationType",
      "clearLocationType",
      "setLocationTypeStatus",
      "clearLocationTypeStatus",
    ]),
  },
  computed: {
    ...mapGetters("locationtype", [
      "getLocationType",
      "getLocationTypeStatus",
      "getLocationTypeForm",
    ]),
  },
};
</script>

<style>
.toolbar-label,
.toolbar-label > b {
  font-size: 16px;
}

#products {
  margin-top: 10px;
}

.padding-block {
  padding-left: 20px;
  padding-right: 20px;
}
</style>
<template>
  <div>
    <div class="master-detail-caption">Stock Out Detail</div>
    <dx-data-grid
      class="dx-card wide-card"
      :data-source="dataSource"
      :show-borders="true"
      :focused-row-index="0"
      :focused-row-enabled="true"
      :column-auto-width="true"
      :column-hiding-enabled="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :loadPanel="loadPanel"
      :selected-row-keys="selectedRowKeys"
      @toolbar-preparing="onToolbarPreparing($event)"
      @selection-changed="onSelectionChanged"
      @rowExpanding="onRowExpanding"
      @exporting="onExporting"
      :ref="gridRef"
    >
      <dx-paging :page-size="10" />
      <dx-pager
        :showPageSizeSelector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
        :showNavigationButtons="true"
        infoText="Total Item : Hal {0} dari {1} ({2} items)"
        :visible="true"
      />
      <dx-filter-row :visible="filterStatus" />
      <DxSelection mode="multiple" />
      <DxSearchPanel :visible="true" />
      <DxExport :enabled="true" />
      <DxColumn
        data-field="rowNumber"
        caption="No"
        :width="70"
      />
      <!-- <template #nomor="{ data }">
        {{ data.rowIndex + 1 }}
      </template> -->

      <DxColumn
        data-field="transaction_number"
        width="200"
        caption="No Transaksi"
      />
      <DxColumn data-field="uuid" width="200" :visible="false" caption="uuid" />
      <DxColumn data-field="item_name" width="200" caption="Nama Item" />
      <DxColumn data-field="qty" caption="Jumlah" />
      <!-- <DxColumn data-field="price" caption="Harga" /> -->
      <DxColumn data-field="batch_number" caption="Batch Number" />
      <DxColumn data-field="item_condition_name" caption="Kondisi" />
      <!-- <DxColumn
        data-field="item_condition"
        caption="Item Condition"
        :customize-text="customizeText"
      /> -->
      <DxColumn
        data-field="expired_date"
        dataType="date"
        format="dd/MM/yyyy"
        caption="Tanggal Expired"
      />
    </dx-data-grid>
    <div class="lastQty">
      Total Jumlah :
      <span class="lastItemQty">{{ totalQty }}</span>
    </div>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxPager,
  DxPaging,
  DxSelection,
  DxColumn,
  DxFilterRow,
  DxExport,
  DxSearchPanel,
} from "devextreme-vue/data-grid";
import CustomStore from "devextreme/data/custom_store";
import StockOutDetailService from "@/services/StockOutDetailService";

import router from "../../router";
import { mapGetters, mapActions } from "vuex";
import { confirm } from "devextreme/ui/dialog";

import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import axios from "axios";
import moment from "moment";


const gridRef = "agreement-table";

export default {
  // props: ["CustomerId"],
  components: {
    DxDataGrid,
    DxPager,
    DxPaging,
    DxSelection,
    DxColumn,
    DxFilterRow,
    DxExport,
    DxSearchPanel,
    // DivisionTable,
  },
  props: {
    templateData: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.userId = window.localStorage.getItem("usrid");
    this.roleid = window.localStorage.getItem("roleid");
    var my_json = {
      transaction_number: this.templateData.data.transaction_number,
      warehouse_id: this.templateData.data.origin_id,
    };
    this.StockOutDetailTemp.push(my_json);
    this.setStockOutDetailTrxNum(this.StockOutDetailTemp);
    this.getTotalQty(this.templateData.data.transaction_number);
    // this.setStockOutDetailTrxNum(this.templateData.data.transaction_number);
    console.log(
      "templateData transaction_number : ",
      this.templateData.data.transaction_number
    );
    console.log("templateData user_id: ", this.templateData.data.user_id);
    // this.setAgreement(this.templateData.data);
    console.log("dataSource: ", this.dataSource);
  },
  data() {
    const vthis = this;
    const dataSource = {
      store: new CustomStore({
        key: "id",
        load: async function (loadOptions) {
          var sortedBy = "";
          if (loadOptions.sort) {
            sortedBy += loadOptions.sort[0].selector;
            if (loadOptions.sort[0].desc) {
              sortedBy += " desc";
            } else {
              sortedBy += " asc";
            }
          }

          var perPage = loadOptions.take;
          var offset = loadOptions.skip;
          var page = offset / perPage + 1;

          var datax = vthis.fetch({
            perPage: perPage || 10,
            page: page || 1,
            sortedBy: sortedBy,
            parentId: "null",
          });

          return await datax;
        },
      }),
    };
    return {
      gridRef,
      dataSource: dataSource,
      pageSizes: [5, 10, 15],
      selectedRowKeys: [],
      loadPanel: {
        text: "Memuat...",
      },
      btnAdd: null,
      btnEdit: null,
      btnDelete: null,
      btnView: null,
      btnCustFollow: null,
      btnCustPotensial: null,
      btnCustNew: null,
      tipe: this.tipe,
      filterStatus: false,
      filterText: "Filter On",
      StockOutDetailTemp: [],
      totalQty: null,
    };
  },
  methods: {
    ...mapActions("stockout", [
      "setStockOut",
      "clearStockOut",
      "setStockOutStatus",
      "clearStockOutStatus",
      "setStockOutForm",
    ]),
    ...mapActions("stockoutdetail", [
      "setStockOutDetail",
      "clearStockOutDetail",
      "setStockOutDetailStatus",
      "clearStockOutDetailStatus",
      "setStockOutDetailForm",
      "setStockOutDetailTrxNum",
      "clearStockOutDetailTemp",
    ]),
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },
    customizeText(cellInfo) {
      if (cellInfo.value == 1) {
        return "Good";
      } else {
        return "Not Good";
      }
    },
    async getTotalQty(trx_num) {
      try {
        const response = await axios.get(
          "stock_out_details/qty/trx/" + trx_num
        );
        this.totalQty = response.data.data;
        console.log("totalQty: " + this.totalQty);
      } catch (error) {
        console.error(error);
      }
    },
    async fetch(params = {}) {
      // eslint-disable-next-line no-console
      if (Object.keys(params).length === 0) {
        params = {
          perPage: 10,
          page: 1,
          // parentId: "null",
        };
      }
      // var results = await StockOutService.getData(params);
      var results =
        await StockOutDetailService.getStockOutDetailDataByTrxNumber(
          this.templateData.data.user_id,
          this.templateData.data.transaction_number,
          params
        );
      // eslint-disable-next-line no-console
      var datax = await {
        data: results.data,
        totalCount: 10,
        // totalCount: results.total,
      };
      return await datax;
    },

    onSelectionChanged({ selectedRowKeys, selectedRowsData }) {
      // console.log("selectedRowKeys : " + selectedRowKeys.length);
      if (selectedRowKeys.length > 0) {
        if (selectedRowKeys.length == 1) {
          this.setStockOutDetail(selectedRowsData[0]);
          console.log(
            "getStockOutDetail :" + JSON.stringify(this.getStockOutDetail)
          );
          this.btnAdd.option({
            disabled: false,
          });
          this.btnEdit.option({
            disabled: false,
          });
          this.btnView.option({
            disabled: false,
          });
        } else {
          this.setStockOut(selectedRowsData);
          // console.log(selectedRowsData);
          this.btnAdd.option({
            disabled: true,
          });
          this.btnEdit.option({
            disabled: true,
          });
          this.btnView.option({
            disabled: true,
          });
        }
        this.btnDelete.option({
          disabled: false,
        });
      } else {
        this.clearStockOut();
        this.btnAdd.option({
          disabled: true,
        });
        this.btnEdit.option({
          disabled: true,
        });
        this.btnDelete.option({
          disabled: true,
        });
        this.btnView.option({
          disabled: true,
        });
      }
    },
    onToolbarPreparing(e) {
      var vThis = this;

      e.toolbarOptions.items.unshift(
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "add",
            text: "Tambah",
            onInitialized: function (e) {
              vThis.btnAdd = e.component;
            },
            onClick: function () {
              vThis.setStockOutDetailStatus("add");
              vThis.clearStockOutDetailTemp();
              vThis.clearStockOutDetail();
              router.push("/stock/out/detail/add");
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "edit",
            text: "Edit",
            disabled: true,
            visible: false, //jika role bukan user maka dihide
            onInitialized: function (e) {
              vThis.btnEdit = e.component;
            },
            onClick: function () {
              vThis.setStockOutDetailStatus("edit");
              vThis.clearStockOutDetailTemp();
              router.push("/stock/out/detail/edit");
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "trash",
            text: "Hapus",
            disabled: true,
            visible: this.roleid == 5 ? false : true, //jika role adalah user maka dihide
            onInitialized: function (e) {
              vThis.btnDelete = e.component;
            },
            onClick: function () {
              let result = confirm(
                "<i>Anda yakin mau menghapus data ini ?</i>",
                "Hapus StockOut StockOut"
              );
              result.then((dialogResult) => {
                if (dialogResult) {
                  var StockOutDetail = vThis.getStockOutDetail;
                  console.log(vThis.getStockOutDetail);
                  var ids = "";
                  if (Array.isArray(StockOutDetail)) {
                    StockOutDetail.forEach(function (item, index) {
                      if (index == 0) {
                        ids += item.id;
                      } else {
                        ids += "," + item.id;
                      }
                    });
                  } else {
                    ids = StockOutDetail.id;
                  }
                  vThis.deleteReport(StockOutDetail.uuid);

                  StockOutDetailService.delete(
                    ids,
                    function () {
                      vThis.clearStockOutDetail();
                      vThis.dataGrid.refresh();
                    },
                    function (error) {
                      // eslint-disable-next-line no-console
                      console.log(error);
                    }
                  );
                }
              });
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "detailslayout",
            text: "Detail",
            disabled: true,
            onInitialized: function (e) {
              vThis.btnView = e.component;
            },
            onClick: function () {
              router.push("/stock/out/detail/view");
            },
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "refresh",
            text: "Muat Ulang",
            onClick: function () {
              vThis.dataGrid.refresh();
            },
          },
        },
        // Filter //
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "search",
            text: vThis.filterText,
            disabled: false,
            visible: false,
            onInitialized: function (e) {
              vThis.btnFilter = e.component;
            },
            onClick: function () {
              if (vThis.filterStatus === true) {
                vThis.filterStatus = false;
                vThis.filterText = "Filter ON";
              } else {
                vThis.filterStatus = true;
                vThis.filterText = "Filter Off";
              }
            },
          },
        }
      );
    },
    async deleteReport(uuid) {
      var vThis = this;
      // var vuuid = uuid;
      /////////////////////////
      try {
        const response1 = await axios.get("stock_reports?uuid=" + uuid);
        var reportId = JSON.stringify(response1.data.data[0].id);
        // console.log(
        //   "report_id : " +
        //     JSON.stringify("reportId :" + response1.data.data[0].id)
        // );
      } catch (error) {
        console.error(error);
      }
      /////////////////////////
      try {
        const response2 = await axios.delete("stock_reports/" + reportId);
        // console.log(response2);
        vThis.success = response2.data.success;
      } catch (error) {
        console.error(error);
      }
    },
    onRowExpanding(e) {
      var dataGrid = e.component;
      dataGrid.collapseAll(-1);
    },
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Workbook");

      var vThis = this;

      // const worksheet = workbook
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        topLeftCell: { row: 6, column: 1 },
      })
        .then((cellRange) => {
          // header
          const headerRow2 = worksheet.getRow(2);
          const headerRow3 = worksheet.getRow(3);
          const headerRow4 = worksheet.getRow(4);

          headerRow2.height = 30;
          worksheet.mergeCells(2, 1, 2, 7);

          headerRow2.getCell(1).value = "Stok Out Detail - Picking Slip";
          headerRow2.getCell(1).font = { name: "Segoe UI Light", size: 22 };
          headerRow2.getCell(1).alignment = { horizontal: "center" };

          headerRow3.getCell(1).value = "Warehouse : "+this.templateData.data.origin_name;
          headerRow3.getCell(1).font = { name: "Segoe UI Light", size: 12 };
          headerRow3.getCell(1).alignment = { horizontal: "left" };

          headerRow3.getCell(5).value = "No. Invoice : "+this.templateData.data.invoice_number;
          headerRow3.getCell(5).font = { name: "Segoe UI Light", size: 12 };
          headerRow3.getCell(5).alignment = { horizontal: "left" };

          headerRow4.getCell(1).value = "Tanggal Pengiriman : "+this.format_date(this.templateData.data.delivery_date);
          headerRow4.getCell(1).font = { name: "Segoe UI Light", size: 12 };
          headerRow4.getCell(1).alignment = { horizontal: "left" };

          headerRow4.getCell(5).value = "Tujuan Pengiriman : "+this.templateData.data.destination_name;
          headerRow4.getCell(5).font = { name: "Segoe UI Light", size: 12 };
          headerRow4.getCell(5).alignment = { horizontal: "left" };

          const summaryRowIndex = cellRange.to.row + 1;
          const summaryRow = worksheet.getRow(summaryRowIndex);
          worksheet.mergeCells(summaryRowIndex, 1, summaryRowIndex, 3);

          summaryRow.getCell(3).value = "Total";
          summaryRow.getCell(3).alignment = { horizontal: "right" };
          summaryRow.getCell(3).font = {
            bold: true
          };

          summaryRow.getCell(4).value = this.totalQty;
          summaryRow.getCell(4).alignment = { horizontal: "right" };
          summaryRow.getCell(4).font = {
            bold: true
          };

          const footerRowIndex = cellRange.to.row + 2;
          const footerRow = worksheet.getRow(footerRowIndex);
          worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 7);

          footerRow.getCell(1).value = "Warehouse Management System, Ver.1.0.0";
          footerRow.getCell(1).font = {
            color: { argb: "BFBFBF" },
            italic: true,
          };
          footerRow.getCell(1).alignment = { horizontal: "right" };
        })
        .then(function () {
          workbook.xlsx.writeBuffer().then(function (buffer) {
            saveAs(
              new Blob([buffer], { type: "application/octet-stream" }),
              "Stock-out-detail-"+vThis.templateData.data.invoice_number+".xlsx"
            );
          });
        });
      e.cancel = true;
    },
  },
  computed: {
    ...mapGetters("stockout", [
      "getStockOut",
      "getStockOutStatus",
      "getStockOutForm",
    ]),
    ...mapGetters("stockoutdetail", [
      "getStockOutDetail",
      "getStockOutDetailStatus",
      "getStockOutDetailForm",
    ]),
    dataGrid: function () {
      return this.$refs[gridRef].instance;
    },
  },
};
</script>

<style>
.master-detail-caption {
  padding: 0 0 5px 10px;
  font-size: 14px;
  font-weight: bold;
}
</style>
<template>
  <DxForm
    class="form-block"
    @field-data-changed="formFieldDataChanged"
    :form-data="getWarehouseStatus == 'edit' ? getWarehouse : null"
    :ref="formRef"
  >
    <DxGroupItem :col-count="2" css-class="group-padding">
      <DxGroupItem>
        <DxSimpleItem
          data-field="code"
          :label="{ text: 'Warehouse Code' }"
        >
          <DxRequiredRule message="Warehouse Code Wajib di Isi" />
        </DxSimpleItem>

        <DxSimpleItem
          data-field="name"
          :label="{ text: 'Name' }"
        >
          <DxRequiredRule message="Warehouse Name Wajib di Isi" />
        </DxSimpleItem>

        <DxSimpleItem
          data-field="location"
          :label="{ text: 'Location' }"
        >
          <DxRequiredRule message="Warehouse Location Wajib di Isi" />
        </DxSimpleItem>

        <DxSimpleItem
          data-field="phone"
          :label="{ text: 'Phone' }"
        >
          <DxRequiredRule message="Warehouse Phone Wajib di Isi" />
        </DxSimpleItem>
      </DxGroupItem>

      <DxGroupItem>
        <DxSimpleItem
          data-field="dimension"
          :label="{ text: 'Dimension' }"
        >
          <DxRequiredRule message="Warehouse Dimension Wajib di Isi" />
        </DxSimpleItem>
        <DxSimpleItem
          data-field="address"
          editor-type="dxTextArea"
          :editor-options="{ height: 90 }"
          :label="{ text: 'Alamat' }"
        />

        <DxSimpleItem
          data-field="desc"
          editor-type="dxTextArea"
          :editor-options="{ height: 90 }"
          :label="{ text: 'Deskripsi' }"
        />
      </DxGroupItem>
    </DxGroupItem>
  </DxForm>
</template>
<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from "devextreme-vue/form";
import DxTextArea from "devextreme-vue/text-area";
import { mapGetters, mapActions } from "vuex";

const formRef = "warehouse-form";

export default {
  components: {
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    // eslint-disable-next-line
    DxTextArea,
    DxRequiredRule,
  },
  data: function () {
    return {
      formRef,
    };
  },

  mounted() {
    // console.log(
    //   "WarehouseWarehouseForm : getWarehouse = " +
    //     JSON.stringify(this.getWarehouse)
    // );
    // console.log(
    //   "WarehouseWarehouseForm : getWarehouseStatus = " + this.getStatus
    // );
    this.formSet();
  },
  methods: {
    ...mapActions("warehouse", [
      "setWarehouse",
      "clearWarehouse",
      "setWarehouseStatus",
      "clearWarehouseStatus",
      "setWarehouseForm",
    ]),

    formFieldDataChanged(e) {
      this.setWarehouse(e.component.option("formData"));
    },
    formSet() {
      this.setWarehouseForm(this.$refs[formRef].instance);
    },
  },

  computed: {
    ...mapGetters("warehouse", [
      "getWarehouse",
      "getWarehouseStatus",
      "getWarehouseForm",
    ]),
  },
};
</script>
<style>
.group-padding {
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.form-block {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  padding-left: 40px;
  padding-right: 40px;
}
</style>

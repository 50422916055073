<template>
  <DxForm
    class="form-block"
    :form-data="getStockInDetail"
    :read-only="true"
    :ref="formRef"
    :col-count="2"
  >
    <DxGroupItem>
          <!-- <DxSimpleItem
            data-field="uuid"
            :visible="false"
            :editor-options="{
              value: currentUUid,
            }"
          /> -->
          <DxSimpleItem
            data-field="transaction_number"
            :label="{ text: 'No Transaksi' }"
          >
          </DxSimpleItem>

          <DxSimpleItem
            data-field="item_name"
            :label="{ text: 'Nama Item' }"
          >
          </DxSimpleItem>
          <DxSimpleItem data-field="qty" :label="{ text: 'Jumlah Item' }">
          </DxSimpleItem>
          <DxSimpleItem data-field="price" :label="{ text: 'Harga' }">
          </DxSimpleItem>
        </DxGroupItem>

        <DxGroupItem>
          <DxSimpleItem
            data-field="batch_number"
            :label="{ text: 'batch number' }"
          >
          </DxSimpleItem>

          <DxSimpleItem
            data-field="item_condition_name"
            :label="{ text: 'Kondisi Item' }"
          >
          </DxSimpleItem>

          <DxSimpleItem
            data-field="expired_date"
            :label="{ text: 'Tanggal Expired' }"
            editor-type="dxDateBox"
            :editor-options="{ dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ss' }"
          >
          </DxSimpleItem>
        </DxGroupItem>
  </DxForm>
</template>
<script>
import { DxForm, DxSimpleItem, DxGroupItem } from "devextreme-vue/form";
import DxTextArea from "devextreme-vue/text-area";
import { mapGetters, mapActions } from "vuex";
// import axios from "axios";
const formRef = "stockin-form";

export default {
  components: {
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    // eslint-disable-next-line
    DxTextArea,
  },
  data: function () {
    return {
      formRef,
    };
  },
  created() {
    // axios
    //   .get( "stock_in_details")
    //   .then((response) => {
    //     //console.log(JSON.stringify(response.data));
    //     this.getStockInDetail = response.data.data;
    //   });
  },
  methods: {
    ...mapActions("stockindetail", [
      "setStockInDetail",
      "clearStockInDetail",
      "setStockInDetailStatus",
      "clearStockInDetailStatus",
    ]),
  },
  computed: {
    ...mapGetters("stockindetail", [
      "getStockInDetail",
      "getStockInDetailStatus",
      "getStockInDetailForm",
    ]),
  },
};
</script>
<style>
.group-padding {
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.form-block {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  padding-left: 40px;
  padding-right: 40px;
}
</style>

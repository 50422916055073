const state = {
  stockquarantine: null,
  stockquarantineStatus: "",
  stockquarantineForm: null,
  stockquarantineTemp: null,
};

const getters = {
  getStockQuarantine: (state) => {
    return state.stockquarantine;
  },
  getStockQuarantineStatus: (state) => {
    return state.stockquarantineStatus;
  },
  getStockQuarantineForm: (state) => {
    return state.stockquarantineForm;
  },
  getStockQuarantineTemp: (state) => {
    return state.stockquarantineTemp;
  },
};

const mutations = {
  setStockQuarantine: (state, stockquarantine) => {
    state.stockquarantine = stockquarantine;
  },
  clearStockQuarantine: (state) => {
    state.stockquarantine = null;
  },

  setStockQuarantineStatus: (state, status) => {
    state.stockquarantineStatus = status;
  },
  clearStockQuarantineStatus: (state) => {
    state.stockquarantineStatus = "";
  },

  setStockQuarantineForm: (state, status) => {
    state.stockquarantineForm = status;
  },
  clearStockQuarantineForm: (state) => {
    state.stockquarantineForm = "";
  },
  
  setStockQuarantineTemp: (state, status) => {
    state.stockquarantineTemp = status;
  },
  clearStockQuarantineTemp: (state) => {
    state.stockquarantineTemp = "";
  },
};

const actions = {
  setStockQuarantine({ commit }, data) {
    commit("setStockQuarantine", data);
  },
  clearStockQuarantine({ commit }) {
    commit("clearStockQuarantine");
  },

  setStockQuarantineStatus({ commit }, status) {
    commit("setStockQuarantineStatus", status);
  },
  clearStockQuarantineStatus({ commit }) {
    commit("clearStockQuarantineStatus");
  },

  setStockQuarantineForm({ commit }, data) {
    commit("setStockQuarantineForm", data);
  },
  clearStockQuarantineForm({ commit }) {
    commit("clearStockQuarantineForm");
  },
    
  setStockQuarantineTemp({ commit }, data) {
    commit("setStockQuarantineTemp", data);
  },
  clearStockQuarantineTemp({ commit }) {
    commit("clearStockQuarantineTemp");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div id="DeptForm">
    <h2 class="content-block">
      {{ getCompanyStatus == "add" ? "Tambah" : "Ubah" }}
      Tipe Company
    </h2>
    <DxToolbar class="padding-block">
      <DxItem
        :options="backButtonOptions"
        widget="dxButton"
        location="before"
      />
      <DxItem
        :options="saveButtonOptions"
        widget="dxButton"
        location="before"
      />
    </DxToolbar>
    <CompanyForm />
    <span v-html="linebreaks"></span>
    <DxToolbar class="padding-block">
      <DxItem
        :options="backButtonOptions"
        widget="dxButton"
        location="before"
      />
      <DxItem
        :options="saveButtonOptions"
        widget="dxButton"
        location="before"
      />
    </DxToolbar>
    <DxLoadPanel
      :position="{ of: '#mainContent' }"
      :visible.sync="loadingVisible"
      :show-indicator="true"
      :show-pane="true"
      :shading="true"
      :close-on-outside-click="false"
      shading-color="rgba(0,0,0,0.4)"
    />
  </div>
</template>
<script>
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import { DxLoadPanel } from "devextreme-vue/load-panel";
import CompanyForm from "@/components/company/CompanyForm.vue";
import router from "../../router";
import { mapGetters, mapActions } from "vuex";
import CompanyService from "@/services/CompanyService";
// import { uuid } from 'vue-uuid';
// import axios from "axios";


export default {
  components: {
    DxToolbar,
    DxItem,
    DxLoadPanel,
    CompanyForm,
  },
  data() {
    return {
      PriceData: [],
      linebreaks: "<br>",
      loadingVisible: false,
      backButtonOptions: {
        icon: "chevronleft",
        text: "Kembali",
        onClick: () => {
          // this.clearCustomer();
          // this.clearStatus();
          this.clearCompanyStatus();

          // console.log(
          //   "ModifyCompanyPages : Company = " +
          //     JSON.stringify(this.getCompany.description)
          // );
          // this.clearCompany();
          // router.push("/master/company");
                  this.$router.go(-1);
        },
      },
      saveButtonOptions: {
        icon: "save",
        text: "Simpan",
        onClick: () => {
          // eslint-disable-next-line no-console
          var validateResult = this.getCompanyForm.validate();
          if (validateResult.isValid) {
            // var Customer = {
            //   name : this.getCustomer.name,
            //   shortName : this.getCustomer.shortName,
            //   // uniqueId : uuid.v4(),
            //   description : this.getCustomer.description
            // };

            // var Customer = this.getCustomer;
            this.userId = window.localStorage.getItem("usrid");
            var Company = this.getCompany;
            // console.log(
            //   "ModifyCompanyPages : getCustomer = " +
            //     JSON.stringify(Customer)
            // );
            // console.log(
            //   "ModifyCompanyPages : getCompanyStatus = " +
            //     JSON.stringify(this.getCompanyStatus)
            // );
            // console.log(
            //   "ModifyCompanyPages : getCompany = " +
            //     JSON.stringify(Company)
            // );
            var vThis = this;

            if (Company) {
              console.log("Company = " + JSON.stringify(Company));
              // vThis.setContactStatus("add");

              if (this.getCompanyStatus == "add") {
                this.loadingVisible = true;

                Company.created_by = this.userId;
                CompanyService.insert(
                  Company,
                  function () {
                    vThis.loadingVisible = false;
                    vThis.clearCompanyStatus();
                    vThis.clearCompany();
                    router.push("/master/company");
                  },
                  function () {
                    vThis.loadingVisible = false;
                  }
                );
  
              } else if (this.getCompanyStatus == "edit") {
                Company.updated_by = this.userId;
                CompanyService.update(
                  this.getCompany.id,
                  Company,
                  function () {
                    vThis.loadingVisible = false;
                    vThis.clearCompanyStatus();
                    vThis.clearCompany();
                  },
                  function () {
                    vThis.loadingVisible = false;
                  }
                );

                // if (this.uploadfile1 != null) {
                //   this.uploadFileSubmit1();
                // }
              }
            } else {
              router.push("/master/company");
            }
          }
        },
      },
    };
  },
  methods: {
    ...mapActions("company", [
      "setCompany",
      "setCompanyStatus",
      "clearCompany",
      "clearCompanyStatus",
    ]),

  },
  computed: {
    ...mapGetters("company", [
      "getCompany",
      "getCompanyStatus",
      "getCompanyForm",
    ]),
  },
};
</script>

<style>
.toolbar-label,
.toolbar-label > b {
  font-size: 16px;
}

#products {
  margin-top: 10px;
}

.padding-block {
  padding-left: 20px;
  padding-right: 20px;
}
</style>
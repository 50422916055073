<template>
  <DxForm
    class="form-block"
    :form-data="getClientsCustomer"
    :read-only="true"
    :ref="formRef"
    :col-count="2"
  >
    <DxGroupItem>
      <DxSimpleItem
        data-field="customer_name"
        :label="{ text: 'customer_name' }"
      />

      <DxSimpleItem
        data-field="code"
        :label="{ text: 'ClientsCustomer Code' }"
      />

      <DxSimpleItem
        data-field="name"
        :label="{ text: 'ClientsCustomer Name' }"
      />

      <DxSimpleItem
        data-field="phone"
        :label="{ text: 'ClientsCustomer Phone' }"
      />
    </DxGroupItem>

    <DxGroupItem>
      <DxSimpleItem
        data-field="address"
        editor-type="dxTextArea"
        :editor-options="{ height: 90 }"
        :label="{ text: 'ClientsCustomer Address' }"
      />

      <DxSimpleItem
        data-field="desc"
        editor-type="dxTextArea"
        :editor-options="{ height: 90 }"
        :label="{ text: 'ClientsCustomer Desc' }"
      />
    </DxGroupItem>
  </DxForm>
</template>
<script>
import { DxForm, DxSimpleItem, DxGroupItem } from "devextreme-vue/form";
import DxTextArea from "devextreme-vue/text-area";
import { mapGetters, mapActions } from "vuex";

const formRef = "clientscustomer-form";

export default {
  components: {
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    // eslint-disable-next-line
    DxTextArea,
  },
  data: function () {
    return {
      formRef,
    };
  },

  methods: {
    ...mapActions("clientscustomer", [
      "setClientsCustomer",
      "clearClientsCustomer",
      "setClientsCustomerStatus",
      "clearClientsCustomerStatus",
    ]),
  },
  computed: {
    ...mapGetters("clientscustomer", [
      "getClientsCustomer",
      "getClientsCustomerStatus",
      "getClientsCustomerForm",
    ]),
  },
};
</script>
<style>
.group-padding {
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.form-block {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  padding-left: 40px;
  padding-right: 40px;
}
</style>

const state = {
  Employee: null,
  EmployeeStatus: null,
  EmployeeForm: null,
};

const getters = {
  getEmployee: (state) => {
    return state.Employee;
  },
  getEmployeeStatus: (state) => {
    return state.EmployeeStatus;
  },
  getEmployeeForm: (state) => {
    return state.EmployeeForm;
  },
};

const mutations = {
  setEmployee: (state, Employee) => {
    state.Employee = Employee;
  },
  clearEmployee: (state) => {
    state.Employee = null;
  },

  setEmployeeStatus: (state, status) => {
    state.EmployeeStatus = status;
  },
  clearEmployeeStatus: (state) => {
    state.EmployeeStatus = "";
  },

  setEmployeeForm: (state, status) => {
    state.EmployeeForm = status;
  },
  clearEmployeeForm: (state) => {
    state.EmployeeForm = "";
  },
};

const actions = {
  setEmployee({ commit }, data) {
    commit("setEmployee", data);
  },
  clearEmployee({ commit }) {
    commit("clearEmployee");
  },

  setEmployeeStatus({ commit }, status) {
    commit("setEmployeeStatus", status);
  },
  clearEmployeeStatus({ commit }) {
    commit("clearEmployeeStatus");
  },

  setEmployeeForm({ commit }, data) {
    commit("setEmployeeForm", data);
  },
  clearEmployeeForm({ commit }) {
    commit("clearEmployeeForm");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import Api from "@/services/Api";

export default {
  async getData(params) {
    var results = null;

    params.withResult = true;

    await Api()
      .get("stock_destroys", {
        params: params
      })
      .then(data => {
        results = data.data;
        // console.log(results);
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error);
      });

    return results;
  },
  async getDataByWarehouseId(WarehouseId, params) {
    var results = null;

    await Api()
      .get("stock_destroys/wrh_id/" + WarehouseId, {
        params: params,
      })
      .then((data) => {
        results = data.data;
        // console.log(results);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });

    return results;
  },
  insert(requestBody, callback, errCallback) {
    return Api()
      .post("stock_destroys", requestBody)
      .then(data => {
        callback(data);
      })
      .catch(error => {
        errCallback(error);
      });
  },
  update(id, requestBody, callback, errCallback) {
    return Api()
      .put("stock_destroys/" + id, requestBody)
      .then(data => {
        callback(data);
      })
      .catch(error => {
        errCallback(error);
      });
  },
  delete(id, callback, errCallback) {
    return Api()
      .delete("stock_destroys/" + id)
      .then(data => {
        callback(data);
      })
      .catch(error => {
        errCallback(error);
      });
  },
};

<!-- JourneyViewPages -->
<template>
  <div id="DeptForm">
    <h2 class="content-block">Lihat Master Menu</h2>
    <DxToolbar class="padding-block">
      <DxItem
        :options="backButtonOptions"
        widget="dxButton"
        location="before"
      />
    </DxToolbar>
    <MasterMenuView />
    <DxLoadPanel
      :position="{ of: '#mainContent' }"
      :visible.sync="loadingVisible"
      :show-indicator="true"
      :show-pane="true"
      :shading="true"
      :close-on-outside-click="false"
      shading-color="rgba(0,0,0,0.4)"
    />
  </div>
</template>

<script>
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import { DxLoadPanel } from "devextreme-vue/load-panel";
// import notify from 'devextreme/ui/notify';
import MasterMenuView from "@/components/master-menu/MasterMenuView.vue";
// import router from "../../router";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    DxToolbar,
    DxItem,
    MasterMenuView,
    DxLoadPanel,
  },
  data() {
    return {
      loadingVisible: false,
      backButtonOptions: {
        icon: "chevronleft",
        text: "Kembali",
        onClick: () => {
          this.clearMasterMenuStatus();
          this.clearMasterMenu();
          // router.push("/master/menu");
             this.$router.go(-1);
        },
      },
    };
  },

  methods: {
    // ...mapActions("customer", ["clearCustomer", "clearStatus"]),
    ...mapActions("mastermenu", [
      "setMasterMenu",
      "clearMasterMenu",
      "setMasterMenuStatus",
      "clearMasterMenuStatus",
    ]),
  },
  computed: {
    ...mapGetters("mastermenu", [
      "getMasterMenu",
      "getMasterMenuStatus",
    ]),
  },
};
</script>

<style>
.toolbar-label,
.toolbar-label > b {
  font-size: 16px;
}

#products {
  margin-top: 10px;
}

.padding-block {
  padding-left: 20px;
  padding-right: 20px;
}
</style>
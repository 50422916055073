<template>
  <div id="DeptForm">
    <h2 class="content-block">
      {{ getMasterItemStatus == "add" ? "Tambah" : "Ubah" }}
      Master Item
    </h2>
    <DxToolbar class="padding-block">
      <DxItem
        :options="backButtonOptions"
        widget="dxButton"
        location="before"
      />
      <DxItem
        :options="saveButtonOptions"
        widget="dxButton"
        location="before"
      />
    </DxToolbar>
    <MasterItemForm />
    <span v-html="linebreaks"></span>
    <DxToolbar class="padding-block">
      <DxItem
        :options="backButtonOptions"
        widget="dxButton"
        location="before"
      />
      <DxItem
        :options="saveButtonOptions"
        widget="dxButton"
        location="before"
      />
    </DxToolbar>
    <DxLoadPanel
      :position="{ of: '#mainContent' }"
      :visible.sync="loadingVisible"
      :show-indicator="true"
      :show-pane="true"
      :shading="true"
      :close-on-outside-click="false"
      shading-color="rgba(0,0,0,0.4)"
    />
  </div>
</template>
<script>
import MasterItemForm from "@/components/master-item/MasterItemForm.vue";
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import { DxLoadPanel } from "devextreme-vue/load-panel";
import router from "../../router";
import { mapGetters, mapActions } from "vuex";
import MasterItemService from "@/services/MasterItemService";

// import { uuid } from 'vue-uuid';

export default {
  components: {
    DxToolbar,
    DxItem,
    MasterItemForm,
    DxLoadPanel,
  },
  data() {
    return {
      linebreaks: "<br>",
      loadingVisible: false,
      backButtonOptions: {
        icon: "chevronleft",
        text: "Kembali",
        onClick: () => {
          this.$router.go(-1);
        },
      },
      saveButtonOptions: {
        icon: "save",
        text: "Simpan",
        onClick: () => {
          // eslint-disable-next-line no-console
          var validateResult = this.getMasterItemForm.validate();
          if (validateResult.isValid) {
            // var MasterItem = {
            //   name : this.getMasterItem.name,
            //   shortName : this.getMasterItem.shortName,
            //   // uniqueId : uuid.v4(),
            //   description : this.getMasterItem.description
            // };
            this.userId = window.localStorage.getItem("usrid");
            var vThis = this;
            var MasterItem = this.getMasterItem;

            if (MasterItem) {
              if (this.getMasterItemStatus == "add") {
                console.log("Add MasterItem MasterItem ");
                this.loadingVisible = true;
                MasterItem.created_by = this.userId;
                MasterItemService.insert(
                  MasterItem,
                  function () {
                    vThis.loadingVisible = false;
                    vThis.clearMasterItemStatus();
                    vThis.clearMasterItem();
                    router.push("/master/item");
                  },
                  function () {
                    vThis.loadingVisible = false;
                  }
                );
              } else if (this.getMasterItemStatus == "edit") {
                console.log("Edit MasterItem MasterItem ");
                MasterItem.updated_by = this.userId;
                MasterItemService.update(
                  this.getMasterItem.id,
                  MasterItem,
                  function () {
                    vThis.loadingVisible = false;
                    vThis.clearMasterItemStatus();
                    vThis.clearMasterItem();
                    router.push("/master/item");
                  },
                  function () {
                    vThis.loadingVisible = false;
                  }
                );
              }
            }
          }
        },
      },
    };
  },
  methods: {
    ...mapActions("masteritem", [
      "setMasterItem",
      "clearMasterItem",
      "setMasterItemStatus",
      "clearMasterItemStatus",
    ]),
  },
  computed: {
    ...mapGetters("masteritem", [
      "getMasterItem",
      "getMasterItemStatus",
      "getMasterItemForm",
    ]),
  },
};
</script>

<style>
.toolbar-label,
.toolbar-label > b {
  font-size: 16px;
}

#products {
  margin-top: 10px;
}

.padding-block {
  padding-left: 20px;
  padding-right: 20px;
}
</style>
<!---JourneypView -->
<template>
  <DxForm
    class="form-block"
    :form-data="getEmployee"
    :read-only="true"
    :ref="formRef"
  >
    <DxGroupItem :col-count="2" css-class="group-padding">
      <DxGroupItem>
        <DxSimpleItem
          data-field="full_name"
          :label="{ text: 'Nama Lengkap Karyawan' }"
        />
        <DxSimpleItem data-field="address" :label="{ text: 'Alamat' }" />
        <DxSimpleItem
          data-field="province_name"
          :label="{ text: 'Provinsi' }"
        />
        <DxSimpleItem data-field="city_name" :label="{ text: 'Kota' }" />
        <DxSimpleItem
          data-field="department_name"
          :label="{ text: 'Department' }"
        />
        <DxSimpleItem
          data-field="job_position_name"
          :label="{ text: 'Jabatan' }"
        />
        <DxSimpleItem
          data-field="company_name"
          :label="{ text: 'Perusahaan' }"
        />
        <DxSimpleItem
          data-field="location_office_name"
          :label="{ text: 'Lokasi' }"
          :editor-options="{
            value:
              getEmployee.location_office_id == 1
                ? 'Pusat'
                : 'Cabang',
          }"
        />
        <DxSimpleItem
          data-field="company_branch_name"
          :label="{ text: 'Cabang' }"
        />
      </DxGroupItem>
    </DxGroupItem>
  </DxForm>
</template>
<script>
import { DxForm, DxSimpleItem, DxGroupItem } from "devextreme-vue/form";
import DxTextArea from "devextreme-vue/text-area";
import { mapGetters, mapActions } from "vuex";

const formRef = "customer-form";

export default {
  components: {
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    // eslint-disable-next-line
    DxTextArea,
  },
  data: function () {
    return {
      formRef,
    };
  },
  mounted() {
    // this.formSet();
    //kirim type_id ke JourneyViewPages.vue
    // this.$emit("typecustomer", this.getJourney.type_id);
  },
  methods: {
    ...mapActions("salestarget", ["setSalesTarget", "setSalesTargetStatus"]),
  },
  computed: {
    ...mapGetters("employee", [
      "getEmployee",
      "getEmployeeStatus",
      "getEmployeeStatus",
    ]),
  },
};
</script>
<style>
.group-padding {
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.form-block {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  padding-left: 40px;
  padding-right: 40px;
}
</style>

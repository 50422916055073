<template>
  <DxForm
    class="form-block"
    @field-data-changed="formFieldDataChanged"
    :form-data="getClientsCustomerStatus == 'edit' ? getClientsCustomer : null"
    :ref="formRef"
    :col-count="2"
  >
    <DxGroupItem>
      <DxSimpleItem
        data-field="customer_id"
        :label="{ text: 'Select Customer' }"
      >
        <template #default>
          <DxSelectBox
            :data-source="GetCustomer"
            display-expr="name"
            value-expr="id"
            placeholder="Select customer"
            @value-changed="valueChangedCustomer"
            :search-enabled="true"
          >
          </DxSelectBox>
        </template>
      </DxSimpleItem>

      <DxSimpleItem
        data-field="warehouse_id"
        :label="{ text: 'Select Warehouse' }"
      >
        <template #default>
          <DxSelectBox
            :data-source="GetWarehouse"
            display-expr="name"
            value-expr="id"
            placeholder="Select Warehouse"
            @value-changed="valueChangedWarehouse"
            :search-enabled="true"
          >
          </DxSelectBox>
        </template>
      </DxSimpleItem>

      <DxSimpleItem data-field="code" :label="{ text: 'ClientsCustomer Code' }">
        <DxRequiredRule message="ClientsCustomer Code Wajib di Isi" />
      </DxSimpleItem>

      <DxSimpleItem data-field="name" :label="{ text: 'ClientsCustomer Name' }">
        <DxRequiredRule message="ClientsCustomer Name Wajib di Isi" />
      </DxSimpleItem>

      <DxSimpleItem
        data-field="phone"
        :label="{ text: 'ClientsCustomer Phone' }"
      >
        <DxRequiredRule message="ClientsCustomer Phone Wajib di Isi" />
      </DxSimpleItem>
    </DxGroupItem>

    <DxGroupItem>
      <DxSimpleItem
        data-field="address"
        editor-type="dxTextArea"
        :editor-options="{ height: 90 }"
        :label="{ text: 'ClientsCustomer Address' }"
      />

      <DxSimpleItem
        data-field="desc"
        editor-type="dxTextArea"
        :editor-options="{ height: 90 }"
        :label="{ text: 'ClientsCustomer Desc' }"
      />
    </DxGroupItem>
  </DxForm>
</template>
<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from "devextreme-vue/form";
import DxSelectBox from "devextreme-vue/select-box";
// import DxTextArea from "devextreme-vue/text-area";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import moment from "moment";
const formRef = "clientscustomer-form";

export default {
  components: {
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    DxSelectBox,
    DxRequiredRule,
  },
  data: function () {
    return {
      formRef,
      GetCustomer: [],
      GetWarehouse: [],
    };
  },
  created() {
    axios
      .get( "customers")
      .then((response) => {
        //console.log(JSON.stringify(response.data));
        this.GetCustomer = response.data.data;
      });
    axios
      .get( "warehouses")
      .then((response) => {
        //console.log(JSON.stringify(response.data));
        this.GetWarehouse = response.data.data;
      });
  },
  mounted() {
    this.formSet();
  },
  methods: {
    ...mapActions("clientscustomer", [
      "setClientsCustomer",
      "clearClientsCustomer",
      "setClientsCustomerStatus",
      "clearClientsCustomerStatus",
      "setClientsCustomerForm",
    ]),
    valueChangedCustomer(e) {
      this.$refs[formRef].instance.updateData({
        customer_id: e.value,
      });
    },
    valueChangedWarehouse(e) {
      this.$refs[formRef].instance.updateData({
        warehouse_id: e.value,
      });
    },
    formFieldDataChanged(e) {
      this.setClientsCustomer(e.component.option("formData"));
    },
    formSet() {
      this.setClientsCustomerForm(this.$refs[formRef].instance);
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD HH:mm:ss");
      }
    },
  },

  computed: {
    ...mapGetters("clientscustomer", [
      "getClientsCustomer",
      "getClientsCustomerStatus",
      "getClientsCustomerForm",
    ]),
  },
};
</script>
<style>
.group-padding {
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.form-block {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  padding-left: 40px;
  padding-right: 40px;
}
</style>

<template>
  <DxForm
    class="form-block"
    @field-data-changed="formFieldDataChanged"
    :form-data="getStockDestroyStatus == 'edit' ? getStockDestroy : null"
    :ref="formRef"
    :col-count="2"
  >
    <DxGroupItem>
      <DxSimpleItem
        data-field="user_id"
        :visible="false"
        :editor-options="{ value: userId }"
      />
      <DxSimpleItem
        data-field="delivery_date"
        :label="{ text: 'Tanggal Pengiriman' }"
        editor-type="dxDateBox"
        :editor-options="{ dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ss' }"
      >
      </DxSimpleItem>

      <DxSimpleItem
        data-field="invoice_number"
        :label="{ text: 'invoice Number' }"
      >
        <DxRequiredRule message="invoice Number Wajib di Isi" />
      </DxSimpleItem>

      <DxSimpleItem
        data-field="origin_id"
        :label="{ text: 'Pilih Asal Pengiriman' }"
      >
        <template #default>
          <DxSelectBox
            :data-source="GetWarehouseDest"
            display-expr="name"
            value-expr="id"
            placeholder="Select Origin Warehouse"
            @value-changed="valueChangedWarehouse"
            :search-enabled="true"
            :value="
              getStockDestroyStatus == 'edit' ? getStockDestroy.origin_id : null
            "
          >
          </DxSelectBox>
        </template>
      </DxSimpleItem>
    </DxGroupItem>
    <DxGroupItem>
      <DxSimpleItem
        data-field="destination_type_id"
        :label="{ text: 'Pilih Tipe Tujuan' }"
      >
        <template #default>
          <DxSelectBox
            :data-source="GetDestinationType"
            display-expr="source_type_name"
            value-expr="id"
            placeholder="Select Destination Type"
            @value-changed="valueChangedDestinationType"
            :search-enabled="true"
            :value="
              getStockDestroyStatus == 'edit'
                ? getStockDestroy.destination_type_id
                : null
            "
          >
          </DxSelectBox>
        </template>
      </DxSimpleItem>

      <DxSimpleItem
        data-field="destination_id"
        :label="{ text: 'Pilih Tujuan Pengiriman' }"
      >
        <template #default>
          <DxSelectBox
            :data-source="GetDestination"
            display-expr="name"
            value-expr="id"
            placeholder="Select Destination"
            @value-changed="valueChangedDestination"
            :search-enabled="true"
            :value="
              getStockDestroyStatus == 'edit'
                ? getStockDestroy.destination_id
                : null
            "
          >
          </DxSelectBox>
        </template>
      </DxSimpleItem>

      <DxSimpleItem data-field="remark" :label="{ text: 'Catatan' }">
      </DxSimpleItem>
    </DxGroupItem>
  </DxForm>
</template>
<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from "devextreme-vue/form";
import DxTextArea from "devextreme-vue/text-area";
import { mapGetters, mapActions } from "vuex";
import DxSelectBox from "devextreme-vue/select-box";
import axios from "axios";
const formRef = "stockdestroy-form";

export default {
  components: {
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    // eslint-disable-next-line
    DxTextArea,
    DxRequiredRule,
    DxSelectBox,
  },
  data: function () {
    return {
      userId: null,
      formRef,
      GetDestinationType: [],
      GetDestination: [],
      GetWarehouse: [],
      GetOrigin: [],
      GetWarehouseDest: [],
    };
  },
  created() {
    this.userId = window.localStorage.getItem("usrid");
    axios
      .get( "source_types")
      .then((response) => {
        this.GetDestinationType = response.data.data;
      });
    axios
      .get( "warehouses")
      .then((response) => {
        //console.log(JSON.stringify(response.data));
        this.GetWarehouseDest = response.data.data;
      });
    if (this.getStockDestroy) {
      switch (this.getStockDestroy.destination_type_id) {
        case 1:
          axios
            .get( "warehouses")
            .then((response) => {
              this.GetDestination = response.data.data;
            });
          break;
        case 2:
          axios
            .get( "customers")
            .then((response) => {
              this.GetDestination = response.data.data;
            });
          break;
        case 3:
          axios
            .get( "clients_customers")
            .then((response) => {
              this.GetDestination = response.data.data;
            });
          break;
        default:
          axios
            .get( "warehouses")
            .then((response) => {
              this.GetDestination = response.data.data;
            });
      }
    }
  },
  mounted() {
    this.formSet();
  },
  methods: {
    ...mapActions("stockdestroy", [
      "setStockDestroy",
      "clearStockDestroy",
      "setStockDestroyStatus",
      "clearStockDestroyStatus",
      "setStockDestroyForm",
    ]),
    valueChangedDestinationType(e) {
      this.$refs[formRef].instance.updateData({
        destination_type_id: e.value,
      });
      switch (e.value) {
        case 1:
          axios
            .get( "warehouses")
            .then((response) => {
              this.GetDestination = response.data.data;
            });
          break;
        case 2:
          axios
            .get( "customers")
            .then((response) => {
              this.GetDestination = response.data.data;
            });
          break;
        case 3:
          axios
            .get( "clients_customers")
            .then((response) => {
              this.GetDestination = response.data.data;
            });
          break;
        default:
          axios
            .get( "warehouses")
            .then((response) => {
              this.GetDestination = response.data.data;
            });
      }
    },

    valueChangedWarehouse(e) {
      this.$refs[formRef].instance.updateData({
        origin_id: e.value,
      });
    },
    valueChangedOrigin(e) {
      this.$refs[formRef].instance.updateData({
        origin_id: e.value,
      });
    },
    valueChangedDestination(e) {
      this.$refs[formRef].instance.updateData({
        destination_id: e.value,
      });
    },
    formFieldDataChanged(e) {
      this.setStockDestroy(e.component.option("formData"));
    },
    formSet() {
      this.setStockDestroyForm(this.$refs[formRef].instance);
    },
  },

  computed: {
    ...mapGetters("stockdestroy", [
      "getStockDestroy",
      "getStockDestroyStatus",
      "getStockDestroyForm",
    ]),
  },
};
</script>
<style>
.group-padding {
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.form-block {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  padding-left: 40px;
  padding-right: 40px;
}
</style>

const state = {
  company: null,
  companyStatus: null,
  companyForm: null,
};

const getters = {
  getCompany: (state) => {
    return state.company;
  },
  getCompanyStatus: (state) => {
    return state.companyStatus;
  },
  getCompanyForm: (state) => {
    return state.companyForm;
  },
};

const mutations = {
  setCompany: (state, company) => {
    state.company = company;
  },
  clearCompany: (state) => {
    state.company = null;
  },

  setCompanyStatus: (state, status) => {
    state.companyStatus = status;
  },
  clearCompanyStatus: (state) => {
    state.companyStatus = "";
  },

  setCompanyForm: (state, status) => {
    state.companyForm = status;
  },
  clearCompanyForm: (state) => {
    state.companyForm = "";
  },
};

const actions = {
  setCompany({ commit }, data) {
    commit("setCompany", data);
  },
  clearCompany({ commit }) {
    commit("clearCompany");
  },

  setCompanyStatus({ commit }, status) {
    commit("setCompanyStatus", status);
  },
  clearCompanyStatus({ commit }) {
    commit("clearCompanyStatus");
  },

  setCompanyForm({ commit }, status) {
    commit("setCompanyForm", status);
  },
  clearCompanyForms({ commit }) {
    commit("clearCompanyForm");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div>
    <h2 class="content-block">Stock Destroy</h2>
    <stock-destroy-table />
  </div>
</template>

<script>
import StockDestroyTable from "@/components/stock-destroy/StockDestroyTable.vue";
export default {
  components: {
    StockDestroyTable,
  },
};
</script>
<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <h2>
        Selamat Datang <strong>{{ this.username }}</strong>
      </h2>
    </div>
  </div>
</template>

<script>
// import { mapActions, mapGetters } from "vuex";
// import axios from "axios";

export default {
  data() {
    return {
      loggedIn: localStorage.getItem("loggedIn"),
      // token: localStorage.getItem("token"),
      username: localStorage.getItem("username"),
      useremail: localStorage.getItem("useremail"),
    };
  },

  created() {
    // var vthis = this;
    // axios
    //   .get("user", {
    //     headers: { Authorization: "Bearer " + this.token },
    //   })
    //   .then((response) => {
    //     console.log(response);
    //     this.user = response.data; // assign response to state user
    //     console.log("Client setUser : " + JSON.stringify(response.data));
    //     vthis.setUserLogin = response.data;
    //     // console.log("getUserLogin : " + response.data.user_id);
    //     localStorage.setItem("useremail", response.data.email);
    //     localStorage.setItem("roleid", response.data.role_id);
    //     localStorage.setItem("usrid", response.data.id);
    //   });
  },

  methods: {
    // ...mapActions("userlogin", ["setUserLogin", "setUserLoginStatus"]),
    // logout() {
    //   axios.get("logout").then(() => {
    //     //remove localStorage
    //     localStorage.removeItem("loggedIn");
    //     //redirect
    //     return this.$router.push({ name: "login" });
    //   });
    // },
  },

  //check user logged in or not
  mounted() {
    if (!this.loggedIn) {
      return this.$router.push({ name: "login" });
    }
  },
  computed: {
    // ...mapGetters("userlogin", ["getUserLogin", "getUserLoginStatus"]),
  },
};
</script>